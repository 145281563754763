import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import PatientFragment from '../../fragments/PatientFragment';
import useSnackbar from '../System/useSnackbar';

const UPDATE_LOG = gql`
  mutation UpdatePatientLog(
    $userGroupId: String!
    $patientId: String!
    $logId: String!
    $text: String!
  ) {
    updatePatientLog(
      userGroupId: $userGroupId
      patientId: $patientId
      logId: $logId
      text: $text
    ) {
      ...PatientFragment
    }
  }

  ${PatientFragment}
`;

const useUpdateLog = ({ logId, initialText = '', onUpdated = () => {} }) => {
  const { userGroupId, patientId } = useParams();
  const { showSnackbar } = useSnackbar();

  const [text, setText] = useState(initialText);

  const [updateLog, { loading }] = useMutation(UPDATE_LOG, {
    variables: {
      userGroupId,
      patientId,
      logId,
      text,
    },
    onCompleted() {
      showSnackbar('Commentaire mis à jour');
      onUpdated();
    },
  });

  return {
    loading,
    text,
    onTextChange: (event) => setText(event.target.value),
    onUpdate: updateLog,
  };
};

export default useUpdateLog;
