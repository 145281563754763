import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import PatientFragment from '../../fragments/PatientFragment';
import useSnackbar from '../System/useSnackbar';

const DELETE_LOG = gql`
  mutation DeletePatientLog(
    $userGroupId: String!
    $patientId: String!
    $logId: String!
  ) {
    deletePatientLog(
      userGroupId: $userGroupId
      patientId: $patientId
      logId: $logId
    ) {
      ...PatientFragment
    }
  }

  ${PatientFragment}
`;

const useDeleteLog = ({ logId }) => {
  const { userGroupId, patientId } = useParams();
  const { showSnackbar } = useSnackbar();

  const [deleteLog, { loading }] = useMutation(DELETE_LOG, {
    variables: {
      userGroupId,
      patientId,
      logId,
    },
    onCompleted() {
      showSnackbar('Commentaire supprimé');
    },
  });

  return {
    loading,
    onDelete: deleteLog,
  };
};

export default useDeleteLog;
