import React from 'react';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  DialogContentText,
} from '@material-ui/core';
import moment from 'moment';

import ConfirmDialog from '../System/ConfirmDialog';
import useDialog from '../System/useDialog';
import useTours from '../Tours/useTours';
import useDeleteOutsourcedTreatment from './useDeleteOutsourcedTreatment';

const OutsourcedTreatment = ({ treatmentId, outsourcedTreatment }) => {
  const { tours, loading } = useTours();
  const { loading: deleteLoading, onDelete } = useDeleteOutsourcedTreatment({
    treatmentId,
    outsourcedTreatmentId: outsourcedTreatment.id,
  });
  const { open, onOpen, onClose } = useDialog();

  return loading ? null : (
    <>
      <ListItem>
        <ListItemText
          primary={tours.find((t) => t.id === outsourcedTreatment.tourId)?.name}
          secondary={`Du ${moment(Number(outsourcedTreatment.from)).format(
            'DD/MM/YYYY',
          )} au ${moment(Number(outsourcedTreatment.to)).format('DD/MM/YYYY')}`}
        />

        <ListItemSecondaryAction>
          <Button size="small" variant="outlined" onClick={onOpen}>
            Annuler...
          </Button>
        </ListItemSecondaryAction>
      </ListItem>

      <ConfirmDialog
        title="Annuler le changement de tournée"
        open={open}
        confirmLoading={deleteLoading}
        onConfirm={onDelete}
        onClose={onClose}
      >
        <DialogContentText>
          Cette action annulera le changement de tournée temporaire.
        </DialogContentText>

        <DialogContentText>
          <strong>Attention</strong>, toutes les transmissions passées ne seront
          pas modifiées.
        </DialogContentText>
      </ConfirmDialog>
    </>
  );
};

export default OutsourcedTreatment;
