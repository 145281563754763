import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service
    console.log(error);
    console.log(errorInfo);
  }

  render() {
    const { history, match } = this.props;
    const { userGroupId } = match.params;

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <Typography>Une erreur s'est produite.</Typography>
          <div>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                history.replace(`/g/${userGroupId}/tours`);
                this.setState({ hasError: false });
              }}
            >
              Fermer
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
