import { gql, useMutation } from '@apollo/client';

import AbsenceFragment from '../../fragments/AbsenceFragment';
import { useParams } from 'react-router-dom';

const MARK_ABSENCE_PROCESSED = gql`
  mutation MarkAbsenceProcessed($userGroupId: String!, $absenceId: String!) {
    markAbsenceProcessed(userGroupId: $userGroupId, absenceId: $absenceId) {
      ...AbsenceFragment
    }
  }

  ${AbsenceFragment}
`;

const useMarkAbsenceProcessed = () => {
  const { userGroupId, absenceId, absenceStartDate } = useParams();

  const [markAbsenceProcessed, { loading }] = useMutation(
    MARK_ABSENCE_PROCESSED,
    {
      variables: {
        userGroupId,
        absenceId: `${absenceStartDate}#${absenceId}`,
      },
    },
  );

  return {
    onMarkAbsenceProcessed: markAbsenceProcessed,
    loading,
  };
};

export default useMarkAbsenceProcessed;
