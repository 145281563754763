import { useState } from 'react';

import useCreateCareCheckup from './useCreateCareCheckup';
import useUpload from '../../utils/useUpload';
import useUpdateCareCheckup from './useUpdateCareCheckup';

const useCareCheckupForm = ({
  initialCareCheckup = {},
  type,
  onSubmitted,
  checkupType,
}) => {
  // General inputs
  const data =
    checkupType === 'bsi'
      ? {
          environnementhumain: initialCareCheckup['environnementhumain'] || '1',
          poidskg: initialCareCheckup['poidskg'] || '0',
          taillem: initialCareCheckup['taillem'] || '0',
          forfait: initialCareCheckup['forfait'] || '',
          imc: initialCareCheckup['imc'] || '0',
          segaage: initialCareCheckup['segaage'] || '1',
          provenance: initialCareCheckup['provenance'] || '1',
          medicaments: initialCareCheckup['medicaments'] || '1',
          humeur: initialCareCheckup['humeur'] || '1',
          perceptiondesante: initialCareCheckup['perceptiondesante'] || '1',
          chutesds6derniersmois:
            initialCareCheckup['chutesds6derniersmois'] || '1',
          nutrition: initialCareCheckup['nutrition'] || '1',
          maladiesassociees: initialCareCheckup['maladiesassociees'] || '1',
          aivq: initialCareCheckup['aivq'] || '1',
          mobilite: initialCareCheckup['mobilite'] || '1',
          continenceurinaireetoufecal920761:
            initialCareCheckup['continenceurinaireetoufecal920761'] || '1',
          prisedesrepas: initialCareCheckup['prisedesrepas'] || '1',
          fonctionscognitives: initialCareCheckup['fonctionscognitives'] || '1',
          hospitalisationaucoursdes6579351:
            initialCareCheckup['hospitalisationaucoursdes6579351'] || '1',
          vision: initialCareCheckup['vision'] || '1',
          audition: initialCareCheckup['audition'] || '1',
          supportsocialentourage:
            initialCareCheckup['supportsocialentourage'] || '1',
          aideadomicileprofessionnell912751:
            initialCareCheckup['aideadomicileprofessionnell912751'] || '1',
          aidantnaturel: initialCareCheckup['aidantnaturel'] || '1',
          perceptiondelachargeparle953731:
            initialCareCheckup['perceptiondelachargeparle953731'] || '1',
          habitat: initialCareCheckup['habitat'] || '1',
          situationfinanciere: initialCareCheckup['situationfinanciere'] || '1',
          perpectivesdavenirselonlap40541:
            initialCareCheckup['perpectivesdavenirselonlap40541'] || '1',
          perpectivesdavenirselonlent650271:
            initialCareCheckup['perpectivesdavenirselonlent650271'] || '1',
          diagnosticsinfirmiersaliment415641:
            initialCareCheckup['diagnosticsinfirmiersaliment415641'] || '1',
          precisionsdiagnoticsalimenta680191:
            initialCareCheckup['precisionsdiagnoticsalimenta680191'] || '',
          testmnalepatientatilmoin719751:
            initialCareCheckup['testmnalepatientatilmoin719751'] || '1',
          perterecentedepoids3mois:
            initialCareCheckup['perterecentedepoids3mois'] || '1',
          motricite: initialCareCheckup['motricite'] || '1',
          maladieaigueoustresspsycho903311:
            initialCareCheckup['maladieaigueoustresspsycho903311'] || false,
          problemesneurologiques:
            initialCareCheckup['problemesneurologiques'] || '1',
          imc39: initialCareCheckup['imc39'] || '1',
          circonferencedumolletencm:
            initialCareCheckup['circonferencedumolletencm'] || '1',
          interventionsinfirmieresalim626481:
            initialCareCheckup['interventionsinfirmieresalim626481'] || [],
          observationscomplementairesd962741:
            initialCareCheckup['observationscomplementairesd962741'] || '',
          pressionarteriellesystolique908731:
            initialCareCheckup['pressionarteriellesystolique908731'] || '0',
          pressionarteriellediastoliqu945421:
            initialCareCheckup['pressionarteriellediastoliqu945421'] || '0',
          frequencecardiaqueparmn:
            initialCareCheckup['frequencecardiaqueparmn'] || '0',
          frequencecardiaquenonmesura873931:
            initialCareCheckup['frequencecardiaquenonmesura873931'] ===
            undefined
              ? true
              : initialCareCheckup['frequencecardiaquenonmesura873931'],
          frequencerespiratoireparmn:
            initialCareCheckup['frequencerespiratoireparmn'] || '0',
          diaginfirespietcardiotrou330941:
            initialCareCheckup['diaginfirespietcardiotrou330941'] || false,
          diaginfirespietcardiooede840971:
            initialCareCheckup['diaginfirespietcardiooede840971'] || false,
          diaginfirespietcardiodysp330241:
            initialCareCheckup['diaginfirespietcardiodysp330241'] || false,
          diaginfirespietcardioautr332651:
            initialCareCheckup['diaginfirespietcardioautr332651'] || false,
          interventionsinfirmierescard931121:
            initialCareCheckup['interventionsinfirmierescard931121'] || '1',
          surveillancecliniquefonction628161:
            initialCareCheckup['surveillancecliniquefonction628161'] || false,
          posedeposedecompressionvas880451:
            initialCareCheckup['posedeposedecompressionvas880451'] || false,
          observationscomplementairesd555111:
            initialCareCheckup['observationscomplementairesd555111'] || '',
          diagnosticsinfirmierselimina653501:
            initialCareCheckup['diagnosticsinfirmierselimina653501'] || [],
          diagnosticsinfirmierselimina864971:
            initialCareCheckup['diagnosticsinfirmierselimina864971'] || '',
          eliminationinterventionsinfi990881:
            initialCareCheckup['eliminationinterventionsinfi990881'] || [],
          planificationdessoinsperso347551:
            initialCareCheckup['planificationdessoinsperso347551'] || false,
          eliminationinterventionsinfi214901:
            initialCareCheckup['eliminationinterventionsinfi214901'] || false,
          observationscomplementairesd801501:
            initialCareCheckup['observationscomplementairesd801501'] || '1',
          diagnosticsinfirmiershygiene32921:
            initialCareCheckup['diagnosticsinfirmiershygiene32921'] || [],
          diagnosticsinfirmiershygiene707201:
            initialCareCheckup['diagnosticsinfirmiershygiene707201'] || '',
          hygienetestdevaluationrisq886031:
            initialCareCheckup['hygienetestdevaluationrisq886031'] || '1',
          hygienetestdevaluationrisq724871:
            initialCareCheckup['hygienetestdevaluationrisq724871'] || '1',
          hygienetestdevaluationrisq483801:
            initialCareCheckup['hygienetestdevaluationrisq483801'] || '1',
          hygienetestdevaluationrisq406311:
            initialCareCheckup['hygienetestdevaluationrisq406311'] || '1',
          hygienetestdevaluationrisq964411:
            initialCareCheckup['hygienetestdevaluationrisq964411'] || '1',
          hygienetestdevaluationrisq979521:
            initialCareCheckup['hygienetestdevaluationrisq979521'] || '1',
          hygienetestdevaluationrisq941951:
            initialCareCheckup['hygienetestdevaluationrisq941951'] || '1',
          hygienetestdevaluationrisq56981:
            initialCareCheckup['hygienetestdevaluationrisq56981'] || '1',
          hygienetestdevaluationrisq362901:
            initialCareCheckup['hygienetestdevaluationrisq362901'] || '1',
          hygienetestdevaluationrisq734191:
            initialCareCheckup['hygienetestdevaluationrisq734191'] || '1',
          hygienetestdevaluationrisq765861:
            initialCareCheckup['hygienetestdevaluationrisq765861'] || '1',
          hygieneinterventionsinfirmie328911:
            initialCareCheckup['hygieneinterventionsinfirmie328911'] || [],
          observationscomplementairesd278571:
            initialCareCheckup['observationscomplementairesd278571'] || '',
          diagnosticsinfirmiersmobilit878901:
            initialCareCheckup['diagnosticsinfirmiersmobilit878901'] || [],
          precisionsdiagnoticsmobilite329291:
            initialCareCheckup['precisionsdiagnoticsmobilite329291'] || '',
          seleverdunfauteuilavecacc23891:
            initialCareCheckup['seleverdunfauteuilavecacc23891'] || '1',
          traverserlapiecedistance3411121:
            initialCareCheckup['traverserlapiecedistance3411121'] || '1',
          fairedemitour: initialCareCheckup['fairedemitour'] || '1',
          revenirsasseoir: initialCareCheckup['revenirsasseoir'] || '1',
          dureenecessaire: initialCareCheckup['dureenecessaire'] || '0',
          interpretationscore: initialCareCheckup['interpretationscore'] || '',
          teststationunipodalepiedd:
            initialCareCheckup['teststationunipodalepiedd'] || '1',
          teststationunipodalepiedg:
            initialCareCheckup['teststationunipodalepiedg'] || '1',
          intervinfirmieremobilite:
            initialCareCheckup['intervinfirmieremobilite'] || [],
          intervinfirmieremobilitebi660131:
            initialCareCheckup['intervinfirmieremobilitebi660131'] || '1',
          observationscomplementairesd10461:
            initialCareCheckup['observationscomplementairesd10461'] || '',
          diagnosticsinfirmierstraitem889831:
            initialCareCheckup['diagnosticsinfirmierstraitem889831'] || [],
          diagnosticsinfirmierstraitem45831:
            initialCareCheckup['diagnosticsinfirmierstraitem45831'] || '',
          testdevaluationdeladouleur260241:
            initialCareCheckup['testdevaluationdeladouleur260241'] || '0',
          testdevaluationdeladouleur120591:
            initialCareCheckup['testdevaluationdeladouleur120591'] || '0',
          testdoloplusplaintessomatiq232711:
            initialCareCheckup['testdoloplusplaintessomatiq232711'] || '1',
          testdolopluspositionsantalg648021:
            initialCareCheckup['testdolopluspositionsantalg648021'] || '1',
          testdoloplusprotectiondez853931:
            initialCareCheckup['testdoloplusprotectiondez853931'] || '1',
          testdoloplusmimique: initialCareCheckup['testdoloplusmimique'] || '1',
          testdoloplussommeil: initialCareCheckup['testdoloplussommeil'] || '1',
          testdoloplustoiletteetouha224021:
            initialCareCheckup['testdoloplustoiletteetouha224021'] || '1',
          testdoloplusmouvements:
            initialCareCheckup['testdoloplusmouvements'] || '1',
          testdolopluscommunication:
            initialCareCheckup['testdolopluscommunication'] || '1',
          testdoloplusviesociale:
            initialCareCheckup['testdoloplusviesociale'] || '1',
          testdoloplustroublesducomp56071:
            initialCareCheckup['testdoloplustroublesducomp56071'] || '1',
          testdn4douleurscaracteristi610261:
            initialCareCheckup['testdn4douleurscaracteristi610261'] || false,
          testdn4douleurscaracteristi690951:
            initialCareCheckup['testdn4douleurscaracteristi690951'] || false,
          testdn4douleurscaracteristi180841:
            initialCareCheckup['testdn4douleurscaracteristi180841'] || false,
          testdn4douleurassocieedsm100621:
            initialCareCheckup['testdn4douleurassocieedsm100621'] || false,
          testdn4douleurassocieedsm485311:
            initialCareCheckup['testdn4douleurassocieedsm485311'] || false,
          testdn4douleurassocieedsm97351:
            initialCareCheckup['testdn4douleurassocieedsm97351'] || false,
          testdn4douleurassocieedsm745641:
            initialCareCheckup['testdn4douleurassocieedsm745641'] || false,
          testdn4douleurlocaliseehyp364731:
            initialCareCheckup['testdn4douleurlocaliseehyp364731'] || false,
          testdn4douleurlocaliseehyp181581:
            initialCareCheckup['testdn4douleurlocaliseehyp181581'] || false,
          testdn4douleurprovoqueepar41051:
            initialCareCheckup['testdn4douleurprovoqueepar41051'] || false,
          interventionsinfirmieresdoul689941:
            initialCareCheckup['interventionsinfirmieresdoul689941'] || [],
          observationcomplementairespo113741:
            initialCareCheckup['observationcomplementairespo113741'] || '',
          interventionsinfirmieressoin157741:
            initialCareCheckup['interventionsinfirmieressoin157741'] || [],
          observationscomplementairesp226621:
            initialCareCheckup['observationscomplementairesp226621'] || '',
          interventionsinfirmieressoin780331:
            initialCareCheckup['interventionsinfirmieressoin780331'] || false,
          interventionsinfirmieressoin469011:
            initialCareCheckup['interventionsinfirmieressoin469011'] || [],
          interventionsinfirmieressoin780101:
            initialCareCheckup['interventionsinfirmieressoin780101'] || '',
          interventionsinfirmieressoin184071:
            initialCareCheckup['interventionsinfirmieressoin184071'] || false,
          interventionsinfirmieressoin307381:
            initialCareCheckup['interventionsinfirmieressoin307381'] || [],
          interventionsinfirmieressoin751801:
            initialCareCheckup['interventionsinfirmieressoin751801'] || '',
          observationcomplementairespo416171:
            initialCareCheckup['observationcomplementairespo416171'] || '',
          criteresdemajorationliesau380611:
            initialCareCheckup['criteresdemajorationliesau380611'] || [],
          criteresdemajorationliesa420071:
            initialCareCheckup['criteresdemajorationliesa420071'] || [],
          observationcomplementairespo556321:
            initialCareCheckup['observationcomplementairespo556321'] || '',
          n1prelevementparponctionvei77561:
            initialCareCheckup['n1prelevementparponctionvei77561'] || '0',
          n1prelevementparponctionvei16591:
            initialCareCheckup['n1prelevementparponctionvei16591'] || '0',
          n2prelevementcutaneoudesec944441:
            initialCareCheckup['n2prelevementcutaneoudesec944441'] || '0',
          n2prelevementcutaneoudesec955381:
            initialCareCheckup['n2prelevementcutaneoudesec955381'] || '0',
          n3saigneenbredactesparmois542781:
            initialCareCheckup['n3saigneenbredactesparmois542781'] || '0',
          n3saigneedureeprevisiblede620771:
            initialCareCheckup['n3saigneedureeprevisiblede620771'] || '0',
          n4injectionintramusculaireso218181:
            initialCareCheckup['n4injectionintramusculaireso218181'] || '0',
          n4injectionintramusculaireso977511:
            initialCareCheckup['n4injectionintramusculaireso977511'] || '0',
          n5injectionintraveineusedire572361:
            initialCareCheckup['n5injectionintraveineusedire572361'] || '0',
          n5injectionintraveineusedire504891:
            initialCareCheckup['n5injectionintraveineusedire504891'] || '0',
          n6pansementcourantnbredac925731:
            initialCareCheckup['n6pansementcourantnbredac925731'] || '0',
          n6pansementcourantdureepr176721:
            initialCareCheckup['n6pansementcourantdureepr176721'] || '0',
          n7pansementlourdetcomplexe832891:
            initialCareCheckup['n7pansementlourdetcomplexe832891'] || '0',
          n7pansementlourdetcomplexe237111:
            initialCareCheckup['n7pansementlourdetcomplexe237111'] || '0',
          n8pansementlourdetcomplexe34821:
            initialCareCheckup['n8pansementlourdetcomplexe34821'] || '0',
          n8pansementlourdetcomplexe114951:
            initialCareCheckup['n8pansementlourdetcomplexe114951'] || '0',
          n9pansementlourdetcomplexe120121:
            initialCareCheckup['n9pansementlourdetcomplexe120121'] || '0',
          n9pansementlourdetcomplexe333601:
            initialCareCheckup['n9pansementlourdetcomplexe333601'] || '0',
          n10soinsdestomiesycpansem501041:
            initialCareCheckup['n10soinsdestomiesycpansem501041'] || '0',
          n10soinsdestomiesycpansem404151:
            initialCareCheckup['n10soinsdestomiesycpansem404151'] || '0',
          n11aerosolmedicamenteuxnbr866851:
            initialCareCheckup['n11aerosolmedicamenteuxnbr866851'] || '0',
          n11aerosolmedicamenteuxdur394511:
            initialCareCheckup['n11aerosolmedicamenteuxdur394511'] || '0',
          n12lavagedunsinusnbredac899871:
            initialCareCheckup['n12lavagedunsinusnbredac899871'] || '0',
          n12lavagedunsinusdureepr169981:
            initialCareCheckup['n12lavagedunsinusdureepr169981'] || '0',
          n13pansementdetracheotomiey407651:
            initialCareCheckup['n13pansementdetracheotomiey407651'] || '0',
          n13pansementdetracheotomiey49251:
            initialCareCheckup['n13pansementdetracheotomiey49251'] || '0',
          n14injectionvaginalenbred447571:
            initialCareCheckup['n14injectionvaginalenbred447571'] || '0',
          n14injectionvaginaleduree468511:
            initialCareCheckup['n14injectionvaginaleduree468511'] || '0',
          n15posechangementetuipenien761161:
            initialCareCheckup['n15posechangementetuipenien761161'] || '0',
          n15posechangementetuipenien693971:
            initialCareCheckup['n15posechangementetuipenien693971'] || '0',
          n16sondageurinaireposechang937791:
            initialCareCheckup['n16sondageurinaireposechang937791'] || '0',
          n16sondageurinaireposechang576821:
            initialCareCheckup['n16sondageurinaireposechang576821'] || '0',
          n17instillationetoulavageve356011:
            initialCareCheckup['n17instillationetoulavageve356011'] || '0',
          n17instillationetoulavageve63651:
            initialCareCheckup['n17instillationetoulavageve63651'] || '0',
          n18soinsdeboucheavecapplic147321:
            initialCareCheckup['n18soinsdeboucheavecapplic147321'] || '0',
          n18soinsdeboucheavecapplic246031:
            initialCareCheckup['n18soinsdeboucheavecapplic246031'] || '0',
          n19sondegastriqueposesurvei628751:
            initialCareCheckup['n19sondegastriqueposesurvei628751'] || '0',
          n19sondegastriqueposesurvei457361:
            initialCareCheckup['n19sondegastriqueposesurvei457361'] || '0',
          n20seancedalimentationentera610461:
            initialCareCheckup['n20seancedalimentationentera610461'] || '0',
          n20seancedalimentationentera680401:
            initialCareCheckup['n20seancedalimentationentera680401'] || '0',
          n21lavementevacuateurnbre454241:
            initialCareCheckup['n21lavementevacuateurnbre454241'] || '0',
          n21lavementevacuateurduree635541:
            initialCareCheckup['n21lavementevacuateurduree635541'] || '0',
          n22extractiondefecalomeoum713871:
            initialCareCheckup['n22extractiondefecalomeoum713871'] || '0',
          n22extractiondefecalomeoum522631:
            initialCareCheckup['n22extractiondefecalomeoum522631'] || '0',
          n23irrigationcoliquecontinue337981:
            initialCareCheckup['n23irrigationcoliquecontinue337981'] || '0',
          n23irrigationcoliquecontinue100701:
            initialCareCheckup['n23irrigationcoliquecontinue100701'] || '0',
          n24soinsdentretienduncathet541821:
            initialCareCheckup['n24soinsdentretienduncathet541821'] || '0',
          n24soinsdentretienduncathet209201:
            initialCareCheckup['n24soinsdentretienduncathet209201'] || '0',
          n25soinsdentretienduncathet445191:
            initialCareCheckup['n25soinsdentretienduncathet445191'] || '0',
          n25soinsdentretienduncathet830571:
            initialCareCheckup['n25soinsdentretienduncathet830571'] || '0',
          n26seancedeperfusion1heur529641:
            initialCareCheckup['n26seancedeperfusion1heur529641'] || '0',
          n26seancedeperfusion1heur164461:
            initialCareCheckup['n26seancedeperfusion1heur164461'] || '0',
          n27seancedeperfusion1heur207771:
            initialCareCheckup['n27seancedeperfusion1heur207771'] || '0',
          n27seancedeperfusion1heur189651:
            initialCareCheckup['n27seancedeperfusion1heur189651'] || '0',
          n28surveillanceobservationave483411:
            initialCareCheckup['n28surveillanceobservationave483411'] || '0',
          n28surveillanceobservationave708361:
            initialCareCheckup['n28surveillanceobservationave708361'] || '0',
          n29organisationsurveillanceb782991:
            initialCareCheckup['n29organisationsurveillanceb782991'] || '0',
          n29organisationsurveillanceb857511:
            initialCareCheckup['n29organisationsurveillanceb857511'] || '0',
          techCare: initialCareCheckup.techCare,
        }
      : checkupType === 'bsi2'
      ? {
          environnementhumain: initialCareCheckup['environnementhumain'] || '1',
          poidskg: initialCareCheckup['poidskg'] || '0',
          taillem: initialCareCheckup['taillem'] || '0',
          forfait: initialCareCheckup['forfait'] || '',
          segaage: initialCareCheckup['segaage'] || '1',
          provenance: initialCareCheckup['provenance'] || '1',
          medicaments: initialCareCheckup['medicaments'] || '1',
          humeur: initialCareCheckup['humeur'] || '1',
          perceptiondesante: initialCareCheckup['perceptiondesante'] || '1',
          chutesds6derniersmois:
            initialCareCheckup['chutesds6derniersmois'] || '1',
          nutrition: initialCareCheckup['nutrition'] || '1',
          maladiesassociees: initialCareCheckup['maladiesassociees'] || '1',
          aivq: initialCareCheckup['aivq'] || '1',
          mobilite: initialCareCheckup['mobilite'] || '1',
          continenceurinaireetoufecal920761:
            initialCareCheckup['continenceurinaireetoufecal920761'] || '1',
          prisedesrepas: initialCareCheckup['prisedesrepas'] || '1',
          fonctionscognitives: initialCareCheckup['fonctionscognitives'] || '1',
          hospitalisationaucoursdes6579351:
            initialCareCheckup['hospitalisationaucoursdes6579351'] || '1',
          vision: initialCareCheckup['vision'] || '1',
          audition: initialCareCheckup['audition'] || '1',
          supportsocialentourage:
            initialCareCheckup['supportsocialentourage'] || '1',
          aideadomicileprofessionnell912751:
            initialCareCheckup['aideadomicileprofessionnell912751'] || '1',
          aidantnaturel: initialCareCheckup['aidantnaturel'] || '1',
          perceptiondelachargeparle953731:
            initialCareCheckup['perceptiondelachargeparle953731'] || '1',
          habitat: initialCareCheckup['habitat'] || '1',
          situationfinanciere: initialCareCheckup['situationfinanciere'] || '1',
          perpectivesdavenirselonlap40541:
            initialCareCheckup['perpectivesdavenirselonlap40541'] || '1',
          perpectivesdavenirselonlent650271:
            initialCareCheckup['perpectivesdavenirselonlent650271'] || '1',
          diagnosticsinfirmiersaliment415641:
            initialCareCheckup['diagnosticsinfirmiersaliment415641'] || '1',
          precisionsdiagnoticsalimenta680191:
            initialCareCheckup['precisionsdiagnoticsalimenta680191'] || '',
          testmnalepatientatilmoin719751:
            initialCareCheckup['testmnalepatientatilmoin719751'] || '1',
          perterecentedepoids3mois:
            initialCareCheckup['perterecentedepoids3mois'] || '1',
          motricite: initialCareCheckup['motricite'] || '1',
          maladieaigueoustresspsycho903311:
            initialCareCheckup['maladieaigueoustresspsycho903311'] || false,
          problemesneurologiques:
            initialCareCheckup['problemesneurologiques'] || '1',
          imc39: initialCareCheckup['imc39'] || '1',
          circonferencedumolletencm:
            initialCareCheckup['circonferencedumolletencm'] || '1',
          interventionsinfirmieresalim626481:
            initialCareCheckup['interventionsinfirmieresalim626481'] || [],
          observationscomplementairesd962741:
            initialCareCheckup['observationscomplementairesd962741'] || '',
          pressionarteriellesystolique908731:
            initialCareCheckup['pressionarteriellesystolique908731'] || '0',
          pressionarteriellediastoliqu945421:
            initialCareCheckup['pressionarteriellediastoliqu945421'] || '0',
          frequencecardiaqueparmn:
            initialCareCheckup['frequencecardiaqueparmn'] || '0',
          frequencecardiaquenonmesura873931:
            initialCareCheckup['frequencecardiaquenonmesura873931'] ===
            undefined
              ? true
              : initialCareCheckup['frequencecardiaquenonmesura873931'],
          frequencerespiratoireparmn:
            initialCareCheckup['frequencerespiratoireparmn'] || '0',
          diaginfirespietcardiotrou330941:
            initialCareCheckup['diaginfirespietcardiotrou330941'] || false,
          diaginfirespietcardiooede840971:
            initialCareCheckup['diaginfirespietcardiooede840971'] || false,
          diaginfirespietcardiodysp330241:
            initialCareCheckup['diaginfirespietcardiodysp330241'] || false,
          diaginfirespietcardioautr332651:
            initialCareCheckup['diaginfirespietcardioautr332651'] || false,
          interventionsinfirmierescard931121:
            initialCareCheckup['interventionsinfirmierescard931121'] || '1',
          surveillancecliniquefonction628161:
            initialCareCheckup['surveillancecliniquefonction628161'] || false,
          posedeposedecompressionvas880451:
            initialCareCheckup['posedeposedecompressionvas880451'] || false,
          observationscomplementairesd555111:
            initialCareCheckup['observationscomplementairesd555111'] || '',
          diagnosticsinfirmierselimina653501:
            initialCareCheckup['diagnosticsinfirmierselimina653501'] || [],
          diagnosticsinfirmierselimina864971:
            initialCareCheckup['diagnosticsinfirmierselimina864971'] || '',
          eliminationinterventionsinfi990881:
            initialCareCheckup['eliminationinterventionsinfi990881'] || [],
          planificationdessoinsperso347551:
            initialCareCheckup['planificationdessoinsperso347551'] || false,
          eliminationinterventionsinfi214901:
            initialCareCheckup['eliminationinterventionsinfi214901'] || false,
          observationscomplementairesd801501:
            initialCareCheckup['observationscomplementairesd801501'] || '1',
          diagnosticsinfirmiershygiene32921:
            initialCareCheckup['diagnosticsinfirmiershygiene32921'] || [],
          diagnosticsinfirmiershygiene707201:
            initialCareCheckup['diagnosticsinfirmiershygiene707201'] || '',
          hygienetestdevaluationrisq886031:
            initialCareCheckup['hygienetestdevaluationrisq886031'] || '1',
          hygienetestdevaluationrisq724871:
            initialCareCheckup['hygienetestdevaluationrisq724871'] || '1',
          hygienetestdevaluationrisq483801:
            initialCareCheckup['hygienetestdevaluationrisq483801'] || '1',
          hygienetestdevaluationrisq406311:
            initialCareCheckup['hygienetestdevaluationrisq406311'] || '1',
          hygienetestdevaluationrisq964411:
            initialCareCheckup['hygienetestdevaluationrisq964411'] || '1',
          hygienetestdevaluationrisq979521:
            initialCareCheckup['hygienetestdevaluationrisq979521'] || '1',
          hygienetestdevaluationrisq941951:
            initialCareCheckup['hygienetestdevaluationrisq941951'] || '1',
          hygienetestdevaluationrisq56981:
            initialCareCheckup['hygienetestdevaluationrisq56981'] || '1',
          hygienetestdevaluationrisq362901:
            initialCareCheckup['hygienetestdevaluationrisq362901'] || '1',
          hygienetestdevaluationrisq734191:
            initialCareCheckup['hygienetestdevaluationrisq734191'] || '1',
          hygienetestdevaluationrisq765861:
            initialCareCheckup['hygienetestdevaluationrisq765861'] || '1',
          hygieneinterventionsinfirmie328911:
            initialCareCheckup['hygieneinterventionsinfirmie328911'] || [],
          observationscomplementairesd278571:
            initialCareCheckup['observationscomplementairesd278571'] || '',
          diagnosticsinfirmiersmobilit878901:
            initialCareCheckup['diagnosticsinfirmiersmobilit878901'] || [],
          precisionsdiagnoticsmobilite329291:
            initialCareCheckup['precisionsdiagnoticsmobilite329291'] || '',
          seleverdunfauteuilavecacc23891:
            initialCareCheckup['seleverdunfauteuilavecacc23891'] || '1',
          traverserlapiecedistance3411121:
            initialCareCheckup['traverserlapiecedistance3411121'] || '1',
          fairedemitour: initialCareCheckup['fairedemitour'] || '1',
          revenirsasseoir: initialCareCheckup['revenirsasseoir'] || '1',
          dureenecessaire: initialCareCheckup['dureenecessaire'] || '0',
          interpretationscore: initialCareCheckup['interpretationscore'] || '',
          teststationunipodalepiedd:
            initialCareCheckup['teststationunipodalepiedd'] || '1',
          teststationunipodalepiedg:
            initialCareCheckup['teststationunipodalepiedg'] || '1',
          intervinfirmieremobilite:
            initialCareCheckup['intervinfirmieremobilite'] || [],
          intervinfirmieremobilitebi660131:
            initialCareCheckup['intervinfirmieremobilitebi660131'] || '1',
          observationscomplementairesd10461:
            initialCareCheckup['observationscomplementairesd10461'] || '',
          diagnosticsinfirmierstraitem889831:
            initialCareCheckup['diagnosticsinfirmierstraitem889831'] || [],
          diagnosticsinfirmierstraitem45831:
            initialCareCheckup['diagnosticsinfirmierstraitem45831'] || '',
          testdevaluationdeladouleur260241:
            initialCareCheckup['testdevaluationdeladouleur260241'] || '0',
          testdevaluationdeladouleur120591:
            initialCareCheckup['testdevaluationdeladouleur120591'] || '0',
          testdoloplusplaintessomatiq232711:
            initialCareCheckup['testdoloplusplaintessomatiq232711'] || '1',
          testdolopluspositionsantalg648021:
            initialCareCheckup['testdolopluspositionsantalg648021'] || '1',
          testdoloplusprotectiondez853931:
            initialCareCheckup['testdoloplusprotectiondez853931'] || '1',
          testdoloplusmimique: initialCareCheckup['testdoloplusmimique'] || '1',
          testdoloplussommeil: initialCareCheckup['testdoloplussommeil'] || '1',
          testdoloplustoiletteetouha224021:
            initialCareCheckup['testdoloplustoiletteetouha224021'] || '1',
          testdoloplusmouvements:
            initialCareCheckup['testdoloplusmouvements'] || '1',
          testdolopluscommunication:
            initialCareCheckup['testdolopluscommunication'] || '1',
          testdoloplusviesociale:
            initialCareCheckup['testdoloplusviesociale'] || '1',
          testdoloplustroublesducomp56071:
            initialCareCheckup['testdoloplustroublesducomp56071'] || '1',
          testdn4douleurscaracteristi610261:
            initialCareCheckup['testdn4douleurscaracteristi610261'] || false,
          testdn4douleurscaracteristi690951:
            initialCareCheckup['testdn4douleurscaracteristi690951'] || false,
          testdn4douleurscaracteristi180841:
            initialCareCheckup['testdn4douleurscaracteristi180841'] || false,
          testdn4douleurassocieedsm100621:
            initialCareCheckup['testdn4douleurassocieedsm100621'] || false,
          testdn4douleurassocieedsm485311:
            initialCareCheckup['testdn4douleurassocieedsm485311'] || false,
          testdn4douleurassocieedsm97351:
            initialCareCheckup['testdn4douleurassocieedsm97351'] || false,
          testdn4douleurassocieedsm745641:
            initialCareCheckup['testdn4douleurassocieedsm745641'] || false,
          testdn4douleurlocaliseehyp364731:
            initialCareCheckup['testdn4douleurlocaliseehyp364731'] || false,
          testdn4douleurlocaliseehyp181581:
            initialCareCheckup['testdn4douleurlocaliseehyp181581'] || false,
          testdn4douleurprovoqueepar41051:
            initialCareCheckup['testdn4douleurprovoqueepar41051'] || false,
          interventionsinfirmieresdoul689941:
            initialCareCheckup['interventionsinfirmieresdoul689941'] || [],
          observationcomplementairespo113741:
            initialCareCheckup['observationcomplementairespo113741'] || '',
          interventionsinfirmieressoin157741:
            initialCareCheckup['interventionsinfirmieressoin157741'] || [],
          observationscomplementairesp226621:
            initialCareCheckup['observationscomplementairesp226621'] || '',
          interventionsinfirmieressoin780331:
            initialCareCheckup['interventionsinfirmieressoin780331'] || false,
          interventionsinfirmieressoin469011:
            initialCareCheckup['interventionsinfirmieressoin469011'] || [],
          interventionsinfirmieressoin780101:
            initialCareCheckup['interventionsinfirmieressoin780101'] || '',
          interventionsinfirmieressoin184071:
            initialCareCheckup['interventionsinfirmieressoin184071'] || false,
          interventionsinfirmieressoin307381:
            initialCareCheckup['interventionsinfirmieressoin307381'] || [],
          interventionsinfirmieressoin751801:
            initialCareCheckup['interventionsinfirmieressoin751801'] || '',
          observationcomplementairespo416171:
            initialCareCheckup['observationcomplementairespo416171'] || '',
          criteresdemajorationliesau380611:
            initialCareCheckup['criteresdemajorationliesau380611'] || [],
          criteresdemajorationliesa420071:
            initialCareCheckup['criteresdemajorationliesa420071'] || [],
          observationcomplementairespo556321:
            initialCareCheckup['observationcomplementairespo556321'] || '',
          n1prelevementparponctionvei77561:
            initialCareCheckup['n1prelevementparponctionvei77561'] || '0',
          n1prelevementparponctionvei16591:
            initialCareCheckup['n1prelevementparponctionvei16591'] || '0',
          n2prelevementcutaneoudesec944441:
            initialCareCheckup['n2prelevementcutaneoudesec944441'] || '0',
          n2prelevementcutaneoudesec955381:
            initialCareCheckup['n2prelevementcutaneoudesec955381'] || '0',
          n3saigneenbredactesparmois542781:
            initialCareCheckup['n3saigneenbredactesparmois542781'] || '0',
          n3saigneedureeprevisiblede620771:
            initialCareCheckup['n3saigneedureeprevisiblede620771'] || '0',
          n4injectionintramusculaireso218181:
            initialCareCheckup['n4injectionintramusculaireso218181'] || '0',
          n4injectionintramusculaireso977511:
            initialCareCheckup['n4injectionintramusculaireso977511'] || '0',
          n5injectionintraveineusedire572361:
            initialCareCheckup['n5injectionintraveineusedire572361'] || '0',
          n5injectionintraveineusedire504891:
            initialCareCheckup['n5injectionintraveineusedire504891'] || '0',
          n6pansementcourantnbredac925731:
            initialCareCheckup['n6pansementcourantnbredac925731'] || '0',
          n6pansementcourantdureepr176721:
            initialCareCheckup['n6pansementcourantdureepr176721'] || '0',
          n7pansementlourdetcomplexe832891:
            initialCareCheckup['n7pansementlourdetcomplexe832891'] || '0',
          n7pansementlourdetcomplexe237111:
            initialCareCheckup['n7pansementlourdetcomplexe237111'] || '0',
          n8pansementlourdetcomplexe34821:
            initialCareCheckup['n8pansementlourdetcomplexe34821'] || '0',
          n8pansementlourdetcomplexe114951:
            initialCareCheckup['n8pansementlourdetcomplexe114951'] || '0',
          n9pansementlourdetcomplexe120121:
            initialCareCheckup['n9pansementlourdetcomplexe120121'] || '0',
          n9pansementlourdetcomplexe333601:
            initialCareCheckup['n9pansementlourdetcomplexe333601'] || '0',
          n10soinsdestomiesycpansem501041:
            initialCareCheckup['n10soinsdestomiesycpansem501041'] || '0',
          n10soinsdestomiesycpansem404151:
            initialCareCheckup['n10soinsdestomiesycpansem404151'] || '0',
          n11aerosolmedicamenteuxnbr866851:
            initialCareCheckup['n11aerosolmedicamenteuxnbr866851'] || '0',
          n11aerosolmedicamenteuxdur394511:
            initialCareCheckup['n11aerosolmedicamenteuxdur394511'] || '0',
          n12lavagedunsinusnbredac899871:
            initialCareCheckup['n12lavagedunsinusnbredac899871'] || '0',
          n12lavagedunsinusdureepr169981:
            initialCareCheckup['n12lavagedunsinusdureepr169981'] || '0',
          n13pansementdetracheotomiey407651:
            initialCareCheckup['n13pansementdetracheotomiey407651'] || '0',
          n13pansementdetracheotomiey49251:
            initialCareCheckup['n13pansementdetracheotomiey49251'] || '0',
          n14injectionvaginalenbred447571:
            initialCareCheckup['n14injectionvaginalenbred447571'] || '0',
          n14injectionvaginaleduree468511:
            initialCareCheckup['n14injectionvaginaleduree468511'] || '0',
          n15posechangementetuipenien761161:
            initialCareCheckup['n15posechangementetuipenien761161'] || '0',
          n15posechangementetuipenien693971:
            initialCareCheckup['n15posechangementetuipenien693971'] || '0',
          n16sondageurinaireposechang937791:
            initialCareCheckup['n16sondageurinaireposechang937791'] || '0',
          n16sondageurinaireposechang576821:
            initialCareCheckup['n16sondageurinaireposechang576821'] || '0',
          n17instillationetoulavageve356011:
            initialCareCheckup['n17instillationetoulavageve356011'] || '0',
          n17instillationetoulavageve63651:
            initialCareCheckup['n17instillationetoulavageve63651'] || '0',
          n18soinsdeboucheavecapplic147321:
            initialCareCheckup['n18soinsdeboucheavecapplic147321'] || '0',
          n18soinsdeboucheavecapplic246031:
            initialCareCheckup['n18soinsdeboucheavecapplic246031'] || '0',
          n19sondegastriqueposesurvei628751:
            initialCareCheckup['n19sondegastriqueposesurvei628751'] || '0',
          n19sondegastriqueposesurvei457361:
            initialCareCheckup['n19sondegastriqueposesurvei457361'] || '0',
          n20seancedalimentationentera610461:
            initialCareCheckup['n20seancedalimentationentera610461'] || '0',
          n20seancedalimentationentera680401:
            initialCareCheckup['n20seancedalimentationentera680401'] || '0',
          n21lavementevacuateurnbre454241:
            initialCareCheckup['n21lavementevacuateurnbre454241'] || '0',
          n21lavementevacuateurduree635541:
            initialCareCheckup['n21lavementevacuateurduree635541'] || '0',
          n22extractiondefecalomeoum713871:
            initialCareCheckup['n22extractiondefecalomeoum713871'] || '0',
          n22extractiondefecalomeoum522631:
            initialCareCheckup['n22extractiondefecalomeoum522631'] || '0',
          n23irrigationcoliquecontinue337981:
            initialCareCheckup['n23irrigationcoliquecontinue337981'] || '0',
          n23irrigationcoliquecontinue100701:
            initialCareCheckup['n23irrigationcoliquecontinue100701'] || '0',
          n24soinsdentretienduncathet541821:
            initialCareCheckup['n24soinsdentretienduncathet541821'] || '0',
          n24soinsdentretienduncathet209201:
            initialCareCheckup['n24soinsdentretienduncathet209201'] || '0',
          n25soinsdentretienduncathet445191:
            initialCareCheckup['n25soinsdentretienduncathet445191'] || '0',
          n25soinsdentretienduncathet830571:
            initialCareCheckup['n25soinsdentretienduncathet830571'] || '0',
          n26seancedeperfusion1heur529641:
            initialCareCheckup['n26seancedeperfusion1heur529641'] || '0',
          n26seancedeperfusion1heur164461:
            initialCareCheckup['n26seancedeperfusion1heur164461'] || '0',
          n27seancedeperfusion1heur207771:
            initialCareCheckup['n27seancedeperfusion1heur207771'] || '0',
          n27seancedeperfusion1heur189651:
            initialCareCheckup['n27seancedeperfusion1heur189651'] || '0',
          n28surveillanceobservationave483411:
            initialCareCheckup['n28surveillanceobservationave483411'] || '0',
          n28surveillanceobservationave708361:
            initialCareCheckup['n28surveillanceobservationave708361'] || '0',
          n29organisationsurveillanceb782991:
            initialCareCheckup['n29organisationsurveillanceb782991'] || '0',
          n29organisationsurveillanceb857511:
            initialCareCheckup['n29organisationsurveillanceb857511'] || '0',
          techCare: initialCareCheckup.techCare,
          bsi_79: initialCareCheckup['bsi_79'] || false,
          bsi_80: initialCareCheckup['bsi_80'] || false,
          bsi_81: initialCareCheckup['bsi_81'] || false,
          bsi_82: initialCareCheckup['bsi_82'] || false,
          bsi_83: initialCareCheckup['bsi_83'] || false,
          bsi_84: initialCareCheckup['bsi_84'] || '',
          bsi_85: initialCareCheckup['bsi_85'] || false,
          bsi_86: initialCareCheckup['bsi_86'] || false,
          bsi_87: initialCareCheckup['bsi_87'] || false,
          bsi_88: initialCareCheckup['bsi_88'] || false,
          bsi_89: initialCareCheckup['bsi_89'] || false,
          bsi_90: initialCareCheckup['bsi_90'] || false,
          bsi_91: initialCareCheckup['bsi_91'] || false,
          bsi_96: initialCareCheckup['bsi_96'] || false,
        }
      : checkupType === 'bip'
      ? {
          poidskg: initialCareCheckup['poidskg'] || '0',
          taillem: initialCareCheckup['taillem'] || '0',
          motif: initialCareCheckup['motif'] || '',
          prestataire: initialCareCheckup['prestataire'] || '',
          histopec: initialCareCheckup['histopec'] || '1',
          plaieaiguetrauma: initialCareCheckup['plaieaiguetrauma'] || [],
          plaiechronique: initialCareCheckup['plaiechronique'] || [],
          prelevementcutane: initialCareCheckup['prelevementcutane'] || false,
          suivispe: initialCareCheckup['suivispe'] || false,
          intolerancesconnues:
            initialCareCheckup['intolerancesconnues'] || false,
          intolerances: initialCareCheckup['intolerances'] || '',
          douleur: initialCareCheckup['douleur'] || false,
          eva: initialCareCheckup['eva'] || '0',
          examcompltreal: initialCareCheckup['examcompltreal'] || [],
          examcompltrealtxt: initialCareCheckup['examcompltrealtxt'] || '',
          atcdmedcognitifs: initialCareCheckup['atcdmedcognitifs'] || false,
          atcdmedrisques: initialCareCheckup['atcdmedrisques'] || [],
          traitements: initialCareCheckup['examctraitementsompltrealtxt'] || '',
          allergie: initialCareCheckup['allergie'] || '',
          localisation: initialCareCheckup['localisation'] || '',
          stade: initialCareCheckup['stade'] || [],
          litplaie: initialCareCheckup['litplaie'] || '1',
          peauperilesion: initialCareCheckup['peauperilesion'] || [],
          bords: initialCareCheckup['bords'] || [],
          signesinflammatoires:
            initialCareCheckup['signesinflammatoires'] || '1',
          exsudats: initialCareCheckup['exsudats'] || [],
          contention: initialCareCheckup['contention'] || '1',
          chaussage: initialCareCheckup['chaussage'] || '1',
          conditionshygiene: initialCareCheckup['conditionshygiene'] || '',
          marche: initialCareCheckup['marche'] || '1',
          education: initialCareCheckup['education'] || false,
          compliant: initialCareCheckup['compliant'] || false,
          retentissement: initialCareCheckup['retentissement'] || '1',
          retentissementautre: initialCareCheckup['retentissementautre'] || '',
          facteursretardcicatrisation:
            initialCareCheckup['facteursretardcicatrisation'] || [],
          objectifsdesoin: initialCareCheckup['objectifsdesoin'] || '',
          actions: initialCareCheckup['actions'] || '',
          photoPlaie: initialCareCheckup.photoPlaie,
        }
      : {};
  const [generalInputs, setGeneralInputs] = useState(data);

  const handleGeneralInputChange = (event) => {
    const { name, checked, type, value } = event.target;

    setGeneralInputs((prevGeneralInputs) => ({
      ...prevGeneralInputs,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Create
  const { loading: createLoading, onCreate } = useCreateCareCheckup({
    generalInputs,
    type: checkupType,
  });

  // Update
  const { loading: updateLoading, onUpdate } = useUpdateCareCheckup({
    generalInputs,
  });

  // Images uploads
  const { progress: techcareProgress, onUpload: handleTechCareUploadChange } =
    useUpload({
      name: 'techCare',
      onUploaded: handleGeneralInputChange,
    });

  const {
    progress: photoPlaieProgress,
    onUpload: handlePhotoPlaieUploadChange,
  } = useUpload({
    name: 'photoPlaie',
    onUploaded: handleGeneralInputChange,
  });

  // Submit
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (type === 'update') {
      await onUpdate();
    } else if (type === 'create') {
      await onCreate();
    }

    onSubmitted && onSubmitted();
  };

  return {
    loading:
      createLoading || updateLoading || techcareProgress || photoPlaieProgress,
    generalInputs,
    onTechCareUploadChange: handleTechCareUploadChange,
    onPhotoPlaieUploadChange: handlePhotoPlaieUploadChange,
    onGeneralInputChange: handleGeneralInputChange,
    onSubmit: handleSubmit,
  };
};

export default useCareCheckupForm;
