import { useState, useCallback, useEffect } from 'react';
import isEmail from 'validator/lib/isEmail';
import IMask from 'imask';
import moment from 'moment';

import useCreatePatient from './useCreatePatient';
import useUpdatePatient from './useUpdatePatient';
import useUpload from '../../utils/useUpload';
import checkSecuNumber from './libs/check-secu-number';
import useAfterMount from '../../utils/useAfterMount';
import { useParams } from 'react-router-dom';

const socialSecuNumberMasked = IMask.createMask({
  mask: '0 00 00 0* 000 000 00',
});

const phoneNumerMasked = IMask.createMask({
  mask: '00 00 00 00 00',
});

const usePatientForm = ({ initialPatient = {}, tours, type, onSubmitted }) => {
  const now = moment();
  // General inputs
  const [generalInputs, setGeneralInputs] = useState({
    firstName: initialPatient.firstName || '',
    lastName: initialPatient.lastName || '',
    useName: initialPatient.useName || '',
    visitAddress: initialPatient.visitAddress || {},
    homeAddress: initialPatient.homeAddress || {},
    addressExtra: initialPatient.addressExtra || '',
    comment: initialPatient.comment || '',
    email1Name: initialPatient.email1Name || '',
    email1Value: initialPatient.email1Value || '',
    contact1: initialPatient.contact1 || '',
    birthDay: initialPatient.birthDay || now.date(),
    birthMonth:
      initialPatient.birthMonth === undefined
        ? now.month()
        : initialPatient.birthMonth,
    birthYear: initialPatient.birthYear || now.year(),
    preferredTourId:
      tours?.find((t) => t.id === initialPatient.preferredTourId)?.id ||
      tours[0]?.id,
    socialSecuNumber: initialPatient.socialSecuNumber || '',
    socialSecuNumberRank: initialPatient.socialSecuNumberRank || 1,
    vitaleCard: initialPatient.vitaleCard,
    mutuelleCertificate: initialPatient.mutuelleCertificate,
    mutuelle: initialPatient.mutuelle || '',
    dmpINSC: initialPatient.dmpINSC || '',
    dmpImage: initialPatient.dmpImage,
    dmpId: initialPatient.dmpId || '',
    dmpPassword: initialPatient.dmpPassword || '',
    resources: initialPatient.resources || [],
    doctor: initialPatient.doctor,
    providers: initialPatient.providers || [],
  });

  const [previousPatientResources, setPreviousPatientResources] = useState(
    JSON.stringify(initialPatient.resources),
  );
  useEffect(() => {
    if (previousPatientResources !== JSON.stringify(initialPatient.resources)) {
      generalInputs.resources = initialPatient.resources;
      setPreviousPatientResources(JSON.stringify(initialPatient.resources));
      setPreviousResources(JSON.stringify(generalInputs.resources));
    }
  }, [generalInputs, initialPatient.resources, previousPatientResources]);

  const handleGeneralInputChange = useCallback((event) => {
    const { name, value } = event.target;

    let newValue = value;
    if (name === 'firstName') {
      newValue = value.charAt(0).toUpperCase() + value.slice(1);
    }
    if (name === 'lastName') {
      newValue = value.toUpperCase();
    }
    if (name === 'socialSecuNumber') {
      if (['5', '6', '9', '0'].indexOf(value[0]) > -1) {
        // Prevent beginning by 5, 6, 9 or 0
        return;
      }
      newValue = socialSecuNumberMasked.resolve(value);
    }

    setGeneralInputs((prevGeneralInputs) => ({
      ...prevGeneralInputs,
      [name]: newValue,
    }));
  }, []);

  const handleGeneralInputsChange = (values) => {
    setGeneralInputs((prevGeneralInputs) => {
      return {
        ...prevGeneralInputs,
        ...values,
      };
    });
  };

  // Phones inputs
  const [phones, setPhones] = useState(
    initialPatient.phones?.length
      ? initialPatient.phones.map((p) => ({
          name: p.name || '',
          number: p.number || '',
        }))
      : [{ name: '', number: '' }],
  );
  const handleAddPhone = () => {
    setPhones([...phones, { name: '', number: '' }]);
  };
  const handlePhoneChange = (event) => {
    // Name format: phone-{name|number}-{index}
    const { name, value } = event.target;
    setPhones(
      phones.map((p, index) => {
        const [, fieldToEdit, indexToEdit] = name.split('-');
        if (indexToEdit === index.toString()) {
          return {
            ...p,
            [fieldToEdit]:
              fieldToEdit === 'name' ? value : phoneNumerMasked.resolve(value),
          };
        }
        return p;
      }),
    );
  };

  const handleProviderAdd = (event) => {
    const { name, value } = event.target;
    handleGeneralInputChange({
      target: { name, value: [...generalInputs[name], value] },
    });
  };

  const handleProviderDelete = (providerId) => {
    handleGeneralInputChange({
      target: {
        name: 'providers',
        value: generalInputs.providers.filter((p) => p.id !== providerId),
      },
    });
  };

  // Images uploads
  const { progress: vitaleCardProgress, onUpload: handleVitaleUploadChange } =
    useUpload({
      name: 'vitaleCard',
      onUploaded: handleGeneralInputChange,
    });

  const {
    progress: mutuelleCertificateProgress,
    onUpload: handleMutuelleUploadChange,
  } = useUpload({
    name: 'mutuelleCertificate',
    onUploaded: handleGeneralInputChange,
  });

  // Create
  const {
    loading: createLoading,
    error: createError,
    onCreate,
  } = useCreatePatient({
    generalInputs,
    phones,
  });

  // Update
  const {
    loading: updateLoading,
    error: updateError,
    onUpdate,
  } = useUpdatePatient({
    generalInputs,
    phones,
    onCompleted: () => {
      onSubmitted && onSubmitted();
    },
  });

  // Auto-submit
  const { patientId } = useParams();
  /*
  useAfterMount(async () => {
    if (patientId) {
      if (
        !generalInputs.vitaleCard ||
        (typeof generalInputs.vitaleCard?.key && generalInputs.vitaleCard?.src)
      ) {
        await onUpdate();
      }
    }
  }, [generalInputs.vitaleCard?.key]);

  useAfterMount(async () => {
    if (patientId) {
      if (
        !generalInputs.mutuelleCertificate ||
        (typeof generalInputs.mutuelleCertificate?.key &&
          generalInputs.mutuelleCertificate?.src)
      ) {
        await onUpdate();
      }
    }
  }, [generalInputs.mutuelleCertificate?.key]);
*/

  useAfterMount(() => {
    if (patientId) {
      const hasChanged =
        JSON.stringify(generalInputs.resources) !== previousResources;
      if (hasChanged) {
        onUpdate();
      }
    }
  }, [JSON.stringify(generalInputs.resources)]);

  const [previousResources, setPreviousResources] = useState(
    JSON.stringify(generalInputs.resources),
  );

  // Submit
  const [error, setError] = useState();
  const handleSubmit = async (event) => {
    setError(null);
    event.preventDefault();

    if (generalInputs.email1Vaue && !isEmail(generalInputs.email1Value)) {
      setError("Format d'e-mail invalide");
      return;
    }

    // Check socialSecuNumber key
    if (generalInputs.socialSecuNumber) {
      if (!checkSecuNumber(generalInputs.socialSecuNumber)) {
        setError('Format de numéro de sécurité social invalide');
        return;
      }
    }

    // Check birth date
    const now = moment().startOf('day');
    const birthMoment = moment([
      Number(generalInputs.birthYear),
      Number(generalInputs.birthMonth),
      Number(generalInputs.birthDay),
    ]);
    if (now.isSame(birthMoment)) {
      setError('Date de naissance requise');
      return;
    }

    if (type === 'update') {
      await onUpdate();
    } else if (type === 'create') {
      await onCreate();
    }
  };

  return {
    loading:
      createLoading ||
      updateLoading ||
      vitaleCardProgress ||
      mutuelleCertificateProgress,
    generalInputs,
    phones,
    error,
    mutationError: createError || updateError,
    onAddPhone: handleAddPhone,
    onPhoneChange: handlePhoneChange,
    onGeneralInputChange: handleGeneralInputChange,
    onGeneralInputsChange: handleGeneralInputsChange,
    onVitaleUploadChange: handleVitaleUploadChange,
    onMutuelleUploadChange: handleMutuelleUploadChange,
    onProviderAdd: handleProviderAdd,
    onProviderDelete: handleProviderDelete,
    onSubmit: handleSubmit,
  };
};

export default usePatientForm;
