import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';

import useDialog from '../System/useDialog';
import useUpdateConstantForm from './useUpdateConstantForm';
import useDeleteConstantForm from './useDeleteConstantForm';
import ConstantForm from './ConstantForm';
import { getTypes } from '../../utils/get-constant-type';

const useStyles = makeStyles((theme) => ({
  dataGrid: {
    margin: theme.spacing(0, 1, 0, 1),
  },
}));

const ConstantList = ({ patientId, constants, disabled, className }) => {
  const classes = useStyles();
  const [pageSize, setPageSize] = React.useState(5);

  const [selectedConstant, setSelectedConstant] = useState();
  const [inputs, setInputs] = useState({
    date: selectedConstant?.date,
    datas: selectedConstant?.datas,
  });

  const columns = [{ field: 'date', headerName: 'Date', width: 140 }];
  getTypes().forEach((type) =>
    columns.push({
      field: type.key,
      headerName: type.shortName,
      width: type.width || 100,
      sortable: false,
    }),
  );
  const rows = constants.map((constant) => {
    const results = {
      id: constant.id,
      date: moment(Number(constant.date)).format('DD/MM/YYYY HH:mm'),
    };

    getTypes().forEach((type) => {
      const value =
        constant.datas.find((data) => data.name === type.key)?.number || '';
      const numberFormatter = new Intl.NumberFormat('fr', {
        maximumFractionDigits: type.digits || 0,
      });

      results[type.key] = value
        ? type.min
          ? numberFormatter.format(value)
          : value
        : '';
    });

    return results;
  });
  const { open, onOpen, onClose } = useDialog();
  const {
    loading,
    onInputChange,
    onUpdate,
    error: errorOnUpdate,
  } = useUpdateConstantForm({
    patientId,
    constantId: selectedConstant?.id,
    inputs,
    setInputs,
    onUpdated: onClose,
  });
  const {
    loading: delLoading,
    onDelete,
    error: errorOnDelete,
  } = useDeleteConstantForm({
    patientId,
    constantId: selectedConstant?.id,
    onDeleteted: onClose,
  });

  return (
    <>
      <DataGrid
        className={className || classes.dataGrid}
        rows={rows}
        rowHeight={25}
        columns={columns}
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 20, 50]}
        onRowClick={(params) => {
          if (!disabled && params.row?.id) {
            const constant = constants.find(
              (constant) => constant.id === params.row?.id,
            );

            if (constant) {
              setSelectedConstant(constant);
              setInputs({
                date: constant?.date,
                datas: constant?.datas,
              });
              onOpen();
            }
          }
        }}
        disableColumnMenu
        disableColumnFilter
        disableSelectionOnClick
        disableColumnSelector
        hideFooterSelectedRowCount
        autoHeight
      />
      <ConstantForm
        inputs={inputs}
        loading={loading || delLoading}
        open={open}
        onClose={onClose}
        onInputChange={onInputChange}
        onSubmit={onUpdate}
        onDelete={onDelete}
        error={errorOnUpdate ? errorOnUpdate : errorOnDelete}
      />
    </>
  );
};

export default ConstantList;
