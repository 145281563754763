import { useState } from 'react';
import Papa from 'papaparse';
import { gql, useMutation } from '@apollo/client';
import shortid from 'shortid';
import IMask from 'imask';

import getMoment from '../../utils/get-moment';
import PrescriptionFragment from '../../fragments/PrescriptionFragment';
import { GET_PATIENT } from '../Patient/usePatient';

const socialSecuNumberMasked = IMask.createMask({
  mask: '0 00 00 00 000 000 00',
});

const IMPORT_PRESCRIPTION = gql`
  mutation ImportPrescription(
    $userGroupId: String!
    $socialSecuNumber: String
    $firstName: String
    $lastName: String
    $dateDay: Int
    $dateMonth: Int
    $dateYear: Int
    $soins2000Number: String
    $treatments: [TreatmentInput]!
  ) {
    importPrescription(
      userGroupId: $userGroupId
      socialSecuNumber: $socialSecuNumber
      firstName: $firstName
      lastName: $lastName
      dateDay: $dateDay
      dateMonth: $dateMonth
      dateYear: $dateYear
      soins2000Number: $soins2000Number
      treatments: $treatments
    ) {
      ...PrescriptionFragment
    }
  }

  ${PrescriptionFragment}
`;

const useImportPrescriptions = ({ refetch }) => {
  const [errors, setErrors] = useState([]);
  const [importPrescription] = useMutation(IMPORT_PRESCRIPTION, {
    onCompleted() {
      refetch();
    },
  });

  const checkDuration = (duration) => {
    return duration === 'days' || duration === 'weeks' || duration === 'months';
  };

  const checkSoin = (element, tourId) => {
    const treatments = [];
    let errorStr = '';

    for (let i = 1; i <= 3; i++) {
      const idx = 9 * i;

      if (element.length >= idx + 11) {
        const now = new Date();
        const day = Number(element[idx]);
        const month = Number(element[idx + 1]);
        const year = Number(element[idx + 2]);
        const timeHour = Number(element[idx + 3]);
        const timeMinute = Number(element[idx + 4]);
        const type = element[idx + 5];
        const count = Number(element[idx + 6]);
        const duration = element[idx + 7];
        const frequency = Number(element[idx + 8]);
        const weekDays = element[idx + 9]?.split(':').map((day) => Number(day));
        const comment = element[idx + 10];

        if (!checkDuration(duration)) {
          errorStr =
            errorStr + `\nSoin ${i} : la durée ${element[15]} n'existe pas`;
        }
        if (day < 1 || day > 31) {
          errorStr = errorStr + `\nSoin ${i} : le jour ${day} est incorrect`;
        }
        if (month < 1 || month > 12) {
          errorStr = errorStr + `\nSoin ${i} : le mois ${month} est incorrect`;
        }
        if (year < 2000 || year > now.getFullYear()) {
          errorStr = errorStr + `\nSoin ${i} : l'année ${year} est incorrecte`;
        }
        if (timeHour < 0 || timeHour > 23) {
          errorStr =
            errorStr + `\nSoin ${i} : l'heure ${timeHour} est incorrecte`;
        }
        if (timeMinute < 0 || timeMinute > 59) {
          errorStr =
            errorStr +
            `\nSoin ${i} : les minutes ${timeMinute} sont incorrectes`;
        }
        if (count < 0 || count > 365) {
          errorStr =
            errorStr + `\nSoin ${i} : la quantité ${count} est incorrecte`;
        }
        if (frequency < 0 || frequency > 365) {
          errorStr =
            errorStr + `\nSoin ${i} : la fréquence ${frequency} est incorrecte`;
        }
        if (frequency === 0 && weekDays.length === 0) {
          errorStr = errorStr + `\nSoin ${i} : il manque une fréquence`;
        }

        treatments.push({
          tour: tourId,
          day,
          month: month - 1,
          year,
          type,
          timeHour,
          timeMinute,
          count,
          duration,
          moment: getMoment(timeHour, timeMinute)?.value,
          frequency,
          weekDays: frequency > 0 ? null : weekDays,
          comment,
          outsourcedTreatments: [],
        });
      }
    }

    return { treatments, errorStr };
  };

  const clearErrors = () => {
    setErrors([]);
  };

  const handleUpload = (event, userGroupId, tours) => {
    const { files } = event.target;

    if (files && files.length) {
      const file = files[0];
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        async () => {
          const csv = Papa.parse(reader.result, {});
          const csvErrors = [];
          let errorStr = '';

          csv.data.forEach(async (element, index) => {
            if (element.length >= 20) {
              const now = new Date();
              const tour = tours.find((t) => t.name === element[8]);
              const dateDay = Number(element[4]);
              const dateMonth = Number(element[5]);
              const dateYear = Number(element[6]);

              if (!tour) {
                errorStr = errorStr + `\nLa tournée ${element[8]} n'existe pas`;
              }
              if (dateDay < 1 || dateDay > 31) {
                errorStr = errorStr + `\nLe jour ${dateDay} est incorrect`;
              }
              if (dateMonth < 1 || dateMonth > 12) {
                errorStr = errorStr + `\nLe mois ${dateMonth} est incorrect`;
              }
              if (dateYear < 2000 || dateYear > now.getFullYear()) {
                errorStr = errorStr + `\nL'année ${dateYear} est incorrecte`;
              }

              const result = checkSoin(element, tour?.id);
              errorStr = errorStr + result.errorStr;
              if (errorStr.length > 0) {
                csvErrors.push({ line: index + 1, text: errorStr });
              }
            }
          });

          setErrors(csvErrors);
          if (csvErrors.length === 0) {
            for (let index = 0; index < csv.data.length; index++) {
              const element = csv.data[index];

              if (element.length >= 20) {
                const tour = tours.find((t) => t.name === element[8]);
                const dateDay = Number(element[4]);
                const dateMonth = Number(element[5]);
                const dateYear = Number(element[6]);
                const result = checkSoin(element, tour?.id);
                const socialSecuNumber = socialSecuNumberMasked.resolve(
                  element[0],
                );

                try {
                  await importPrescription({
                    variables: {
                      userGroupId,
                      socialSecuNumber,
                      socialSecuNumberRank: element[1],
                      lastName: element[2],
                      firstName: element[3],
                      dateDay,
                      dateMonth: dateMonth - 1,
                      dateYear,
                      soins2000Number: element[7],
                      treatments: result.treatments.map((treatment) => ({
                        id: shortid.generate(),
                        ...treatment,
                      })),
                    },
                    update(cache, { data: { importPrescription } }) {
                      try {
                        const { getPatient } = cache.readQuery({
                          query: GET_PATIENT,
                          variables: {
                            userGroupId,
                            id: importPrescription.patient.id,
                          },
                        });
                        const firstTreatmentDate =
                          importPrescription.treatments.length &&
                          new Date(
                            importPrescription.treatments[0].year,
                            importPrescription.treatments[0].month,
                            importPrescription.treatments[0].day,
                          )
                            .valueOf()
                            .toString();

                        cache.writeQuery({
                          query: GET_PATIENT,
                          variables: {
                            userGroupId,
                            id: importPrescription.patient.id,
                          },

                          data: {
                            getPatient: {
                              ...getPatient,
                              prescriptions: [
                                {
                                  __typename: 'PrescriptionSummary',
                                  id: importPrescription.id,
                                  dateDay: importPrescription.dateDay,
                                  dateMonth: importPrescription.dateMonth,
                                  dateYear: importPrescription.dateYear,
                                  cause: importPrescription.cause,
                                  doctor: importPrescription.doctor,
                                  firstTreatmentDate: firstTreatmentDate,
                                  soins2000Number:
                                    importPrescription.soins2000Number,
                                },
                                ...getPatient.prescriptions,
                              ],
                            },
                          },
                        });
                      } catch (err) {}
                    },
                  });
                } catch (error) {
                  csvErrors.push({ line: index + 1, text: error.message });
                }
              }
            }

            setErrors(csvErrors);
          }
        },
        false,
      );

      if (file) {
        reader.readAsText(file);
      }
    }
  };

  return {
    upload: handleUpload,
    errors,
    clearErrors,
  };
};

export default useImportPrescriptions;
