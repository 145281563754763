import { gql } from '@apollo/client';

const ImageFragment = gql`
  fragment ImageFragment on Image {
    key
    alt
  }
`;

export default ImageFragment;
