import { gql } from '@apollo/client';

const UserGroupSummaryFragment = gql`
  fragment UserGroupSummaryFragment on UserGroupSummary {
    id
    name
    archived
    users
  }
`;

export default UserGroupSummaryFragment;
