import React, { useEffect } from 'react';
import { makeStyles, Grid, Typography, ButtonBase } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useLazyQuery } from '@apollo/client';

import SectionProgress from '../System/SectionProgress';
import { SEARCH_DOCTORS } from './useDoctors';
import { sortDoctors } from '../../utils/filters';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  listContainer: {
    flex: 1,
  },
  listItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    //paddingLeft: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  actionIcons: {
    marginLeft: 'auto',
    '& > *': {
      marginRight: theme.spacing(2),
      '& svg': {
        opacity: 0.9,
      },
    },
    '& > *:last-child': {
      marginRight: 0,
    },
  },
  emptyState: {
    marginTop: theme.spacing(1),
  },
  name: {
    textAlign: 'left',
    marginLeft: theme.spacing(1),
  },
  address: {
    textAlign: 'left',
    marginLeft: theme.spacing(1),
    color: 'grey',
    fontSize: '12px',
  },
}));

const Doctors = ({ select, onSelect, search }) => {
  const [execQuery, { loading, data }] = useLazyQuery(SEARCH_DOCTORS, {
    fetchPolicy: 'no-cache',
  });
  const classes = useStyles();
  const { userGroupId } = useParams();
  const doctors = search && data ? sortDoctors(data.searchDoctors) : [];

  useEffect(() => {
    search?.length > 2 &&
      (() => {
        execQuery({ variables: { search } });
      })();
  }, [execQuery, search]);

  return (
    <div className={classes.root}>
      {loading ? (
        <SectionProgress />
      ) : doctors.length ? (
        <div className={classes.listContainer}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                width={width}
                itemCount={doctors.length}
                itemSize={50}
              >
                {({ index, style }) => {
                  const doctor = doctors[index];
                  return (
                    <ButtonBase
                      style={style}
                      component={select ? undefined : Link}
                      className={classes.listItem}
                      to={
                        select
                          ? undefined
                          : `/g/${userGroupId}/doctor/update/${doctor.id}`
                      }
                      onClick={select ? () => onSelect(doctor) : undefined}
                    >
                      <Grid
                        container
                        justifyContent="flex-start"
                        style={{ maxHeight: '50px', overflow: 'hidden' }}
                      >
                        <Grid item sm={4} xs={5}>
                          <Typography className={classes.name}>
                            <strong>{doctor.lastName}</strong>{' '}
                            {doctor.firstName}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sm={8}
                          xs={7}
                          style={{
                            maxHeight: '50px',
                            overflow: 'hidden',
                          }}
                        >
                          <Typography className={classes.address}>
                            {doctor.address?.formattedAddress}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ButtonBase>
                  );
                }}
              </List>
            )}
          </AutoSizer>
        </div>
      ) : (
        <Typography align="center" className={classes.emptyState}>
          {search?.length > 2
            ? 'Aucun médecin existant'
            : 'Veuillez saisir un critère de recherche (au moins 3 caractères)'}
        </Typography>
      )}
    </div>
  );
};

export default Doctors;
