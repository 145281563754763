import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

const GET_ABSENCE = gql`
  query GetAbsence($userGroupId: String!, $id: String!) {
    getAbsence(userGroupId: $userGroupId, id: $id) {
      id
      startDate
      startMoment
      endDate
      endMoment
      prescriptionsIds
      processed
    }
  }
`;

const useAbsence = () => {
  const { userGroupId, absenceId, absenceStartDate } = useParams();

  const { data, loading } = useQuery(GET_ABSENCE, {
    variables: { userGroupId, id: `${absenceStartDate}#${absenceId}` },
  });

  return {
    loading,
    absence: data?.getAbsence,
  };
};

export default useAbsence;
export { GET_ABSENCE };
