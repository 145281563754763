import React from 'react';
import { IconButton } from '@material-ui/core';
import DirectionsIcon from '@material-ui/icons/Directions';
import { useQuery } from '@apollo/client';

import { GET_MAP_APPS, GOOGLE_APP, APPLE_APP, WAZE_APP } from '../../utils/map-app';

const DirectionsButton = ({ patient }) => {
  const { data } = useQuery(GET_MAP_APPS);
  const mapApps = data?.mapApps || GOOGLE_APP;
  let href = null;
  let directionsParams = encodeURI(patient.visitAddress.formattedAddress,);

  if (mapApps === GOOGLE_APP) {
    directionsParams = `destination=${directionsParams}`;
    if (patient.visitAddress.placeId) {
      directionsParams = `${directionsParams}&origin_place_id=${patient.visitAddress.placeId}`;
    }
    href = `https://www.google.com/maps/dir/?api=1&${directionsParams}`;
  } else if (mapApps === APPLE_APP) {
    if (patient.visitAddress.geometry?.lng && patient.visitAddress.geometry?.lat) {
      directionsParams = `q=${directionsParams}&sll=${patient.visitAddress.geometry.lat},${patient.visitAddress.geometry.lng}`;
    } else {
      directionsParams = `address=${directionsParams}`;
    }
    href = `https://maps.apple.com/?${directionsParams}`;
  } else if (mapApps === WAZE_APP) {
    directionsParams = `q=${directionsParams}`;
    if (patient.visitAddress.geometry?.lng && patient.visitAddress.geometry?.lat) {
      directionsParams = `${directionsParams}&ll=${patient.visitAddress.geometry.lat},${patient.visitAddress.geometry.lng}`;
    }
    href = `https://waze.com/ul?${directionsParams}`;
  }

  return (
    <IconButton
      size="small"
      component="a"
      target="_blank"
      href={href}
    >
      <DirectionsIcon />
    </IconButton>
  );
};

export default DirectionsButton;
