import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Button,
  ListItemSecondaryAction,
} from '@material-ui/core';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import LogsEditDialog from './LogsEditDialog';

const Logs = ({
  logs,
  editMode = false,
  seeAllLogs,
  hasMore,
  onToggleSeeAllLogs,
}) => {
  return (
    <>
      <List dense>
        {logs.map((log) => (
          <ListItem divider key={log.id} disableGutters>
            <ListItemText style={{ whiteSpace: 'pre-wrap' }}>
              <i style={{ opacity: 0.8 }}>
                {moment(Number(log.date)).format('DD/MM/YYYY HH:mm')}
              </i>{' '}
              - {log.text}
            </ListItemText>
            {editMode ? (
              <ListItemSecondaryAction>
                <LogsEditDialog logId={log.id} initialText={log.text} />
              </ListItemSecondaryAction>
            ) : null}
          </ListItem>
        ))}
      </List>

      {hasMore ? (
        <div>
          <Button
            startIcon={seeAllLogs ? <RemoveIcon /> : <AddIcon />}
            size="small"
            variant="outlined"
            onClick={onToggleSeeAllLogs}
          >
            {seeAllLogs ? 'Voir moins...' : 'Voir plus...'}
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default Logs;
