import { gql } from '@apollo/client';

const AddressFragment = gql`
  fragment AddressFragment on Address {
    formattedAddress
    placeId
    geometry {
      lat
      lng
    }
  }
`;

export default AddressFragment;
