import React from 'react';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import useDialog from '../System/useDialog';
import useAddConstantForm from './useAddConstantForm';
import ConstantForm from './ConstantForm';

const AddConstantForm = ({ patientId, className, disabled }) => {
  const { open, onOpen, onClose } = useDialog();
  const { loading, inputs, onInputChange, onAdd, error } = useAddConstantForm({
    patientId,
    onAdded: onClose,
  });

  return (
    <>
      <Button
        className={className}
        size="small"
        startIcon={<AddIcon />}
        variant="outlined"
        color="primary"
        onClick={onOpen}
        disabled={disabled}
      >
        Ajouter une constante
      </Button>

      <ConstantForm
        inputs={inputs}
        loading={loading}
        open={open}
        onClose={onClose}
        onInputChange={onInputChange}
        onSubmit={onAdd}
        error={error}
      />
    </>
  );
};

export default AddConstantForm;
