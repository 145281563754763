import { useRef, useEffect } from 'react';

// Global Google maps object
const googleScriptEl = document.createElement('script');
googleScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
document.body.appendChild(googleScriptEl);

const useGoogleMaps = ({ name = 'address', onChange }) => {
  const inputRef = useRef();

  useEffect(() => {
    const initMaps = () => {
      const maps = window.google?.maps;
      if (maps?.places) {
        const autocomplete = new maps.places.Autocomplete(inputRef.current, {
          componentRestrictions: { country: 'fr' },
          types: ['address'],
        });
        autocomplete.setFields(['place_id', 'geometry', 'formatted_address']);
        autocomplete.addListener('place_changed', (e) => {
          const {
            place_id: placeId,
            geometry,
            formatted_address: formattedAddress,
          } = autocomplete.getPlace();
          if (geometry) {
            const place = {
              placeId,
              geometry: {
                lat: geometry.location.lat().toString(),
                lng: geometry.location.lng().toString(),
              },
              formattedAddress,
            };
            if (onChange) {
              onChange({ target: { name, value: place } });
            }
          }
        });
      } else {
        // Try again if maps API is not loaded yet
        setTimeout(initMaps, 500);
      }
    };

    initMaps();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInputChange = (event) => {
    const { value } = event.target;

    if (onChange) {
      onChange({
        target: { name, value: { formattedAddress: value } },
      });
    }
  };

  return { inputRef, onInputChange: handleInputChange };
};

export default useGoogleMaps;
