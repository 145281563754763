import React from 'react';
import {
  makeStyles,
  Button,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import {
  createTheme as createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';

import BipCheckupForm from './BipCheckupForm';

import useCareCheckup from './useCareCheckup';
import usePatient from '../Patient/usePatient';

const printTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      paper: '#fff',
      default: '#fafafa',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0)',
    },
    error: {
      main: '#f44336', // AAA Contrast ratio with primary: 8.45
    },
    green: {
      main: '#00c310', // AAA Contrast ratio with primary: 7.46
    },
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    h1: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '1.1rem',
      fontWeight: 'bold',
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        color: 'black',
        '&$disabled': {
          color: 'black',
        },
      },
    },
    MuiTypography: {
      root: {
        color: 'black',
      },
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    color: 'black',
    backgroundColor: 'white',
    width: '760px',
    height: '100%',
    padding: '1em',
  },
  divButton: {
    textAlign: 'left',
  },
  button: {
    margin: '1em',
    backgroundColor: '#28bcff',
  },
  text: {
    color: 'black',
  },
  copyright: {
    color: 'black',
    textAlign: 'right',
    fontSize: '0.8em',
  },
  textBold: {
    color: 'black',
    fontWeight: 'bold',
  },
  table: {
    backgroundColor: 'white',
    marginTop: '1em',
  },
  tableBorder: {
    color: 'black',
    borderWidth: 1,
    borderColor: 'black',
    borderStyle: 'solid',
    padding: '5px',
  },
  form: {
    backgroundColor: 'white',
    color: 'black !important',
    width: '760px',
    padding: '1em',
  },
  fieldset: {
    color: 'black !important',
    padding: theme.spacing(2),
  },
}));

const CareCheckupPrintable = ({ props }) => {
  const { loading, careCheckup, createdBy } = useCareCheckup();
  const { loading: loadingPatient, patient } = usePatient();
  const classes = useStyles();

  return loading || loadingPatient ? (
    <CircularProgress />
  ) : (
    <ThemeProvider theme={printTheme}>
      <div
        style={{
          backgroundColor: 'white',
          padding: '1em',
        }}
      >
        <div id="no-print" className={classes.divButton}>
          <Button
            onClick={() => window.close()}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            <Typography variant="button" className={classes.text}>
              Fermer
            </Typography>
          </Button>
          <Button
            onClick={() => window.print()}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            <Typography variant="button" className={classes.text}>
              Imprimer
            </Typography>
          </Button>
        </div>
        <BipCheckupForm
          initialCareCheckup={careCheckup}
          createdBy={createdBy}
          type="update"
          editMode={false}
          patient={patient}
          printMode={true}
          parentClasses={classes}
          //onSubmitted={handleStopEdit}
        />
        <Typography className={classes.copyright}>
          {`© ${new Date().getFullYear()} EsaVitale`}
        </Typography>
      </div>
    </ThemeProvider>
  );
};

export default CareCheckupPrintable;
