import React, { useMemo } from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';
import DirectionsIcon from '@material-ui/icons/Directions';

import TextField from './TextField';
import useGoogleMaps from '../../utils/useGoogleMaps';

const GooglePlaceAutoComplete = ({ name, value, onChange, ...rest }) => {
  const { inputRef, onInputChange } = useGoogleMaps({ name, onChange });

  let directionsParams = useMemo(() => {
    let a = `destination=${encodeURI(value.formattedAddress)}`;
    if (value.placeId) {
      a = `${a}&origin_place_id=${value.placeId}`;
    }
    return a;
  }, [value.formattedAddress, value.placeId]);

  return (
    <TextField
      ref={inputRef}
      value={value.formattedAddress || ''}
      onChange={onInputChange}
      InputProps={{
        endAdornment: value.formattedAddress ? (
          <InputAdornment position="end">
            <IconButton
              size="small"
              component="a"
              target="_blank"
              href={`https://www.google.com/maps/dir/?api=1&${directionsParams}`}
            >
              <DirectionsIcon />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      {...rest}
    />
  );
};

export default GooglePlaceAutoComplete;
