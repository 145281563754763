const s3PostSignedUrl = async (
  { buffer, file, signedUrl: { url, fields } },
  { onProgress } = {},
) => {
  return new Promise((resolve, reject) => {
    const dataFields = JSON.parse(fields);

    const formData = new FormData();
    if (buffer) {
      formData.append('Content-Encoding', 'base64');
    }
    Object.entries(dataFields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const fileToUpload = file;
    formData.append('file', fileToUpload); // must be the last one

    // POST to S3
    const request = new XMLHttpRequest();
    request.onreadystatechange = () => {
      if (request.readyState === 4) {
        if (request.status === 200 || request.status === 204) {
          return resolve({ key: dataFields.key });
        } else {
          return reject();
        }
      }
    };

    if (request.upload) {
      request.upload.onprogress = (evt) => {
        if (evt.lengthComputable) {
          onProgress &&
            onProgress({ progress: Math.ceil((evt.loaded / evt.total) * 100) });
        }
      };
    }

    request.open('POST', url);
    request.send(formData);
  });
};

export default s3PostSignedUrl;
