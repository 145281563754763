import { gql } from '@apollo/client';
import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import { frFR as frFRGrid } from '@mui/x-data-grid';
import { frFR } from '@material-ui/core/locale';

const THEME_MODE = 'THEME_MODE';
const getThemeMode = () => {
  return window.localStorage.getItem(THEME_MODE) || 'manual';
};
const onChangeThemeMode = (mode) => {
  window.localStorage.setItem(
    THEME_MODE,
    isAutoTheme(mode) ? 'manual' : 'auto',
  );
};
const isAutoTheme = (mode) => {
  return mode === 'auto';
};

const GET_THEME_MODE = gql`
  {
    themeMode @client
  }
`;
const THEME_TYPE = 'THEME_TYPE';
const getThemeType = () => {
  return window.localStorage.getItem(THEME_TYPE) || 'dark';
};
const onChangeThemeType = (type) => {
  window.localStorage.setItem(
    THEME_TYPE,
    isLightTheme(type) ? 'dark' : 'light',
  );
};
const isLightTheme = (type) => {
  return type === 'light';
};
const GET_THEME_TYPE = gql`
  {
    themeType @client
  }
`;

const createTheme = (mediaDarkMode, dataThemeMode, dataThemeType) => {
  const darkMode = isDarkMode(mediaDarkMode, dataThemeMode, dataThemeType);
  const DIVIDER = '#3f4c57';
  const theme = createMuiTheme(
    {
      palette: {
        type: darkMode ? 'dark' : 'light',
        primary: {
          main: darkMode ? '#28bcff' : '#3f51b5',
        },
        secondary: {
          main: darkMode ? '#e91e63' : '#f50057',
        },
        background: {
          paper: darkMode ? '#1d2d3c' : '#fff',
          default: darkMode ? '#091928' : '#fafafa',
        },
        text: {
          primary: darkMode ? '#fff' : 'rgba(0, 0, 0, 0.87)',
        },
        error: {
          main: darkMode ? '#ff0000' : '#ff0000', // AAA Contrast ratio with primary: 8.45
        },
        green: {
          main: darkMode ? '#00c310' : '#00c310', // AAA Contrast ratio with primary: 7.46
        },
        yellow: {
          main: darkMode ? '#ffbe00' : '#ffbe00',
        },
      },
      typography: {
        fontFamily: '"Open Sans", sans-serif',
        h1: {
          fontSize: '1.2rem',
          fontWeight: 'bold',
        },
        h2: {
          fontSize: '1.1rem',
          fontWeight: 'bold',
        },
      },
      overrides: {
        MuiTableCell: {
          root: {
            borderBottomColor: DIVIDER,
          },
          head: {
            fontWeight: 'bold',
          },
        },
        MuiListItemText: {
          root: {
            wordBreak: 'break-word',
          },
        },
      },
    },
    frFR,
    frFRGrid,
  );

  return theme;
};

const isDarkMode = (mediaDarkMode, dataThemeMode, dataThemeType) => {
  const themeMode = dataThemeMode || getThemeMode();
  const themeType = dataThemeType || getThemeType();
  const themeAuto = isAutoTheme(themeMode);
  const themeLight = isLightTheme(themeType);
  const darkMode = themeAuto ? mediaDarkMode : !themeLight;

  return darkMode;
};

export {
  THEME_MODE,
  GET_THEME_MODE,
  THEME_TYPE,
  GET_THEME_TYPE,
  createTheme,
  getThemeMode,
  onChangeThemeMode,
  isAutoTheme,
  getThemeType,
  onChangeThemeType,
  isLightTheme,
  isDarkMode,
};
