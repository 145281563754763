import { gql } from '@apollo/client';

import MessageFragment from './MessageFragment';

const MessageListFragment = gql`
  fragment MessageListFragment on MessageList {
    data {
      ...MessageFragment
    }
  }

  ${MessageFragment}
`;

export default MessageListFragment;
