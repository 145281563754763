import React, { useMemo } from 'react';
import {
  makeStyles,
  Button,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';

import usePatient from '../Patient/usePatient';
import { getTypes } from '../../utils/get-constant-type';

const useStyles = makeStyles((theme) => ({
  root: {},
  divButton: {
    textAlign: 'left',
  },
  button: {
    margin: '1em',
  },
  text: {
    color: 'black',
  },
  copyright: {
    color: 'black',
    textAlign: 'right',
    fontSize: '0.8em',
  },
  textBold: {
    color: 'black',
    fontWeight: 'bold',
  },
  table: {
    backgroundColor: 'white',
    marginTop: '1em',
  },
  tableBorder: {
    color: 'black',
    borderWidth: 1,
    borderColor: 'black',
    borderStyle: 'solid',
    padding: '5px',
  },
}));

const ConstantPrintable = ({ props }) => {
  const classes = useStyles();

  const { loading, patient } = usePatient();

  const columns = [{ field: 'date', headerName: 'Date', width: 100 }];
  getTypes().forEach((type) =>
    columns.push({
      field: type.key,
      headerName: type.shortName,
      width: type.width || 100,
    }),
  );

  const rows = useMemo(() => {
    return patient?.constants.map((constant) => {
      const results = {
        id: constant.id,
        date: moment(Number(constant.date)).format('DD/MM/YYYY HH:mm'),
      };

      getTypes().forEach((type) => {
        const value =
          constant.datas.find((data) => data.name === type.key)?.number || '';
        const numberFormatter = new Intl.NumberFormat('fr', {
          maximumFractionDigits: type.digits || 0,
        });

        results[type.key] = value
          ? type.min
            ? numberFormatter.format(value)
            : value
          : '';
      });

      return results;
    });
  }, [patient?.constants]);

  return loading ? (
    <CircularProgress />
  ) : (
    <div
      style={{
        backgroundColor: 'white',
        width: '1000px',
        height: '100%',
        padding: '1em',
      }}
    >
      <div id="no-print" className={classes.divButton}>
        <Button
          onClick={() => window.close()}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          <Typography variant="button" className={classes.text}>
            Fermer
          </Typography>
        </Button>
        <Button
          onClick={() => window.print()}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          <Typography variant="button" className={classes.text}>
            Imprimer
          </Typography>
        </Button>
      </div>
      <Typography className={classes.text}>
        Nom:
        <span className={classes.textBold}>{` ${patient.lastName}`}</span>
      </Typography>
      <Typography className={classes.text}>
        Prénom:
        <span className={classes.textBold}>{` ${patient.firstName}`}</span>
      </Typography>
      <Typography className={classes.text}>
        Date de naissance:
        <span className={classes.textBold}>{` ${patient.birthDay}/${
          patient.birthMonth + 1
        }/${patient.birthYear}`}</span>
      </Typography>
      <TableContainer component={Paper} className={classes.table}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell
                  width={col.width}
                  align="left"
                  className={classes.tableBorder}
                >
                  {col.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow key={row.id}>
                {columns.map((col) => (
                  <TableCell
                    key={col.field}
                    width={col.width}
                    align="left"
                    className={classes.tableBorder}
                  >
                    {row[col.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography className={classes.copyright}>
        {`© ${new Date().getFullYear()} EsaVitale`}
      </Typography>
    </div>
  );
};

export default ConstantPrintable;
