import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Grid, Typography, makeStyles, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import useAbsence from './useAbsence';
import AppLayout from '../System/AppLayout';
import AbsenceForm from './AbsenceForm';
import SectionProgress from '../System/SectionProgress';
import DeleteAbsence from './DeleteAbsence';
import MarkProcessedButton from './MarkProcessedButton';
import useCurrentUser from '../System/useCurrentUser';
import { backSearch, urlWithBacks } from '../../utils/back-search';
import usePatient from '../Patient/usePatient';

const useStyles = makeStyles((theme) => ({
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  edit: {
    marginRight: theme.spacing(1),
  },
}));

const UpdateAbsence = () => {
  const classes = useStyles();

  const { userGroupId, patientId } = useParams();
  const { loadingPatient, patient } = usePatient();
  const { isAdmin, isSuperAdmin } = useCurrentUser();

  // Absence
  const { loading, absence } = useAbsence();

  // Edit mode
  const [editMode, setEditMode] = useState(false);
  const handleEdit = () => {
    setEditMode(true);
  };
  const handleStopEdit = () => {
    setEditMode(false);
  };

  const { search } = useLocation();
  const backToUrl = backSearch(search);

  return (
    <AppLayout
      title="Absence"
      backTo={urlWithBacks({
        url: `/g/${userGroupId}/patient/update/${patientId}`,
        back: backToUrl,
      })}
      action={
        absence ? (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            {!editMode ? (
              <>
                <div className={classes.edit}>
                  <IconButton onClick={handleEdit}>
                    <EditIcon />
                  </IconButton>
                </div>
                {!absence.processed ? (
                  isAdmin || isSuperAdmin ? (
                    <MarkProcessedButton />
                  ) : null
                ) : (
                  <Typography variant="caption">Absence traitée</Typography>
                )}
              </>
            ) : null}
          </Grid>
        ) : null
      }
    >
      {loading || loadingPatient || !patient ? (
        <SectionProgress />
      ) : (
        <>
          <AbsenceForm
            initialAbsence={{
              ...absence,
              patient,
            }}
            editMode={editMode}
            type="update"
            onSubmitted={handleStopEdit}
          />

          {editMode || absence?.processed ? null : <DeleteAbsence />}
        </>
      )}
    </AppLayout>
  );
};

export default UpdateAbsence;
