import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import usePatient, { GET_PATIENT } from '../Patient/usePatient';
import ConstantFragment from '../../fragments/ConstantFragment';
import useSnackbar from '../System/useSnackbar';

const UPDATE_CONSTANT = gql`
  mutation UpdateConstant(
    $id: String!
    $userGroupId: String!
    $patientId: String!
    $date: String!
    $datas: [ConstantDataInput]!
  ) {
    updateConstant(
      id: $id
      userGroupId: $userGroupId
      patientId: $patientId
      date: $date
      datas: $datas
    ) {
      ...ConstantFragment
    }
  }

  ${ConstantFragment}
`;

const useUpdateConstantForm = ({ constantId, patientId, inputs, setInputs, onUpdated }) => {
  const { userGroupId } = useParams();
  const { loading: loadingPatient, patient } = usePatient({ id: patientId });

  const handleInputChange = (event) => {
    if (inputs?.datas) {
      const { name, value } = event.target;
      const keys = name.split('.');

      if (keys.length && keys[0] === 'datas') {
        const datas = inputs.datas.filter(data => data.name !== keys[1]);

        if (value && value !== '') {
          setInputs((prevInputs) => ({ ...prevInputs, datas: [...datas, { name: keys[1], number: value }] }));
        } else {
          setInputs((prevInputs) => ({ ...prevInputs, datas }));
        }
      } else {
        setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
      }
    }
  };

  const { showSnackbar } = useSnackbar();
  const [updateConstant, { loading, error }] = useMutation(
    UPDATE_CONSTANT,
    {
      variables: {
        id: constantId,
        userGroupId,
        patientId,
        date: moment
          .utc(Number(inputs?.date))
          .valueOf()
          .toString(),
        datas: inputs?.datas?.map(data => ({ name: data.name, number: data.number })),
      },
      update(cache, { data: { updateConstant } }) {
        const constants = [...patient.constants.filter(aConstant => aConstant.id !== constantId), updateConstant];

        cache.writeQuery({
          query: GET_PATIENT,
          variables: { userGroupId, id: patientId },
          data: {
            getPatient: {
              ...patient,
              constants: constants.sort((a, b) =>
                a.date > b.date ? -1 : 1,
              )
            },
          },
        });
      },
      onCompleted() {
        setInputs({ datas: [] });
        showSnackbar('Constante modifiée');
        onUpdated && onUpdated();
      },
    },
  );

  const handleUpdate = async (event) => {
    event && event.preventDefault();
    try {
      await updateConstant();
    } catch (err) {
      // Do nothing, let Apollo useMutation handle errors
    }
  };

  return {
    loading: loading | loadingPatient,
    error,
    onInputChange: handleInputChange,
    onUpdate: handleUpdate,
  };
};

export default useUpdateConstantForm;
