import React from 'react';
import {
  Typography,
} from '@material-ui/core';

import useStyles from './useStyles';
import TextField from '../System/TextField';

const CareCheckupField = ({ label, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.field}>
      <Typography component="p" className={classes.label} variant="caption">
        {label}
      </Typography>
      <TextField margin="none" size="small" {...rest} />
    </div>
  );
};

export default CareCheckupField;