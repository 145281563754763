import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import moment from 'moment';

import FieldsContainer from '../System/FieldsContainer';
import ProgressButton from '../System/ProgressButton';
import TextField from '../System/TextField';
import useMessageForm from './useMessageForm';
import SelectDatePicker from '../System/SelectDatePicker';

const useStyles = makeStyles((theme) => ({
  form: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  inputsContainer: {
    overflow: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    marginBottom: 'auto',
  },
  fieldset: {
    padding: theme.spacing(2),
  },
  fieldsContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
  },
  disablePadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  submitContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

const MessageForm = ({
  initialMessage,
  type,
  autoFocus,
  editMode,
  onSubmitted,
  isAdmin,
}) => {
  const classes = useStyles();

  const { loading, generalInputs, onGeneralInputChange, onSubmit } =
    useMessageForm({ initialMessage, type, onSubmitted });

  const [messageDate, setMessageDate] = React.useState(
    moment(Number(generalInputs.date)),
  );

  return (
    <div className={classes.form}>
      <div className={classes.inputsContainer}>
        <fieldset className={classes.fieldset} disabled={!editMode}>
          {initialMessage?.createdAt ? (
            <Typography align="center">
              <Typography variant="caption">
                Message créé le{' '}
                {moment(Number(initialMessage.createdAt)).format(
                  'DD/MM/YYYY à HH:mm',
                )}{' '}
                par <b>{initialMessage.author?.lastName || 'utilisateur'}</b>{' '}
                {initialMessage.author?.firstName || 'inconnu'}
              </Typography>
            </Typography>
          ) : null}
          {initialMessage?.patient ? (
            <FieldsContainer>
              <Typography>
                Patient : <b>{initialMessage.patient.lastName}</b>{' '}
                {initialMessage.patient.firstName}
              </Typography>
            </FieldsContainer>
          ) : null}

          <FieldsContainer>
            <SelectDatePicker
              disableToolbar={false}
              label="Date de l'évènement"
              value={messageDate}
              onChange={(date) => {
                onGeneralInputChange({
                  target: { name: 'date', value: date.valueOf().toString() },
                });
                setMessageDate(date);
              }}
              readOnly={!editMode}
            />
          </FieldsContainer>

          <FieldsContainer>
            <TextField
              required
              autoFocus={autoFocus}
              name="text"
              multiline
              value={generalInputs.text}
              label="Commentaire"
              onChange={onGeneralInputChange}
            />
          </FieldsContainer>

          {initialMessage?.id ? (
            <>
              <FieldsContainer>
                <TextField
                  autoFocus={autoFocus}
                  name="response"
                  multiline
                  value={generalInputs.response}
                  label="Réponse"
                  onChange={onGeneralInputChange}
                />
              </FieldsContainer>
              {isAdmin ? (
                <FieldsContainer>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={generalInputs.processed}
                        color="primary"
                        onChange={onGeneralInputChange}
                        name="processed"
                      />
                    }
                    label={<Typography>Traité</Typography>}
                  />
                </FieldsContainer>
              ) : null}
            </>
          ) : null}
        </fieldset>
      </div>

      {editMode ? (
        <div className={classes.submitContainer}>
          <ProgressButton
            loading={loading}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            disabled={!generalInputs.text}
            onClick={onSubmit}
          >
            {type === 'update' ? 'Sauvegarder' : 'Créer'}
          </ProgressButton>
        </div>
      ) : null}
    </div>
  );
};

export default MessageForm;
