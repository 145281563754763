import React from 'react';
import Auth from '@aws-amplify/auth';
import { useApolloClient } from '@apollo/client';
import { Button } from '@material-ui/core';

const LogoutButton = () => {
  const apolloClient = useApolloClient();

  const handleLogout = async () => {
    await Auth.signOut();
    apolloClient.resetStore();
  };

  return (
    <Button variant="outlined" color="primary" onClick={handleLogout}>
      Déconnexion
    </Button>
  );
};

export default LogoutButton;
