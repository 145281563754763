import React /*, { lazy }*/ from 'react';
import { useParams, Redirect } from 'react-router-dom';

import UserGroups from './UserGroups';
import CreateUserGroup from './CreateUserGroup';
import EditUserGroup from './EditUserGroup';
import InviteUser from './InviteUser';

/*const UserGroups = lazy(() => import('./UserGroups'));
const CreateUserGroup = lazy(() => import('./CreateUserGroup'));
const EditUserGroup = lazy(() => import('./EditUserGroup'));
const InviteUser = lazy(() => import('./InviteUser'));
*/

const UserGroupLayout = () => {
  const { action } = useParams();

  if (!action) {
    return <UserGroups />;
  }

  switch (action) {
    case 'create': {
      return <CreateUserGroup />;
    }
    case 'edit': {
      return <EditUserGroup />;
    }
    case 'invite': {
      return <InviteUser />;
    }
    default:
      return <Redirect to="/admin/user-groups" />;
  }
};

export default UserGroupLayout;
