import React from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import TextField from './TextField';

const SearchField = ({ disabled, ...rest }) => {
  return (
    <TextField
      disabled={disabled}
      label="Rechercher"
      margin="dense"
      fullWidth={false}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <SearchIcon />
            </IconButton>{' '}
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

export default SearchField;
