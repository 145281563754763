import useUsers from '../users/useUsers';

import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

const GET_CARE_CHECKUP = gql`
  query GetCareCheckup($userGroupId: String!, $id: String!) {
    getCareCheckup(userGroupId: $userGroupId, id: $id) {
      id
      checkup
      createdBy
      type
    }
  }
`;

const useCareCheckup = () => {
  const { userGroupId, careCheckupId } = useParams();

  const { loading, data } = useQuery(GET_CARE_CHECKUP, {
    variables: {
      userGroupId,
      id: careCheckupId,
    },
  });

  const { loading: usersLoading, users } = useUsers();

  return {
    loading,
    careCheckup: data && JSON.parse(data.getCareCheckup.checkup),
    type: data && data.getCareCheckup.type,
    createdBy:
      !usersLoading &&
      data?.getCareCheckup &&
      users.find((u) => u.id === data.getCareCheckup.createdBy),
  };
};

export default useCareCheckup;
