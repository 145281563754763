import React, { useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const SnackbarContext = React.createContext();

const SnackbarProvider = ({ children }) => {
  const [snackState, setSnackState] = useState({
    open: false,
    message: '',
    duration: 3000,
    severity: 'success',
  });
  const handleClose = () => {
    setSnackState((prev) => ({ ...prev, open: false }));
  };
  const showSnackbar = (message, duration = 3000, severity = 'success') => {
    setSnackState((prev) => ({ ...prev, message, duration, severity }));
    setTimeout(() => {
      // Test: delay open set state after set messages to avoid a
      // snackbar double rendering
      setSnackState((prev) => ({ ...prev, open: true }));
    }, 300);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      <>
        {children}
        <Snackbar
          open={snackState.open}
          onClose={handleClose}
          autoHideDuration={snackState.duration}
        >
          <Alert onClose={handleClose} severity={snackState.severity}>
            {snackState.message}
          </Alert>
        </Snackbar>
      </>
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
export { SnackbarContext };
