import React, { useState } from 'react';
import {
  Button,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CheckIcon from '@material-ui/icons/Check';

import AppLayout from '../System/AppLayout';
import Absences from './Absences';
import SearchField from '../System/SearchField';
import ConfirmDialog from '../System/ConfirmDialog';
import ProgressButton from '../System/ProgressButton';
import useDialog from '../System/useDialog';
import useAbsences from './useAbsences';
import useMarkAbsencesProcessed from './useMarkAbsencesProcessed';

const useStyles = makeStyles((theme) => ({
  processedButton: {
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
    minWidth: '125px',
    maxWidth: '300px',
  },
  processedContainer: {
    marginLeft: 'auto',
    textAlign: 'left',
    backgroundColor: theme.palette.background.paper,
  },
  loadMoreContainer: {
    margin: theme.spacing(2),
  },
}));

const AbsencesPage = () => {
  const classes = useStyles();
  const minWidth500px = useMediaQuery('(min-width:500px)');
  const {
    loading,
    processedLoading,
    absences,
    showProcessed,
    search,
    onProcessedChange,
    onSearchChange,
    hasMoreProcessed,
    onFetchMoreProcessed,
    refetch,
  } = useAbsences();
  const [checkedAbsences, setCheckedAbsences] = useState([]);
  const { onMarkAbsencesProcessed, loading: loadingMarkProcessed } =
    useMarkAbsencesProcessed(checkedAbsences);
  const { open, onOpen, onClose } = useDialog();

  return (
    <AppLayout
      title="Absences"
      action={
        <Grid
          container
          direction="row"
          justifyContent={minWidth500px ? 'flex-end' : 'flex-start'}
          alignItems="center"
        >
          {minWidth500px ? (
            <>
              <Grid item xs>
                <div className={classes.searchContainer}>
                  <SearchField value={search} onChange={onSearchChange} />
                </div>
              </Grid>
              <Grid item>
                <div className={classes.processedContainer}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showProcessed}
                        color="primary"
                        onChange={onProcessedChange}
                      />
                    }
                    label="Voir les absences traitées"
                  />
                </div>
              </Grid>
              {showProcessed && hasMoreProcessed && (
                <Grid item>
                  <div className={classes.processedContainer}>
                    <ProgressButton
                      loading={loading || processedLoading}
                      variant="outlined"
                      color="primary"
                      onClick={onFetchMoreProcessed}
                    >
                      Voir plus...
                    </ProgressButton>
                  </div>
                </Grid>
              )}
              {checkedAbsences.length > 0 && !showProcessed && (
                <Grid item>
                  <div className={classes.processedButton}>
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      startIcon={<CheckIcon />}
                      onClick={onOpen}
                    >
                      Traiter
                    </Button>
                  </div>
                </Grid>
              )}
            </>
          ) : (
            <Grid item xs>
              <div className={classes.searchContainer}>
                <SearchField value={search} onChange={onSearchChange} />
              </div>
              <div className={classes.processedContainer}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showProcessed}
                      color="primary"
                      onChange={onProcessedChange}
                    />
                  }
                  label="Voir les absences traitées"
                />
              </div>
              {checkedAbsences.length > 0 && !showProcessed && (
                <div className={classes.processedContainer}>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    startIcon={<CheckIcon />}
                    onClick={onOpen}
                  >
                    Traiter
                  </Button>
                </div>
              )}
            </Grid>
          )}
        </Grid>
      }
    >
      <Absences
        loading={loading || processedLoading || loadingMarkProcessed}
        absences={absences}
        showProcessed={showProcessed}
        checkedAbsences={checkedAbsences}
        onCheckedAbsences={(checked) => setCheckedAbsences(checked)}
      />
      {showProcessed && hasMoreProcessed && (
        <div className={classes.loadMoreContainer}>
          <ProgressButton
            loading={loading || processedLoading}
            variant="outlined"
            color="primary"
            onClick={onFetchMoreProcessed}
          >
            Voir plus...
          </ProgressButton>
        </div>
      )}
      <ConfirmDialog
        title="Confirmation"
        open={open}
        confirmLoading={loading || processedLoading}
        onConfirm={() => {
          onMarkAbsencesProcessed();
          setCheckedAbsences([]);
          refetch();
        }}
        onClose={onClose}
      >
        {`Êtes-vous sûr de vouloir marquer ${
          checkedAbsences.length > 1 ? 'ces' : 'cette'
        } absence${checkedAbsences.length > 1 ? 's' : ''} comme traitée${
          checkedAbsences.length > 1 ? 's' : ''
        } ?`}
      </ConfirmDialog>
    </AppLayout>
  );
};

export default AbsencesPage;
