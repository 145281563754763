import React from 'react';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { fr } from 'date-fns/locale';

function SelectTimePicker({
  disabled = false,
  disableToolbar = true,
  showTodayButton = false,
  disableFuture = false,
  format = 'HH:mm',
  value,
  onChange,
  readOnly = false,
  label,
}) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
      <TimePicker
        id="time-picker-inline"
        disabled={disabled}
        disableToolbar={disableToolbar}
        disableFuture={disableFuture}
        inputVariant="outlined"
        format={format}
        margin="dense"
        label={label}
        value={value}
        onChange={onChange}
        keyboardbuttonprops={{
          'aria-label': 'change time',
        }}
        readOnly={readOnly}
        ampm={false}
        invalidDateMessage="Format de l'heure invalide"
        cancelLabel="Annuler"
      />
    </MuiPickersUtilsProvider>
  );
}

export default SelectTimePicker;
