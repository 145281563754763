import { useState } from 'react';

const useDialog = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return { open, onOpen: handleOpen, onClose: handleClose };
};

export default useDialog;
