import { gql, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import TextField from '../System/TextField';
import useDialog from '../System/useDialog';
import TransmissionFragment from '../../fragments/TransmissionFragment';
import ProgressButton from '../System/ProgressButton';
import useSnackbar from '../System/useSnackbar';
import useNetworkStatus from '../System/useNetworkStatus';
import MomentSelect from '../System/MomentSelect';
import useUpdatePrescription from '../Prescription/useUpdatePrescription';
import useGenerateTransmissions from '../Prescription/useGenerateTransmissions';
import { GET_TRANSMISSION } from './useTransmission';
import { LIST_TRANSMISSIONS_BY_TOUR } from './useTransmissions';
import getMoment from '../../utils/get-moment';
import { urlWithBacks } from '../../utils/back-search';
import SelectDateTimePicker from '../System/SelectDateTimePicker';
import SelectTimePicker from '../System/SelectTimePicker';

const useStyles = makeStyles((theme) => ({
  multiFieldSelect: {
    flex: 1,
  },
  datePicker: {
    marginTop: '7px',
  },
}));

const UPDATE_TRANSMISSION = gql`
  mutation UpdateTransmission(
    $userGroupId: String!
    $tourId: String!
    $newTourId: String
    $year: String!
    $id: String!
    $date: String!
    $type: String!
    $moment: String!
    $comment: String!
  ) {
    updateTransmission(
      userGroupId: $userGroupId
      tourId: $tourId
      newTourId: $newTourId
      year: $year
      id: $id
      date: $date
      type: $type
      moment: $moment
      comment: $comment
    ) {
      ...TransmissionFragment
    }
  }

  ${TransmissionFragment}
`;

const UPDATE_MODE = {
  all: 'all',
  one: 'one',
};

const UpdateTransmission = React.forwardRef(
  ({ transmission, tours, onUpdated }, ref) => {
    const classes = useStyles();

    const matches375px = useMediaQuery('(min-width:375px)');
    const treatmentComment = transmission.prescription?.treatments.find(
      (t) => t.id === transmission.treatmentId,
    )?.comment;
    let comment =
      transmission.comment && transmission.comment !== ''
        ? transmission.comment
        : treatmentComment
        ? treatmentComment
        : '';
    const transDate = moment.utc(Number(transmission.date));
    const [treatmentDate, setTreatmentDate] = React.useState(
      new Date(
        transDate.year(),
        transDate.month(),
        transDate.date(),
        transDate.hour(),
        transDate.minute(),
      ),
    );

    const [updateMode, setUpdateMode] = React.useState(UPDATE_MODE.one);

    const { userGroupId, tourId, date, transmissionId } = useParams();

    const { open, onClose, onOpen } = useDialog();
    const { replace, push } = useHistory();

    const [newDate, setNewDate] = useState({
      date: treatmentDate,
      timeHour: transDate.hour().toString(),
      timeMinute: transDate.minute().toString(),
    });
    const [newMoment, setNewMoment] = useState(
      transmission.moment || 'morning',
    );
    const [newType, setNewType] = useState(transmission.type || '');
    const [newComment, setNewComment] = useState(comment);
    const [newTour, setNewTour] = useState(tourId);
    const minDate = moment
      .utc(Number(date))
      .startOf('day')
      .valueOf()
      .toString();
    const maxDate = moment.utc(Number(date)).endOf('day').valueOf().toString();

    const handleTreatmentDateChange = (date) => {
      if (date) {
        const timeHour = date.getHours();
        const timeMinute = date.getMinutes();
        const transMoment = getMoment(timeHour, timeMinute);
        setTreatmentDate(date);
        setNewMoment(transMoment ? transMoment.value : transmission.moment);
        setNewDate({
          date,
          timeHour,
          timeMinute,
        });
      }
    };

    const handleTreatmentTimeChange = (date) => {
      if (date) {
        const timeHour = date.getHours();
        const timeMinute = date.getMinutes();
        const transMoment = getMoment(timeHour, timeMinute);

        setTreatmentDate(
          new Date(
            transDate.year(),
            transDate.month(),
            transDate.date(),
            timeHour,
            timeMinute,
          ),
        );
        setNewMoment(transMoment ? transMoment.value : transmission.moment);
        setNewDate({
          date,
          timeHour,
          timeMinute,
        });
      }
    };

    const {
      loading: loadingUpdatePrescriptionComment,
      onUpdate: onUpdatePrescriptionComment,
    } = useUpdatePrescription({
      generalInputs: {
        ...transmission.prescription,
        treatments: transmission.prescription?.treatments.map((t) => {
          if (t.id === transmission.treatmentId) {
            return { ...t, comment: newComment };
          }
          return t;
        }),
      },
      patientId: transmission.prescription?.patientId,
      prescriptionId: transmission.prescription?.id,
      successMessage: 'Transmission du soin mise à jour',
    });

    const {
      error: errorUpdatePrescription,
      loading: loadingUpdatePrescription,
      onUpdate: onUpdatePrescription,
    } = useUpdatePrescription({
      generalInputs: {
        ...transmission.prescription,
        treatments: transmission.prescription?.treatments.map((t) => {
          if (t.id === transmission.treatmentId) {
            return {
              ...t,
              timeHour: Number(newDate.timeHour),
              timeMinute: Number(newDate.timeMinute),
              moment: newMoment,
              type: newType,
              tour: newTour,
            };
          }
          return t;
        }),
      },
      patientId: transmission.prescription?.patientId,
      prescriptionId: transmission.prescription?.id,
      successMessage: 'Soin mise à jour',
    });

    const {
      loading: loadingGenerateTransmissions,
      onGenerate: onGenerateTransmissions,
    } = useGenerateTransmissions({
      onGenerated: () => {
        onClose();
        onUpdated();
        push(
          `/g/${userGroupId}/transmissions/${newTour}/${newDate.date.valueOf()}`,
        );
      },
    });

    const handleUpdate = async (event) => {
      event && event.preventDefault();
      try {
        if (updateMode === UPDATE_MODE.one || !transmission.prescription) {
          await updateTransmission();
          if (transmission.prescription) {
            await onUpdatePrescriptionComment();
          }
        } else if (updateMode === UPDATE_MODE.all) {
          const updatedTreatment = {
            ...transmission.prescription?.treatments.find(
              (t) => t.id === transmission.treatmentId,
            ),
            type: newType,
            tour: newTour,
          };

          await onUpdatePrescription();
          await onGenerateTransmissions({
            prescriptionId: transmission.prescription.id,
            treatments: [updatedTreatment],
          });
        }
      } catch (err) {
        // Do nothing, let Apollo useMutation handle errors
      }
    };

    const handleChangeMode = (event) => {
      const newMode = event.target.value;

      setUpdateMode(newMode);
      if (newMode === UPDATE_MODE.all) {
        const transMoment = getMoment(newDate.timeHour, newDate.timeMinute);
        const date = new Date(
          transDate.year(),
          transDate.month(),
          transDate.date(),
          newDate.timeHour,
          newDate.timeMinute,
        );
        setNewDate({
          date,
          timeHour: newDate.timeHour,
          timeMinute: newDate.timeMinute,
        });
        setTreatmentDate(date);
        setNewMoment(transMoment ? transMoment.value : transmission.moment);
      }
    };

    const { offline } = useNetworkStatus();
    const { showSnackbar } = useSnackbar();
    const updatedTransmission = transmission
      ? {
          userGroupId,
          tourId,
          newTourId: newTour,
          year: moment.utc(Number(date)).year().toString(),
          id: transmissionId,
          date: moment()
            .utc()
            .year(newDate.date.getFullYear())
            .month(newDate.date.getMonth())
            .date(newDate.date.getDate())
            .hour(Number(newDate.timeHour))
            .minute(Number(newDate.timeMinute))
            .second(0)
            .valueOf()
            .toString(),
          type: newType,
          moment: newMoment,
          comment: newComment,
        }
      : {};
    const [updateTransmission, { loading: loadingUpdateTransmission, error }] =
      useMutation(UPDATE_TRANSMISSION, {
        variables: updatedTransmission,
        optimisticResponse: offline
          ? {
              __typename: 'Mutation',
              updateTransmission: {
                __typename: 'Transmission',
                ...transmission,
                ...updatedTransmission,
              },
            }
          : null,
        update(cache, { data: { updateTransmission } }) {
          try {
            cache.writeQuery({
              query: GET_TRANSMISSION,
              variables: {
                userGroupId,
                tourId,
                year: Number(moment(Number(date)).format('YYYY')),
                id: transmissionId,
              },
              data: {
                getTransmission: updateTransmission,
              },
            });
          } catch (err) {
            console.log(err);
          }

          if (tourId !== newTour) {
            try {
              const { listTransmissionsByTour } = cache.readQuery({
                query: LIST_TRANSMISSIONS_BY_TOUR,
                variables: { userGroupId, tourId, minDate, maxDate },
              });
              cache.writeQuery({
                query: LIST_TRANSMISSIONS_BY_TOUR,
                variables: { userGroupId, tourId, minDate, maxDate },
                data: {
                  listTransmissionsByTour: listTransmissionsByTour.filter(
                    (trans) => trans.id !== transmissionId,
                  ),
                },
              });
            } catch (err) {}
          }
          if (offline) {
            onClose();
            onUpdated();
            showSnackbar(
              'Vous êtes déconnecté, la mise à jour de la transmission est en attente de reconnexion...',
              5000,
            );
          }
        },
        onCompleted() {
          onClose();
          onUpdated();
          if (tourId !== newTour) {
            replace(
              urlWithBacks({
                url: `/g/${userGroupId}/transmission/${newTour}/${date}/${transmission.id}`,
                back: `/g/${userGroupId}/transmissions/${tourId}/${date}`,
              }),
            );
          }
        },
      });

    const loading =
      loadingUpdateTransmission ||
      loadingUpdatePrescription ||
      loadingUpdatePrescriptionComment ||
      loadingGenerateTransmissions;

    return (
      <>
        <IconButton onClick={onOpen}>
          <EditIcon />
        </IconButton>

        <Dialog
          open={open}
          onClose={() => {
            setUpdateMode(UPDATE_MODE.one);
            onClose();
          }}
        >
          <DialogTitle>
            {loading ? <CircularProgress size={20} /> : null}
            Modifier la transmission
            {errorUpdatePrescription ? (
              <Typography variant="body2" paragraph color="error">
                {errorUpdatePrescription}
              </Typography>
            ) : null}
          </DialogTitle>
          <DialogContent>
            <div>
              <TextField
                name="type"
                label="Type de soins"
                value={newType}
                onChange={(e) => setNewType(e.target.value)}
              />
            </div>

            <Grid container justifyContent="flex-start">
              <Grid item className={classes.datePicker} xs={12} sm="auto">
                {updateMode === UPDATE_MODE.one ? (
                  <SelectDateTimePicker
                    label="Date des soins"
                    format={`${
                      matches375px
                        ? 'E dd/MM/yyyy à HH:mm'
                        : 'dd/MM/yyyy à HH:mm'
                    }`}
                    value={treatmentDate}
                    onChange={handleTreatmentDateChange}
                  />
                ) : (
                  <SelectTimePicker
                    label="Heure des soins"
                    value={treatmentDate}
                    onChange={handleTreatmentTimeChange}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm="auto">
                <MomentSelect
                  name="startMoment"
                  size="small"
                  value={newMoment}
                  onChange={(e) => setNewMoment(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="tour"
                  value={newTour}
                  label="Tournée"
                  size="small"
                  select
                  SelectProps={{
                    native: true,
                  }}
                  onChange={(e) => setNewTour(e.target.value)}
                >
                  {tours.map((tour) => (
                    <option key={tour.id} value={tour.id}>
                      {tour.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            {transmission.prescription && !offline ? (
              <FormControl component="fieldset" style={{ marginTop: '7px' }}>
                <FormLabel component="legend">Concerne: </FormLabel>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  spacing={1}
                >
                  <Grid item>
                    <RadioGroup
                      row={updateMode === UPDATE_MODE.one}
                      aria-label="updateMode"
                      name="updateMode"
                      value={updateMode}
                      onChange={handleChangeMode}
                    >
                      <FormControlLabel
                        value={UPDATE_MODE.one}
                        control={<Radio />}
                        label="Uniquement ce RDV"
                      />
                      <FormControlLabel
                        value={UPDATE_MODE.all}
                        control={<Radio />}
                        label="Tous les RDV"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </FormControl>
            ) : null}

            {updateMode === UPDATE_MODE.one && (
              <div>
                <TextField
                  name="comment"
                  multiline
                  value={newComment}
                  label="Transmissions"
                  onChange={(e) => {
                    setNewComment(e.target.value);
                  }}
                />
              </div>
            )}

            {error?.message ? (
              <Typography color="error">
                {error.message === 'transmission slot already exists'
                  ? 'Un rendez-vous existe déjà pour ce créneau.'
                  : `Une erreur s'est produite`}
              </Typography>
            ) : null}
          </DialogContent>

          <DialogActions>
            <Grid container justifyContent="flex-end" spacing={1}>
              <Grid item>
                <Button
                  onClick={() => {
                    setUpdateMode(UPDATE_MODE.one);
                    onClose();
                  }}
                  disabled={loading}
                >
                  Annuler
                </Button>
              </Grid>
              <Grid item>
                <ProgressButton
                  variant="outlined"
                  disabled={loading}
                  onClick={handleUpdate}
                >
                  Valider
                </ProgressButton>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </>
    );
  },
);

export default UpdateTransmission;
