import { gql } from '@apollo/client';

import DoctorFragment from './DoctorFragment';

const PrescriptionSummaryFragment = gql`
  fragment PrescriptionSummaryFragment on PrescriptionSummary {
    id
    dateDay
    dateMonth
    dateYear
    cause
    firstTreatmentDate
    soins2000Number
    doctor {
      ...DoctorFragment
    }
  }

  ${DoctorFragment}
`;

export default PrescriptionSummaryFragment;
