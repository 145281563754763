import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import UserGroupFragment from '../../fragments/UserGroupFragment';

const DISABLE_USER_GROUP_TOUR = gql`
  mutation DisableUserGroupTour($userGroupId: String!, $id: String!) {
    disableUserGroupTour(userGroupId: $userGroupId, id: $id) {
      ...UserGroupFragment
    }
  }

  ${UserGroupFragment}
`;

const useDisableTour = () => {
  const { userGroupId } = useParams();

  const [disableUserGroupTour, { loading }] = useMutation(
    DISABLE_USER_GROUP_TOUR,
  );

  const handleDisable = (id) => {
    disableUserGroupTour({ variables: { id, userGroupId } });
  };

  return {
    loading,
    onDisable: handleDisable,
  };
};

export default useDisableTour;
