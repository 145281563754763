import { jsPDF } from 'jspdf';
import { PDFDocument } from 'pdf-lib';

const concatImagesToPdf = ({ name, files, imageInfos }) => {
  let doc = null;

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const imgData = imageInfos[`${file.name}_${file.lastModified}`];
    const imgInfos = jsPDF.API.getImageProperties(imgData.data);
    const imgWidth = imgInfos.width;
    const imgHeight = imgInfos.height;
    const orientation = imgWidth < imgHeight ? 'p' : 'l';

    if (!doc) {
      doc = new jsPDF({ orientation });
    } else {
      doc.addPage('a4', orientation);
    }

    const width = doc.internal.pageSize.width - 20;
    const height = doc.internal.pageSize.height - 20;
    let aspectWidth = width;
    let aspectHeight = height;
    doc.addImage(imgData.data, 'JPEG', 10, 10, aspectWidth, aspectHeight);
  }

  const fileName = `${name}.pdf`;
  const output = doc.output('arraybuffer', { name: fileName });
  const outputFile = new File([output], fileName, {
    type: 'application/pdf',
  });

  return outputFile;
};

const concatPdfsToPdf = async ({ name, files, pdfInfos }) => {
  const mergedPdf = await PDFDocument.create();

  const createInnerPromise = async (arrayBuffer) => {
    const pdf = await PDFDocument.load(arrayBuffer);
    return await mergedPdf.copyPages(pdf, pdf.getPageIndices());
  };

  const outerPromise = files.map((file) => {
    const pdfData = pdfInfos[`${file.name}_${file.lastModified}`].data;
    const innerPromise = createInnerPromise(pdfData);
    return innerPromise;
  });

  const resultOuterPromise = await Promise.all(outerPromise);

  resultOuterPromise.forEach((pageArray) => {
    pageArray.forEach((page) => {
      mergedPdf.addPage(page);
    });
  });

  const fileName = `${name}.pdf`;
  const output = await mergedPdf.save();
  const outputFile = new File([output], fileName, {
    type: 'application/pdf',
  });

  return outputFile;
};

const concatFilesToPdf = async ({ name, files, fileInfos }) => {
  const mergedPdf = await PDFDocument.create();

  const createInnerPromise = async (file) => {
    const fileName = file.name;
    let pdf;
    const dataInfos = fileInfos[`${file.name}_${file.lastModified}`];
    const data = dataInfos.data;

    if (fileName.toUpperCase().endsWith('.PDF')) {
      pdf = await PDFDocument.load(data);
    } else {
      const infos = jsPDF.API.getImageProperties(data);
      const imgWidth = infos.width;
      const imgHeight = infos.height;
      const orientation = imgWidth < imgHeight ? 'p' : 'l';
      const pageSize =
        orientation === 'p' ? [595.28, 841.89] : [841.89, 595.28];
      pdf = await PDFDocument.create();
      const image = fileName.toUpperCase().endsWith('.PNG')
        ? await pdf.embedPng(data)
        : await pdf.embedJpg(data);
      const page = pdf.addPage(pageSize);
      const width = pageSize[0] - 20;
      const height = pageSize[1] - 20;
      let aspectWidth = width;
      let aspectHeight = height;

      page.drawImage(image, {
        x: 10,
        y: 10,
        width: aspectWidth,
        height: aspectHeight,
      });
    }

    return await mergedPdf.copyPages(pdf, pdf.getPageIndices());
  };

  const outerPromise = Array.from(files).map((file) => {
    const innerPromise = createInnerPromise(file);

    return innerPromise;
  });

  const resultOuterPromise = await Promise.all(outerPromise);

  resultOuterPromise.forEach((pageArray) => {
    pageArray.forEach((page) => {
      mergedPdf.addPage(page);
    });
  });

  const fileName = `${name}.pdf`;
  const output = await mergedPdf.save();
  const outputFile = new File([output], fileName, {
    type: 'application/pdf',
  });

  return outputFile;
};

export { concatFilesToPdf, concatImagesToPdf, concatPdfsToPdf };
