import React, { useMemo } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import {
  makeStyles,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MenuIcon from '@material-ui/icons/MoreVert';
import moment from 'moment';

import AppLayout from '../System/AppLayout';
import useTransmission from './useTransmission';
import Transmission from './Transmission';
import SectionProgress from '../System/SectionProgress';
import DoneButton from './DoneButton';
import useTours from '../Tours/useTours';
import useMenu from '../System/useMenu';
import useNetworkStatus from '../System/useNetworkStatus';
import useTransmissions from './useTransmissions';
import UpdateTransmission from './UpdateTransmission';
import DeleteTransmission from './DeleteTransmission';
import ShowIf from '../System/ShowIf';
import {
  backSearch,
  initialBackSearch,
  urlWithBacks,
} from '../../utils/back-search';

const useStyles = makeStyles((theme) => ({
  transmission: {
    padding: theme.spacing(4, 0),
  },
}));

const TransmissionPage = () => {
  const { offline, readOnly } = useNetworkStatus();
  const classes = useStyles();
  const { loading: toursLoading, tours } = useTours();
  const { userGroupId, tourId, date } = useParams();

  const { anchorEl, onClick: onMenuClick, onClose: onMenuClose } = useMenu();

  const { transmissions } = useTransmissions({
    fetchPolicy: 'cache-first',
  });

  const { loading, transmission, seeAllLogs, hasMoreLogs, onToggleSeeAllLogs } =
    useTransmission({
      fetchPolicy: offline ? 'cache-first' : 'network-only',
    });

  const next = useMemo(() => {
    if (transmission && transmissions?.length) {
      const index = transmissions.findIndex((t) => t.id === transmission.id);
      return transmissions.length > index + 1 ? transmissions[index + 1] : null;
    }
  }, [transmission, transmissions]);

  const previous = useMemo(() => {
    if (transmission && transmissions?.length) {
      const index = transmissions.findIndex((t) => t.id === transmission.id);
      return index > 0 ? transmissions[index - 1] : null;
    }
  }, [transmission, transmissions]);

  const showDone = Boolean(
    !offline &&
      transmission &&
      !transmission.done &&
      moment
        .utc()
        .endOf('day')
        .isSameOrAfter(moment.utc(Number(transmission.date))),
  );
  const isEditable = Boolean(
    !readOnly &&
      transmission &&
      moment(Number(transmission.date)).isSameOrAfter(moment().startOf('day')),
  );
  const { search: s, pathname } = useLocation();
  const backToUrl = backSearch(s);
  const initialBackToUrl = initialBackSearch(s);
  const backTo = `/g/${userGroupId}/transmissions/${tourId}/${date}`;

  return (
    <AppLayout
      title="Transmission"
      direction="row"
      backTo={backTo}
      action={
        loading || !transmission ? null : (
          <Grid
            container
            wrap="nowrap"
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <ShowIf condition={showDone}>
              <Grid item xs>
                <DoneButton />
              </Grid>
            </ShowIf>
            <ShowIf condition={isEditable}>
              <Grid item>
                <UpdateTransmission
                  transmission={transmission}
                  tours={tours}
                  onUpdated={onMenuClose}
                />
              </Grid>
            </ShowIf>
            <ShowIf
              condition={
                !readOnly && !transmission.absence && !transmission.done
              }
            >
              <Grid item>
                <IconButton onClick={onMenuClick}>
                  <MenuIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={onMenuClose}
                >
                  {isEditable ? (
                    <DeleteTransmission
                      transmission={transmission}
                      onDeleted={onMenuClose}
                      backTo={backToUrl || backTo}
                    />
                  ) : null}
                  <MenuItem
                    component={Link}
                    to={urlWithBacks({
                      url: `/g/${userGroupId}/absence/create/${transmission.patient.id}`,
                      back: pathname,
                      initialBack: initialBackToUrl,
                    })}
                  >
                    <ListItemIcon>
                      <AddIcon fontSize="small" />
                    </ListItemIcon>
                    Ajouter une absence
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to={urlWithBacks({
                      url: `/g/${userGroupId}/message/create/${transmission.patient.id}`,
                      back: pathname,
                      initialBack: initialBackToUrl,
                    })}
                  >
                    <ListItemIcon>
                      <AddIcon fontSize="small" />
                    </ListItemIcon>
                    Ajouter un message
                  </MenuItem>
                </Menu>
              </Grid>
            </ShowIf>
          </Grid>
        )
      }
    >
      {loading || toursLoading || !transmission ? (
        <SectionProgress />
      ) : (
        <Transmission
          className={classes.transmission}
          tour={tours.find((t) => t.id === tourId)}
          transmission={transmission}
          seeAllLogs={seeAllLogs}
          hasMoreLogs={hasMoreLogs}
          nextTransmission={next}
          previousTransmission={previous}
          onToggleSeeAllLogs={onToggleSeeAllLogs}
        />
      )}
    </AppLayout>
  );
};

export default TransmissionPage;
