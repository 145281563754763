import React from 'react';
import {
  Fab,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemText,
  Switch,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Link } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';

import useUserGroups from './useUserGroups';
import SectionProgress from '../System/SectionProgress';
import AdminTopbar from '../System/AdminTopbar';
import LogoutButton from '../System/LogoutButton';
import SearchField from '../System/SearchField';
import useCurrentUser from '../System/useCurrentUser';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  emptyState: {
    marginTop: theme.spacing(4),
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: 'auto',
    minWidth: '125px',
    maxWidth: '300px',
    marginRight: '1em',
  },
  listContainer: {
    flex: 1,
  },
  listItemRoot: {
    display: 'flex',
    justifyContent: 'flex-start',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const UserGroups = () => {
  const classes = useStyles();
  const {
    userGroups,
    loading,
    search,
    onSearchChange,
    archived,
    onArchivedChange,
  } = useUserGroups();
  const { isAdmin, isSuperAdmin } = useCurrentUser();

  return (
    <>
      <AdminTopbar
        title="Groupes utilisateurs"
        action={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs>
              <div className={classes.searchContainer}>
                <SearchField value={search} onChange={onSearchChange} />
              </div>
            </Grid>
            <Grid item>
              <FormControlLabel
                className={classes.archived}
                control={
                  <Switch
                    color="primary"
                    checked={archived}
                    onChange={onArchivedChange}
                  />
                }
                label="Archivé"
              />
            </Grid>
            <Grid item>
              <LogoutButton />
            </Grid>
          </Grid>
        }
      />
      {loading ? (
        <SectionProgress />
      ) : userGroups.length ? (
        <div className={classes.listContainer}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                width={width}
                itemCount={userGroups.length}
                itemSize={45}
                disablePadding
              >
                {({ index, style }) => {
                  const userGroup = userGroups[index];
                  return (
                    <ListItem
                      button
                      divider
                      component={Link}
                      style={style}
                      to={
                        isAdmin || isSuperAdmin
                          ? `/admin/user-groups/edit/${userGroup.id}`
                          : `/g/${userGroup.id}`
                      }
                      key={userGroup.id}
                    >
                      <ListItemText>{userGroup.name}</ListItemText>
                      <ArrowRightIcon />
                    </ListItem>
                  );
                }}
              </List>
            )}
          </AutoSizer>
        </div>
      ) : (
        <Typography className={classes.emptyState} align="center">
          Aucun groupe utilisateur
        </Typography>
      )}

      {isSuperAdmin ? (
        <Fab
          className={classes.fab}
          component={Link}
          to="/admin/user-groups/create"
          color="primary"
        >
          <AddIcon />
        </Fab>
      ) : null}
    </>
  );
};

export default UserGroups;
