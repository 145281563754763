export const T = {
  listPrescriptionsSearch: 'LIST_PRESCRIPTIONS_SEARCH',
  listPrescriptionsDense: 'LIST_PRESCRIPTIONS_DENSE',
  listPrescriptionsOrder: 'LIST_PRESCRIPTIONS_ORDER',
  listPrescriptionsOrderBy: 'LIST_PRESCRIPTIONS_ORDER_BY',
  listPatientsSearch: 'LIST_PATIENTS_SEARCH',
  listPatientsArchived: 'LIST_PATIENTS_ARCHIVED',
  listMessagesSearch: 'LIST_MESSAGES_SEARCH',
  listMessagesProcessed: 'LIST_MESSAGES_PROCESSED',
  listAbsencesSearch: 'LIST_ABSENCES_SEARCH',
  listAbsencesProcessed: 'LIST_ABSENCES_PROCESSED',
  listCareCheckupSearch: 'LIST_CARE_CHECKUP_SEARCH',
  listDoctorsSearch: 'LIST_DOCTORS_SEARCH',
  listProvidersSearch: 'LIST_PROVIDERS_SEARCH',
  listUserGroupsSearch: 'LIST_USER_GROUPS_SEARCH',
  listUserGroupsArchived: 'LIST_USER_GROUPS_ARCHIVED',
};

const reducers = {
  [T.listPrescriptionsSearch]: (state, action) => ({
    ...state,
    listPrescriptionsSearch: action.payload,
  }),
  [T.listPrescriptionsDense]: (state, action) => ({
    ...state,
    listPrescriptionsDense: action.payload,
  }),
  [T.listPrescriptionsOrder]: (state, action) => ({
    ...state,
    listPrescriptionsOrder: action.payload,
  }),
  [T.listPrescriptionsOrderBy]: (state, action) => ({
    ...state,
    listPrescriptionsOrderBy: action.payload,
  }),
  [T.listPatientsSearch]: (state, action) => ({
    ...state,
    listPatientsSearch: action.payload,
  }),
  [T.listPatientsArchived]: (state, action) => ({
    ...state,
    listPatientsArchived: action.payload,
  }),
  [T.listMessagesSearch]: (state, action) => ({
    ...state,
    listMessagesSearch: action.payload,
  }),
  [T.listMessagesProcessed]: (state, action) => ({
    ...state,
    listMessagesProcessed: action.payload,
  }),
  [T.listAbsencesSearch]: (state, action) => ({
    ...state,
    listAbsencesSearch: action.payload,
  }),
  [T.listAbsencesProcessed]: (state, action) => ({
    ...state,
    listAbsencesProcessed: action.payload,
  }),
  [T.listCareCheckupSearch]: (state, action) => ({
    ...state,
    listCareCheckupSearch: action.payload,
  }),
  [T.listDoctorsSearch]: (state, action) => ({
    ...state,
    listDoctorsSearch: action.payload,
  }),
  [T.listProvidersSearch]: (state, action) => ({
    ...state,
    listProvidersSearch: action.payload,
  }),
  [T.listUserGroupsSearch]: (state, action) => ({
    ...state,
    listUserGroupsSearch: action.payload,
  }),
  [T.listUserGroupsArchived]: (state, action) => ({
    ...state,
    listUserGroupsArchived: action.payload,
  }),
};

export default reducers;
