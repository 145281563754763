import { useState } from 'react';
import shortid from 'shortid';

import useUpload from '../../utils/useUpload';
import useCreatePrescription from './useCreatePrescription';
import useUpdatePrescription from './useUpdatePrescription';
import useGenerateTransmissions from './useGenerateTransmissions';
import useDeleteTransmissionsByTreatment from '../Transmission/useDeleteTransmissionsByTreatment';
import { useHistory, useParams } from 'react-router-dom';

const GET_DEFAULT_TREATMENT = (tours, previousDate = new Date(), patient) => {
  return {
    id: shortid.generate(),
    day: previousDate.getDate(),
    month: previousDate.getMonth(),
    year: previousDate.getFullYear(),
    type: '',
    timeHour: 5,
    timeMinute: 0,
    count: 1,
    duration: 'days',
    moment: 'morning',
    tour:
      tours.find((t) => t.id === patient.preferredTourId)?.id || tours[0].id,
    frequency: 1,
    frequencyDuration: 'days',
    weekDays: [],
    comment: '',
    cotation: '',
    liens: '',
    outsourcedTreatments: [],
  };
};

const GET_DEFAULT_GENERAL_INPUTS = (initialPrescription, tours, patient) => {
  const now = new Date();
  return {
    num: initialPrescription.num || '',
    dateDay: initialPrescription.dateDay || now.getDate(),
    dateMonth: initialPrescription.dateMonth ?? now.getMonth(),
    dateYear: initialPrescription.dateYear || now.getFullYear(),
    startDay: initialPrescription.startDay || now.getDate(),
    startMonth: initialPrescription.startMonth || now.getMonth(),
    startYear: initialPrescription.startYear || now.getFullYear(),
    photo: initialPrescription.photo,
    cause: initialPrescription.cause || 'disease',
    pec: initialPrescription.pec || [],
    pecImage: initialPrescription.pecImage,
    motherHoodDay: initialPrescription.motherHoodDay || now.getDate(),
    motherHoodMonth: initialPrescription.motherHoodMonth || now.getMonth(),
    motherHoodYear: initialPrescription.motherHoodYear || now.getFullYear(),
    workAccidentDay: initialPrescription.workAccidentDay || now.getDate(),
    workAccidentMonth: initialPrescription.workAccidentMonth || now.getMonth(),
    workAccidentYear: initialPrescription.workAccidentYear || now.getFullYear(),
    workAccidentPhoto: initialPrescription.workAccidentPhoto,
    soins2000Number: initialPrescription.soins2000Number || '',
    chronic: initialPrescription.chronic || false,
    treatments: initialPrescription.treatments || [
      GET_DEFAULT_TREATMENT(tours, new Date(), patient),
    ],
    doctor: initialPrescription.doctor,
    archived: Boolean(initialPrescription.archived),
    cotation: initialPrescription.cotation || '',
    liens: initialPrescription.liens || '',
  };
};

const usePrescriptionForm = ({
  initialPrescription,
  type,
  tours,
  patient,
  onSubmitted,
} = {}) => {
  const { userGroupId, patientId, prescriptionId } = useParams();
  const { goBack, replace } = useHistory();

  const [generalInputs, setGeneralInputs] = useState(
    GET_DEFAULT_GENERAL_INPUTS(initialPrescription, tours, patient),
  );

  const handleGeneralInputChange = (event) => {
    const { name, value, checked, type } = event.target;

    setGeneralInputs((prevGeneralInputs) => {
      return {
        ...prevGeneralInputs,
        [name]: type === 'checkbox' ? checked : value,
      };
    });
  };

  const handleGeneralInputsChange = (values) => {
    setGeneralInputs((prevGeneralInputs) => {
      return {
        ...prevGeneralInputs,
        ...values,
      };
    });
  };

  // Images uploads
  const { progress: photoProgress, onUpload: handlePhotoUploadChange } =
    useUpload({
      name: 'photo',
      onUploaded: handleGeneralInputChange,
    });

  const {
    progress: workAccidentPhotoProgress,
    onUpload: handleWorkAccidentPhotoUploadChange,
  } = useUpload({
    name: 'workAccidentPhoto',
    onUploaded: handleGeneralInputChange,
  });

  const { progress: pecImageProgress, onUpload: handlePecImageUploadChange } =
    useUpload({
      name: 'pecImage',
      onUploaded: handleGeneralInputChange,
    });

  // Generate on save
  const [generateOnSave, setGenerateOnSave] = useState(true);
  const { loading: generateLoading, onGenerate } = useGenerateTransmissions();

  // Create
  const {
    loading: createLoading,
    error: createError,
    onCreate,
  } = useCreatePrescription({
    generalInputs,
  });

  // Update
  const {
    loading: updateLoading,
    error: updateError,
    onUpdate,
  } = useUpdatePrescription({
    generalInputs,
  });

  const { onDelete } = useDeleteTransmissionsByTreatment();
  // Archive
  const handleArchive = async () => {
    await onUpdate({ archived: true });
    generalInputs.treatments.map(async (treatment) => {
      await onDelete({
        variables: {
          userGroupId,
          prescriptionId: prescriptionId,
          treatmentId: treatment.id,
        },
      });
    });
    goBack();
  };

  // Submit
  const handleSubmit = async (event) => {
    let flag = true;
    event && event.preventDefault();

    try {
      if (type === 'update') {
        await onUpdate();
        // Generate transmission on save
        if (generateOnSave) {
          flag = await onGenerate({
            treatments: generalInputs.treatments,
          });
        }
      } else if (type === 'create' || type === 'duplicate') {
        const {
          data: {
            createPrescription: {
              id: createdPrescriptionId,
              treatments: createdTreatments,
            },
          },
        } = await onCreate();

        // Generate transmission on save
        if (generateOnSave) {
          flag = await onGenerate({
            prescriptionId: createdPrescriptionId,
            treatments: createdTreatments,
            noDelete: true,
          });
        }

        replace(
          `/g/${userGroupId}/prescription/update/${patientId}/${createdPrescriptionId}`,
        );
      }

      flag && onSubmitted && onSubmitted();
    } catch (err) {}
  };

  return {
    loading:
      createLoading ||
      updateLoading ||
      generateLoading ||
      photoProgress ||
      pecImageProgress ||
      workAccidentPhotoProgress,
    generalInputs,
    error: createError || updateError,
    generateOnSave,
    onGenerateOnSaveChange: (e) => setGenerateOnSave(e.target.checked),
    onGeneralInputChange: handleGeneralInputChange,
    onGeneralInputsChange: handleGeneralInputsChange,
    onPhotoUploadChange: handlePhotoUploadChange,
    onPecImageUploadChange: handlePecImageUploadChange,
    onWorkAccidentPhotoUploadChange: handleWorkAccidentPhotoUploadChange,
    onSubmit: handleSubmit,
    onArchive: handleArchive,
  };
};

export default usePrescriptionForm;
export { GET_DEFAULT_GENERAL_INPUTS, GET_DEFAULT_TREATMENT };
