import React from 'react';
import DoneIcon from '@material-ui/icons/Done';

import ProgressButton from '../System/ProgressButton';
import useDone from './useDone';

const DoneButton = () => {
  const { loading, onDone } = useDone();

  return (
    <ProgressButton
      size="small"
      variant="contained"
      color="primary"
      startIcon={<DoneIcon />}
      loading={loading}
      onClick={onDone}
    >
      Valider...
    </ProgressButton>
  );
};

export default DoneButton;
