import React from 'react';
import ReactDOM from 'react-dom';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import moment from 'moment';

import 'moment/locale/fr';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import './styles.css';
import '../node_modules/react-vis/dist/style.css';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://d995e1e010bf4837a69f5a6ea7d206c0@o912473.ingest.sentry.io/5849543',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
  });
}

moment.locale('fr');

Hub.listen('auth', ({ payload: { event, data } }) => {
  switch (event) {
    case 'signIn_failure': {
      if (data.message.includes('user-not-invited')) {
        document.location.replace('/login?err=not-invited');
      } else {
        document.location.replace('login?err=failure');
      }
      break;
    }
    default:
      break;
  }
});

Auth.configure({
  region: process.env.REACT_APP_AWS_EV_REGION,
  userPoolId: process.env.REACT_APP_AWS_EV_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_EV_USER_POOL_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_AWS_OAUTH_DOMAIN,
    scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGNIN,
    redirectSignOut: process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGNOUT,
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (process.env.REACT_APP_PWA_MODE === 'true') {
  console.log('serviceWorkerRegistration.register');
  serviceWorkerRegistration.register();
} else {
  console.log('serviceWorkerRegistration.unregister');
  serviceWorkerRegistration.unregister();
}
