import React from 'react';
import { Typography, makeStyles, Button, IconButton } from '@material-ui/core';
import moment from 'moment';
import { Link, useParams, useLocation } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import LocationIcon from '@material-ui/icons/MyLocation';
import PreviousIcon from '@material-ui/icons/KeyboardArrowLeft';
import NextIcon from '@material-ui/icons/KeyboardArrowRight';

import FieldsContainer from '../System/FieldsContainer';
import PrescriptionsList from '../Prescription/PrescriptionsList';
import Logs from '../Patient/Logs';
import AddPatientLogForm from '../Patient/AddPatientLogForm';
import clsx from 'clsx';
import DirectionsButton from '../System/DirectionsButton';
import getBirthday from '../System/getBirthday';
import useCurrentUser from '../System/useCurrentUser';
import { MOMENTS } from '../System/MomentSelect';
import TransmissionComment from './TransmissionComment';
import useNetworkStatus from '../System/useNetworkStatus';
import AddConstantForm from '../Constant/AddConstantForm';
import ConstantList from '../Constant/ConstantList';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    padding: theme.spacing(0, 1),
  },
  contentHeader: {
    flex: 1,
  },
  content: {
    padding: theme.spacing(0, 2),
  },
  patientName: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '1.2rem',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
      '& sup': {
        position: 'relative',
        '& svg': {
          position: 'absolute',
          width: 12,
          marginLeft: theme.spacing(0.5),
        },
      },
    },
  },
  patientAge: {
    fontWeight: 'normal',
    textAlign: 'center',
    fontSize: '1.0rem',
  },
  date: {
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: '0.9rem',
  },
  birthday: {
    textAlign: 'center',
    fontSize: '1rem',
    margin: theme.spacing(2, 2, 0.5, 2),
    backgroundColor: '#cddc39',
    color: 'black',
    padding: theme.spacing(0.5, 1),
    borderRadius: 4,
    fontStyle: 'italic',
  },
  birthdayInfo: {
    textAlign: 'center',
    margin: theme.spacing(0, 2, 3, 2),
    '& > span': {
      fontSize: '0.9rem',
    },
  },
  type: {
    marginTop: theme.spacing(2),
    whiteSpace: 'pre-wrap',
  },
  addressTitle: {
    marginBottom: theme.spacing(1),
  },
  helper: {
    color: 'orange',
    fontStyle: 'italic',
  },
  currentPrescription: {
    color: 'orange',
  },
  PDS: {
    color: theme.palette.green.main,
  },
  absenceText: {
    textDecoration: 'line-through',
    color: theme.palette.error.main,
  },
  visitAddress: {
    display: 'flex',
    alignItems: 'center',
    '& a': {
      marginLeft: theme.spacing(1),
    },
  },
  lastDate: {
    textAlign: 'center',
    fontSize: '0.9rem',
    color: 'red',
  },
  geoLoc: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    '& > a': {
      '& > span': {
        textTransform: 'none',
      },
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
      color: theme.palette.text.primary,
      '&:focus, &:visited': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
      },
    },
  },
  constants: {
    margin: theme.spacing(1, 0),
  },
  addConstants: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));

const Transmission = ({
  className,
  transmission,
  seeAllLogs,
  hasMoreLogs,
  tour,
  previousTransmission,
  nextTransmission,
  onToggleSeeAllLogs,
}) => {
  const { readOnly } = useNetworkStatus();
  const classes = useStyles();
  const { userGroupId, tourId, date } = useParams();
  const { pathname } = useLocation();
  const { isSuperAdmin } = useCurrentUser();

  const isPDS = Boolean(transmission.type?.toLowerCase()?.trim() === 'pds');

  const isCloseToLastDate =
    transmission.lastDate &&
    moment(Number(transmission.lastDate))
      .startOf('day')
      .isBefore(
        moment(Number(transmission.date)).startOf('day').add(5, 'days'),
      );

  const { showBirthday, age, duration, birthday } = getBirthday({
    patient: transmission.patient,
  });

  return (
    <div className={className}>
      <div className={classes.header}>
        <div style={{ width: 45 }}>
          {previousTransmission ? (
            <IconButton
              component={Link}
              to={`/g/${userGroupId}/transmission/${tourId}/${date}/${previousTransmission.id}`}
            >
              <PreviousIcon />
            </IconButton>
          ) : null}
        </div>
        <div className={classes.contentHeader}>
          <Typography
            gutterBottom
            color={transmission.absence ? 'error' : 'inherit'}
            className={classes.patientName}
          >
            <Link
              to={`/g/${userGroupId}/patient/update/${transmission.patient.id}?back=${pathname}`}
            >
              <span>{`${
                transmission.patient.firstName
              } ${transmission.patient.lastName.toUpperCase()}`}</span>
              <span className={classes.patientAge}>{` (${age} ans)`}</span>
              <sup>
                <OpenInNewIcon size="small" />
              </sup>
            </Link>
          </Typography>
          <Typography
            className={clsx(
              classes.date,
              transmission.absence && classes.absenceText,
            )}
          >
            Le{' '}
            {moment
              .utc(Number(transmission.date))
              .format('dddd DD MMMM YYYY à HH:mm')}{' '}
            -{' '}
            {`${
              MOMENTS.find((m) => m.value === transmission.moment)
                ? `${
                    MOMENTS.find((m) => m.value === transmission.moment).label
                  } - `
                : ''
            }`}
            {tour.name}
          </Typography>

          {isCloseToLastDate ? (
            <Typography className={classes.lastDate}>
              L'ordonnance se termine le{' '}
              {moment(Number(transmission.lastDate)).format(
                'dddd DD MMMM YYYY',
              )}
            </Typography>
          ) : null}

          {transmission.absence ? (
            <Typography align="center" color="error">
              <b>Patient absent</b>
            </Typography>
          ) : null}

          {transmission.done ? (
            <>
              <Typography className={classes.date}>
                Terminé
                {transmission.doneBy
                  ? ` par ${transmission.doneBy.firstName} ${transmission.doneBy.lastName}, `
                  : ' '}
                le{' '}
                {moment(Number(transmission.done.date)).format(
                  'dddd DD MMMM YYYY à HH:mm',
                )}
              </Typography>

              {isSuperAdmin &&
              transmission.done.latitude &&
              transmission.done.longitude ? (
                <div className={classes.geoLoc}>
                  <Button
                    size="small"
                    component="a"
                    target="_blank"
                    href={`https://www.google.com/maps/search/?api=1&query=${transmission.done.latitude},${transmission.done.longitude}`}
                    startIcon={<LocationIcon />}
                    variant="contained"
                  >
                    {transmission.done.accuracy != null ? (
                      <>Precision: {transmission.done.accuracy}m</>
                    ) : (
                      <>Precision inconnue</>
                    )}
                  </Button>
                </div>
              ) : null}
            </>
          ) : null}
        </div>

        <div style={{ width: 45 }}>
          {nextTransmission ? (
            <IconButton
              component={Link}
              to={`/g/${userGroupId}/transmission/${tourId}/${date}/${nextTransmission.id}`}
            >
              <NextIcon />
            </IconButton>
          ) : null}
        </div>
      </div>

      <div className={classes.content}>
        {showBirthday ? (
          <>
            <Typography className={classes.birthday}>
              <span role="img" aria-label="birthday">
                🎉
              </span>{' '}
              {transmission.patient.firstName} fête ses {age} ans {duration} !{' '}
            </Typography>
            <Typography className={classes.birthdayInfo}>
              <Typography variant="caption">
                Date de naissance : {birthday}
              </Typography>
            </Typography>
          </>
        ) : null}

        <FieldsContainer>
          <Typography variant="caption" component="p">
            Type de soins
          </Typography>

          <Typography className={clsx(classes.type, isPDS && classes.PDS)}>
            {isPDS ? '💉 ' : null}
            {transmission.type || 'Non renseigné'}
          </Typography>
        </FieldsContainer>

        {transmission.treatmentId ? (
          <TransmissionComment
            transmission={transmission}
            userGroupId={userGroupId}
          />
        ) : (
          <FieldsContainer>
            <Typography variant="caption" component="p">
              Transmissions
            </Typography>

            <Typography className={clsx(classes.type)}>
              {transmission.comment || ''}
            </Typography>
          </FieldsContainer>
        )}

        <FieldsContainer>
          <Typography variant="caption" component="p">
            Diagnostics
          </Typography>

          <Logs
            logs={transmission.patient.logs}
            seeAllLogs={seeAllLogs}
            hasMore={hasMoreLogs}
            onToggleSeeAllLogs={onToggleSeeAllLogs}
          />

          <AddPatientLogForm
            patientId={transmission.patient.id}
            disabled={readOnly}
          />
        </FieldsContainer>

        <FieldsContainer>
          <Typography variant="caption" component="p">
            Constantes
          </Typography>
          <div>
            <AddConstantForm
              patientId={transmission.patient.id}
              className={classes.addConstants}
              disabled={readOnly}
            />
            <Button
              className={classes.addConstants}
              size="small"
              variant="outlined"
              color="primary"
              component={Link}
              to={`/g/${userGroupId}/patient/constantCharts/${transmission.patient.id}?back=${pathname}`}
              disabled={transmission.patient.constants?.length === 0}
            >
              Voir les courbes
            </Button>
            <Button
              className={classes.addConstants}
              size="small"
              variant="outlined"
              color="primary"
              component={Link}
              to={`/constantPrint/${userGroupId}/patient/${transmission.patient.id}`}
              target="_blank"
              disabled={transmission.patient.constants?.length === 0}
            >
              Impression
            </Button>
          </div>
          <ConstantList
            patientId={transmission.patient.id}
            constants={transmission.patient.constants}
            disabled={readOnly}
            className={classes.constants}
          />
        </FieldsContainer>

        <FieldsContainer>
          <Typography
            className={classes.addressTitle}
            variant="caption"
            component="p"
          >
            Adresse
          </Typography>

          <Typography className={classes.visitAddress}>
            {transmission.patient.visitAddress.formattedAddress}
            <DirectionsButton patient={transmission.patient} />
          </Typography>
          <Typography>{transmission.patient.addressExtra}</Typography>
          <Typography>{transmission.patient.comment}</Typography>
        </FieldsContainer>

        <FieldsContainer>
          <Typography variant="caption" component="p">
            Ordonnances du patient
          </Typography>
          <Typography
            variant="caption"
            component="p"
            className={classes.helper}
          >
            L'ordonnance orange est l'ordonnance ayant générée cette
            transmission
          </Typography>

          {transmission.patient.prescriptions?.length ? (
            <PrescriptionsList
              prescriptions={transmission.patient.prescriptions}
              patientId={transmission.patient.id}
              customClasses={
                transmission.prescription
                  ? [
                      {
                        id: transmission.prescription.id,
                        classes: { root: classes.currentPrescription },
                      },
                    ]
                  : null
              }
            />
          ) : (
            <Typography>Aucune ordonnance</Typography>
          )}
        </FieldsContainer>
      </div>
    </div>
  );
};

export default Transmission;
