import React, { useMemo } from 'react';
import { Link, Typography } from '@material-ui/core';
import FieldsContainer from '../System/FieldsContainer';
import TextField from '../System/TextField';

import { v1 as uuid } from 'uuid';

const Links = (props) => {
  const { editMode, generalInputs, onGeneralInputChange } = props;
  const links = useMemo(() => {
    return (
      generalInputs.liens?.split('\n').map((x) => {
        if (x.toLowerCase().startsWith('http://')) {
          return { href: x, label: x.substring(7) };
        } else if (x.toLowerCase().startsWith('https://')) {
          return { href: x, label: x.substring(8) };
        } else {
          return { href: `https://${x}`, label: x };
        }
      }) || []
    );
  }, [generalInputs.liens]);

  return (
    <FieldsContainer>
      <Typography gutterBottom component="p" variant="caption">
        Liens
      </Typography>

      {editMode ? (
        <TextField
          name="liens"
          multiline
          value={generalInputs.liens || ''}
          onChange={onGeneralInputChange}
        />
      ) : (
        links.map((link) => (
          <span key={uuid()}>
            <Link href={link.href} target="_blank">
              {link.label}
            </Link>
            <br />
          </span>
        ))
      )}
    </FieldsContainer>
  );
};

export default Links;
