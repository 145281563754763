import React from 'react';
import { useParams } from 'react-router-dom';

import AppLayout from '../System/AppLayout';
import DoctorForm from './DoctorForm';

const CreateDoctor = () => {
  const { userGroupId } = useParams();

  return (
    <AppLayout title="Nouveau médecin" backTo={`/g/${userGroupId}/doctors`}>
      <DoctorForm type="create" autoFocus editMode />
    </AppLayout>
  );
};

export default CreateDoctor;
