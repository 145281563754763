import { gql, useMutation } from '@apollo/client';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import useSnackbar from '../System/useSnackbar';
import { backSearch } from '../../utils/back-search';
import {
  LIST_CARE_CHECKUPS,
  LIST_CARE_CHECKUPS_BY_PATIENT,
} from './useCareCheckups';

const DELETE_CARE_CHECKUP = gql`
  mutation DeleteCareCheckup($userGroupId: String!, $id: String!) {
    deleteCareCheckup(userGroupId: $userGroupId, id: $id)
  }
`;

const useDeleteCareCheckup = () => {
  const { userGroupId, careCheckupId, patientId } = useParams();
  const { showSnackbar } = useSnackbar();
  const { replace } = useHistory();
  const { search } = useLocation();
  const backToUrl = backSearch(search);

  const [deleteCareCheckup, { loading }] = useMutation(DELETE_CARE_CHECKUP, {
    variables: {
      userGroupId,
      id: careCheckupId,
    },
    update(cache) {
      try {
        const { listCareCheckups } = cache.readQuery({
          query: LIST_CARE_CHECKUPS,
          variables: { userGroupId },
        });
        cache.writeQuery({
          query: LIST_CARE_CHECKUPS,
          variables: { userGroupId },
          data: {
            listCareCheckups: listCareCheckups.filter(
              (careCheckup) => careCheckup.id !== careCheckupId,
            ),
          },
        });
      } catch (err) {}
      try {
        if (patientId) {
          const { listCareCheckupsByPatient } = cache.readQuery({
            query: LIST_CARE_CHECKUPS_BY_PATIENT,
            variables: { userGroupId, patientId },
          });
          cache.writeQuery({
            query: LIST_CARE_CHECKUPS_BY_PATIENT,
            variables: { userGroupId, patientId },
            data: {
              listCareCheckupsByPatient: listCareCheckupsByPatient.filter(
                (careCheckup) => careCheckup.id !== careCheckupId,
              ),
            },
          });
        }
      } catch (err) {}
    },
    onCompleted() {
      replace(backToUrl || `/g/${userGroupId}/care-checkups`);
      showSnackbar('Bilan supprimé');
    },
  });

  return {
    loading,
    onDelete: deleteCareCheckup,
  };
};

export default useDeleteCareCheckup;
