import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useContext, useMemo } from 'react';

import { filterPatients } from '../../utils/filters';
import PatientSummaryFragment from '../../fragments/PatientSummaryFragment';
import { Store } from '../../utils/store';
import { T } from '../../utils/reducers';

const EXPORT_PATIENTS = gql`
  query ExportPatients($userGroupId: String!) {
    exportPatients(userGroupId: $userGroupId)
  }
`;

const LIST_PATIENTS = gql`
  query ListPatients($userGroupId: String!) {
    listPatients(userGroupId: $userGroupId) {
      ...PatientSummaryFragment
    }
  }

  ${PatientSummaryFragment}
`;

const LIST_PATIENTS_WITH_PRESC = gql`
  query ListPatientsWithPresc($userGroupId: String!) {
    listPatientsWithPresc(userGroupId: $userGroupId) {
      ...PatientSummaryFragment
    }
  }

  ${PatientSummaryFragment}
`;

const SEARCH_PATIENTS = gql`
  query SearchPatients(
    $userGroupId: String!
    $search: String
    $archived: Boolean
  ) {
    searchPatients(
      userGroupId: $userGroupId
      search: $search
      archived: $archived
    ) {
      ...PatientSummaryFragment
    }
  }

  ${PatientSummaryFragment}
`;

const usePatients = () => {
  //const { userGroupId } = useParams();
  const { state, dispatch } = useContext(Store);

  const setSearch = (payload) =>
    dispatch({ type: T.listPatientsSearch, payload });
  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  const setArchived = (payload) =>
    dispatch({ type: T.listPatientsArchived, payload });
  const handleArchivedChange = () => {
    setArchived(!state.listPatientsArchived);
  };
  /*
  const { data, loading } = useQuery(LIST_PATIENTS, {
    variables: {
      userGroupId,
    },
  });

  const patients = useMemo(() => {
    const listPatients = data?.listPatients.filter((patient) =>
      state.listPatientsArchived
        ? patient.archived === state.listPatientsArchived
        : !patient.archived || patient.archived === state.listPatientsArchived,
    );

    return data && state.listPatientsSearch
      ? filterPatients(listPatients, state.listPatientsSearch)
      : listPatients;
  }, [data, state.listPatientsSearch, state.listPatientsArchived]);
*/
  return {
    //loading,
    //patients,
    search: state.listPatientsSearch,
    onSearchChange: handleSearchChange,
    archived: state.listPatientsArchived,
    onArchivedChange: handleArchivedChange,
  };
};

const usePatientsWithPresc = () => {
  const { userGroupId } = useParams();
  const { state, dispatch } = useContext(Store);

  const setSearch = (payload) =>
    dispatch({ type: T.listPatientsSearch, payload });
  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  const setArchived = (payload) =>
    dispatch({ type: T.listPatientsArchived, payload });
  const handleArchivedChange = () => {
    setArchived(!state.listPatientsArchived);
  };

  const { data, loading } = useQuery(LIST_PATIENTS_WITH_PRESC, {
    variables: {
      userGroupId,
    },
  });

  const patients = useMemo(() => {
    const listPatients = data?.listPatientsWithPresc.filter((patient) =>
      state.listPatientsArchived
        ? patient.archived === state.listPatientsArchived
        : !patient.archived || patient.archived === state.listPatientsArchived,
    );

    return data && state.listPatientsSearch
      ? filterPatients(listPatients, state.listPatientsSearch)
      : listPatients;
  }, [data, state.listPatientsSearch, state.listPatientsArchived]);

  return {
    loading,
    patients,
    search: state.listPatientsSearch,
    onSearchChange: handleSearchChange,
    archived: state.listPatientsArchived,
    onArchivedChange: handleArchivedChange,
  };
};

export default usePatients;
export {
  usePatientsWithPresc,
  EXPORT_PATIENTS,
  LIST_PATIENTS,
  SEARCH_PATIENTS,
};
