import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import PatientFragment from '../../fragments/PatientFragment';

const GET_PATIENT = gql`
  query GetPatient($userGroupId: String!, $id: String!) {
    getPatient(userGroupId: $userGroupId, id: $id) {
      ...PatientFragment
    }
  }

  ${PatientFragment}
`;

const PATIENT_LIMIT = 4;

const usePatient = ({ id, seeAllLogs = false } = {}) => {
  const { patientId, userGroupId } = useParams();

  const { data, loading, refetch } = useQuery(GET_PATIENT, {
    variables: { userGroupId, id: patientId ? patientId : id },
    fetchPolicy: 'cache-first',
  });

  const patient = useMemo(() => {
    return data?.getPatient
      ? {
          ...data.getPatient,
          prescriptions: data.getPatient.prescriptions
            .slice()
            .sort((a, b) =>
              a.firstTreatmentDate < b.firstTreatmentDate ? 1 : -1,
            ),
          logs: [...data.getPatient.logs]
            .sort((a, b) => {
              return Number(a.date) < Number(b.date) ? 1 : -1;
            })
            .slice(0, seeAllLogs ? undefined : PATIENT_LIMIT),
        }
      : null;
  }, [data, seeAllLogs]);

  return {
    loading,
    patient,
    hasMoreLogs: data?.getPatient.logs?.length > PATIENT_LIMIT,
    refetch,
  };
};

export default usePatient;
export { GET_PATIENT };
