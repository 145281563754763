import React, { useMemo } from 'react';

import TextField from './TextField';

const twoDigits = (n) => (n < 10 ? `0${n}` : `${n}`);

const NumberSelect = ({ start, end, step, onChange, ...rest }) => {
  const options = useMemo(() => {
    let opts = [];
    for (let i = start; i <= end; i += step) {
      opts = [...opts, i];
    }
    return opts;
  }, [start, end, step]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    onChange && onChange({ target: { name, value: Number(value) } });
  };

  return (
    <TextField
      select
      SelectProps={{
        native: true,
      }}
      onChange={handleChange}
      {...rest}
    >
      {options.map((option) => (
        <option key={option} value={option}>
          {twoDigits(option)}
        </option>
      ))}
    </TextField>
  );
};

export default NumberSelect;
