import React, { useState } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import MenuIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';

import usePatient from './usePatient';
import AppLayout from '../System/AppLayout';
import PatientForm from './PatientForm';
import SectionProgress from '../System/SectionProgress';
import useMenu from '../System/useMenu';
import useNetworkStatus from '../System/useNetworkStatus';
import useTours from '../Tours/useTours';
import ArchivePatientMenuItem from './ArchivePatientMenuItem';
import { getTypes } from '../../utils/get-care-checkup-type';
import ExportJson, { cleanPatient } from '../System/export-json';
import useCurrentUser from '../System/useCurrentUser';
import { urlWithBacks } from '../../utils/back-search';

const UpdatePatient = () => {
  const { readOnly } = useNetworkStatus();
  const { isAdmin, isSuperAdmin } = useCurrentUser();
  const { pathname } = useLocation();

  const { userGroupId, patientId } = useParams();

  const { loading: toursLoading, tours } = useTours();

  // Logs display
  const [seeAllLogs, setSeeAllLogs] = useState(false);
  const handleToggleSeeAllLogs = () => {
    setSeeAllLogs((prev) => !prev);
  };

  // Patient
  const { loading, patient, hasMoreLogs } = usePatient({ seeAllLogs });

  // Edit mode
  const [editMode, setEditMode] = useState(false);
  const handleEdit = () => {
    setEditMode(true);
  };
  const handleStopEdit = () => {
    setEditMode(false);
    //refetch();
  };

  const { anchorEl, onClick: onMenuClick, onClose: onMenuClose } = useMenu();

  return (
    <AppLayout
      title={
        (patient &&
          patient.lastName &&
          `${patient.firstName} ${patient.lastName}`) ||
        'Patient'
      }
      direction="row"
      backTo={`/g/${userGroupId}/patients`}
      editMode={editMode}
      action={
        readOnly ? null : (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            wrap="nowrap"
          >
            {!editMode ? (
              <div>
                <IconButton onClick={handleEdit}>
                  <EditIcon />
                </IconButton>
              </div>
            ) : null}

            <IconButton onClick={onMenuClick}>
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={onMenuClose}
            >
              <MenuItem
                component={Link}
                to={`/g/${userGroupId}/absence/create/${patientId}`}
              >
                <ListItemIcon>
                  <AddIcon fontSize="small" />
                </ListItemIcon>
                Ajouter une absence
              </MenuItem>
              <MenuItem
                component={Link}
                to={`/g/${userGroupId}/message/create/${patientId}`}
              >
                <ListItemIcon>
                  <AddIcon fontSize="small" />
                </ListItemIcon>
                Ajouter un message
              </MenuItem>

              {getTypes().map((type) => (
                <MenuItem
                  key={type.key}
                  component={Link}
                  to={urlWithBacks({
                    url: `/g/${userGroupId}/care-checkup/create/${patientId}/${type.key}`,
                    back: pathname,
                  })}
                >
                  <ListItemIcon>
                    <AddIcon fontSize="small" />
                  </ListItemIcon>
                  {`Créer un ${type.name}`}
                </MenuItem>
              ))}

              {!editMode &&
              !readOnly &&
              patient &&
              (isSuperAdmin || isAdmin) ? (
                <ExportJson
                  title="Exporter le patient"
                  filename={`${patient.firstName}.${patient.lastName}.json`}
                  data={cleanPatient(patient)}
                />
              ) : null}

              {patient ? (
                <ArchivePatientMenuItem archived={!Boolean(patient.archived)} />
              ) : null}
            </Menu>
          </Grid>
        )
      }
    >
      {loading || toursLoading ? (
        <SectionProgress />
      ) : patient ? (
        <PatientForm
          initialPatient={patient}
          type="update"
          tours={tours}
          editMode={editMode}
          seeAllLogs={seeAllLogs}
          hasMoreLogs={hasMoreLogs}
          onToggleSeeAllLogs={handleToggleSeeAllLogs}
          onSubmitted={handleStopEdit}
        />
      ) : null}
    </AppLayout>
  );
};

export default UpdatePatient;
