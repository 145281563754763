import { gql, useMutation } from '@apollo/client';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { GET_PATIENT } from '../Patient/usePatient';
import backSearch from '../../utils/back-search';

const DELETE_ABSENCE = gql`
  mutation DeleteAbsence($userGroupId: String!, $id: String!) {
    deleteAbsence(userGroupId: $userGroupId, id: $id)
  }
`;

const useDeleteAbsence = ({ noRedirect } = {}) => {
  const { userGroupId, patientId, absenceId, absenceStartDate } = useParams();
  const { search: s } = useLocation();
  const backTo = backSearch(s) || `/g/${userGroupId}/absences`;

  const { replace } = useHistory();

  console.log('useDeleteAbsence', absenceId, absenceStartDate);
  const [deleteAbsence, { loading }] = useMutation(DELETE_ABSENCE, {
    variables: {
      userGroupId,
      id: `${absenceStartDate}#${absenceId}`,
    },
    update(cache) {
      try {
        const { getPatient } = cache.readQuery({
          query: GET_PATIENT,
          variables: { userGroupId, id: patientId },
        });
        cache.writeQuery({
          query: GET_PATIENT,
          variables: { userGroupId, id: patientId },
          data: {
            getPatient: {
              ...getPatient,
              absences: getPatient.absences.filter((a) => a.id !== absenceId),
            },
          },
        });
      } catch (err) {}
    },
    onCompleted() {
      if (!noRedirect) {
        replace(backTo);
      }
    },
  });

  return { loading, onDelete: deleteAbsence };
};

export default useDeleteAbsence;
