import React from 'react';
import { ButtonBase, IconButton, Typography } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import CallIcon from '@material-ui/icons/Call';
import MessageIcon from '@material-ui/icons/Message';

import DirectionsButton from '../System/DirectionsButton';

const PatientsList = ({ height, width, patients, classes }) => {
  const { userGroupId } = useParams();

  return (
    <List
      height={height}
      width={width}
      itemCount={patients.length}
      itemSize={45}
    >
      {({ index, style }) => {
        const patient = patients[index];
        const phone = patient.phones.find((p) => !!p?.number)?.number;

        return (
          <div className={classes.listItemRoot} style={style}>
            <ButtonBase
              className={classes.listItem}
              component={Link}
              to={`/g/${userGroupId}/patient/update/${patient.id}`}
            >
              <Typography>
                <strong>{patient.lastName}</strong> {patient.firstName}
                {patient.useName ? ` (${patient.useName})` : ''}
              </Typography>
            </ButtonBase>
            <div className={classes.actionIcons}>
              {phone ? (
                <>
                  <IconButton component="a" href={`tel:${phone}`} size="small">
                    <CallIcon />
                  </IconButton>
                  <IconButton component="a" href={`sms:${phone}`} size="small">
                    <MessageIcon />
                  </IconButton>
                </>
              ) : null}

              <DirectionsButton patient={patient} />
            </div>
          </div>
        );
      }}
    </List>
  );
};

export default PatientsList;
