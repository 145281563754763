import { useContext, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import UserGroupSummaryFragment from '../../fragments/UserGroupSummaryFragment';
import { filterUserGroups, sortUserGroups } from '../../utils/filters';
import { Store } from '../../utils/store';
import { T } from '../../utils/reducers';

const LIST_USER_GROUPS = gql`
  query ListUserGroups {
    listUserGroups {
      ...UserGroupSummaryFragment
    }
  }

  ${UserGroupSummaryFragment}
`;

const useUserGroups = () => {
  const { state, dispatch } = useContext(Store);
  const { data, loading } = useQuery(LIST_USER_GROUPS, {
    fetchPolicy: 'network-only',
  });
  const setSearch = (payload) =>
    dispatch({ type: T.listUserGroupsSearch, payload });
  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  const setArchived = (payload) =>
    dispatch({ type: T.listUserGroupsArchived, payload });
  const handleArchivedChange = () => {
    setArchived(!state.listUserGroupsArchived);
  };

  const userGroups = useMemo(() => {
    const listUserGroups = data?.listUserGroups.filter((userGroup) =>
      state.listUserGroupsArchived
        ? userGroup.archived === state.listUserGroupsArchived
        : !userGroup.archived ||
          userGroup.archived === state.listUserGroupsArchived,
    );
    return data && state.listUserGroupsSearch
      ? filterUserGroups(listUserGroups, state.listUserGroupsSearch)
      : listUserGroups;
  }, [data, state.listUserGroupsSearch, state.listUserGroupsArchived]);

  return {
    userGroups: sortUserGroups(userGroups),
    loading,
    search: state.listUserGroupsSearch,
    onSearchChange: handleSearchChange,
    archived: state.listUserGroupsArchived,
    onArchivedChange: handleArchivedChange,
  };
};

export default useUserGroups;
export { LIST_USER_GROUPS };
