import React from 'react';
import { makeStyles, Button, Typography } from '@material-ui/core';
import GoToIcon from '@material-ui/icons/Launch';
import { Link } from 'react-router-dom';

import useEditUserGroup from './useEditUserGroup';
import EditUserGroupForm from './EditUserGroupForm';
import SectionProgress from '../System/SectionProgress';
import AdminTopBar from '../System/AdminTopbar';
import DeleteUserGroup from './DeleteUserGroup';
import Users from './Users';
import Tours from './Tours';
import AddTourDialog from './AddTourDialog';
import FieldsContainer from '../System/FieldsContainer';
import useCurrentUser from '../System/useCurrentUser';
import useNetworkStatus from '../System/useNetworkStatus';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  deleteContainer: {
    marginLeft: 'auto',
  },
  users: {
    marginTop: theme.spacing(6),
  },
  toursContainer: {
    marginTop: theme.spacing(4),
  },
  addTourContainer: {
    margin: theme.spacing(2, 0),
  },
  openGroup: {
    marginLeft: '10px',
  },
}));

const EditUserGroup = () => {
  const classes = useStyles();
  const { isAdmin, isSuperAdmin } = useCurrentUser();
  const { loading, userGroup } = useEditUserGroup();
  const { readOnly } = useNetworkStatus();

  return (
    <>
      <AdminTopBar
        direction="row"
        backTo={
          isSuperAdmin || isAdmin
            ? '/admin/user-groups'
            : userGroup
            ? `/g/${userGroup.id}`
            : '/'
        }
        title={userGroup?.name || ''}
        action={
          userGroup && (isAdmin || isSuperAdmin) ? (
            <Button
              variant="contained"
              color="primary"
              size="small"
              endIcon={<GoToIcon />}
              component={Link}
              to={`/g/${userGroup.id}`}
              className={classes.openGroup}
            >
              Ouvrir
            </Button>
          ) : null
        }
      />
      {loading ? (
        <SectionProgress />
      ) : (
        <>
          {isSuperAdmin && !readOnly ? (
            <div className={classes.container}>
              {!userGroup.tours.filter((t) => !t.disabled).length &&
              !userGroup.users.length ? (
                <div className={classes.header}>
                  <div className={classes.deleteContainer}>
                    <DeleteUserGroup />
                  </div>
                </div>
              ) : null}

              <EditUserGroupForm initialUserGroup={userGroup} />
            </div>
          ) : null}

          <Users
            className={classes.users}
            users={userGroup.users}
            disabled={readOnly}
          />

          <FieldsContainer className={classes.toursContainer}>
            <Typography variant="h2">Tournées</Typography>
            <div className={classes.addTourContainer}>
              <AddTourDialog disabled={readOnly} />
            </div>
            <Tours tours={userGroup.tours} noLocalForm disabled={readOnly} />
          </FieldsContainer>
        </>
      )}
    </>
  );
};

export default EditUserGroup;
