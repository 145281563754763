import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { CircularProgress, ListItem, ListItemText } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import moment from 'moment';

import usePrescription from './usePrescription';
import getCause from '../../utils/get-cause';
import getLastTreatmentDate from '../../utils/get-last-treatment-date';

const PrescriptionDetail = ({
  patientId,
  prescriptionId,
  customClasses = [],
  disabled,
}) => {
  const { userGroupId } = useParams();
  const { loading, prescription } = usePrescription(prescriptionId);
  const rootClass = customClasses?.find((c) => c.id === prescriptionId)?.classes
    .root;
  const doctor = prescription?.doctor;
  const treatments = prescription?.treatments;
  const endDate = getLastTreatmentDate({ treatments });

  return loading ? (
    <ListItem divider key={prescriptionId}>
      <CircularProgress size={12} color="inherit" />
    </ListItem>
  ) : prescription ? (
    <ListItem
      divider
      button
      key={prescriptionId}
      component={Link}
      to={`/g/${userGroupId}/prescription/update/${patientId}/${prescriptionId}`}
      classes={{ root: rootClass }}
      disabled={disabled}
    >
      <ListItemText
        secondary={
          <div>
            {doctor ? (
              <Typography component="div" variant="body2">
                Prescripteur : {doctor.lastName} {doctor.firstName || ''}
              </Typography>
            ) : (
              ''
            )}
            {treatments?.map((treatment) => {
              const firstTreatmentDate = moment(
                new Date(treatment.year, treatment.month, treatment.day),
              ).format('DD/MM/YYYY');

              return (
                <Typography component="div" variant="body2" key={treatment.id}>
                  Type de soins: {treatment.type}, premier soin le{' '}
                  {firstTreatmentDate}
                </Typography>
              );
            })}
          </div>
        }
        secondaryTypographyProps={{ component: 'div' }}
        classes={{ secondary: rootClass }}
      >
        {`Date d'ordonnance : ${moment(
          new Date(
            prescription.dateYear,
            prescription.dateMonth,
            prescription.dateDay,
          ),
        ).format('DD/MM/YYYY')} - `}
        {getCause(prescription.cause)}
        {endDate ? `- Fin des soins le ${endDate.format('DD/MM/YYYY')}` : ''}
        {prescription.soins2000Number
          ? ` - N° fact. ${prescription.soins2000Number}`
          : ''}
      </ListItemText>
      <ArrowRightIcon />
    </ListItem>
  ) : null;
};

export default PrescriptionDetail;
