import React from 'react';
import { makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import ProgressButton from '../System/ProgressButton';
import useDeleteUserGroup from './useDeleteUserGroup';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
}));

const DeleteUserGroup = () => {
  const classes = useStyles();

  const { loading, onDelete } = useDeleteUserGroup();

  return (
    <div>
      <ProgressButton
        loading={loading}
        className={classes.button}
        size="small"
        variant="outlined"
        disabled={loading}
        startIcon={<DeleteIcon />}
        onClick={onDelete}
      >
        Supprimer
      </ProgressButton>
    </div>
  );
};

export default DeleteUserGroup;
