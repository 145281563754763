import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import CareCheckupSummaryFragment from '../../fragments/CareCheckupSummaryFragment';

const LIST_CARE_CHECKUPS = gql`
  query ListCareCheckups($userGroupId: String!) {
    listCareCheckups(userGroupId: $userGroupId) {
      ...CareCheckupSummaryFragment
    }
  }
  ${CareCheckupSummaryFragment}
`;

const LIST_CARE_CHECKUPS_BY_PATIENT = gql`
  query listCareCheckupsByPatient($userGroupId: String!, $patientId: String!) {
    listCareCheckupsByPatient(
      userGroupId: $userGroupId
      patientId: $patientId
    ) {
      id
      patientId
      type
    }
  }
`;

const useCareCheckups = () => {
  const { userGroupId } = useParams();
  const { loading, data } = useQuery(LIST_CARE_CHECKUPS, {
    variables: { userGroupId },
  });

  return { loading, careCheckups: data?.listCareCheckups };
};

const useCareCheckupsByPatient = (patientId) => {
  const { userGroupId } = useParams();
  const { loading, data } = useQuery(LIST_CARE_CHECKUPS_BY_PATIENT, {
    variables: { userGroupId, patientId },
  });

  return { loading, careCheckups: data?.listCareCheckupsByPatient };
};

export default useCareCheckups;
export {
  LIST_CARE_CHECKUPS,
  LIST_CARE_CHECKUPS_BY_PATIENT,
  useCareCheckupsByPatient,
};
