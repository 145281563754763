import { gql } from '@apollo/client';

import OutsourcedTreatmentFragment from './OutsourcedTreatmentFragment';

const TreatmentFragment = gql`
  fragment TreatmentFragment on Treatment {
    id
    day
    month
    year
    type
    timeHour
    timeMinute
    count
    duration
    moment
    tour
    frequency
    frequencyDuration
    weekDays
    comment
    transmissionsGenerated
    haveTransmissions
    outsourcedTreatments {
      ...OutsourcedTreatmentFragment
    }
  }

  ${OutsourcedTreatmentFragment}
`;

export default TreatmentFragment;
