import { useEffect, useState } from 'react';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  gql,
} from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import QueueLink from 'apollo-link-queue';
//import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import SerializingLink from 'apollo-link-serialize';
import Cookies from 'js-cookie';

import { GET_MAP_APPS, getMapApps } from './map-app';

//const SCHEMA_VERSION = '1'
//const SCHEMA_VERSION_KEY = 'apollo-schema-version'

const GET_CURRENT_USER = gql`
  {
    currentUser @client
  }
`;
const updateUser = async (cache, { bypassCache = false } = {}) => {
  const user = await Auth.currentAuthenticatedUser({
    bypassCache,
  });

  const { payload } = user.signInUserSession.idToken;
  const { sub, email } = payload;
  const rolesString = payload['custom:roles'];
  const roles = (rolesString && JSON.parse(rolesString)) || [];

  const currentUser = {
    id: sub,
    email,
    roles,
    userGroup: payload['custom:user-group'],
  };

  cache.writeQuery({
    query: GET_CURRENT_USER,
    data: { currentUser },
  });
};
const GET_NETWORK_STATUS = gql`
  {
    offline @client
  }
`;
const updateNetworkStatus = async (cache, { isOffline = false } = {}) => {
  cache.writeQuery({
    query: GET_NETWORK_STATUS,
    data: { offline: isOffline },
  });
};

const updateMapApp = async (cache) => {
  cache.writeQuery({
    query: GET_MAP_APPS,
    data: { mapApps: getMapApps() },
  });
};

const useApollo = () => {
  const [client, setClient] = useState();

  useEffect(() => {
    if (!client) {
      const initApollo = async () => {
        const httpLink = createHttpLink({
          uri: process.env.REACT_APP_EV_API_URL,
        });
        const retryLink = new RetryLink({ attempts: { max: 5 } });
        const authLink = setContext(async () => {
          try {
            const session = await Auth.currentSession();
            const token = session?.idToken?.jwtToken;
            if (token) {
              return {
                headers: { authorization: `bearer ${token}` },
              };
            }
          } catch (err) {}
        });
        const errorLink = onError(({ networkError }) => {
          if (networkError && networkError.statusCode === 401) {
            Cookies.remove('token');
            window.location.replace('/login');
          }
        });
        const queueLink = new QueueLink();
        const serializingLink = new SerializingLink();
        const link = ApolloLink.from([
          queueLink,
          serializingLink,
          retryLink,
          errorLink,
          authLink,
          httpLink,
        ]);
        const mergeReplace = (existing, incoming) => {
          return incoming;
        };

        const cache = new InMemoryCache({
          typePolicies: {
            Patient: {
              fields: {
                absences: {
                  merge: mergeReplace,
                },
              },
            },
            Query: {
              fields: {
                listAbsences: {
                  merge: mergeReplace,
                },
                listTransmissionsByTour: {
                  merge: mergeReplace,
                },
              },
            },
          },
        });
        /*const currentVersion = window.localStorage.getItem(SCHEMA_VERSION_KEY);
        const persistor = new CachePersistor({
          cache,
          storage: new LocalStorageWrapper(window.localStorage),
        });*/

        window.addEventListener('offline', () => {
          queueLink.close();
          updateNetworkStatus(cache, { isOffline: true });
        });
        window.addEventListener('online', () => {
          queueLink.open();
          updateNetworkStatus(cache, { isOffline: false });
        });

        updateMapApp(cache);
        /*if (currentVersion === SCHEMA_VERSION) {
          await persistor.restore();
        } else {
          await persistor.purge();
          window.localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
        }*/

        Hub.listen('auth', ({ payload: { event } }) => {
          switch (event) {
            case 'signIn': {
              updateUser(cache);
              break;
            }
            default:
              break;
          }
        });

        try {
          await updateUser(cache, { bypassCache: true });
        } catch (err) {}

        const newApolloClient = new ApolloClient({ cache, link });

        setClient(newApolloClient);
      };

      initApollo();
    }
  }, [client]);

  return { client };
};

export default useApollo;
export { GET_NETWORK_STATUS };
