import React, { useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import useDeleteCareCheckup from './useDeleteCareCheckup';
import ConfirmDialog from '../System/ConfirmDialog';
import useDialog from '../System/useDialog';

const DeleteButton = () => {
  const { open, onOpen, onClose } = useDialog();
  const { loading, onDelete } = useDeleteCareCheckup();
  const handleDelete = useCallback(() => {
    onClose();
    onDelete();
  }, [onClose, onDelete]);

  return (
    <>
      <div>
        <IconButton onClick={onOpen} title="Supprimer">
          <DeleteIcon />
        </IconButton>
      </div>

      <ConfirmDialog
        title="Confirmation"
        open={open}
        confirmLoading={loading}
        onConfirm={handleDelete}
        onClose={onClose}
      >
        Êtes-vous sûr de vouloir supprimer ce bilan ?
      </ConfirmDialog>
    </>
  );
};

export default DeleteButton;
