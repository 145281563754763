import React from 'react';
import {
  FormControl,
  InputLabel,
  Grid,
  Typography,
  makeStyles,
  List,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';

import TextField from '../System/TextField';
import WeekDaysSelect from '../System/WeekDaysSelect';
import useSnackbar from '../System/useSnackbar';
import OutsourcedTreatment from './OutsourcedTreatment';
import MomentSelect from '../System/MomentSelect';
import getLastTreatmentDate from '../../utils/get-last-treatment-date';
import { getMoment } from '../../utils/get-moment';
import SelectDatePicker from '../System/SelectDatePicker';
import SelectDateTimePicker from '../System/SelectDateTimePicker';
import useTreatmentTransmissions from '../Transmission/useTreatmentTransmissions';
import useDialog from '../System/useDialog';

const useStyles = makeStyles((theme) => ({
  timeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  timeSep: {
    margin: theme.spacing(0, 1),
  },
  outsourcedListContainer: {
    borderRadius: 4,
    padding: theme.spacing(2, 0),
    backgroundColor: theme.palette.background.default,
    '& > p': {
      margin: theme.spacing(0, 2),
    },
  },
  durationTitle: {
    margin: theme.spacing(1, 0),
  },
  multiFieldContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  countTextField: {
    width: 80,
    marginRight: theme.spacing(1),
  },
  momentSelect: {
    marginRight: theme.spacing(0),
  },
  frequencyMainContainer: {
    display: 'flex',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1),
  },
  frequencyLabel: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(0, 0, 0, -1),
    padding: theme.spacing(0, 2, 0, 1),
    zIndex: 1,
  },
  frequencyTextField: {
    width: 100,
    marginRight: theme.spacing(1),
  },
  frequencyDurationTextField: {
    width: 100,
    marginRight: theme.spacing(1),
  },
  multiFieldSelect: {
    flex: 1,
  },
  datePicker: {
    marginTop: '7px',
  },
  multiTypo: {
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
  orContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0, 1, 0),
    '& div': {
      height: 1,
      flex: 1,
      backgroundColor: theme.palette.divider,
    },
    '& p': {
      fontSize: '0.85rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      margin: theme.spacing(0, 2),
    },
  },
  chronicCaption: {
    lineHeight: '0.1rem',
  },
  dataGridMainContainer: {
    display: 'flex',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2, 0, 1, 0),
  },
  dataGridLabel: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(0, 0, 0, -1),
    padding: theme.spacing(0, 2, 0, 1),
    zIndex: 1,
  },
  dataGrid: {
    margin: theme.spacing(0, 1, 0, 1),
  },
}));

const TreatmentForm = ({ treatment, tours, onChange, editMode }) => {
  const classes = useStyles();
  const [endDate, setEndDate] = React.useState(
    getLastTreatmentDate({ treatments: [treatment] }),
  );
  const [treatmentDate, setTreatmentDate] = React.useState(
    new moment(
      new Date(
        treatment.year,
        treatment.month,
        treatment.day,
        treatment.timeHour,
        treatment.timeMinute,
      ),
    ),
  );
  const [selectedDate, setSelectedDate] = React.useState(endDate.toDate());
  const [selectedTrans, setSelectedTrans] = React.useState();
  const [pageSizeTrans, setPageSizeTrans] = React.useState(5);
  const { showSnackbar } = useSnackbar();

  const { loading, transmissions } = useTreatmentTransmissions(treatment.id);
  const rows = React.useMemo(() => {
    return transmissions
      ?.filter((t) => t.comment !== null)
      .sort((a, b) => b.date - a.date)
      .map((trans) => {
        const results = {
          id: trans.id,
          date: moment.utc(Number(trans.date)).format('DD/MM/YYYY HH:mm'),
          doneDate: trans.done?.date
            ? moment(Number(trans.done.date)).format('DD/MM/YYYY HH:mm')
            : '',
          doneBy: trans.doneBy
            ? `${trans.doneBy?.firstName} ${trans.doneBy?.lastName}`
            : '',
          type: trans.type,
          comment: trans.comment,
        };

        return results;
      });
  }, [transmissions]);
  const columns = [
    { field: 'date', headerName: 'Date', width: 140 },
    { field: 'doneDate', headerName: 'Validée le', width: 140 },
    { field: 'doneBy', headerName: 'Par', width: 140 },
    { field: 'type', headerName: 'Type de soins', width: 160 },
    {
      field: 'comment',
      headerName: 'Transmission',
      minWidth: 300,
      flex: 1,
    },
  ];

  const handleTreatmentDateChange = (date) => {
    if (date) {
      const timeHour = date.getHours();
      const timeMinute = date.getMinutes();
      const pssgMoment = getMoment(timeHour, timeMinute);
      const newValue = {
        ...treatment,
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
        timeHour,
        timeMinute,
        moment: pssgMoment ? pssgMoment.value : treatment.moment,
      };

      setTreatmentDate(date);
      setSelectedDate(
        getLastTreatmentDate({ treatments: [newValue] }).toDate(),
      );
      onChange &&
        onChange({
          target: { value: newValue },
        });
    }
  };

  const handleDateChange = (date) => {
    const startDate = moment(
      new Date(treatment.year, treatment.month, treatment.day),
    );
    const newEndDate = moment(date);

    if (newEndDate.isSameOrAfter(startDate)) {
      const diff = newEndDate.diff(startDate, 'days') + 1;

      setEndDate(newEndDate);
      setSelectedDate(date);
      onChange &&
        onChange({
          target: { value: { ...treatment, count: diff, duration: 'days' } },
        });
    } else {
      showSnackbar('Veuillez saisir une date postérieure au début des soins');
    }
  };

  const currentEndDate = getLastTreatmentDate({ treatments: [treatment] });
  const handleInputChange = (event) => {
    const { name, value, checked, type } = event.target;
    let newValue = { ...treatment };

    if (name === 'frequency' && treatment.weekDays.length) {
      newValue = { ...treatment, frequency: value, weekDays: [] };
      onChange &&
        onChange({
          target: { value: newValue },
        });
    } else if (name === 'weekDays') {
      const frequency = value.length > 0 ? '' : '1';

      newValue = { ...treatment, weekDays: value, frequency };
      onChange &&
        onChange({
          target: { value: newValue },
        });
    } else if (name === 'timeHour' || name === 'timeMinute') {
      const pssgMoment =
        name === 'timeHour'
          ? getMoment(value, treatment.timeMinute)
          : getMoment(treatment.timeHour, value);

      newValue = {
        ...treatment,
        [name]: value,
        moment: pssgMoment ? pssgMoment.value : treatment.moment,
      };
      onChange &&
        onChange({
          target: { value: newValue },
        });
    } else {
      newValue = {
        ...treatment,
        [name]: type === 'checkbox' ? checked : value,
      };
      onChange &&
        onChange({
          target: { value: newValue },
        });
    }

    setSelectedDate(getLastTreatmentDate({ treatments: [newValue] }).toDate());
  };
  const { open, onOpen, onClose } = useDialog();

  return (
    <div>
      <fieldset disabled={!editMode}>
        <Grid container justifyContent="flex-start" spacing={1}>
          <Grid item className={classes.datePicker} xs={12} sm="auto">
            <SelectDateTimePicker
              label="Début des soins *"
              value={treatmentDate}
              onChange={handleTreatmentDateChange}
              readOnly={!editMode}
            />
          </Grid>

          <Grid item xs={6} sm="auto">
            <MomentSelect
              value={treatment.moment}
              size="small"
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6} sm="auto">
            <TextField
              name="tour"
              value={treatment.tour}
              label="Tournée"
              size="small"
              select
              SelectProps={{
                native: true,
              }}
              onChange={handleInputChange}
            >
              {tours.map((tour) => (
                <option key={tour.id} value={tour.id}>
                  {tour.name}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>

        {treatment.outsourcedTreatments?.length ? (
          <FormControl
            variant="outlined"
            className={classes.frequencyMainContainer}
          >
            <Grid container className={classes.timeContainer}>
              <div className={classes.outsourcedListContainer}>
                <Typography variant="caption" component="p">
                  Tournées temporaires :
                </Typography>
                <List disablePadding dense>
                  {treatment.outsourcedTreatments.map((outsourcedTreatment) => (
                    <OutsourcedTreatment
                      key={outsourcedTreatment.id}
                      treatmentId={treatment.id}
                      outsourcedTreatment={outsourcedTreatment}
                    />
                  ))}
                </List>
              </div>
            </Grid>
          </FormControl>
        ) : null}

        <FormControl
          variant="outlined"
          className={classes.frequencyMainContainer}
        >
          <InputLabel shrink className={classes.frequencyLabel}>
            Durée des soins *
          </InputLabel>
          <Grid container className={classes.multiFieldContainer}>
            <div>
              <TextField
                className={classes.countTextField}
                name="count"
                value={treatment.count}
                type="number"
                margin="dense"
                inputProps={{ min: 1, step: 1 }}
                onChange={(event) =>
                  handleInputChange({
                    target: {
                      name: event.target.name,
                      value: Number(event.target.value),
                    },
                  })
                }
              />
            </div>

            <div className={classes.timeSep}>
              <TextField
                name="duration"
                value={treatment.duration}
                select
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                onChange={handleInputChange}
              >
                <option value={'acts'}>Actes</option>
                <option value={'days'}>Jours</option>
                <option value={'weeks'}>Semaines</option>
                <option value={'months'}>Mois</option>
              </TextField>
            </div>
            {editMode ? (
              <>
                <div className={classes.orContainer}>
                  <div />
                  <Typography>ou</Typography> <div />
                </div>
                <SelectDatePicker
                  label="Date butoir"
                  value={selectedDate}
                  onChange={handleDateChange}
                  readOnly={!editMode}
                />
              </>
            ) : null}
          </Grid>
          <Typography
            className={classes.durationTitle}
            component="p"
            variant="caption"
          >
            {currentEndDate
              ? `Fin prévue le ${currentEndDate.format('DD/MM/YYYY')}`
              : ''}
          </Typography>
        </FormControl>

        <FormControl
          variant="outlined"
          className={classes.frequencyMainContainer}
        >
          <InputLabel shrink className={classes.frequencyLabel}>
            Fréquence *
          </InputLabel>
          <div className={classes.multiFieldContainer}>
            <Typography
              component="div"
              variant="caption"
              className={classes.multiTypo}
            >
              Tous les
            </Typography>
            <TextField
              className={classes.frequencyTextField}
              required={!treatment.weekDays || treatment.weekDays.length === 0}
              name="frequency"
              value={treatment.frequency || ''}
              type="number"
              margin="dense"
              inputProps={{ min: 1, step: 1 }}
              onChange={(event) =>
                handleInputChange({
                  target: {
                    name: event.target.name,
                    value: Number(event.target.value),
                  },
                })
              }
            />
            <TextField
              className={classes.frequencyDurationTextField}
              name="frequencyDuration"
              value={treatment.frequencyDuration}
              select
              margin="dense"
              SelectProps={{
                native: true,
              }}
              onChange={handleInputChange}
            >
              <option value={'days'}>Jours</option>
              <option value={'months'}>Mois</option>
            </TextField>
          </div>

          <div className={classes.orContainer}>
            <div />
            <Typography>ou</Typography> <div />
          </div>

          <div>
            <WeekDaysSelect
              name="weekDays"
              value={treatment.weekDays || []}
              color="primary"
              onChange={handleInputChange}
            />
          </div>
        </FormControl>

        <div>
          <TextField
            required
            name="type"
            multiline
            value={treatment.type || ''}
            label="Type de soins"
            helperText="Description des soins à faire"
            onChange={handleInputChange}
          />
        </div>

        <div>
          <TextField
            name="comment"
            multiline
            value={treatment.comment || ''}
            label="Transmissions"
            onChange={handleInputChange}
          />
        </div>
      </fieldset>

      {treatment.id && !loading && rows?.length > 0 && (
        <FormControl
          variant="outlined"
          className={classes.dataGridMainContainer}
        >
          <InputLabel shrink className={classes.dataGridLabel}>
            Historique des transmissions
          </InputLabel>
          <DataGrid
            className={classes.dataGrid}
            rows={rows}
            rowHeight={25}
            columns={columns}
            pagination
            pageSize={pageSizeTrans}
            onPageSizeChange={setPageSizeTrans}
            rowsPerPageOptions={[5, 20, 50]}
            disableColumnMenu
            disableColumnFilter
            disableSelectionOnClick
            disableColumnSelector
            hideFooterSelectedRowCount
            autoHeight
            onCellClick={(params, event) => {
              setSelectedTrans(params.row);
              onOpen();
            }}
          />
          <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>Transmission</DialogTitle>
            <DialogContent>
              <div>
                <span>Date: </span>
                <b>{selectedTrans?.date}</b>
              </div>
              <div>
                <span>Validée le: </span>
                <b>{selectedTrans?.doneDate}</b>
              </div>
              <div>
                <span>Par: </span>
                <b>{selectedTrans?.doneBy}</b>
              </div>
              <div>
                <span>Type de soins: </span>
                <b>{selectedTrans?.type}</b>
              </div>
              <div>
                <TextField
                  name="comment"
                  multiline
                  value={selectedTrans?.comment}
                  label="Transmissions"
                  disabled
                />
              </div>
            </DialogContent>

            <DialogActions>
              <div>
                <Button onClick={onClose}>Fermer</Button>
              </div>
            </DialogActions>
          </Dialog>
        </FormControl>
      )}
    </div>
  );
};

export default TreatmentForm;
