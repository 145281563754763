import React from 'react';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  progress: {
    marginLeft: theme.spacing(2),
  },
}));

const ProgressButton = ({
  loading,
  ProgressProps: {
    className: ProgressPropsClassName,
    ...ProgressPropsRest
  } = {},
  children,
  disabled,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Button disabled={disabled || !!loading} {...rest}>
      {children}
      {loading ? (
        <CircularProgress
          className={clsx(classes.progress, ProgressPropsClassName)}
          size={20}
          color="inherit"
          {...ProgressPropsRest}
        />
      ) : null}
    </Button>
  );
};

export default ProgressButton;
