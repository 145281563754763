import React from 'react';
import { makeStyles, Typography, ButtonBase } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import SectionProgress from '../System/SectionProgress';
import useProviders from './useProviders';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  listContainer: {
    flex: 1,
    minHeight: 200,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  actionIcons: {
    marginLeft: 'auto',
    '& > *': {
      marginRight: theme.spacing(2),
      '& svg': {
        opacity: 0.9,
      },
    },
    '& > *:last-child': {
      marginRight: 0,
    },
  },
  emptyState: {
    marginTop: theme.spacing(2),
  },
}));

const Providers = ({ select, onSelect, search }) => {
  const { loading, providers } = useProviders(search);
  const classes = useStyles();
  const { userGroupId } = useParams();

  return (
    <div className={classes.root}>
      {loading ? (
        <SectionProgress />
      ) : providers.length ? (
        <div className={classes.listContainer}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                width={width}
                itemCount={providers.length}
                itemSize={45}
              >
                {({ index, style }) => {
                  const provider = providers[index];
                  return (
                    <ButtonBase
                      style={style}
                      className={classes.listItem}
                      component={select ? undefined : Link}
                      to={
                        select
                          ? undefined
                          : `/g/${userGroupId}/provider/update/${provider.id}`
                      }
                      onClick={select ? () => onSelect(provider) : undefined}
                    >
                      <Typography>
                        <strong>{provider.lastName}</strong>{' '}
                        {provider.firstName}
                      </Typography>
                    </ButtonBase>
                  );
                }}
              </List>
            )}
          </AutoSizer>
        </div>
      ) : (
            <Typography align="center" className={classes.emptyState}>
              Aucun prestataire existant.
            </Typography>
          )}
    </div>
  );
};

export default Providers;
