import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import FieldsContainer from '../System/FieldsContainer';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  comment: {
    marginTop: theme.spacing(2),
    whiteSpace: 'pre-wrap',
  },
}));

const TransmissionComment = ({ transmission }) => {
  const classes = useStyles();
  const isOldTrans = moment
    .utc(Number(transmission.date))
    .isBefore(moment().startOf('day'));
  const emptyTransComment =
    !transmission.comment || transmission.comment === '';
  const treatmentComment = isOldTrans
    ? ''
    : transmission.prescription?.treatments.find(
        (t) => t.id === transmission.treatmentId,
      )?.comment;
  const emptyTreatmentComment = !treatmentComment || treatmentComment === '';
  let comment = emptyTransComment ? treatmentComment : transmission.comment;

  return isOldTrans && emptyTransComment ? null : (
    <FieldsContainer>
      <Typography variant="caption" component="p">
        {`Transmissions${
          !isOldTrans && emptyTransComment && !emptyTreatmentComment
            ? ' (repris des soins)'
            : ''
        }`}
      </Typography>

      <Typography className={classes.comment}>
        {comment ? comment : ''}
      </Typography>
    </FieldsContainer>
  );
};

export default TransmissionComment;
