import { gql, useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';

import useSnackbar from '../System/useSnackbar';
import { LIST_MESSAGES } from './useMessages';
import { GET_PATIENT } from '../Patient/usePatient';

const DELETE_MESSAGE = gql`
  mutation DeleteMessage(
    $userGroupId: String!
    $id: String!
  ) {
    deleteMessage(
      userGroupId: $userGroupId
      id: $id
    )
  }
`;

const useDeleteMessage = ({ message }) => {
  const { userGroupId } = useParams();
  const { showSnackbar } = useSnackbar();
  const { replace } = useHistory();

  const [deleteMessage, { loading }] = useMutation(DELETE_MESSAGE, {
    variables: {
      userGroupId,
      id: message.id,
    },
    update(cache) {
      // Update messages list
      const updateMessageList = (processed) => {
        try {
          const { listMessages } = cache.readQuery({
            query: LIST_MESSAGES,
            variables: { userGroupId, processed },
          });
          cache.writeQuery({
            query: LIST_MESSAGES,
            variables: { userGroupId, processed },
            data: {
              listMessages: {
                ...listMessages,
                data: listMessages.data.filter(msg => msg.id !== message.id),
              },
            },
          });
        } catch (err) { }
      };
      updateMessageList(false);
      updateMessageList(true);

      // Update patient's messages
      try {
        const { getPatient } = cache.readQuery({
          query: GET_PATIENT,
          variables: { userGroupId, id: message.patientId },
        });
        cache.writeQuery({
          query: GET_PATIENT,
          variables: { userGroupId, id: message.patientId },
          data: {
            getPatient: {
              ...getPatient,
              messages: getPatient.messages.filter(msg => msg.id !== message.id),
            },
          },
        });
      } catch (err) { }
    },
    onCompleted() {
      showSnackbar('Message supprimé');
      replace(`/g/${userGroupId}/messages`);
    },
  });

  return {
    loading,
    onDelete: deleteMessage,
  };
};

export default useDeleteMessage;
