import React from 'react';
import {
  makeStyles,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';

import useEditUserGroupForm from './useEditUserGroupForm';

const useStyles = makeStyles((theme) => ({
  submitButtonContainer: {
    marginTop: theme.spacing(2),
  },
  progress: {
    marginLeft: theme.spacing(2),
  },
}));

const EditUserGroupForm = ({ initialUserGroup }) => {
  const classes = useStyles();

  const { loading, inputs, onInputChange, onSubmit } = useEditUserGroupForm({
    initialUserGroup,
  });

  return (
    <form noValidate autoComplete="off" onSubmit={onSubmit}>
      <div>
        <TextField
          name="name"
          label="Nom du groupe"
          value={inputs.name}
          variant="outlined"
          disabled={loading}
          fullWidth
          onChange={onInputChange}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={inputs.archived}
              color="primary"
              onChange={onInputChange}
              name="archived"
            />
          }
          label={<Typography>Archivé</Typography>}
        />
      </div>

      <div className={classes.submitButtonContainer}>
        <Button
          disabled={loading}
          fullWidth
          type="submit"
          variant="outlined"
          color="primary"
        >
          Sauvegarder
          {loading ? (
            <CircularProgress
              className={classes.progress}
              color="inherit"
              size={20}
            />
          ) : null}
        </Button>
      </div>
    </form>
  );
};

export default EditUserGroupForm;
