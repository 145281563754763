import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useCurrentUser from '../System/useCurrentUser';

const ProtectedRoute = ({ redirect, roles = [], ...rest }) => {
  const { user, userLoading } = useCurrentUser();

  if (userLoading) {
    return null;
  }

  if (user.roles?.length) {
    let roleFound = false;
    for (let i = 0; i < user.roles.length; i += 1) {
      const userRole = user.roles[i];
      const found = roles.find((r) => r === userRole);
      if (found) {
        roleFound = true;
        break;
      }
    }
    if (roleFound) {
      return <Route {...rest} />;
    }
  }

  return <Redirect to={redirect} />;
};

export default ProtectedRoute;
