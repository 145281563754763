import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import useProvider from './useProvider';
import AppLayout from '../System/AppLayout';
import ProviderForm from './ProviderForm';
import SectionProgress from '../System/SectionProgress';
import useNetworkStatus from '../System/useNetworkStatus';

const UpdateProvider = () => {
  const { readOnly } = useNetworkStatus();
  const { userGroupId } = useParams();

  // Provider
  const { loading, provider } = useProvider();

  // Edit mode
  const [editMode, setEditMode] = useState(false);
  const handleEdit = () => {
    setEditMode(true);
  };
  const handleStopEdit = () => {
    setEditMode(false);
  };

  return (
    <AppLayout
      title={
        (provider?.lastName && `${provider.firstName} ${provider.lastName}`) ||
        'Prestataire'
      }
      backTo={`/g/${userGroupId}/providers`}
      action={
        !editMode && !readOnly ? (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <IconButton onClick={handleEdit}>
              <EditIcon />
            </IconButton>
          </Grid>
        ) : null
      }
    >
      {loading ? (
        <SectionProgress />
      ) : (
        <ProviderForm
          initialProvider={provider}
          type="update"
          editMode={editMode}
          onSubmitted={handleStopEdit}
        />
      )}
    </AppLayout>
  );
};

export default UpdateProvider;
