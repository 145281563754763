import React from 'react';

import AppLayout from '../System/AppLayout';
import MessageForm from './MessageForm';

const CreateMessage = () => {
  return (
    <AppLayout title="Nouveau message">
      <MessageForm type="create" editMode />
    </AppLayout>
  );
};

export default CreateMessage;
