import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import moment from 'moment';

import PrescriptionFragment from '../../fragments/PrescriptionFragment';
import { useMemo } from 'react';

const GET_PRESCRIPTION = gql`
  query GetPrescription($userGroupId: String!, $id: String!) {
    getPrescription(userGroupId: $userGroupId, id: $id) {
      ...PrescriptionFragment
    }
  }

  ${PrescriptionFragment}
`;

const usePrescription = (prescriptionId) => {
  const { userGroupId, prescriptionId: paramasPrescriptionId } = useParams();

  const { data, loading } = useQuery(GET_PRESCRIPTION, {
    variables: {
      userGroupId,
      id: prescriptionId ? prescriptionId : paramasPrescriptionId,
    },
    fetchPolicy: 'network',
  });

  const prescription = useMemo(() => {
    if (!loading && data?.getPrescription) {
      return {
        ...data.getPrescription,
        treatments: data.getPrescription.treatments
          .slice()
          .sort((a, b) =>
            moment([a.year, a.month, a.day, a.timeHour, a.timeMinute]).isBefore(
              moment([b.year, b.month, b.day, b.timeHour, b.timeMinute]),
            )
              ? -1
              : 1,
          ),
      };
    }
  }, [data, loading]);

  return { loading, prescription };
};

export default usePrescription;
export { GET_PRESCRIPTION };
