import { gql } from '@apollo/client';

const ProviderSummaryFragment = gql`
  fragment ProviderSummaryFragment on ProviderSummary {
    id
    firstName
    lastName
  }
`;

export default ProviderSummaryFragment;
