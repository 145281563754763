import normalize from './normalize';
import { getType } from './get-care-checkup-type';

const filterPatients = (patients, search) => {
  return patients && search
    ? patients.filter((p) => {
        const keys = search.trim().split(' ');
        const result = keys
          .map((key) => {
            return !!(
              (p.lastName && normalize(p.lastName).includes(normalize(key))) ||
              (p.firstName &&
                normalize(p.firstName).includes(normalize(key))) ||
              (p.useName && normalize(p.useName).includes(normalize(key)))
            );
          })
          .filter((result) => !result);

        return result.length === 0;
      })
    : patients;
};

const filterTransmissionsWithPatient = (transmissions, search) => {
  return transmissions && search
    ? transmissions.filter((trans) => {
        const keys = search.trim().split(' ');
        const result = keys
          .map((key) => {
            return !!(
              (trans.patient.lastName &&
                normalize(trans.patient.lastName).includes(normalize(key))) ||
              (trans.patient.firstName &&
                normalize(trans.patient.firstName).includes(normalize(key))) ||
              (trans.patient.useName &&
                normalize(trans.patient.useName).includes(normalize(key)))
            );
          })
          .filter((result) => !result);

        return result.length === 0;
      })
    : transmissions;
};

const sortPatients = (patients) => {
  return patients?.sort((a, b) => sortPatient(a, b));
};

const sortPatient = (a, b) => {
  // We sort by last name, then first name if last names are equal
  if (normalize(a.lastName) === normalize(b.lastName)) {
    if (normalize(a.firstName) === normalize(b.firstNameName)) {
      return normalize(a.useName) < normalize(b.useName) ? -1 : 1;
    }
    return normalize(a.firstName) < normalize(b.firstName) ? -1 : 1;
  }

  return normalize(a.lastName) < normalize(b.lastName) ? -1 : 1;
};

const filterDoctors = (doctors, search) => {
  return doctors && search
    ? doctors.filter((p) => {
        const keys = search.trim().split(' ');
        const result = keys
          .map((key) => {
            return !!(
              (p.lastName && normalize(p.lastName).includes(normalize(key))) ||
              (p.firstName && normalize(p.firstName).includes(normalize(key)))
            );
          })
          .filter((result) => !result);

        return result.length === 0;
      })
    : doctors;
};

const sortDoctors = (doctors) => {
  return doctors?.sort((a, b) => sortDoctor(a, b));
};

const sortDoctor = (a, b) => {
  // We sort by last name, then first name if last names are equal
  if (normalize(a?.lastName) === normalize(b?.lastName)) {
    return normalize(a?.firstName) < normalize(b?.firstName) ? -1 : 1;
  }

  return normalize(a?.lastName) < normalize(b?.lastName) ? -1 : 1;
};

const filterProviders = (providers, search) => {
  return providers && search
    ? providers.filter((p) => {
        const keys = search.trim().split(' ');
        const result = keys
          .map((key) => {
            return !!(
              (p.lastName && normalize(p.lastName).includes(normalize(key))) ||
              (p.firstName && normalize(p.firstName).includes(normalize(key)))
            );
          })
          .filter((result) => !result);

        return result.length === 0;
      })
    : providers;
};

const sortProviders = (doctors) => {
  return doctors?.sort((a, b) => {
    // We sort by last name, then first name if last names are equal
    if (normalize(a.lastName) === normalize(b.lastName)) {
      return normalize(a.firstName) < normalize(b.firstName) ? -1 : 1;
    }

    return normalize(a.lastName) < normalize(b.lastName) ? -1 : 1;
  });
};

const filterCareCheckups = (careCheckups, search) => {
  return careCheckups && search
    ? careCheckups.filter((c) => {
        const keys = search.trim().split(' ');
        const result = keys
          .map((key) => {
            return !!(
              normalize(getType(c.type)).includes(normalize(search)) ||
              (c.patient?.lastName &&
                normalize(c.patient?.lastName).includes(normalize(key))) ||
              (c.patient?.firstName &&
                normalize(c.patient?.firstName).includes(normalize(key))) ||
              (c.patient?.useName &&
                normalize(c.patient?.useName).includes(normalize(key)))
            );
          })
          .filter((result) => !result);

        return result.length === 0;
      })
    : careCheckups;
};

const sortCareCheckups = (careCheckups) => {
  return careCheckups?.sort((a, b) => {
    if (normalize(a.type) === normalize(b.type)) {
      return normalize(a.patientId) < normalize(b.patientId) ? -1 : 1;
    }

    return normalize(a.type) < normalize(b.type) ? -1 : 1;
  });
};

const sortTours = (tours) => {
  return tours?.sort((a, b) => {
    return normalize(a.name) < normalize(b.name) ? -1 : 1;
  });
};

const sortUsers = (users) => {
  return users?.sort((a, b) => {
    if (a.isDeleted === b.isDeleted) {
      if (normalize(a.lastName) === normalize(b.lastName)) {
        return normalize(a.firstName) < normalize(b.firstName) &&
          a.isDeleted < b.isDeleted
          ? -1
          : 1;
      }

      return normalize(a.lastName) < normalize(b.lastName) ? -1 : 1;
    }

    return a.isDeleted < b.isDeleted ? -1 : 1;
  });
};

const filterMessages = (messages = [], search = '') => {
  return messages && search
    ? messages.filter((msg) => {
        const keys = search.trim().split(' ');
        const result = keys
          .map((key) => {
            return !!(
              (msg.text && normalize(msg.text).includes(normalize(key))) ||
              (msg.response &&
                normalize(msg.response).includes(normalize(key))) ||
              (msg.patient &&
                normalize(msg.patient.firstName).includes(normalize(key))) ||
              (msg.patient &&
                normalize(msg.patient.lastName).includes(normalize(key)))
            );
          })
          .filter((result) => !result);

        return result.length === 0;
      })
    : messages;
};

const filterUserGroups = (userGroups, search) => {
  return userGroups && search
    ? userGroups.filter((p) => {
        const keys = search.trim().split(' ');
        const result = keys
          .map((key) => {
            return !!(p.name && normalize(p.name).includes(normalize(key)));
          })
          .filter((result) => !result);

        return result.length === 0;
      })
    : userGroups;
};

const sortUserGroups = (userGroups) => {
  const list = userGroups ? [...userGroups] : [];

  return list?.sort((a, b) => {
    return normalize(a.name) < normalize(b.name) ? -1 : 1;
  });
};

export {
  filterDoctors,
  sortDoctors,
  sortDoctor,
  filterProviders,
  sortProviders,
  filterPatients,
  filterTransmissionsWithPatient,
  sortPatients,
  sortPatient,
  filterCareCheckups,
  sortCareCheckups,
  sortTours,
  sortUsers,
  filterMessages,
  filterUserGroups,
  sortUserGroups,
};
