import React from 'react';
import { FormControlLabel, Checkbox, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  controlLabel: {
    margin: 0,
  },
  checkbox: {
    padding: 0,
    marginTop: theme.spacing(1),
  },
}));

const WeekDaysSelect = ({ name, value, color, onChange }) => {
  const classes = useStyles();

  const checkDay = (day) => Boolean(value.indexOf(day) > -1);

  const handleChange = (event) => {
    const { name: checkboxName, checked } = event.target;

    // We filter event if we've checked a new day so we avoid duplicates
    const newWeekDays = value.filter((d) => Number(checkboxName) !== d);

    if (checked) {
      onChange &&
        onChange({
          target: {
            name,
            value: [...newWeekDays, Number(checkboxName)],
          },
        });
    } else {
      onChange && onChange({ target: { name, value: newWeekDays } });
    }
  };

  return (
    <div className={classes.container}>
      <FormControlLabel
        className={classes.controlLabel}
        control={
          <Checkbox
            color={color}
            checked={checkDay(0)}
            name="0"
            className={classes.checkbox}
            onChange={handleChange}
          />
        }
        label="Lu"
        labelPlacement="top"
      />
      <FormControlLabel
        className={classes.controlLabel}
        control={
          <Checkbox
            color={color}
            checked={checkDay(1)}
            className={classes.checkbox}
            onChange={handleChange}
            name="1"
          />
        }
        label="Ma"
        labelPlacement="top"
      />
      <FormControlLabel
        className={classes.controlLabel}
        control={
          <Checkbox
            color={color}
            checked={checkDay(2)}
            className={classes.checkbox}
            onChange={handleChange}
            name="2"
          />
        }
        label="Me"
        labelPlacement="top"
      />
      <FormControlLabel
        className={classes.controlLabel}
        control={
          <Checkbox
            color={color}
            checked={checkDay(3)}
            className={classes.checkbox}
            onChange={handleChange}
            name="3"
          />
        }
        label="Je"
        labelPlacement="top"
      />
      <FormControlLabel
        className={classes.controlLabel}
        control={
          <Checkbox
            color={color}
            checked={checkDay(4)}
            className={classes.checkbox}
            onChange={handleChange}
            name="4"
          />
        }
        label="Ve"
        labelPlacement="top"
      />
      <FormControlLabel
        className={classes.controlLabel}
        control={
          <Checkbox
            color={color}
            checked={checkDay(5)}
            className={classes.checkbox}
            onChange={handleChange}
            name="5"
          />
        }
        label="Sa"
        labelPlacement="top"
      />
      <FormControlLabel
        className={classes.controlLabel}
        control={
          <Checkbox
            color={color}
            checked={checkDay(6)}
            className={classes.checkbox}
            onChange={handleChange}
            name="6"
          />
        }
        label="Di"
        labelPlacement="top"
      />
    </div>
  );
};

export default WeekDaysSelect;
