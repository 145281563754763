import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import shortid from 'shortid';
import moment from 'moment';

import { GET_PRESCRIPTION } from './usePrescription';
import cleanObject from '../../utils/clean-object';
import getLastTreatmentDate from '../../utils/get-last-treatment-date';
import getFirstDay from '../../utils/get-first-day';
import cleanSignedUrl from '../../utils/clean-signed-url';

const useDuplicatePrescription = () => {
  const { userGroupId, patientId, prescriptionId } = useParams();
  const { data, loading } = useQuery(GET_PRESCRIPTION, {
    variables: { userGroupId, id: prescriptionId },
  });

  const prescription = useMemo(() => {
    const prescription = data?.getPrescription;

    if (prescription) {
      return cleanObject({
        userGroupId,
        patientId,
        dateDay: prescription.dateDay,
        dateMonth: prescription.dateMonth,
        dateYear: prescription.dateYear,
        num: prescription.num,
        soins2000Number: 'Virtuelle',
        cause: prescription.cause,
        pec: prescription.pec.map((p) => {
          return {
            key: cleanSignedUrl(p.key) || p.addedKey,
            size: p.size,
            label: p.label,
          };
        }),
        pecImage: {
          key: cleanSignedUrl(prescription.pecImage?.key),
        },
        workAccidentPhoto: {
          key: cleanSignedUrl(prescription.workAccidentPhoto?.key),
        },
        chronic: prescription.chronic,
        cotation: prescription.cotation,
        liens: prescription.liens,
        treatments: prescription.treatments.map((treatment) => {
          const endDate = getLastTreatmentDate({ treatments: [treatment] });
          const nextStartDate = treatment.frequency
            ? moment(endDate).add(
                treatment.frequency,
                treatment.frequencyDuration || 'days',
              )
            : moment(getFirstDay(treatment.weekDays, moment(endDate)).date);

          return {
            id: shortid.generate(),
            day: nextStartDate.date(),
            month: nextStartDate.month(),
            year: nextStartDate.year(),
            type: treatment.type,
            timeHour: treatment.timeHour,
            timeMinute: treatment.timeMinute,
            count: treatment.count,
            duration: treatment.duration,
            moment: treatment.moment,
            tour: treatment.tour,
            frequency: treatment.frequency,
            frequencyDuration: treatment.frequencyDuration,
            weekDays: treatment.weekDays,
            chronic: treatment.chronic,
            comment: treatment.comment,
            outsourcedTreatments: treatment.outsourcedTreatments,
          };
        }),
        doctorId: prescription.doctor?.id,
      });
    }
  }, [data, userGroupId, patientId]);

  return { loading, prescription };
};

export default useDuplicatePrescription;
