import { gql, useQuery } from '@apollo/client';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import PrescriptionFragment from '../../fragments/PrescriptionFragment';
import PrescriptionListFragment from '../../fragments/PrescriptionListFragment';
import { useMemo, useCallback } from 'react';
import getLastTreatmentDate from '../../utils/get-last-treatment-date';

const LIST_PRESCRIPTIONS = gql`
  query ListPrescriptions(
    $userGroupId: String!
    $startKey: String
    $limit: Int
  ) {
    listPrescriptions(
      userGroupId: $userGroupId
      startKey: $startKey
      limit: $limit
    ) {
      ...PrescriptionListFragment
    }
  }

  ${PrescriptionListFragment}
`;

const LIST_PRESCRIPTIONS_BY_PATIENT = gql`
  query ListPrescriptionsByPatient($userGroupId: String!, $patientId: String!) {
    listPrescriptionsByPatient(
      userGroupId: $userGroupId
      patientId: $patientId
    ) {
      ...PrescriptionFragment
    }
  }

  ${PrescriptionFragment}
`;

const usePrescriptions = () => {
  const { userGroupId } = useParams();
  const { data, loading, error, fetchMore, refetch } = useQuery(
    LIST_PRESCRIPTIONS,
    {
      variables: { userGroupId },
      notifyOnNetworkStatusChange: true,
    },
  );

  const handleFetchMore = () => {
    fetchMore({
      variables: {
        startKey: data.listPrescriptions.nextKey,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          ...fetchMoreResult,
          listPrescriptions: {
            ...fetchMoreResult.listPrescriptions,
            data: [
              ...prev.listPrescriptions.data,
              ...fetchMoreResult.listPrescriptions.data,
            ],
          },
        };
      },
    });
  };

  const { push } = useHistory();
  const { pathname } = useLocation();
  const handleClick = useCallback(
    (prescription) => {
      push(
        `/g/${userGroupId}/prescription/update/${prescription.patient.id}/${prescription.id}?back=${pathname}`,
      );
    },
    [push, userGroupId, pathname],
  );

  const maxTreatments = useMemo(() => {
    if (data?.listPrescriptions) {
      return data.listPrescriptions.data.reduce((nb, p) => {
        if (p.treatments.length > nb) {
          return p.treatments.length;
        }
        return nb;
      }, 0);
    }
    return 0;
  }, [data]);

  const prescriptions = useMemo(() => {
    if (data?.listPrescriptions) {
      return data.listPrescriptions.data.map((data) => {
        const endDate = getLastTreatmentDate({
          treatments: data.treatments || [],
        });
        return { ...data, endDate };
      });
    }
    return null;
  }, [data]);

  return {
    error,
    prescriptions,
    loading,
    maxTreatments,
    hasMore: Boolean(data?.listPrescriptions.nextKey),
    onClick: handleClick,
    onFetchMore: handleFetchMore,
    refetch,
  };
};

const usePrescriptionsByPatient = (patientId) => {
  const { userGroupId } = useParams();
  const { loading, data } = useQuery(LIST_PRESCRIPTIONS_BY_PATIENT, {
    fetchPolicy: 'cache-and-network',
    variables: {
      userGroupId,
      patientId,
    },
  });

  return {
    prescriptions: data?.listPrescriptionsByPatient,
    loading: loading,
  };
};

export default usePrescriptions;
export { usePrescriptionsByPatient };
