import { gql } from '@apollo/client';

const UserSummaryFragment = gql`
  fragment UserSummaryFragment on UserSummary {
    id
    email
    firstName
    lastName
    joined
    isAdmin
    isDeleted
  }
`;

export default UserSummaryFragment;
