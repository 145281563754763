import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import UserGroupFragment from '../../fragments/UserGroupFragment';

const GET_USER_GROUP = gql`
  query GetUserGroup($id: String!) {
    getUserGroup(id: $id) {
      ...UserGroupFragment
    }
  }

  ${UserGroupFragment}
`;

const useEditUserGroup = () => {
  const { userGroupId } = useParams();

  const { loading, data } = useQuery(GET_USER_GROUP, {
    variables: { id: userGroupId },
    fetchPolicy: 'network-only',
  });

  return { loading, userGroup: data?.getUserGroup };
};

export { GET_USER_GROUP };
export default useEditUserGroup;
