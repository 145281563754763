import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';

import FieldsContainer from './FieldsContainer';
import UploadButton from './UploadButton';
import Figure from './Figure';
import useResourceDelete from './useResourceDelete';
import { concatImagesToPdf } from '../../utils/concat-images-to-pdf';
import cleanSignedUrl from '../../utils/clean-signed-url';
import useDialog from '../System/useDialog';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  uploadContainer: {
    justifyContent: 'center',
    padding: theme.spacing(2, 0, 0, 0),
  },
  uploadButton: {
    margin: theme.spacing(2, 0.5),
  },
  dialogTitle: {
    fontSize: 20,
  },
}));

const ImageUpload = ({
  name,
  title,
  value,
  editMode,
  emptyText,
  disablePadding = false,
  onChange,
  onDelete,
  multiple = false,
}) => {
  const classes = useStyles();
  const [selectedFiles, setSelectedFiles] = useState();
  const [previewImages, setPreviewImages] = useState();
  const [imageInfos, setImageInfos] = useState({});
  const [progress, setProgress] = useState();

  const { open, onOpen, onClose } = useDialog();

  const selectFiles = (event) => {
    if (multiple) {
      const files = event.target.files;
      const allFiles = selectedFiles
        ? [...selectedFiles, ...files]
        : [...files];
      let images = previewImages ? previewImages : [];
      const infos = imageInfos;

      setProgress(0);
      for (const file of files) {
        const reader = new FileReader();
        const img = new Image();

        img.addEventListener(
          'load',
          () => {
            infos[`${file.name}_${file.lastModified}`] = {
              width: img.width,
              height: img.height,
              data: img.src,
            };
            setImageInfos(infos);

            const percent = (100 * Object.keys(infos).length) / allFiles.length;
            setProgress(percent >= 100 ? null : percent);
          },
          false,
        );
        reader.addEventListener(
          'load',
          () => {
            img.src = reader.result;
          },
          false,
        );

        images.push(URL.createObjectURL(file));
        reader.readAsDataURL(file);
      }

      setSelectedFiles(allFiles);
      setPreviewImages(images);
      if (!open) {
        onOpen();
      }
    } else {
      onChange(event);
    }
  };

  const cancelFiles = () => {
    setProgress(null);
    setSelectedFiles(null);
    setPreviewImages(null);
    onClose();
  };

  const { loading: deleteProgress, onDelete: onResouceDelete } =
    useResourceDelete({
      key: cleanSignedUrl(value?.key),
    });

  const handleDelete = async () => {
    await onResouceDelete();
    onDelete({ target: { name, value: null } });
  };

  const handleEditPreview = (previousSrc, newSrc, blob) => {
    const fileName = `${name}.png`;
    const outputFile = new File([blob], fileName, {
      type: 'application/png',
    });

    setPreviewImages([newSrc]);
    setSelectedFiles([outputFile]);
  };

  const handleDeletePreview = (src) => {
    const index = previewImages.indexOf(src);

    setPreviewImages(previewImages.filter((src, idx) => idx !== index));
    setSelectedFiles(selectedFiles.filter((src, idx) => idx !== index));
  };

  const uploadImages = () => {
    setProgress(selectedFiles.length === 1 ? null : 'Conversion PDF');
    const file =
      selectedFiles.length === 1
        ? selectedFiles[0]
        : concatImagesToPdf({ name, files: selectedFiles, imageInfos });

    setProgress(null);
    setSelectedFiles(null);
    setPreviewImages();
    setImageInfos({});
    onChange(file);
    onClose();
  };

  const masterProgress = progress ? progress : value?.progress;
  const isPdf =
    value?.isPdf || (value?.key && value.key.indexOf('.pdf?') !== -1);
  const canCrop = previewImages?.length === 1 && !isPdf && editMode;

  return (
    <FieldsContainer
      disablePadding={disablePadding}
      style={{ marginTop: '0px' }}
    >
      <Typography className={classes.title} component="p" variant="caption">
        {title}
      </Typography>
      {value?.src || value?.key ? (
        <Figure
          src={value.src ? value.src : value.key}
          isPdf={isPdf}
          editMode={editMode}
          deleteProgress={deleteProgress}
          onDelete={onDelete && handleDelete}
          onEdit={handleEditPreview}
        />
      ) : null}
      {editMode && !value ? (
        <Grid container className={classes.uploadContainer}>
          <UploadButton
            className={classes.uploadButton}
            progress={masterProgress}
            onChange={selectFiles}
            accept="image/*"
            multiple={multiple}
          >
            {selectedFiles?.length && multiple ? 'Ajouter' : 'Choisir'}
          </UploadButton>
        </Grid>
      ) : value?.key ? null : (
        <Typography>
          <Typography variant="caption">{emptyText}</Typography>
        </Typography>
      )}
      <Dialog open={open} onClose={cancelFiles}>
        <DialogTitle>
          <Typography className={classes.dialogTitle}>{title}</Typography>
          <Typography variant="body2" color="textSecondary">
            {`Cliquez sur l'image pour l'${canCrop ? 'éditer' : 'agrandir'}`}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {previewImages
            ? previewImages.map((src, index) => (
                <Figure
                  key={index}
                  src={src}
                  isPdf={isPdf}
                  editMode={editMode}
                  deleteProgress={deleteProgress}
                  onDelete={handleDeletePreview}
                  onEdit={handleEditPreview}
                  isPreview={true}
                  canCrop={canCrop}
                />
              ))
            : null}
        </DialogContent>
        <DialogActions>
          <Grid container className={classes.uploadContainer}>
            <UploadButton
              className={classes.uploadButton}
              progress={masterProgress}
              onChange={selectFiles}
              accept="image/*"
              multiple={multiple}
            >
              Ajouter
            </UploadButton>
            {!value && selectedFiles?.length ? (
              <Button
                variant="outlined"
                color="primary"
                className={classes.uploadButton}
                component="span"
                disabled={!!masterProgress}
                onClick={uploadImages}
                endIcon={
                  masterProgress ? (
                    <CircularProgress color="inherit" size={16} />
                  ) : (
                    <PublishIcon />
                  )
                }
              >
                Valider
              </Button>
            ) : null}
            {!value ? (
              <Button
                variant="outlined"
                color="primary"
                className={classes.uploadButton}
                component="span"
                onClick={cancelFiles}
              >
                Annuler
              </Button>
            ) : null}
          </Grid>
        </DialogActions>
      </Dialog>
    </FieldsContainer>
  );
};

export default ImageUpload;
