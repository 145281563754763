import React from 'react';
import moment from 'moment';
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  makeStyles,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Link, useParams, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
  text: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '& > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  processed: {
    opacity: 0.7,
    fontStyle: 'italic',
  },
  checkIcon: {
    fontSize: '0.9rem',
    position: 'relative',
    top: 2,
  },
  paper: {
    maxHeight: 300,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },
}));

const MessagesList = ({ messages, disabled }) => {
  const classes = useStyles();

  const { userGroupId } = useParams();
  const { pathname } = useLocation();

  return (
    <Paper className={classes.paper}>
      <List disablePadding dense>
        {messages.length ? (
          messages.map(({ id, text, processed, response, author, date }) => {
            return (
              <ListItem
                key={id}
                button
                component={Link}
                to={`/g/${userGroupId}/message/update/${id}?back=${pathname}`}
                disabled={disabled}
              >
                <ListItemText
                  className={clsx(classes.text, processed && classes.processed)}
                >
                  {processed ? (
                    <>
                      <CheckIcon className={classes.checkIcon} />
                      <CheckIcon
                        style={{ marginLeft: -8, top: 3 }}
                        className={classes.checkIcon}
                      />
                    </>
                  ) : response ? (
                    <CheckIcon
                      style={{ marginRight: 4 }}
                      className={classes.checkIcon}
                    />
                  ) : null}
                  <b>
                    {moment(Number(date)).format('DD/MM/YYYY')}{' '}
                    {author.lastName}
                  </b>{' '}
                  : {text}
                </ListItemText>
                <ArrowRightIcon />
              </ListItem>
            );
          })
        ) : (
          <ListItem>
            <ListItemText>Aucun message</ListItemText>
          </ListItem>
        )}
      </List>
    </Paper>
  );
};

export default MessagesList;
