import React from 'react';
import { useParams } from 'react-router-dom';

import AppLayout from '../System/AppLayout';
import PrescriptionForm from './PrescriptionForm';
import useTours from '../Tours/useTours';
import SectionProgress from '../System/SectionProgress';
import usePatient from '../Patient/usePatient';

const CreatePrescription = () => {
  const { userGroupId, patientId } = useParams();
  const { loading, tours } = useTours();
  const { loading: patientLoading, patient } = usePatient();

  return (
    <AppLayout
      title="Nouvelle ordonnance"
      backTo={`/g/${userGroupId}/patient/update/${patientId}`}
    >
      {loading || patientLoading ? (
        <SectionProgress />
      ) : (
          <PrescriptionForm
            initialPrescription={{}}
            type="create"
            editMode
            tours={tours}
            patient={patient}
            userGroupId={userGroupId}
          />
        )}
    </AppLayout>
  );
};

export default CreatePrescription;
