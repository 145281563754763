import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import UserSummaryFragment from '../../fragments/UserSummaryFragment';
import useCurrentUser from '../System/useCurrentUser';
import { adminUsers } from '../../config/global-config'
import { sortUsers } from '../../utils/filters';

const LIST_USERS = gql`
  query ListUsers($userGroupId: String!) {
    listUsers(userGroupId: $userGroupId) {
      ...UserSummaryFragment
    }
  }

  ${UserSummaryFragment}
`;

const useUsers = () => {
  const { userGroupId } = useParams();

  const { user } = useCurrentUser();

  const { loading, data } = useQuery(LIST_USERS, {
    variables: {
      userGroupId,
    },
  });

  const adminUser = adminUsers[user.email];
  const currentUser = adminUser ? [{ ...user, ...adminUser }] : [];

  return {
    loading,
    users: sortUsers([...new Set(data?.listUsers || []), ...currentUser]),
  };
};

export default useUsers;
export { LIST_USERS };