import { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import useSnackbar from '../System/useSnackbar';
import useDeleteAbsence from './useDeleteAbsence';
import useCreateAbsence from './useCreateAbsence';
import { backSearch } from '../../utils/back-search';

const useUpdateAbsence = ({ generalInputs, unknownEnd }) => {
  const { showSnackbar } = useSnackbar();

  const { onDelete } = useDeleteAbsence({ noRedirect: true });
  const { createAbsence, error: createError } = useCreateAbsence({
    noRedirect: true,
    generalInputs,
    unknownEnd,
    noSnackbar: true,
  });

  const { replace } = useHistory();
  const { userGroupId, patientId } = useParams();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const { search } = useLocation();
  const backTo =
    backSearch(search) || `/g/${userGroupId}/patient/update/${patientId}`;

  const handleUpdate = async (event) => {
    event && event.preventDefault();
    setLoading(true);

    try {
      await onDelete();
      const { data } = await createAbsence();

      if (!createError && data.createAbsence) {
        showSnackbar('Absence mise à jour');
        replace(backTo);
      }
    } catch (err) {
      setError(err);
    }

    setLoading(false);
  };

  return { loading, error: error || createError, onUpdate: handleUpdate };
};

export default useUpdateAbsence;
