import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useParams, useLocation, Link } from 'react-router-dom';
import moment from 'moment';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CheckIcon from '@material-ui/icons/Check';

import useTreatmentTransmissions from './useTreatmentTransmissions';
import AppLayout from '../System/AppLayout';
import SectionProgress from '../System/SectionProgress';
import useTours from '../Tours/useTours';
import { initialBackSearch, urlWithBacks } from '../../utils/back-search';

const useStyles = makeStyles({
  itemTextAbsence: {
    textDecoration: 'line-through',
  },
});

const Transmissions = () => {
  const classes = useStyles();
  const { userGroupId, patientId, prescriptionId } = useParams();
  const { search: s, pathname } = useLocation();
  const { loading, transmissions } = useTreatmentTransmissions();
  const { loading: toursLoading, tours } = useTours(false);
  const backToUrl = initialBackSearch(s);

  return (
    <AppLayout
      title="Transmissions"
      backTo={urlWithBacks({
        url: `/g/${userGroupId}/prescription/update/${patientId}/${prescriptionId}`,
        back: backToUrl,
      })}
    >
      {loading || toursLoading ? (
        <SectionProgress />
      ) : (
        <List dense>
          {transmissions?.length ? (
            transmissions.map((transmission) => {
              const tour = tours.find((t) => t.id === transmission.tourId);

              return (
                <ListItem
                  button
                  key={transmission.id}
                  component={Link}
                  to={urlWithBacks({
                    url: tour
                      ? `/g/${userGroupId}/transmission/${transmission.tourId}/${transmission.date}/${transmission.id}`
                      : pathname,
                    back: pathname,
                    initialBack: backToUrl,
                  })}
                >
                  <ListItemText
                    classes={{
                      primary: transmission.absence && classes.itemTextAbsence,
                      secondary:
                        transmission.absence && classes.itemTextAbsence,
                    }}
                    secondary={`Tournée ${tour?.name || '?'}${
                      transmission.type ? ` - ${transmission.type}` : ''
                    }`}
                  >
                    {moment
                      .utc(Number(transmission.date))
                      .format('dddd DD MMMM YYYY HH:mm')}
                  </ListItemText>
                  {Boolean(transmission.done?.date) ? <CheckIcon /> : null}
                  <ArrowRightIcon />
                </ListItem>
              );
            })
          ) : (
            <Typography align="center">
              Aucune transmission n'a été trouvée
            </Typography>
          )}
        </List>
      )}
    </AppLayout>
  );
};

export default Transmissions;
