import { useState, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { GET_PATIENT } from './usePatient';
import TransmissionBaseFragment from '../../fragments/TransmissionBaseFragment';
import useSnackbar from '../System/useSnackbar';
import getMoment from '../../utils/get-moment';

const CREATE_TRANSMISSION = gql`
  mutation CreateTransmission(
    $userGroupId: String!
    $patientId: String!
    $tourId: String!
    $date: String!
    $type: String!
    $moment: MOMENT!
    $comment: String!
  ) {
    createTransmission(
      userGroupId: $userGroupId
      patientId: $patientId
      tourId: $tourId
      date: $date
      type: $type
      moment: $moment
      comment: $comment
    ) {
      ...TransmissionBaseFragment
    }
  }

  ${TransmissionBaseFragment}
`;

const useAddTransmissionForm = ({ tours, onAdded }) => {
  const { userGroupId, patientId } = useParams();

  const [inputs, setInputs] = useState({
    type: '',
    tourId: tours?.length ? tours[0].id : '',
    date: moment().hour(5).minute(0).second(0).millisecond(0).valueOf(),
    timeHour: 5,
    timeMinute: 0,
    moment: 'morning',
    comment: '',
  });

  useEffect(() => {
    if (tours?.length) {
      setInputs((prevInputs) => ({ ...prevInputs, tourId: tours[0].id }));
    }
  }, [tours]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'timeHour' || name === 'timeMinute') {
      const transMoment =
        name === 'timeHour'
          ? getMoment(value, inputs.timeMinute)
          : getMoment(inputs.timeHour, value);

      setInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        moment: transMoment ? transMoment.value : inputs.moment,
      }));
    }
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const handleInputsChange = (values) => {
    setInputs((prevInputs) => ({ ...prevInputs, ...values }));
  };

  const { showSnackbar } = useSnackbar();
  const [createTransmission, { loading, error }] = useMutation(
    CREATE_TRANSMISSION,
    {
      variables: {
        userGroupId,
        patientId,
        tourId: inputs.tourId,
        date: moment
          .utc(Number(inputs.date))
          .hour(inputs.timeHour)
          .minute(inputs.timeMinute)
          .valueOf()
          .toString(),
        type: inputs.type,
        moment: inputs.moment,
        comment: inputs.comment,
      },
      update(cache, { data: { createTransmission } }) {
        const { getPatient } = cache.readQuery({
          query: GET_PATIENT,
          variables: { userGroupId, id: patientId },
        });
        cache.writeQuery({
          query: GET_PATIENT,
          variables: { userGroupId, id: patientId },
          data: {
            getPatient: {
              ...getPatient,
              customTransmissions: [
                ...getPatient.customTransmissions,
                createTransmission,
              ],
            },
          },
        });
      },
      onCompleted() {
        showSnackbar('Transmission créée');
        onAdded && onAdded();
      },
    },
  );

  const handleAdd = async (event) => {
    event && event.preventDefault();
    try {
      await createTransmission();
    } catch (err) {
      // Do nothing, let Apollo useMutation handle errors
    }
  };

  return {
    loading,
    error,
    inputs,
    onInputChange: handleInputChange,
    onInputsChange: handleInputsChange,
    onAdd: handleAdd,
  };
};

export default useAddTransmissionForm;
