import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import DuplicateIcon from '@material-ui/icons/FileCopyOutlined';
import EditIcon from '@material-ui/icons/Edit';
import MenuIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';

import usePrescription from './usePrescription';
import AppLayout from '../System/AppLayout';
import PrescriptionForm from './PrescriptionForm';
import SectionProgress from '../System/SectionProgress';
import useMenu from '../System/useMenu';
import useTours from '../Tours/useTours';
import usePatient from '../Patient/usePatient';
import useNetworkStatus from '../System/useNetworkStatus';
import ExportJson, { cleanPrescription } from '../System/export-json';
import useCurrentUser from '../System/useCurrentUser';
import ArchivePrescriptionMenuItem from './ArchivePrescriptionMenuItem';

const UpdatePrescription = () => {
  const { userGroupId, patientId, prescriptionId } = useParams();
  const { prescription: prescriptionFromCache } =
    usePrescription(prescriptionId);
  const { loading: toursLoading, tours } = useTours();
  const { loading: patientLoading, patient } = usePatient();
  const { readOnly } = useNetworkStatus();
  const { isAdmin, isSuperAdmin } = useCurrentUser();

  const [prescription, setPrescription] = useState();
  const [editMode, setEditMode] = useState(false);
  const handleEdit = () => {
    setEditMode(true);
  };
  const handleStopEdit = () => {
    setEditMode(false);
  };
  const { anchorEl, onClick: onMenuClick, onClose: onMenuClose } = useMenu();

  useEffect(() => {
    if (prescriptionFromCache) {
      setPrescription(prescriptionFromCache);
    }
  }, [prescriptionFromCache]);

  return (
    <AppLayout
      title="Ordonnance"
      backTo={`/g/${userGroupId}/patient/update/${patientId}`}
      direction="row"
      editMode={editMode}
      action={
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          wrap="nowrap"
        >
          {!editMode && !readOnly && !patient?.archived ? (
            <IconButton onClick={handleEdit}>
              <EditIcon />
            </IconButton>
          ) : null}
          {!editMode && prescription?.archived ? (
            <Typography style={{ marginLeft: 8 }} variant="body2">
              Archivée
            </Typography>
          ) : null}
          {!editMode && !readOnly && prescription && patient ? (
            <Grid item>
              <IconButton onClick={onMenuClick}>
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={onMenuClose}
              >
                {!editMode && !patient?.archived ? (
                  <MenuItem
                    component={Link}
                    to={`/g/${userGroupId}/prescription/duplicate/${patient.id}/${prescription.id}`}
                  >
                    <ListItemIcon>
                      <DuplicateIcon fontSize="small" />
                    </ListItemIcon>
                    Dupliquer...
                  </MenuItem>
                ) : null}
                {isSuperAdmin || isAdmin ? (
                  <ArchivePrescriptionMenuItem
                    initialPrescription={prescription}
                    patient={patient}
                    tours={tours}
                  />
                ) : null}
                {isSuperAdmin || isAdmin ? (
                  <ExportJson
                    title="Exporter l'ordonnance"
                    filename={`Ordonnance.${prescription.id}.json`}
                    data={cleanPrescription(prescription, patient)}
                  />
                ) : null}
              </Menu>
            </Grid>
          ) : null}
        </Grid>
      }
    >
      {toursLoading || patientLoading || !prescription ? (
        <SectionProgress />
      ) : (
        <PrescriptionForm
          initialPrescription={prescription}
          type="update"
          editMode={editMode}
          patient={patient}
          tours={tours}
          onSubmitted={handleStopEdit}
          userGroupId={userGroupId}
        />
      )}
    </AppLayout>
  );
};

export default UpdatePrescription;
