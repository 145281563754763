import React from 'react';
import { Button, makeStyles, DialogContentText } from '@material-ui/core';
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';

import ConfirmDialog from '../System/ConfirmDialog';
import useDialog from '../System/useDialog';
import useSnackbar from '../System/useSnackbar';
import { GET_USER_GROUP } from './useEditUserGroup';
import { LIST_USERS } from '../users/useUsers';

const DELETE_USER = gql`
  mutation DeleteUser($groupId: String!, $userId: String!) {
    deleteUser(groupId: $groupId, userId: $userId)
  }
`;

const useStyles = makeStyles((theme) => ({
  delete: {
    marginTop: theme.spacing(2),
  },
}));

const DeleteUser = ({ userId, userEmail, onDelete }) => {
  const classes = useStyles();

  const { userGroupId } = useParams();
  const { open, onOpen, onClose } = useDialog();

  const { showSnackbar } = useSnackbar();
  const [deleteUser, { loading }] = useMutation(DELETE_USER, {
    variables: {
      groupId: userGroupId,
      userId,
    },
    update(cache) {
      try {
        const { getUserGroup } = cache.readQuery({
          query: GET_USER_GROUP,
          variables: { id: userGroupId },
        });
        cache.writeQuery({
          query: GET_USER_GROUP,
          variables: { id: userGroupId },
          data: {
            getUserGroup: {
              ...getUserGroup,
              users: getUserGroup.users.filter((user) => user.id !== userId),
            },
          },
        });
      } catch (err) {}

      try {
        const { users } = cache.readQuery({
          query: LIST_USERS,
          variables: { userGroupId },
        });
        cache.writeQuery({
          query: LIST_USERS,
          variables: { userGroupId },
          data: { users: users.filter((user) => user.id !== userId) },
        });
      } catch (err) {}
    },
    onCompleted() {
      showSnackbar('Utilisateur supprimé');
      onDelete && onDelete();
    },
  });

  return (
    <>
      <div className={classes.delete}>
        <Button
          fullWidth
          variant="outlined"
          startIcon={<DeleteIcon />}
          onClick={onOpen}
        >
          Supprimer l'utilisateur...
        </Button>
      </div>

      <ConfirmDialog
        title="Confirmer"
        open={open}
        confirmLoading={loading}
        confirmText="Confirmer la suppression"
        onConfirm={deleteUser}
        onClose={onClose}
      >
        <DialogContentText>
          Vous êtes sur le point de supprimer définitivement l'utilisateur{' '}
          {decodeURIComponent(userEmail)}
        </DialogContentText>
        <DialogContentText>Êtes-vous sûr ?</DialogContentText>
      </ConfirmDialog>
    </>
  );
};

export default DeleteUser;
