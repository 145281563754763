import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { filterTransmissionsWithPatient } from '../../utils/filters';

const LIST_TRANSMISSIONS_BY_TOUR = gql`
  query ListTransmissionsByTour(
    $userGroupId: String!
    $tourId: String!
    $minDate: String!
    $maxDate: String!
  ) {
    listTransmissionsByTour(
      userGroupId: $userGroupId
      tourId: $tourId
      minDate: $minDate
      maxDate: $maxDate
    ) {
      id
      type
      lastDate
      absence
      date
      prescriptionPhotoMissing
      done {
        date
      }
      patient {
        socialSecuNumber
        lastName
        firstName
        birthMonth
        birthDay
        birthYear
        phones {
          number
        }
      }
    }
  }
`;

const useTransmissions = ({ fetchPolicy = 'network-only' } = {}) => {
  const { userGroupId, tourId, date } = useParams();
  const { replace } = useHistory();

  const [search, setSearch] = useState('');
  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  const minDate = moment.utc(Number(date)).startOf('day').valueOf().toString();
  const maxDate = moment.utc(Number(date)).endOf('day').valueOf().toString();

  const { loading, data } = useQuery(LIST_TRANSMISSIONS_BY_TOUR, {
    // Fetch policy network-only because it is too complicated to know wich queries with
    // which variables are cached when we need to update cache
    // after some mutations (eg: outsource a treatment to another tour)
    fetchPolicy,
    variables: {
      userGroupId,
      tourId,
      minDate,
      maxDate,
    },
  });

  const handleDateChange = (event) => {
    const { name, value } = event.target;

    const newDate = moment
      .utc(Number(minDate))
      [name === 'day' ? 'date' : name](value);

    replace(`/g/${userGroupId}/transmissions/${tourId}/${newDate.valueOf()}`);
  };

  const handlePickerDateChange = (date) => {
    if (date) {
      const newDate = moment
        .utc(Number(date.valueOf()))
        .startOf('day')
        .add(12, 'hours');

      replace(`/g/${userGroupId}/transmissions/${tourId}/${newDate.valueOf()}`);
    }
  };

  const handleDateOffset = (offset) => {
    const newDate = moment.utc(Number(minDate)).add(offset, 'days');

    replace(`/g/${userGroupId}/transmissions/${tourId}/${newDate.valueOf()}`);
  };

  const listTransmissionsByTour = useMemo(() => {
    const listTransmissionsByTour = data?.listTransmissionsByTour;
    return data && search
      ? filterTransmissionsWithPatient(listTransmissionsByTour, search)
      : listTransmissionsByTour;
  }, [data, search]);

  return {
    dateRange: { minDate, maxDate },
    transmissions: listTransmissionsByTour,
    loading: loading,
    search,
    onDateChange: handleDateChange,
    onPickerDateChange: handlePickerDateChange,
    onDateOffset: handleDateOffset,
    onSearchChange: handleSearchChange,
  };
};

export default useTransmissions;
export { LIST_TRANSMISSIONS_BY_TOUR };
