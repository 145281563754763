import React from 'react';
import { MenuItem, DialogContentText, ListItemIcon } from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';

import useDialog from '../System/useDialog';
import ConfirmDialog from '../System/ConfirmDialog';
import usePrescriptionForm from './usePrescriptionForm';

const ArchivePrescriptionMenuItem = ({
  initialPrescription,
  tours,
  patient,
}) => {
  const label = "Supprimer l'ordonnance";
  const { open, onClose, onOpen } = useDialog();

  const { loading, onArchive } = usePrescriptionForm({
    initialPrescription,
    tours,
    patient,
  });

  return !initialPrescription?.archived ? (
    <>
      <MenuItem button onClick={onOpen}>
        <ListItemIcon>
          <ArchiveIcon fontSize="small" />
        </ListItemIcon>
        {label}
      </MenuItem>

      <ConfirmDialog
        title={label}
        open={open}
        confirmLoading={loading}
        onConfirm={onArchive}
        onClose={onClose}
      >
        <DialogContentText>
          Cette action est irréversible et supprimera l'ordonnance.
        </DialogContentText>
        <DialogContentText>
          Elle n'apparaitra plus dans la fiche de son patient.
        </DialogContentText>

        <DialogContentText>Êtes-vous sûr ?</DialogContentText>
      </ConfirmDialog>
    </>
  ) : null;
};

export default ArchivePrescriptionMenuItem;
