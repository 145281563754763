import React from 'react';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { fr } from 'date-fns/locale';

function SelectDatePicker({
  disableToolbar = true,
  showTodayButton = true,
  disablePast = false,
  disableFuture = false,
  keyboard = false,
  format,
  value,
  onChange,
  readOnly = false,
  label,
  openTo,
  views,
}) {
  const theFormat = format || (keyboard ? 'dd/MM/yyyy' : 'E dd/MM/yyyy');

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
      {keyboard ? (
        <KeyboardDatePicker
          id="date-picker-inline"
          disableToolbar={disableToolbar}
          disablePast={disablePast}
          disableFuture={disableFuture}
          showTodayButton={showTodayButton}
          openTo={openTo}
          views={views}
          inputVariant="outlined"
          format={theFormat}
          margin="dense"
          label={label}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          invalidDateMessage="Format de date invalide"
          cancelLabel="Annuler"
          todayLabel="Aujourd'hui"
          inputProps={{ inputMode: 'numeric' }}
        />
      ) : (
        <DatePicker
          id="date-picker-inline"
          disableToolbar={disableToolbar}
          disablePast={disablePast}
          disableFuture={disableFuture}
          showTodayButton={showTodayButton}
          openTo={openTo}
          views={views}
          inputVariant="outlined"
          format={theFormat}
          margin="dense"
          label={label}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          invalidDateMessage="Format de date invalide"
          cancelLabel="Annuler"
          todayLabel="Aujourd'hui"
        />
      )}
    </MuiPickersUtilsProvider>
  );
}

export default SelectDatePicker;
