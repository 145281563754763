import moment from 'moment';
import getFirstDay from './get-first-day';
import getNextDayFromDays from './get-next-day-from-days';

const getLastTreatmentDate = ({ treatments }) => {
  const maxEndDate = treatments?.reduce((total, originalTreatment) => {
    const treatment = {
      ...originalTreatment,
      weekDays: originalTreatment.weekDays
        ?.slice()
        .sort((a, b) => (a < b ? -1 : 1)),
    };
    const initialDate = moment([
      treatment.year,
      treatment.month,
      treatment.day,
    ]);

    let endDate = moment(0);
    if (treatment.duration === 'acts') {
      if (treatment.frequency) {
        // transmission = generateActFromFreq({ treatment });
        let date = initialDate;

        for (let i = 0; i < treatment.count; i += 1) {
          const newDate = moment(date).add(
            treatment.frequency,
            treatment.frequencyDuration || 'days',
          );
          date = newDate;
        }

        endDate = moment(date).subtract(1, 'days');
      } else if (treatment.weekDays?.length) {
        // transmission = generateActFromWeekDays({ treatment });
        let { date, index: daysIndex } = getFirstDay(
          treatment.weekDays,
          initialDate,
        );

        for (let i = 0; i < treatment.count - 1; i += 1) {
          const nextDay = getNextDayFromDays(
            date,
            treatment.weekDays,
            daysIndex,
          );
          daysIndex = nextDay.daysIndex;
          date = nextDay.date;
        }

        endDate = moment(date);
      }
    } else {
      if (treatment.frequency) {
        // transmission = generateDaysFromFreq({ treatment });
        let date = moment([treatment.year, treatment.month, treatment.day]);
        const limitDate = moment(date).add(treatment.count, treatment.duration);

        while (moment(date).isBefore(limitDate)) {
          endDate = moment(date);
          date.add(treatment.frequency, treatment.frequencyDuration || 'days');
        }
      } else if (treatment.weekDays?.length) {
        // transmission = generateDaysFromWeekDays({ treatment });
        let { date, index: daysIndex } = getFirstDay(
          treatment.weekDays,
          initialDate,
        );
        const limitDate = moment(initialDate).add(
          treatment.count,
          treatment.duration,
        );

        while (moment(date).isBefore(limitDate)) {
          endDate = moment(date);
          const nextDay = getNextDayFromDays(
            date,
            treatment.weekDays,
            daysIndex,
          );
          daysIndex = nextDay.daysIndex;
          date = nextDay.date;
        }
      } else {
        endDate = initialDate;
      }
    }

    if (endDate.isAfter(total)) {
      return moment(endDate);
    }

    return total;
  }, moment(0));

  return maxEndDate;
};

export default getLastTreatmentDate;
