import React from 'react';
import {
  Typography,
  Button,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/PersonAdd';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';

import UpdateUser from './UpdateUser';
import { sortUsers } from '../../utils/filters';
import useCurrentUser from '../System/useCurrentUser';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  addContainer: {
    margin: theme.spacing(2, 0),
  },
  activeLegendContainer: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1, 2),
    borderRadius: 4,
    '& > p': {
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'italic',
      '& > div': {
        marginRight: theme.spacing(1),
      },
      '& > p': {
        opacity: 0.8,
      },
    },
  },
  emptyStateUsers: {
    padding: theme.spacing(2),
  },
  active: {
    display: 'flex',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: theme.palette.yellow.main,
    marginRight: theme.spacing(1),
  },
  activeColor: {
    backgroundColor: theme.palette.green.main,
  },
  inactiveColor: {
    backgroundColor: 'red',
  },
}));

const Users = ({ className, users, disabled }) => {
  const classes = useStyles();
  const { userGroupId } = useParams();
  const sortedUsers = sortUsers(users.slice());
  const { isSuperAdmin } = useCurrentUser();

  return (
    <div className={className}>
      <div className={classes.header}>
        <Typography variant="h2">Utilisateurs</Typography>

        {isSuperAdmin && !disabled ? (
          <div className={classes.addContainer}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
              component={Link}
              to={`/admin/user-groups/invite/${userGroupId}`}
            >
              Inviter
            </Button>
          </div>
        ) : null}

        <div className={classes.activeLegendContainer}>
          <Typography variant="body2">
            <span className={classes.active} />
            L'utilisateur ne s'est jamais connecté
          </Typography>
          <Typography variant="body2">
            <span className={clsx(classes.active, classes.activeColor)} />
            L'utilisateur s'est déjà connecté
          </Typography>
          <Typography variant="body2">
            <span className={clsx(classes.active, classes.inactiveColor)} />
            L'utilisateur a été supprimé
          </Typography>
        </div>
      </div>

      {sortedUsers?.length ? (
        <List dense>
          {sortedUsers.map((user) => {
            return (
              <ListItem key={user.id}>
                {!user.isAdmin && !user.isDeleted && (
                  <div
                    className={clsx(
                      classes.active,
                      user.joined && classes.activeColor,
                    )}
                  />
                )}
                {user.isDeleted && (
                  <div
                    className={clsx(
                      classes.active,
                      user.joined && classes.inactiveColor,
                    )}
                  />
                )}
                <ListItemText secondary={decodeURIComponent(user.email)}>
                  {user.lastName.toUpperCase()} {user.firstName}{' '}
                  {user.isAdmin ? ' (Administrateur)' : null}
                </ListItemText>
                <ListItemSecondaryAction>
                  {!user.isDeleted && !disabled && <UpdateUser user={user} />}
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Typography variant="body2" className={classes.emptyStateUsers}>
          Aucun utilisateur n'a encore été invité
        </Typography>
      )}
    </div>
  );
};

export default Users;
