import React from 'react';
import { makeStyles } from '@material-ui/core';

import Topbar from './Topbar';
import ErrorBoundary from './ErrorBoundary';

const useStyles = makeStyles({
  root: {
    flex: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
});

const AppLayout = ({
  title,
  backTo,
  action,
  children,
  direction,
  editMode = false,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Topbar
        title={title}
        backTo={backTo}
        action={action}
        direction={direction}
        editMode={editMode}
      />
      <ErrorBoundary>
        <div className={classes.content}>{children}</div>
      </ErrorBoundary>
    </div>
  );
};

export default AppLayout;
