import React from 'react';
import { useParams } from 'react-router-dom';

import AppLayout from '../System/AppLayout';
import PrescriptionForm from './PrescriptionForm';
import useTours from '../Tours/useTours';
import SectionProgress from '../System/SectionProgress';
import usePatient from '../Patient/usePatient';
import useDuplicatePrescription from './useDuplicatePrescription';

const DuplicatePrescription = () => {
  const { userGroupId, patientId, prescriptionId } = useParams();
  const { loading, prescription } = useDuplicatePrescription();
  const { loading: toursLoading, tours } = useTours();
  const { loading: patientLoading, patient } = usePatient();

  return (
    <AppLayout
      title="Duplication d'ordonnance"
      backTo={`/g/${userGroupId}/prescription/update/${patientId}/${prescriptionId}`}
    >
      {loading || patientLoading || toursLoading ? (
        <SectionProgress />
      ) : (
        <PrescriptionForm
          initialPrescription={prescription}
          type="duplicate"
          editMode
          tours={tours}
          patient={patient}
          userGroupId={userGroupId}
        />
      )}
    </AppLayout>
  );
};

export default DuplicatePrescription;
