import { useState, createContext } from 'react';

const SidebarDrawerContext = createContext();

const useSidebarDrawerContext = () => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return {
    open,
    onClose: handleClose,
    onToggle: handleToggle,
  };
};

export default useSidebarDrawerContext;
export { SidebarDrawerContext };
