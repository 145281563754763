import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  makeStyles,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Crosshair,
  LineMarkSeries,
  XYPlot,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
} from 'react-vis';
import moment from 'moment';
import { fr } from 'date-fns/locale';

import AppLayout from '../System/AppLayout';
import SectionProgress from '../System/SectionProgress';
import usePatient from '../Patient/usePatient';
import backSearch from '../../utils/back-search';
import { getType, getTypesForChart } from '../../utils/get-constant-type';

const useStyles = makeStyles((theme) => ({
  plot: {
    margin: theme.spacing(1),
  },
  crosshair: {
    color: theme.palette.type === 'dark' ? 'rgba(0, 0, 0, 0.87)' : '#fff',
    background: theme.palette.type === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.87)',
    whiteSpace: 'nowrap',
  },
  crosshairText: {
    margin: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  chartSelect: {},
  noData: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
}));

function chart({
  type,
  data,
  classes,
  yAxisTitle,
  onMouseLeave,
  onNearestX,
  crosshairValues,
  numberFormatter,
  height,
  width,
}) {
  if (data.length > 0) {
    let crosshairText = null;
    let crosshairText2 = null;

    if (crosshairValues?.length > 0) {
      crosshairText = `Le ${moment(crosshairValues[0].x).format(
        'DD/MM/YYYY à HH:mm',
      )}: `;
      if (type.keys) {
        const values = [];

        for (const aData of data) {
          const aValue = aData.find(
            (value) => value.x === crosshairValues[0].x,
          );

          if (aValue) {
            values.push(aValue.y);
          }
        }

        crosshairText2 = values.join('/');
      } else {
        crosshairText2 = `${numberFormatter.format(crosshairValues[0].y)}`;
      }
    }
    return (
      <div className={classes.plot}>
        <XYPlot
          onMouseLeave={onMouseLeave}
          height={height}
          width={width}
          xType="time"
          yDomain={[type.min, type.max]}
          locale={fr}
        >
          <VerticalGridLines />
          <HorizontalGridLines />
          <XAxis
            title="Date"
            tickFormat={(value) => moment(value).format('DD/MM HH:mm')}
            tickLabelAngle={-20}
          />
          <YAxis title={yAxisTitle} left={10} hideLine />
          {type.keys ? (
            type.keys.map((key, index) => (
              <LineMarkSeries
                key={key}
                data={data[index]}
                curve={'curveMonotoneX'}
                onNearestX={index === 0 ? onNearestX : null}
              />
            ))
          ) : (
            <LineMarkSeries
              data={data}
              curve={'curveMonotoneX'}
              onNearestX={onNearestX}
            />
          )}
          {crosshairValues?.length > 0 ? (
            <Crosshair values={crosshairValues}>
              <div className={classes.crosshair}>
                <p className={classes.crosshairText}>
                  {crosshairText} <b>{crosshairText2}</b>
                </p>
              </div>
            </Crosshair>
          ) : null}
        </XYPlot>
      </div>
    );
  } else
    return (
      <Typography
        gutterBottom
        component="p"
        variant={'caption'}
        className={classes.noData}
      >
        <h2>Pas de données</h2>
      </Typography>
    );
}

const ConstantChart = () => {
  const [type, setType] = useState('temp');
  const [chartCount, setChartCount] = useState(10);
  const [crosshairValues, setCrosshairValues] = useState([]);
  const { userGroupId, patientId } = useParams();
  const { loading: patientLoading, patient } = usePatient();
  const data = [];
  const classes = useStyles();
  const constType = getType(type);
  const numberFormatter = new Intl.NumberFormat('fr', {
    maximumFractionDigits: constType?.digits || 0,
  });
  const { search: s } = useLocation();
  const backTo =
    backSearch(s) || `/g/${userGroupId}/patient/update/${patientId}`;
  const matchesHeight = useMediaQuery('(min-height:737px)');

  if (patient?.constants) {
    const constants = patient.constants
      .slice(0, chartCount)
      .sort((a, b) => (a.date > b.date ? -1 : 1));

    if (constType.keys) {
      for (const key of constType.keys) {
        const subData = [];
        constants.forEach((constant) => {
          const value = constant.datas.find(
            (data) => data.name === key,
          )?.number;

          if (value) {
            subData.push({
              x: moment(Number(constant.date)).valueOf(),
              y: Number(value),
            });
          }
        });
        if (subData.length > 0) {
          data.push(subData);
        }
      }
    } else {
      constants.forEach((constant) => {
        const value = constant.datas.find((data) => data.name === type)?.number;

        if (value) {
          data.push({
            x: moment(Number(constant.date)).valueOf(),
            y: Number(value),
          });
        }
      });
    }
  }

  return (
    <AppLayout
      title="Courbe des constantes"
      backTo={backTo}
      action={
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <FormControl className={classes.formControl}>
            <InputLabel id="chartType-label">Courbe de</InputLabel>
            <Select
              labelId="chartType-label"
              className={classes.chartSelect}
              value={type}
              onChange={(event) => setType(event.target.value)}
            >
              {getTypesForChart().map((type) => (
                <MenuItem key={type.key} value={type.key}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="chartSelect-label">Nbre de points</InputLabel>
            <Select
              labelId="chartSelect-label"
              className={classes.chartSelect}
              value={chartCount}
              onChange={(event) => setChartCount(event.target.value)}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      }
    >
      {patientLoading ? (
        <SectionProgress />
      ) : (
        chart({
          type: constType,
          data,
          classes,
          yAxisTitle: constType.unit,
          onMouseLeave: () => setCrosshairValues([]),
          onNearestX: (value) => setCrosshairValues([value]),
          crosshairValues,
          numberFormatter,
          height: window.innerHeight - (matchesHeight ? 180 : 210),
          width: window.innerWidth - 30,
        })
      )}
    </AppLayout>
  );
};

export default ConstantChart;
