import React from 'react';
import { Dialog } from '@material-ui/core';

import QRCodeReader from './QRCodeReader';

const QRCodeReaderDialog = ({ name, open, onClose, onDecode }) => {
  const handleDecode = (event) => {
    onDecode && onDecode(event);
    onClose();
  };

  return (
    <Dialog open={open} fullScreen onClose={onClose}>
      <QRCodeReader name={name} onDecode={handleDecode} onClose={onClose} />
    </Dialog>
  );
};

export default QRCodeReaderDialog;
