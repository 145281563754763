import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const T = React.forwardRef(({ children, type, ...rest }, ref) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      inputRef={ref}
      margin={rest.margin || 'normal'}
      variant="outlined"
      fullWidth={rest.fullWidth || true}
      InputLabelProps={{ shrink: true, ...rest.InputLabelProps }}
      InputProps={{
        endAdornment:
          type === 'password' ? (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ) : null,
        ...rest.InputProps,
      }}
      type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
      {...rest}
    >
      {children}
    </TextField>
  );
});

export default T;
