import React from 'react';
import { Tabs, Tab, makeStyles } from '@material-ui/core';
import { Link, useLocation, useParams } from 'react-router-dom';
import ManagementIcon from '@material-ui/icons/List';
import PatientsIcon from '@material-ui/icons/People';
import SplitIcon from '@material-ui/icons/WebAsset';
import TransmissionIcon from '@material-ui/icons/Assignment';
import clsx from 'clsx';

import useNetworkStatus from '../System/useNetworkStatus';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 0 30px rgba(0, 0, 0, 0.4)',
    position: 'relative',
    zIndex: 1,
  },
  readOnly: {
    color: 'red',
  },
}));

const Nav = ({ className, showEmbed, onEmbed }) => {
  const classes = useStyles();

  const { pathname } = useLocation();
  const { userGroupId } = useParams();
  const { readOnly } = useNetworkStatus();

  let tabsValue = false;

  if (/\/management\/?$/.test(pathname)) tabsValue = 0;
  if (/\/patients\/?$/.test(pathname)) tabsValue = 1;
  if (/\/tours\/?$/.test(pathname)) tabsValue = 2;

  return (
    <Tabs
      className={clsx(classes.root, className, readOnly && classes.readOnly)}
      variant="fullWidth"
      value={tabsValue}
    >
      <Tab
        label="Messagerie"
        icon={<ManagementIcon />}
        component={Link}
        value={0}
        to={`/g/${userGroupId}/messages`}
      />

      <Tab
        label="Patients"
        icon={<PatientsIcon />}
        component={Link}
        value={1}
        to={`/g/${userGroupId}/patients`}
      />
      <Tab
        label={'Tournées'}
        icon={<TransmissionIcon />}
        component={Link}
        value={2}
        to={`/g/${userGroupId}/tours`}
      />
      {showEmbed ? (
        <Tab label="Multi" icon={<SplitIcon />} onClick={onEmbed} />
      ) : null}
    </Tabs>
  );
};

export default Nav;
