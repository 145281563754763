import moment from 'moment';

const getFirstDay = (days, start) => {
  // Check start is a moment object
  if (!moment.isMoment(start)) {
    throw new Error('start must be a momentjs object');
  }

  // check that days are sorted
  for (let i = 1; i < days.length; i += 1) {
    if (days[i] <= days[i - 1]) {
      throw new Error('days must be sorted');
    }
  }

  // check days is an array
  if (!Array.isArray(days)) {
    throw new Error('days must be an array');
  }

  // check days is not empty
  if (!days.length) {
    throw new Error('days must not be empty');
  }

  const equalIndex = days.indexOf(moment(start).isoWeekday() - 1);
  if (equalIndex > -1) {
    return { date: start.valueOf(), index: equalIndex };
  }

  const startWeekDay = moment(start).isoWeekday() - 1;
  const nextIndex = days.reduce((result, day) => {
    if (day > startWeekDay && (day < result || result === null)) {
      return day;
    }

    return result;
  }, null);
  const date = moment(start)
    .isoWeekday(nextIndex + 1)
    .valueOf();

  if (nextIndex !== null) {
    return { date, index: days.indexOf(nextIndex) };
  }

  return {
    date: moment(start)
      .isoWeekday(days[0] + 1 + 7)
      .valueOf(),
    index: 0,
  };
};

export default getFirstDay;
