import React, { useRef } from 'react';
import AddIcon from '@material-ui/icons/Add';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';

import TreatmentMenu from './TreatmentMenu';
import TreatmentForm from './TreatmentForm';
import { Button, Typography, makeStyles } from '@material-ui/core';
import { GET_DEFAULT_TREATMENT } from './usePrescriptionForm';
import FieldsContainer from '../System/FieldsContainer';

const useStyles = makeStyles((theme) => ({
  treatmentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  treatmentTitle: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  treatmentMenu: {
    marginLeft: 'auto',
  },
  deleteIcon: {
    marginLeft: 'auto',
  },
}));

const Treatments = ({
  name,
  treatments,
  tours,
  patient,
  editMode,
  contentRef,
  onChange,
}) => {
  const lastTreatmentRef = useRef();

  const classes = useStyles();

  const handleChange = (event, id) => {
    const { value } = event.target;

    onChange &&
      onChange({
        target: {
          name,
          value: treatments.map((t) => {
            if (id === t.id) {
              return value;
            }
            return t;
          }),
        },
      });
  };

  const handleAdd = () => {
    const previousTreatment = treatments.length
      ? treatments[treatments.length - 1]
      : null;
    const previousDate = previousTreatment
      ? new Date(
          previousTreatment.year,
          previousTreatment.month,
          previousTreatment.day,
        )
      : new Date();
    onChange &&
      onChange({
        target: {
          name,
          value: [
            ...treatments,
            GET_DEFAULT_TREATMENT(tours, previousDate, patient),
          ],
        },
      });

    // Scroll to added treatment after creation
    setTimeout(() => {
      contentRef.current.scrollTop = lastTreatmentRef.current.offsetTop - 70;
    }, 300);
  };

  const handleDelete = (treatmentId) => {
    onChange &&
      onChange({
        target: { name, value: treatments.filter((t) => t.id !== treatmentId) },
      });
  };

  return (
    <div>
      {treatments.map((treatment, index) => (
        <FieldsContainer
          ref={index === treatments.length - 1 ? lastTreatmentRef : null}
          key={treatment.id}
        >
          <header className={classes.treatmentHeader}>
            <Typography className={classes.treatmentTitle} component="p">
              Soin n° {index + 1}
            </Typography>

            {treatment.transmissionsGenerated ? <ReceiptIcon /> : null}

            {editMode ? null : (
              <TreatmentMenu
                className={classes.treatmentMenu}
                treatment={treatment}
              />
            )}

            {!treatment.transmissionsGenerated &&
            !treatment.haveTransmissions &&
            editMode ? (
              <div className={classes.deleteIcon}>
                <IconButton onClick={() => handleDelete(treatment.id)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            ) : null}
          </header>

          <TreatmentForm
            treatment={treatment}
            tours={tours}
            onChange={(event) => handleChange(event, treatment.id)}
            editMode={editMode}
          />
        </FieldsContainer>
      ))}

      {editMode ? (
        <div>
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            color="primary"
            onClick={handleAdd}
          >
            Ajouter un soin
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default Treatments;
