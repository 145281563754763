import { gql } from '@apollo/client';

const GOOGLE_APP = 'google';
const APPLE_APP = 'apple';
const WAZE_APP = 'waze';
const MAP_APPS = 'MAP_APPS';
const getMapApps = () => {
  return window.localStorage.getItem(MAP_APPS) || GOOGLE_APP;
}
const onChangeMapApps = (mappApps) => {
  window.localStorage.setItem(MAP_APPS, mappApps);
}

const GET_MAP_APPS = gql`
  {
    mapApps @client
  }
`;

export { getMapApps, onChangeMapApps, GET_MAP_APPS, GOOGLE_APP, APPLE_APP, WAZE_APP };
