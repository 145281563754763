const getAllTypes = () => [
  {
    key: 'pas/pad',
    keys: ['pas', 'pad'],
    name: 'Pression artérielle',
    shortName: 'PAS/PAD',
    unit: 'en mmHg',
    min: 0,
    max: 300,
    forChart: true,
  },
  {
    key: 'pas',
    name: 'Pression artérielle systolique',
    shortName: 'PAS',
    unit: 'en mmHg',
    min: 0,
    max: 300,
    width: 80,
  },
  {
    key: 'pad',
    name: 'Pression artérielle diastolique',
    shortName: 'PAD',
    unit: 'en mmHg',
    min: 0,
    max: 200,
    width: 80,
  },
  {
    key: 'fc',
    name: 'Fréquence cardiaque',
    shortName: 'FC',
    unit: 'par min',
    min: 0,
    max: 200,
    width: 80,
    forChart: true,
  },
  {
    key: 'fr',
    name: 'Fréquence respiratoire',
    shortName: 'FR',
    unit: 'par min',
    min: 0,
    max: 50,
    width: 80,
    forChart: true,
  },
  {
    key: 'sato2',
    name: 'Saturation O2',
    shortName: 'SatO2',
    unit: 'en %',
    min: 0,
    max: 100,
    width: 90,
    forChart: true,
  },
  {
    key: 'temp',
    name: 'Température',
    shortName: 'Température',
    unit: 'en °C',
    min: 30,
    max: 45,
    digits: 1,
    width: 130,
    forChart: true,
  },
  {
    key: 'gly',
    name: 'Glycémie',
    shortName: 'Glycémie',
    unit: 'g/l',
    min: 0,
    max: 6,
    digits: 2,
    width: 110,
    forChart: true,
  },
  {
    key: 'poids',
    name: 'Poids',
    shortName: 'Poids',
    min: 0,
    max: 250,
    digits: 1,
    width: 90,
    forChart: true,
  },
  {
    key: 'avk',
    name: 'AVK',
    shortName: 'AVK',
    min: 1,
    max: 5,
    digits: 2,
    width: 80,
    forChart: true,
  },
  {
    key: 'diu',
    name: 'Diurèse',
    shortName: 'Diurèse',
    min: 0,
    max: 5000,
    width: 100,
    forChart: true,
  },
  {
    key: 'eva',
    name: 'Evaluation de la douleur',
    shortName: 'EVA',
    min: 1,
    max: 10,
    width: 60,
    forChart: true,
  },
  {
    key: 'comment',
    name: 'Champ libre',
    shortName: 'Champ libre',
    width: 250,
    dataType: 'text',
  },
];

const getTypes = () => {
  return getAllTypes().filter((type) => type.keys === undefined);
};

const getTypesForChart = () => {
  return getAllTypes().filter((type) => type.forChart);
};

const getType = (type) => {
  return getAllTypes().find((t) => t.key === type);
};

export { getType, getTypes, getTypesForChart };
