import React from 'react';
import { List, Paper, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import PrescriptionDetail from './PrescriptionDetail';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxHeight: 300,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },
}));

const PrescriptionsList = ({
  prescriptions,
  patientId,
  customClasses = [],
  disabled,
}) => {
  const { patientId: paramPatientId } = useParams();
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <List dense disablePadding>
        {prescriptions.map(({ id }) => {
          return (
            <PrescriptionDetail
              key={id}
              patientId={patientId ? patientId : paramPatientId}
              prescriptionId={id}
              customClasses={customClasses}
              disabled={disabled}
            />
          );
        })}
      </List>
    </Paper>
  );
};

export default PrescriptionsList;
