import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import useSnackbar from '../System/useSnackbar';

const UPDATE_CARE_CHECKUP = gql`
  mutation UpdateCareCheckup(
    $userGroupId: String!
    $id: String!
    $checkup: String!
  ) {
    updateCareCheckup(userGroupId: $userGroupId, id: $id, checkup: $checkup) {
      id
      checkup
    }
  }
`;

const useUpdateCareCheckup = ({ generalInputs }) => {
  const { showSnackbar } = useSnackbar();

  const { userGroupId, careCheckupId } = useParams();
  const [updateCareCheckup, { loading }] = useMutation(UPDATE_CARE_CHECKUP, {
    variables: {
      userGroupId,
      id: careCheckupId,
      checkup: JSON.stringify(generalInputs),
    },

    onCompleted() {
      showSnackbar('Bilan mis à jour.');
    },
  });

  return { loading, onUpdate: updateCareCheckup };
};

export default useUpdateCareCheckup;
