const allTypes = () => [
  { key: 'bsi', name: 'Bilan de Soins Infirmiers' },
  { key: 'bsi2', name: 'Bilan de Soins Infirmiers (v2)' },
  { key: 'bip', name: 'Bilan Initial Plaie' },
];

const getTypes = () => [
  { key: 'bsi2', name: 'Bilan de Soins Infirmiers (v2)' },
  { key: 'bip', name: 'Bilan Initial Plaie' },
];

const getType = (type) => {
  return allTypes().find((t) => t.key === type)?.name || 'Bilan';
};

export { getType, getTypes };
