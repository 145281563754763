const backSearch = (s, key = 'back') => {
  const search = s?.substr(1);
  const backSearch = search
    ?.split('&')
    .find((split) => split.split('=')[0] === key);
  const [, backToUrl] = backSearch?.split('=') || [null, null];

  return backToUrl;
}

const initialBackSearch = (s) => {
  return backSearch(s, 'initialBack');
}

const operator = (url) => {
  return url.indexOf('?') !== -1 ? '&' : '?';
}

const urlWithBacks = ({ url, back, initialBack } = {}) => {
  let result = url;

  if (result) {
    if (back && back !== '') {
      result += operator(result) + 'back=' + back;
    }

    if (initialBack && initialBack !== '') {
      result += operator(result) + 'initialBack=' + initialBack;
    }
  }

  return result;
}

export default backSearch;
export { backSearch, initialBackSearch, urlWithBacks };