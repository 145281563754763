import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, IconButton, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import useDoctor from './useDoctor';
import AppLayout from '../System/AppLayout';
import DoctorForm from './DoctorForm';
import SectionProgress from '../System/SectionProgress';
import useNetworkStatus from '../System/useNetworkStatus';

const UpdateDoctor = () => {
  const { readOnly } = useNetworkStatus();
  const { userGroupId } = useParams();

  // Doctor
  const { loading, doctor } = useDoctor();

  // Edit mode
  const [editMode, setEditMode] = useState(false);
  const handleEdit = () => {
    setEditMode(true);
  };
  const handleStopEdit = () => {
    setEditMode(false);
  };

  return (
    <AppLayout
      title={
        (doctor?.lastName && `${doctor.firstName || ''} ${doctor.lastName}`) ||
        'Médecin'
      }
      backTo={`/g/${userGroupId}/doctors`}
      action={
        readOnly ? null : (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            {!editMode ? (
              <div style={{ marginRight: 8 }}>
                <IconButton onClick={handleEdit}>
                  <EditIcon />
                </IconButton>
              </div>
            ) : null}
            {doctor?.archived ? (
              <Typography variant="body2">Archivé</Typography>
            ) : null}
          </Grid>
        )
      }
    >
      {loading ? (
        <SectionProgress />
      ) : (
        <DoctorForm
          initialDoctor={doctor}
          type="update"
          editMode={editMode}
          onSubmitted={handleStopEdit}
        />
      )}
    </AppLayout>
  );
};

export default UpdateDoctor;
