import React, { useCallback, useEffect, useState } from 'react';
import { Drawer, Button, makeStyles } from '@material-ui/core';
import _debounce from 'lodash/debounce';

import SearchField from '../System/SearchField';
import Doctors from './Doctors';

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    height: '85%',
  },
}));

const DoctorPicker = ({ name, onChange }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [search, setSearch] = useState('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(_debounce(setSearch, 500), []);
  const handleSearchChange = (event) => {
    const { value } = event.target;

    setSearch(value);
  };
  const handleSelect = (doctor) => {
    onChange && onChange({ target: { name, value: doctor } });
    handleClose();
  };

  useEffect(() => {
    debounceFn(search);
  }, [debounceFn, search]);

  return (
    <>
      <div>
        <Button
          variant="outlined"
          fullWidth
          color="primary"
          onClick={handleOpen}
        >
          Choisir un médecin
        </Button>
      </div>

      <Drawer
        PaperProps={{ classes: { root: classes.paperRoot } }}
        anchor="bottom"
        open={open}
        onClose={handleClose}
      >
        <>
          <SearchField value={search} onChange={handleSearchChange} />
          <Doctors select onSelect={handleSelect} search={search} />
        </>
      </Drawer>
    </>
  );
};

export default DoctorPicker;
