import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import ProviderFragment from '../../fragments/ProviderFragment';

const GET_PROVIDER = gql`
  query GetProvider($userGroupId: String!, $id: String!) {
    getProvider(userGroupId: $userGroupId, id: $id) {
      ...ProviderFragment
    }
  }

  ${ProviderFragment}
`;

const useProvider = () => {
  const { userGroupId, providerId } = useParams();

  const { data, loading } = useQuery(GET_PROVIDER, {
    variables: { userGroupId, id: providerId },
  });

  return {
    loading,
    provider: data?.getProvider,
  };
};

export default useProvider;
export { GET_PROVIDER };
