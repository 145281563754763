import React, { useState } from 'react';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import ProgressButton from '../System/ProgressButton';
import useResourceDelete from '../System/useResourceDelete';
import cleanSignedUrl from '../../utils/clean-signed-url';

const ResourceDelete = ({ resourceKey, onDelete: onDeleteCallback }) => {
  const deleteKey = cleanSignedUrl(resourceKey);

  const { loading, onDelete } = useResourceDelete({ key: deleteKey });
  const handleDelete = async () => {
    await onDelete();
    setOpenDelete(false);
    onDeleteCallback(resourceKey);
  };

  const [openDelete, setOpenDelete] = useState(false);
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  return resourceKey ? (
    <>
      <div>
        <IconButton size="small" onClick={handleOpenDelete}>
          <DeleteIcon />
        </IconButton>
      </div>

      <Dialog open={openDelete} onClose={handleCloseDelete}>
        <DialogTitle>Suppression</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir supprimer cette ressource ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              autoFocus
              variant="contained"
              color="primary"
              onClick={handleCloseDelete}
            >
              Annuler
            </Button>
          </div>
          <div>
            <ProgressButton loading={loading} onClick={handleDelete}>
              Supprimer
            </ProgressButton>
          </div>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
};

export default ResourceDelete;
