import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  form: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  inputsContainer: {
    overflow: 'auto',
    '-webkit-overflow-scrolling': 'touch',
  },
  fieldset: {
    padding: theme.spacing(2),
  },
  submitContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  label: {
    marginBottom: theme.spacing(0.5),
  },
  switch: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    whiteSpace: 'break-spaces',
    overflow: 'hidden',
  },
  chapter: {
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '1rem',
    marginBottom: '10px',
  },
  subChapter: {
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '0.8rem',
    marginBottom: '10px',
  },
}));

export default useStyles;
