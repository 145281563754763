import React, { useMemo, useRef, useState } from 'react';
import {
  makeStyles,
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import moment from 'moment';
import { Link, useLocation, useParams } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import usePrescriptionForm from './usePrescriptionForm';
import TextField from '../System/TextField';
import Resources from '../Resource/Resources';
import ImageUpload from '../System/ImageUpload';
import FieldsContainer from '../System/FieldsContainer';
import ProgressButton from '../System/ProgressButton';
import Treatments from './Treatments';
import GenerateTransmissions from './GenerateTransmissions';
import DoctorPicker from '../Doctor/DoctorPicker';
import SelectDatePicker from '../System/SelectDatePicker';
import Links from './Links';
import { backSearch, urlWithBacks } from '../../utils/back-search';

const useStyles = makeStyles((theme) => ({
  form: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  fieldset: {
    padding: theme.spacing(0, 2, 0, 2),
  },
  patientName: {
    textAlign: 'left',
    fontSize: '1.2rem',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
      '& sup': {
        position: 'relative',
        '& svg': {
          position: 'absolute',
          width: 12,
          marginLeft: theme.spacing(0.5),
        },
      },
    },
  },
  inputsContainer: {
    overflow: 'auto',
    padding: theme.spacing(2),
    '-webkit-overflow-scrolling': 'touch',
  },
  idsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    '& > *:first-child': {
      marginRight: theme.spacing(1),
    },
  },
  treatmentsTitle: {
    margin: theme.spacing(0, 0, 1, 0),
  },
  duplicate: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  archiveContainer: {
    marginTop: theme.spacing(2),
  },
  generateCheckbox: {
    marginBottom: theme.spacing(2),
  },
  generateCheckboxLabel: {
    fontSize: theme.typography.body2.fontSize,
  },
  submitContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

const PrescriptionForm = ({
  initialPrescription,
  editMode,
  type,
  tours,
  patient,
  onSubmitted,
}) => {
  const classes = useStyles();

  const {
    loading,
    generalInputs,
    error,
    generateOnSave,
    onGenerateOnSaveChange,
    onGeneralInputChange,
    onGeneralInputsChange,
    onPhotoUploadChange,
    onWorkAccidentPhotoUploadChange,
    onPecImageUploadChange,
    onSubmit,
  } = usePrescriptionForm({
    initialPrescription: initialPrescription,
    tours,
    patient,
    type,
    onSubmitted,
  });
  const { userGroupId } = useParams();
  const { pathname, search } = useLocation();
  const backToUrl = backSearch(search);
  const atLeastOneTransmission = generalInputs.treatments.reduce(
    (result, treatment) => {
      return result || treatment.transmissionsGenerated;
    },
    false,
  );
  const [prescriptionDate, setPrescriptionDate] = useState(
    new moment(
      new Date(
        generalInputs.dateYear,
        generalInputs.dateMonth,
        generalInputs.dateDay,
      ),
    ),
  );
  const [motherHoodDate, setMotherHoodDate] = useState(
    new moment(
      new Date(
        generalInputs.motherHoodYear,
        generalInputs.motherHoodMonth,
        generalInputs.motherHoodDay,
      ),
    ),
  );
  const [workAccidentDate, setWorkAccidentDate] = useState(
    new moment(
      new Date(
        generalInputs.workAccidentYear,
        generalInputs.workAccidentMonth,
        generalInputs.workAccidentDay,
      ),
    ),
  );

  const contentRef = useRef();

  const loadingImg = useMemo(() => {
    return (
      typeof generalInputs.photo?.progress !== 'undefined' ||
      typeof generalInputs.workAccidentPhoto?.progress !== 'undefined'
    );
  }, [generalInputs]);

  return (
    <form className={classes.form} onSubmit={onSubmit}>
      <div ref={contentRef} className={classes.inputsContainer}>
        <fieldset disabled={!editMode} className={classes.fieldset}>
          <FieldsContainer>
            <Typography className={classes.patientName}>
              Patient :{' '}
              {backToUrl ? (
                <Link
                  to={urlWithBacks({
                    url: `/g/${userGroupId}/patient/update/${patient.id}`,
                    back: pathname,
                    initialBack: backToUrl,
                  })}
                >
                  <strong>
                    {patient.firstName} {patient.lastName.toUpperCase()}
                  </strong>
                  <sup>
                    <OpenInNewIcon size="small" />
                  </sup>
                </Link>
              ) : (
                <strong>
                  {patient.firstName} {patient.lastName.toUpperCase()}
                </strong>
              )}
            </Typography>

            <div className={classes.idsContainer}>
              {initialPrescription?.id ? (
                <div>
                  <TextField
                    size="small"
                    disabled
                    value={initialPrescription.id}
                    label="N° d'ordonnance"
                  />
                </div>
              ) : null}
              <div>
                <TextField
                  name="soins2000Number"
                  value={generalInputs.soins2000Number}
                  size="small"
                  label={`N° fact.`}
                  onChange={onGeneralInputChange}
                />
              </div>
            </div>
          </FieldsContainer>

          <FieldsContainer>
            <SelectDatePicker
              label="Date de l'ordonnance"
              value={prescriptionDate}
              onChange={(date) => {
                onGeneralInputsChange({
                  dateDay: date.getDate(),
                  dateMonth: date.getMonth(),
                  dateYear: date.getFullYear(),
                });
                setPrescriptionDate(date);
              }}
              readOnly={!editMode}
            />
          </FieldsContainer>
        </fieldset>

        <fieldset className={classes.fieldset}>
          <ImageUpload
            name="photo"
            title="Photo de l'ordonnance"
            value={generalInputs.photo}
            editMode={editMode}
            emptyText="Aucune photo n'a été ajoutée"
            onChange={onPhotoUploadChange}
            onDelete={onGeneralInputChange}
            multiple={true}
          />
        </fieldset>

        <fieldset disabled={!editMode} className={classes.fieldset}>
          <FieldsContainer style={{ marginTop: '0px' }}>
            <Typography gutterBottom component="p" variant="caption">
              Médecin prescripteur
            </Typography>

            <Typography
              gutterBottom
              component="p"
              variant={generalInputs.doctor ? 'body1' : 'caption'}
            >
              {generalInputs.doctor
                ? `${generalInputs.doctor.lastName} ${
                    generalInputs.doctor.firstName || ''
                  }`
                : 'Aucun médecin prescripteur'}
            </Typography>

            {editMode ? (
              <DoctorPicker name="doctor" onChange={onGeneralInputChange} />
            ) : null}
          </FieldsContainer>
        </fieldset>

        <fieldset className={classes.fieldset}>
          {generalInputs.pec?.length ? (
            <Resources
              disabled={!editMode}
              name="pec"
              title="Fichier PEC"
              emptyText="Aucun fichier PEC n'a été ajouté"
              resources={generalInputs.pec}
              max={1}
              onChange={onGeneralInputChange}
            />
          ) : (
            <ImageUpload
              name="pecImage"
              title="Fichier PEC"
              value={generalInputs.pecImage}
              editMode={editMode}
              emptyText="Aucun fichier PEC n'a été ajouté"
              onChange={onPecImageUploadChange}
              onDelete={onGeneralInputChange}
              multiple={true}
            />
          )}
        </fieldset>

        <fieldset disabled={!editMode} className={classes.fieldset}>
          <FieldsContainer style={{ marginTop: '0px' }}>
            <FormControl component="fieldset">
              <Typography gutterBottom component="p" variant="caption">
                Cause
              </Typography>
              <RadioGroup
                name="cause"
                value={generalInputs.cause}
                onChange={onGeneralInputChange}
              >
                <FormControlLabel
                  value="disease"
                  control={<Radio color="primary" />}
                  label="Maladie"
                />
                <FormControlLabel
                  value="motherhood"
                  control={<Radio color="primary" />}
                  label="Maternité"
                />
                <FormControlLabel
                  value="workAccident"
                  control={<Radio color="primary" />}
                  label="Accident de travail"
                />
              </RadioGroup>
            </FormControl>
          </FieldsContainer>

          {generalInputs.cause === 'motherhood' ? (
            <FieldsContainer>
              <SelectDatePicker
                label="Date de maternité"
                value={motherHoodDate}
                onChange={(date) => {
                  onGeneralInputsChange({
                    motherHoodDay: date.getDate(),
                    motherHoodMonth: date.getMonth(),
                    motherHoodYear: date.getFullYear(),
                  });
                  setMotherHoodDate(date);
                }}
                readOnly={!editMode}
              />
              <Typography gutterBottom component="p" variant="caption">
                * Si la patiente a accouché : date d'accouchement, sinon : date
                présumée de début de grossesse
              </Typography>
            </FieldsContainer>
          ) : null}

          {generalInputs.cause === 'workAccident' ? (
            <>
              <FieldsContainer>
                <SelectDatePicker
                  label="Date d'accident de travail"
                  value={workAccidentDate}
                  onChange={(date) => {
                    onGeneralInputsChange({
                      workAccidentDay: date.getDate(),
                      workAccidentMonth: date.getMonth(),
                      workAccidentYear: date.getFullYear(),
                    });
                    setWorkAccidentDate(date);
                  }}
                  readOnly={!editMode}
                />
              </FieldsContainer>

              <ImageUpload
                name="workAccidentPhoto"
                title="Photo de l'attestation d'accident de travail"
                value={generalInputs.workAccidentPhoto}
                editMode={editMode}
                emptyText="Aucune photo n'a été ajoutée"
                onChange={onWorkAccidentPhotoUploadChange}
                onDelete={onGeneralInputChange}
                multiple={true}
              />
            </>
          ) : null}

          <FieldsContainer>
            <FormControlLabel
              control={
                <Checkbox
                  checked={generalInputs.chronic}
                  color="primary"
                  onChange={onGeneralInputChange}
                  name="chronic"
                />
              }
              label={
                <Typography>
                  Chronique{' '}
                  <Typography
                    className={classes.chronicCaption}
                    variant="caption"
                  >
                    (si coché, l'ordonnance sera automatiquement renouvelée 5
                    jours avant son échéance)
                  </Typography>
                </Typography>
              }
            />
          </FieldsContainer>

          <FieldsContainer>
            <Typography gutterBottom component="p" variant="caption">
              Cotation
            </Typography>

            <TextField
              name="cotation"
              multiline
              value={generalInputs.cotation || ''}
              onChange={onGeneralInputChange}
            />
          </FieldsContainer>

          <Links
            editMode={editMode}
            generalInputs={generalInputs}
            onGeneralInputChange={onGeneralInputChange}
          />
        </fieldset>

        <fieldset className={classes.fieldset}>
          <Typography className={classes.treatmentsTitle} variant="h2">
            Soins
          </Typography>

          {!editMode && !atLeastOneTransmission ? (
            <GenerateTransmissions treatments={generalInputs.treatments} />
          ) : null}
          <Treatments
            name="treatments"
            treatments={generalInputs.treatments}
            patient={patient}
            tours={tours}
            editMode={editMode}
            contentRef={contentRef}
            onChange={onGeneralInputChange}
          />
        </fieldset>
      </div>

      {editMode ? (
        <div className={classes.submitContainer}>
          {error ? (
            <Typography variant="body2" paragraph color="error">
              {error}
            </Typography>
          ) : null}

          {editMode ? (
            <FormControlLabel
              className={classes.generateCheckbox}
              classes={{ label: classes.generateCheckboxLabel }}
              control={
                <Checkbox
                  checked={generateOnSave}
                  onChange={onGenerateOnSaveChange}
                  color="primary"
                />
              }
              label={`${
                type === 'update' ? 'Mettre à jour' : 'Générer'
              } les transmissions lors de la ${
                type === 'update'
                  ? 'sauvegarde'
                  : type === 'duplicate'
                  ? 'duplication'
                  : 'création'
              } de l'ordonnance`}
            />
          ) : null}

          <ProgressButton
            loading={loading}
            disabled={loadingImg}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
          >
            {loadingImg
              ? 'Chargement des images en cours...'
              : type === 'update'
              ? 'Sauvegarder'
              : type === 'duplicate'
              ? 'Dupliquer'
              : 'Créer'}
          </ProgressButton>
        </div>
      ) : null}
    </form>
  );
};

export default PrescriptionForm;
