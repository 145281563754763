import { gql, useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { useState } from 'react';

import UserGroupFragment from '../../fragments/UserGroupFragment';
import useSnackbar from '../System/useSnackbar';

const INVITE_USER = gql`
  mutation InviteUser(
    $groupId: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $isAdmin: Boolean!
  ) {
    inviteUser(
      groupId: $groupId
      email: $email
      firstName: $firstName
      lastName: $lastName
      isAdmin: $isAdmin
    ) {
      ...UserGroupFragment
    }
  }

  ${UserGroupFragment}
`;

const useInviteUser = () => {
  const { userGroupId } = useParams();
  const { showSnackbar } = useSnackbar();

  const [inputs, setInputs] = useState({
    email: '',
    firstName: '',
    lastName: '',
    isAdmin: false,
  });
  const handleInputChange = (event) => {
    const { name, value, checked, type } = event.target;

    setInputs({ ...inputs, [name]: type === 'checkbox' ? checked : value });
  };

  const { replace } = useHistory();
  const [inviteUser, { loading, error }] = useMutation(INVITE_USER, {
    variables: {
      groupId: userGroupId,
      email: inputs.email,
      firstName: inputs.firstName,
      lastName: inputs.lastName,
      isAdmin: inputs.isAdmin,
    },
    onCompleted() {
      replace(`/admin/user-groups/edit/${userGroupId}`);
      showSnackbar('Utilisateur invité.');
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await inviteUser();
    } catch (err) {
      // Do nothing, let Apollo useMutation handle errors
    }
  };

  return {
    loading,
    inputs,
    error,
    onInputChange: handleInputChange,
    onSubmit: handleSubmit,
  };
};

export default useInviteUser;
