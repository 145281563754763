import React from 'react';
import { Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import useMarkAbsenceProcessed from './useMarkAbsenceProcessed';
import ConfirmDialog from '../System/ConfirmDialog';
import useDialog from '../System/useDialog';

const MarkProcessedButton = () => {
  const { open, onOpen, onClose } = useDialog();
  const { onMarkAbsenceProcessed, loading } = useMarkAbsenceProcessed();

  return (
    <>
      <div>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          startIcon={<CheckIcon />}
          onClick={onOpen}
        >
          Traiter...
        </Button>
      </div>

      <ConfirmDialog
        title="Confirmation"
        open={open}
        confirmLoading={loading}
        onConfirm={onMarkAbsenceProcessed}
        onClose={onClose}
      >
        Êtes-vous sûr de vouloir marquer cette absence comme traitée ?
      </ConfirmDialog>
    </>
  );
};

export default MarkProcessedButton;
