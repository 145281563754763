const cleanObject = (obj) => {
  const newObj = { ...obj };

  for (let propName in obj) {
    if (Array.isArray(obj[propName])) {
      // Arrays
      const newArray = [...obj[propName]]
        .map((entry) => {
          if (typeof entry === 'object') {
            const newE = cleanObject(entry);
            return newE;
          }
          return entry;
        })
        .filter((o) => {
          if (typeof o === 'object') {
            return Object.keys(o).length;
          }
          return true;
        });

      newObj[propName] = newArray;
    } else if (
      obj[propName] &&
      typeof obj[propName] === 'object' &&
      !Array.isArray(obj[propName])
    ) {
      // Objects
      const newObject = Object.entries(obj[propName]).reduce(
        (total, [key, value]) => {
          if (value === '') {
            return { ...total, [key]: null };
          }
          if (!value) {
            return total;
          }
          return { ...total, [key]: value };
        },
        {},
      );

      if (Object.keys(newObject).length) {
        newObj[propName] = newObject;
      } else {
        delete newObj[propName];
      }
    } else if (obj[propName] === null || obj[propName] === undefined) {
      delete newObj[propName];
    } else if (typeof obj[propName] === 'string' && !obj[propName]) {
      newObj[propName] = null;
    }
  }

  return newObj;
};

export default cleanObject;
