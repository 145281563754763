import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import MessageFragment from '../../fragments/MessageFragment';

const GET_MESSAGE = gql`
  query GetMessage($userGroupId: String!, $id: String!) {
    getMessage(userGroupId: $userGroupId, id: $id) {
      ...MessageFragment
    }
  }

  ${MessageFragment}
`;

const useMessage = () => {
  const { messageId, userGroupId } = useParams();

  const { data, loading } = useQuery(GET_MESSAGE, {
    variables: { userGroupId, id: messageId },
  });

  return {
    loading,
    message: data?.getMessage,
  };
};

export default useMessage;
export { GET_MESSAGE };
