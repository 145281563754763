import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  Typography,
  makeStyles,
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';

import FieldsContainer from '../System/FieldsContainer';
import UploadButton from '../System/UploadButton';
import Figure from '../System/Figure';
import ResourceDetail from './ResourceDetail';
import {
  concatImagesToPdf,
  concatFilesToPdf,
  concatPdfsToPdf,
} from '../../utils/concat-images-to-pdf';
import useDialog from '../System/useDialog';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(0, 2, 1, 2),
  },
  emptyState: {
    padding: theme.spacing(0, 2),
  },
  addContainer: {
    justifyContent: 'center',
    padding: theme.spacing(2, 0, 0, 0),
  },
  addButton: {
    padding: theme.spacing(0, 1, 0, 0),
  },
  input: {
    display: 'none',
  },
}));

const Resources = ({
  disabled,
  name,
  title = 'Documents',
  resources,
  max,
  onChange,
  multiple = true,
}) => {
  const classes = useStyles();
  const [fileToUpload, setFileToUpload] = useState();
  const [selectedFiles, setSelectedFiles] = useState();
  const [previewImages, setPreviewImages] = useState();
  const [imageInfos, setImageInfos] = useState({});
  const [progress, setProgress] = useState();
  const { open, onOpen, onClose } = useDialog();

  const handleUploaded = (event) => {
    const { name, value } = event.target;
    onChange && onChange({ target: { name, value: [...resources, value] } });
    setFileToUpload(null);
  };

  const handleDelete = (key) => {
    onChange &&
      onChange({
        target: {
          name,
          value: resources.filter(
            (r) =>
              (r.addedKey && r.addedKey !== key) || (r.key && r.key !== key),
          ),
        },
      });
  };

  const handleLabelChange = ({ key, label }) => {
    onChange &&
      onChange({
        target: {
          name,
          value: resources.map((r) => {
            if (r.key === key) {
              return { ...r, label };
            }
            return r;
          }),
        },
      });
  };

  const handleDescriptionChange = ({ key, description }) => {
    onChange &&
      onChange({
        target: {
          name,
          value: resources.map((r) => {
            if (r.key === key) {
              return { ...r, description };
            }
            return r;
          }),
        },
      });
  };

  const selectFiles = (event) => {
    if (multiple) {
      const files = event.target.files;
      const allFiles = selectedFiles
        ? [...selectedFiles, ...files]
        : [...files];
      let images = previewImages ? previewImages : [];
      const infos = imageInfos;

      setProgress(0);
      for (const file of files) {
        const reader = new FileReader();

        if (file.name.toUpperCase().endsWith('.PDF')) {
          reader.addEventListener(
            'load',
            () => {
              infos[`${file.name}_${file.lastModified}`] = {
                data: reader.result,
                isPdf: true,
              };
              setImageInfos(infos);

              const percent =
                (100 * Object.keys(infos).length) / allFiles.length;
              setProgress(percent >= 100 ? null : percent);
            },
            false,
          );
        } else {
          const img = new Image();

          img.addEventListener(
            'load',
            () => {
              infos[`${file.name}_${file.lastModified}`] = {
                width: img.width,
                height: img.height,
                data: img.src,
              };
              setImageInfos(infos);

              const percent =
                (100 * Object.keys(infos).length) / allFiles.length;
              setProgress(percent >= 100 ? null : percent);
            },
            false,
          );
          reader.addEventListener(
            'load',
            () => {
              img.src = reader.result;
            },
            false,
          );
        }

        images.push(URL.createObjectURL(file));
        reader.readAsDataURL(file);
      }

      setSelectedFiles(allFiles);
      setPreviewImages(images);
      if (!open) {
        onOpen();
      }
    } else {
      onChange(event);
    }
  };

  const cancelFiles = () => {
    setProgress(null);
    setSelectedFiles(null);
    setPreviewImages(null);
    onClose();
  };

  const handleEditPreview = (previousSrc, newSrc, blob) => {
    const fileName = `${name}.png`;
    const outputFile = new File([blob], fileName, {
      type: 'application/png',
    });

    setPreviewImages([newSrc]);
    setSelectedFiles([outputFile]);
  };

  const handleDeletePreview = (src) => {
    const index = previewImages.indexOf(src);

    setPreviewImages(previewImages.filter((src, idx) => idx !== index));
    setSelectedFiles(selectedFiles.filter((src, idx) => idx !== index));
  };

  const uploadImages = async () => {
    setProgress(selectedFiles.length === 1 ? null : 'Conversion PDF');
    let file;

    if (selectedFiles.length === 1) {
      file = selectedFiles[0];
    } else {
      const pdfCount = selectedFiles.filter((x) =>
        x.name.toUpperCase().endsWith('.PDF'),
      ).length;

      if (pdfCount === 0) {
        file = concatImagesToPdf({ name, files: selectedFiles, imageInfos });
      } else if (pdfCount === selectedFiles.length) {
        file = await concatPdfsToPdf({
          name,
          files: selectedFiles,
          pdfInfos: imageInfos,
        });
      } else {
        file = await concatFilesToPdf({
          name,
          files: selectedFiles,
          fileInfos: imageInfos,
        });
      }
    }

    setProgress(null);
    setSelectedFiles(null);
    setPreviewImages();
    setImageInfos({});
    setFileToUpload(file);
    onClose();
  };

  const isPdf = !!previewImages?.find((x) => x.isPdf);
  const canCrop = previewImages?.length === 1 && !isPdf && !disabled;

  return (
    <FieldsContainer>
      <Typography component="p" gutterBottom variant="caption">
        {title}
      </Typography>

      {resources?.length ? (
        <>
          <List dense>
            {resources.map((resource, index) => (
              <ResourceDetail
                key={index}
                resource={{
                  ...resource,
                  tmpKey: resource.addedKey,
                }}
                onLabelChange={handleLabelChange}
                onDescriptionChange={handleDescriptionChange}
                onDelete={handleDelete}
                disabled={disabled}
              />
            ))}
          </List>
        </>
      ) : (
        <Typography variant="caption" component="p">
          Aucune ressource n'a été ajoutée
        </Typography>
      )}

      {fileToUpload ? (
        <ResourceDetail
          fileToUpload={fileToUpload}
          name={name}
          onChange={handleUploaded}
          disabled={disabled}
          onCancel={() => setFileToUpload(null)}
        />
      ) : null}

      {!disabled && (!max || resources.length < max) ? (
        <Grid container className={classes.addContainer}>
          <UploadButton
            className={classes.uploadButton}
            progress={progress}
            onChange={selectFiles}
            accept=".pdf,.png,.jpg,.jpeg"
            multiple={true}
          >
            {selectedFiles?.length ? 'Ajouter' : 'Choisir'}
          </UploadButton>
        </Grid>
      ) : null}
      <Dialog open={open} onClose={cancelFiles}>
        <DialogTitle>
          <Typography className={classes.dialogTitle}>{title}</Typography>
          {isPdf && (
            <Typography variant="body2" color="textSecondary">
              {`Cliquez sur l'image pour l'${canCrop ? 'éditer' : 'agrandir'}`}
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          {previewImages &&
            selectedFiles &&
            previewImages.map((src, index) => (
              <Figure
                key={index}
                src={src}
                fileName={selectedFiles[index].name}
                isPdf={selectedFiles[index].name.toUpperCase().endsWith('.PDF')}
                editMode={!disabled}
                //deleteProgress={deleteProgress}
                onDelete={handleDeletePreview}
                onEdit={handleEditPreview}
                isPreview={true}
                canCrop={canCrop}
              />
            ))}
        </DialogContent>
        <DialogActions>
          <Grid container className={classes.uploadContainer}>
            <UploadButton
              className={classes.uploadButton}
              progress={progress}
              onChange={selectFiles}
              accept=".pdf,.png,.jpg,.jpeg"
              multiple={multiple}
            >
              Ajouter
            </UploadButton>
            {selectedFiles?.length ? (
              <Button
                variant="outlined"
                color="primary"
                className={classes.uploadButton}
                component="span"
                disabled={!!progress}
                onClick={uploadImages}
                endIcon={
                  progress ? (
                    <CircularProgress color="inherit" size={16} />
                  ) : (
                    <PublishIcon />
                  )
                }
              >
                Valider
              </Button>
            ) : null}
            <Button
              variant="outlined"
              color="primary"
              className={classes.uploadButton}
              component="span"
              onClick={cancelFiles}
            >
              Annuler
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </FieldsContainer>
  );
};

export default Resources;
