import { gql } from '@apollo/client';

const MessageFragment = gql`
  fragment MessageFragment on Message {
    id
    createdAt
    patient {
      id
      firstName
      lastName
    }
    date
    text
    author {
      id
      firstName
      lastName
    }
    response
    processed
  }
`;

export default MessageFragment;
