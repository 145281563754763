import { gql, useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';

import useSnackbar from '../System/useSnackbar';
import { LIST_MESSAGES } from './useMessages';
import { GET_PATIENT } from '../Patient/usePatient';

const DELETE_MESSAGES = gql`
  mutation DeleteMessages($userGroupId: String!, $messageIds: [String!]!) {
    deleteMessages(userGroupId: $userGroupId, messageIds: $messageIds)
  }
`;

const useDeleteMessages = (messages) => {
  const { userGroupId } = useParams();
  const { showSnackbar } = useSnackbar();
  const { replace } = useHistory();

  const messageIds = messages?.map((m) => m.id);
  const [deleteMessage, { loading }] = useMutation(DELETE_MESSAGES, {
    variables: {
      userGroupId,
      messageIds,
    },
    update(cache) {
      // Update messages list
      const updateMessageList = (processed) => {
        try {
          const { listMessages } = cache.readQuery({
            query: LIST_MESSAGES,
            variables: { userGroupId, processed },
          });
          cache.writeQuery({
            query: LIST_MESSAGES,
            variables: { userGroupId, processed },
            data: {
              listMessages: {
                ...listMessages,
                data: listMessages.data.filter(
                  (m) => messageIds.indexOf(m.id) === -1,
                ),
              },
            },
          });
        } catch (err) {}
      };
      updateMessageList(false);
      updateMessageList(true);

      // Update patient's messages
      for (const message of messages) {
        try {
          const { getPatient } = cache.readQuery({
            query: GET_PATIENT,
            variables: { userGroupId, id: message.patientId },
          });
          cache.writeQuery({
            query: GET_PATIENT,
            variables: { userGroupId, id: message.patientId },
            data: {
              getPatient: {
                ...getPatient,
                messages: getPatient.messages.filter(
                  (msg) => msg.id !== message.id,
                ),
              },
            },
          });
        } catch (err) {}
      }
    },
    onCompleted() {
      showSnackbar('Messages supprimés');
      replace(`/g/${userGroupId}/messages`);
    },
  });

  return {
    loading,
    onDelete: deleteMessage,
  };
};

export default useDeleteMessages;
