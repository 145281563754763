import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { GET_USER_GROUP } from './useEditUserGroup';
import { LIST_USER_GROUPS } from './useUserGroups';
import UserGroupFragment from '../../fragments/UserGroupFragment';

const CREATE_USER_GROUP = gql`
  mutation CreateUserGroup($name: String!) {
    createUserGroup(name: $name) {
      ...UserGroupFragment
    }
  }

  ${UserGroupFragment}
`;

const useCreateUserGroup = () => {
  const [inputs, setInputs] = useState({ name: '' });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputs({ ...inputs, [name]: value });
  };

  const { replace } = useHistory();

  const [createUserGroup, { loading }] = useMutation(CREATE_USER_GROUP, {
    variables: {
      name: inputs.name,
    },
    update(cache, { data: { createUserGroup } }) {
      try {
        const { listUserGroups } = cache.readQuery({ query: LIST_USER_GROUPS });
        cache.writeQuery({
          query: LIST_USER_GROUPS,
          data: {
            listUserGroups: [
              ...listUserGroups,
              {
                __typename: 'UserGroupSummary',
                id: createUserGroup.id,
                name: createUserGroup.name,
                archived: createUserGroup.archived,
              },
            ],
          },
        });
      } catch (err) {}

      cache.writeQuery({
        query: GET_USER_GROUP,
        variables: { id: createUserGroup.id },
        data: { getUserGroup: createUserGroup },
      });
    },
    onCompleted({ createUserGroup }) {
      replace(`/admin/user-groups/edit/${createUserGroup.id}`);
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    await createUserGroup();
  };

  return {
    loading,
    inputs,
    onInputChange: handleInputChange,
    onSubmit: handleSubmit,
  };
};

export default useCreateUserGroup;
