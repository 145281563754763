import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import UserGroupFragment from '../../fragments/UserGroupFragment';
import useUpload from '../../utils/useUpload';

const ADD_USER_GROUP_TOUR = gql`
  mutation AddUserGroupTour(
    $userGroupId: String!
    $name: String!
    $image: ImageInput
  ) {
    addUserGroupTour(userGroupId: $userGroupId, name: $name, image: $image) {
      ...UserGroupFragment
    }
  }

  ${UserGroupFragment}
`;

const useAddTourDialog = () => {
  const { userGroupId } = useParams();
  const [open, setOpen] = useState(false);

  const [name, setName] = useState('');
  const handleChange = (event) => {
    const { value } = event.target;
    setName(value);
  };

  const [image, setImage] = useState();
  const { onChange: handleImageUploadChange } = useUpload({
    name: 'image',
    onUploaded: (event) => {
      const { value } = event.target;
      setImage(value);
    },
  });

  const [addUserGroupTour, { loading }] = useMutation(ADD_USER_GROUP_TOUR, {
    variables: {
      userGroupId,
      name,
      image: image && { key: image?.key },
    },
    onCompleted() {
      setName('');
      setImage(null);
      setOpen(false);
    },
  });

  return {
    loading,
    name,
    open,
    image,
    onImageUploadChange: handleImageUploadChange,
    onChange: handleChange,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    onSubmit: addUserGroupTour,
  };
};

export default useAddTourDialog;
