import { useCallback, useContext, useMemo, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import normalize from '../../utils/normalize';
import { Store } from '../../utils/store';
import { T } from '../../utils/reducers';
import AbsenceListFragment from '../../fragments/AbsenceListFragment';

const LIST_ABSENCES = gql`
  query ListAbsences($userGroupId: String!, $startKey: String, $limit: Int) {
    listAbsences(
      userGroupId: $userGroupId
      startKey: $startKey
      limit: $limit
    ) {
      ...AbsenceListFragment
    }
  }
  ${AbsenceListFragment}
`;

const LIST_PROCESSED_ABSENCES = gql`
  query ListProcessedAbsences(
    $userGroupId: String!
    $startKey: String
    $limit: Int
  ) {
    listAbsences(
      userGroupId: $userGroupId
      processed: true
      startKey: $startKey
      limit: $limit
    ) {
      ...AbsenceListFragment
    }
  }
  ${AbsenceListFragment}
`;

const useAbsences = () => {
  const { userGroupId } = useParams();
  const { state, dispatch } = useContext(Store);

  const setSearch = (payload) =>
    dispatch({ type: T.listAbsencesSearch, payload });
  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  const { loading, data } = useQuery(LIST_ABSENCES, {
    variables: { userGroupId },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const absences = useMemo(() => {
    return data && state.listAbsencesSearch
      ? data?.listAbsences.data.filter((a) => {
          const result =
            normalize(a.patient.lastName).includes(
              normalize(state.listAbsencesSearch),
            ) ||
            normalize(a.patient.firstName).includes(
              normalize(state.listAbsencesSearch),
            );

          return result;
        })
      : data?.listAbsences.data;
  }, [data, state.listAbsencesSearch]);

  // Processed
  const setShowProcessed = useCallback(
    (payload) => dispatch({ type: T.listAbsencesProcessed, payload }),
    [dispatch],
  );
  const handleProcessedChange = (event) => {
    const { checked } = event.target;
    setShowProcessed(checked);
  };

  const {
    data: processedData,
    loading: processedLoading,
    error: processedError,
    fetchMore,
    refetch,
  } = useQuery(LIST_PROCESSED_ABSENCES, {
    skip: !state.listAbsencesProcessed,
    variables: {
      userGroupId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const handleFetchMore = () => {
    fetchMore({
      variables: {
        startKey: processedData.listAbsences.nextKey,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          ...fetchMoreResult,
          listAbsences: {
            ...fetchMoreResult.listAbsences,
            data: [
              ...prev.listAbsences.data,
              ...fetchMoreResult.listAbsences.data,
            ],
          },
        };
      },
    });
  };

  const processedAbsences = useMemo(() => {
    return processedData && state.listAbsencesSearch
      ? processedData?.listAbsences.data.filter((a) => {
          const result =
            normalize(a.patient.lastName).includes(
              normalize(state.listAbsencesSearch),
            ) ||
            normalize(a.patient.firstName).includes(
              normalize(state.listAbsencesSearch),
            );

          return result;
        })
      : processedData?.listAbsences.data;
  }, [processedData, state.listAbsencesSearch]);

  useEffect(() => {
    if (processedError) {
      setShowProcessed(false);
    }
  }, [processedError, setShowProcessed]);

  let unsortedAbsences = useMemo(() => {
    return state.listAbsencesProcessed
      ? [...(absences || []), ...(processedAbsences || [])]
      : absences;
  }, [state.listAbsencesProcessed, absences, processedAbsences]);

  // Sort absences by date
  let sortedAbsences = useMemo(() => {
    return unsortedAbsences?.slice().sort((a, b) => {
      return a.startDate < b.startDate ? 1 : -1;
    });
  }, [unsortedAbsences]);

  return {
    processedLoading,
    loading,
    absences: sortedAbsences,
    search: state.listAbsencesSearch,
    showProcessed: state.listAbsencesProcessed,
    hasMoreProcessed: Boolean(processedData?.listAbsences.nextKey),
    onProcessedChange: handleProcessedChange,
    onSearchChange: handleSearchChange,
    onFetchMoreProcessed: handleFetchMore,
    refetch,
  };
};

export default useAbsences;
export { LIST_ABSENCES, LIST_PROCESSED_ABSENCES };
