import React from 'react';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import useMessage from './useMessage';
import useDeleteMessage from './useDeleteMessage';
import ConfirmDialog from '../System/ConfirmDialog';
import useDialog from '../System/useDialog';

const DeleteButton = () => {
  const { open, onOpen, onClose } = useDialog();
  const { loading: loadingMessage, message } = useMessage();
  const { loading, onDelete } = useDeleteMessage({ message });

  return (
    <>
      <div>
        <IconButton onClick={onOpen} disabled={loadingMessage}>
          <DeleteIcon />
        </IconButton>
      </div>

      <ConfirmDialog
        title="Confirmation"
        open={open}
        confirmLoading={loading}
        onConfirm={() => onDelete(message)}
        onClose={onClose}
      >
        Êtes-vous sûr de vouloir supprimer ce message ?
      </ConfirmDialog>
    </>
  );
};

export default DeleteButton;
