import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  Button,
  makeStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import TextField from '../System/TextField';
import useDialog from '../System/useDialog';
import ProgressButton from '../System/ProgressButton';
import useUpdateLog from './useUpdateLog';
import useDeleteLog from './useDeleteLog';

const useStyles = makeStyles((theme) => ({
  editIcon: {
    width: '1rem',
    height: '1rem',
  },
  update: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  actions: {
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  deleteButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
}));

const LogsEditDialog = ({ logId, initialText }) => {
  const classes = useStyles();

  const { open, onOpen, onClose } = useDialog();

  const { loading, text, onTextChange, onUpdate } = useUpdateLog({
    logId,
    initialText,
    onUpdated: onClose,
  });

  const { loading: deleteLoading, onDelete } = useDeleteLog({ logId });

  return (
    <>
      <IconButton size="small" edge="end" aria-label="delete" onClick={onOpen}>
        <EditIcon className={classes.editIcon} />
      </IconButton>

      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Commentaire patient</DialogTitle>

        <DialogContent>
          <form>
            <div>
              <TextField
                style={{ width: 400, maxWidth: '100%' }}
                multiline
                margin="dense"
                label="Commentaire"
                value={text}
                onChange={onTextChange}
              />
            </div>
            <div className={classes.update}>
              <ProgressButton
                loading={loading}
                fullWidth
                variant="outlined"
                color="primary"
                onClick={onUpdate}
              >
                Mettre à jour
              </ProgressButton>
            </div>
          </form>
        </DialogContent>

        <DialogActions className={classes.actions}>
          <div>
            <ProgressButton
              loading={deleteLoading}
              variant="outlined"
              className={classes.deleteButton}
              startIcon={<DeleteIcon />}
              size="small"
              onClick={onDelete}
            >
              Supprimer
            </ProgressButton>
          </div>
          <div>
            <Button variant="outlined" color="primary" onClick={onClose}>
              Fermer
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LogsEditDialog;
