import React from 'react';

import ProgressButton from '../System/ProgressButton';
import useGenerateTransmissions from './useGenerateTransmissions';

const GenerateTransmissions = ({ treatments }) => {
  const { loading, onGenerate } = useGenerateTransmissions({ treatments });

  return (
    <div>
      <ProgressButton
        loading={loading}
        fullWidth
        variant="outlined"
        color="primary"
        onClick={onGenerate}
      >
        Générer toutes les transmissions...
      </ProgressButton>
    </div>
  );
};

export default GenerateTransmissions;
