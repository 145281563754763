import React from 'react';
import Auth from '@aws-amplify/auth';
import { useLocation } from 'react-router-dom';
import { Button, makeStyles, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useQuery } from '@apollo/client';
import { isIOS, isMacOs } from 'react-device-detect';

import AppleBlackIcon from './AppleBlackIcon';
import AppleWhiteIcon from './AppleWhiteIcon';
import GoogleIcon from './GoogleIcon';
import { GET_THEME_MODE, GET_THEME_TYPE, isDarkMode } from '../../utils/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  error: {
    marginBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(8),
    fontSize: '2rem',
  },
  loginButton: {
    marginBottom: theme.spacing(2),
  }
}));

const SignInPage = () => {
  const classes = useStyles();
  const { data: dataThemeMode } = useQuery(GET_THEME_MODE);
  const { data: dataThemeType } = useQuery(GET_THEME_TYPE);
  const mediaDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const darkMode = isDarkMode(mediaDarkMode, dataThemeMode, dataThemeType);

  const handleClick = (provider) => {
    Auth.federatedSignIn({ provider });
  };

  const { search } = useLocation();

  const GOOGLE = 'Google';
  const APPLE = 'SignInWithApple';
  const identityProviders = process.env.REACT_APP_IDENTITY_PROVIDERS?.split(',') || [GOOGLE];
  const providers = [
    {
      name: GOOGLE,
      label: GOOGLE,
      lightIcon: <GoogleIcon />,
      darkIcon: <GoogleIcon />,
    },
    {
      name: APPLE,
      label: 'Apple',
      lightIcon: <AppleBlackIcon />,
      darkIcon: <AppleWhiteIcon />,
    }
  ];

  return (
    <div className={classes.root}>
      <Typography color="primary" variant="h1" className={classes.title}>
        EsaVitale
      </Typography>

      {search.includes('err=not-invited') ? (
        <Typography align="center" color="error" className={classes.error}>
          Compte inexistant.
          <br />
          Veuillez contacter votre administrateur.
        </Typography>
      ) : null}

      {search.includes('err=failure') ? (
        <Typography align="center" color="error" className={classes.error}>
          Une erreur s'est produite lors de la connexion.
        </Typography>
      ) : null}

      {providers
        .filter((provider) => identityProviders.includes(provider.name) && (provider.name !== APPLE || isIOS || isMacOs))
        .map(provider => (
          <div key={provider.name}>
            <Button
              variant="outlined"
              size="large"
              startIcon={darkMode ? provider.darkIcon : provider.lightIcon}
              onClick={() => handleClick(provider.name)}
              className={classes.loginButton}
            >
              Poursuivre avec {provider.label}
            </Button>
          </div>
        ))}
    </div>
  );
};

export default SignInPage;
