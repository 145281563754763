import React, { Suspense, useEffect, useState, useRef } from 'react';
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { Typography, Drawer } from '@material-ui/core';

import SignInPage from './SignInPage';
import MainApp from './MainApp';
import SnackbarProvider from '../System/SnackbarProvider';
import AdminLayout from '../Admin/AdminLayout';
import LogoutButton from '../System/LogoutButton';
import useCurrentUser from '../System/useCurrentUser';
import useIsUserActiveInGroup from './useIsUserActiveInGroup';
import AuthRedirected from './AuthRedirected';
import ConstantPrintable from '../Constant/ConstantPrintable';
import CareCheckupPrintable from '../CareCheckup/CareCheckupPrintable';

const AuthRouter = () => {
  const { replace } = useHistory();
  const { pathname } = useLocation();
  const [embedOpen, setEmbedOpen] = useState(false);
  const [isUserActiveInGroup, setIsUserActiveInGroup] = useState();
  const savedPathname = useRef(pathname);
  const handleEmbedOpen = () => {
    savedPathname.current = pathname;
    setEmbedOpen(true);
  };
  const handleEmbedClose = () => {
    setEmbedOpen(false);
    replace(savedPathname.current);
  };
  const { user, userLoading, isAdmin, isSuperAdmin } = useCurrentUser();
  const { loading: useIsUserActiveInGroupLoading, result } =
    useIsUserActiveInGroup({
      userGroupId: user?.userGroup || '',
      email: user?.email || '',
    });

  useEffect(() => {
    if (user && !useIsUserActiveInGroupLoading) {
      setIsUserActiveInGroup(result);
    }
  }, [user, result, useIsUserActiveInGroupLoading]);

  if (userLoading || useIsUserActiveInGroupLoading) {
    return null;
  }

  if (!user) {
    return (
      <SnackbarProvider>
        <Switch>
          <Route path="/login">
            <SignInPage />
          </Route>

          <Route path="/auth-redirected">
            <AuthRedirected />
          </Route>

          <Redirect to="/login" />
        </Switch>
      </SnackbarProvider>
    );
  }

  if (isUserActiveInGroup === false && !(isSuperAdmin || isAdmin)) {
    return (
      <div style={{ margin: 'auto', textAlign: 'center' }}>
        <Typography gutterBottom>Votre compte est désactivé.</Typography>
        <div>
          <LogoutButton />
        </div>
      </div>
    );
  }

  // Error message if user is not super-admin and does not belongs to any user group
  if (!user?.userGroup && !(isSuperAdmin || isAdmin)) {
    return (
      <div style={{ margin: 'auto', textAlign: 'center' }}>
        <Typography gutterBottom>
          Vous devez appartenir à un groupe utilisateur.
        </Typography>
        <div>
          <LogoutButton />
        </div>
      </div>
    );
  }

  return (
    <Suspense fallback={<p>React Suspens...</p>}>
      <SnackbarProvider>
        <Switch>
          <Route path="/admin">
            <AdminLayout />
          </Route>

          <Route path="/constantPrint/:userGroupId/patient/:patientId">
            <ConstantPrintable />
          </Route>

          <Route path="/careCheckupPrint/:userGroupId/care-checkup/:patientId/:careCheckupId">
            <CareCheckupPrintable />
          </Route>

          <Route path="/g/:userGroupId">
            <MainApp showEmbed onEmbed={handleEmbedOpen} />

            <Drawer
              PaperProps={{ style: { bottom: 50 } }}
              open={embedOpen}
              anchor="top"
              onClose={handleEmbedClose}
            >
              <BrowserRouter>
                <Route path="/g/:userGroupId">
                  <MainApp />
                </Route>
              </BrowserRouter>
            </Drawer>
          </Route>

          <Redirect
            to={isAdmin || isSuperAdmin ? '/admin' : `/g/${user.userGroup}`}
          />
        </Switch>
      </SnackbarProvider>
    </Suspense>
  );
};

export default AuthRouter;
