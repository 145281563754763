import { gql, useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';

import DoctorFragment from '../../fragments/DoctorFragment';
import cleanObject from '../../utils/clean-object';
import useSnackbar from '../System/useSnackbar';

const CREATE_DOCTOR = gql`
  mutation CreateDoctor(
    $lastName: String!
    $firstName: String
    $rpps: String
    $adeli: String
    $finess: String
    $phone: String
    $address: AddressInput
    $email: String
    $speciality: String
    $extra: String
  ) {
    createDoctor(
      lastName: $lastName
      firstName: $firstName
      rpps: $rpps
      adeli: $adeli
      finess: $finess
      phone: $phone
      address: $address
      email: $email
      speciality: $speciality
      extra: $extra
    ) {
      ...DoctorFragment
    }
  }

  ${DoctorFragment}
`;

const useCreateDoctor = ({ generalInputs }) => {
  const { showSnackbar } = useSnackbar();

  const { replace } = useHistory();
  const { userGroupId } = useParams();
  const [createDoctor, { loading }] = useMutation(CREATE_DOCTOR, {
    variables: cleanObject({
      firstName: generalInputs.firstName || '',
      lastName: generalInputs.lastName,
      rpps: generalInputs.rpps || '',
      adeli: generalInputs.adeli || '',
      finess: generalInputs.finess || '',
      phone: generalInputs.phone,
      address: generalInputs.address.formattedAddress
        ? generalInputs.address
        : null,
      email: generalInputs.email,
      speciality: generalInputs.speciality,
      extra: generalInputs.extra,
    }),
    onCompleted({ createDoctor }) {
      replace(
        `/g/${userGroupId}/doctor/update/${createDoctor.id}/${createDoctor.lastName}/${createDoctor.firstName}`,
      );
      showSnackbar('Médecin créé avec succès.');
    },
  });

  return { loading, onCreate: createDoctor };
};

export default useCreateDoctor;
export { CREATE_DOCTOR };
