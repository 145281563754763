import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import DoctorFragment from '../../fragments/DoctorFragment';
import DoctorSummaryFragment from '../../fragments/DoctorSummaryFragment';

const LIST_COMPLETE_DOCTORS = gql`
  query ListCompleteDoctors {
    listCompleteDoctors {
      ...DoctorFragment
    }
  }

  ${DoctorFragment}
`;

const SEARCH_DOCTORS = gql`
  query SearchDoctors($search: String) {
    searchDoctors(search: $search) {
      ...DoctorSummaryFragment
    }
  }

  ${DoctorSummaryFragment}
`;

const useCompleteDoctors = () => {
  const { loading, data } = useQuery(LIST_COMPLETE_DOCTORS, {
    fetchPolicy: 'network-only',
  });
  const doctors = useMemo(() => {
    return data?.listCompleteDoctors
      ? data?.listCompleteDoctors.map((doctor) => {
          return {
            ...doctor,
            address: doctor.address ? doctor.address : {},
          };
        })
      : null;
  }, [data]);

  return {
    loading,
    doctors,
  };
};

export default useCompleteDoctors;
export { LIST_COMPLETE_DOCTORS, SEARCH_DOCTORS };
