import { useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import shortid from 'shortid';

import { backSearch, initialBackSearch, urlWithBacks } from '../../utils/back-search';

const useDialogHistory = () => {
  const id = useRef(shortid.generate());

  const { push } = useHistory();
  const { pathname, search } = useLocation();
  const backToUrl = backSearch(search);
  const initialBackToUrl = initialBackSearch(search);
  const handleOpen = () => {
    push(urlWithBacks({ url: `${pathname}?opendiag=${id.current}`, back: backToUrl, initialBack: initialBackToUrl }));
  };
  const handleClose = () => {
    push(urlWithBacks({ url: pathname, back: backToUrl, initialBack: initialBackToUrl }));
  };

  const openId = search
    .substr(1)
    .split('&')
    .find((s) => s.split('=')[0] === 'opendiag');

  const open = Boolean(openId && openId.split('=')[1] === id.current);

  return { open, onOpen: handleOpen, onClose: handleClose };
};

export default useDialogHistory;
