import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { LIST_PATIENTS } from './usePatients';
import PatientFragment from '../../fragments/PatientFragment';
import cleanObject from '../../utils/clean-object';
import { sortPatients } from '../../utils/filters';
import useSnackbar from '../System/useSnackbar';
import cleanSignedUrl from '../../utils/clean-signed-url';

const UPDATE_PATIENT = gql`
  mutation UpdatePatient(
    $userGroupId: String!
    $id: String!
    $firstName: String!
    $lastName: String!
    $useName: String
    $visitAddress: AddressInput!
    $homeAddress: AddressInput
    $addressExtra: String
    $comment: String
    $email1Name: String
    $email1Value: String
    $contact1: String
    $phones: [PhoneInput]!
    $birthDay: Int!
    $birthMonth: Int!
    $birthYear: Int!
    $preferredTourId: String
    $socialSecuNumber: String
    $socialSecuNumberRank: Int
    $vitaleCard: ImageInput
    $mutuelleCertificate: ImageInput
    $mutuelle: String
    $dmpINSC: String
    $dmpImage: ImageInput
    $dmpId: String
    $dmpPassword: String
    $doctorId: String
    $providersIds: [String]!
    $resources: [ResourceInput]!
  ) {
    updatePatient(
      userGroupId: $userGroupId
      id: $id
      firstName: $firstName
      lastName: $lastName
      useName: $useName
      visitAddress: $visitAddress
      homeAddress: $homeAddress
      addressExtra: $addressExtra
      comment: $comment
      email1Name: $email1Name
      email1Value: $email1Value
      contact1: $contact1
      phones: $phones
      birthDay: $birthDay
      birthMonth: $birthMonth
      birthYear: $birthYear
      preferredTourId: $preferredTourId
      socialSecuNumber: $socialSecuNumber
      socialSecuNumberRank: $socialSecuNumberRank
      vitaleCard: $vitaleCard
      mutuelleCertificate: $mutuelleCertificate
      mutuelle: $mutuelle
      dmpINSC: $dmpINSC
      dmpImage: $dmpImage
      dmpId: $dmpId
      dmpPassword: $dmpPassword
      doctorId: $doctorId
      providersIds: $providersIds
      resources: $resources
    ) {
      ...PatientFragment
    }
  }

  ${PatientFragment}
`;

const useUpdatePatient = ({ generalInputs, phones, onCompleted }) => {
  const { patientId, userGroupId } = useParams();
  const { showSnackbar } = useSnackbar();

  const [updatePatient, { loading, error }] = useMutation(UPDATE_PATIENT, {
    context: {
      serializationKey: 'UPDATE_PATIENT',
    },
    update(cache, { data: { updatePatient } }) {
      try {
        const { listPatients } = cache.readQuery({
          query: LIST_PATIENTS,
          variables: { userGroupId },
        });
        cache.writeQuery({
          query: LIST_PATIENTS,
          variables: { userGroupId },
          data: {
            listPatients: sortPatients(
              listPatients.map((patient) => {
                if (patient.id === patientId) {
                  return {
                    ...patient,
                    firstName: generalInputs.firstName,
                    lastName: generalInputs.lastName,
                    useName: generalInputs.useName,
                    visitAddress: updatePatient.visitAddress,
                    phones: updatePatient.phones,
                  };
                }
                return patient;
              }),
            ),
          },
        });
      } catch (err) {}
    },
    onCompleted() {
      showSnackbar('Patient mis à jour');
      onCompleted && onCompleted();
    },
  });

  const handleUpdate = async () => {
    try {
      await updatePatient({
        variables: {
          ...cleanObject({
            userGroupId,
            id: patientId,
            firstName: generalInputs.firstName,
            lastName: generalInputs.lastName,
            useName: generalInputs.useName,
            addressExtra: generalInputs.addressExtra,
            comment: generalInputs.comment,
            email1Name: generalInputs.email1Name,
            email1Value: generalInputs.email1Value,
            contact1: generalInputs.contact1,
            phones,
            birthDay: generalInputs.birthDay,
            birthMonth: generalInputs.birthMonth,
            birthYear: generalInputs.birthYear,
            preferredTourId: generalInputs.preferredTourId,
            socialSecuNumber: generalInputs.socialSecuNumber,
            socialSecuNumberRank: generalInputs.socialSecuNumberRank,
            vitaleCard: { key: cleanSignedUrl(generalInputs.vitaleCard?.key) },
            mutuelleCertificate: {
              key: cleanSignedUrl(generalInputs.mutuelleCertificate?.key),
            },
            mutuelle: generalInputs.mutuelle,
            dmpINSC: generalInputs.dmpINSC,
            dmpImage: { key: cleanSignedUrl(generalInputs.dmpImage?.key) },
            dmpId: generalInputs.dmpId,
            dmpPassword: generalInputs.dmpPassword,
            doctorId: generalInputs.doctor?.id,
            providersIds: generalInputs.providers.map((p) => p.id),
            resources: generalInputs.resources.map((resource) => {
              return {
                key: cleanSignedUrl(resource.key) || resource.addedKey,
                size: resource.size,
                label: resource.label,
                description: resource.description,
              };
            }),
          }),
          visitAddress: generalInputs.visitAddress?.formattedAddress
            ? {
                formattedAddress: generalInputs.visitAddress?.formattedAddress,
                placeId: generalInputs.visitAddress?.placeId,
                geometry: generalInputs.visitAddress?.geometry
                  ? {
                      lat: generalInputs.visitAddress.geometry.lat,
                      lng: generalInputs.visitAddress.geometry.lng,
                    }
                  : null,
              }
            : { formattedAddress: '' },
          homeAddress: generalInputs.homeAddress?.formattedAddress
            ? {
                formattedAddress: generalInputs.homeAddress?.formattedAddress,
                placeId: generalInputs.homeAddress?.placeId,
                geometry: generalInputs.homeAddress?.geometry?.lat
                  ? {
                      lat: generalInputs.homeAddress.geometry.lat,
                      lng: generalInputs.homeAddress.geometry.lng,
                    }
                  : null,
              }
            : { formattedAddress: '' },
        },
      });
    } catch (err) {
      // Do nothing, let Apollo useMutation handle errors
    }
  };

  return { loading, error, onUpdate: handleUpdate };
};

export default useUpdatePatient;
