import { gql, useQuery } from '@apollo/client';

const GET_CURRENT_USER = gql`
  {
    currentUser @client
  }
`;

const useRoles = () => {
  const { data } = useQuery(GET_CURRENT_USER);
  const roles = data?.currentUser.roles || [];

  return { roles, isAdmin: isAdmin(roles), isSuperAdmin: isSuperAdmin(roles) };
};

const isAdmin = (roles) => {
  return roles?.indexOf('admin') !== -1;
};

const isSuperAdmin = (roles) => {
  return roles?.indexOf('super-admin') !== -1;
};

export default useRoles;
export { isAdmin, isSuperAdmin, GET_CURRENT_USER };
