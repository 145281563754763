import React, { useState, useEffect, useRef } from 'react';
import {
  makeStyles,
  Button,
  ButtonBase,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  IconButton,
  Slider,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import useDialogHistory from './useDialogHistory';
import clsx from 'clsx';
import ProgressButton from './ProgressButton';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  rootContainer: {
    position: 'relative',
    padding: theme.spacing(1),
  },
  deleteContainer: {
    position: 'absolute',
    right: theme.spacing(-1.5),
    top: theme.spacing(-1.5),
    '& button': {
      color: 'white',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      padding: 8,
      '& svg': {
        fontSize: '1.4rem',
      },
    },
  },
  buttonRoot: {
    padding: 0,
    width: '100%',
    height: 'auto',
    maxWidth: 210,
    display: 'flex',
  },
  img: {
    width: '100%',
  },
  topBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2),
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flex: 1,
    width: '100%',
    height: '100%',
  },
  fullScreenImg: {
    width: '90vw',
    height: '90vh',
  },
  imgTools: {
    width: '200px',
  },
  bottomBar: {
    boxSizing: 'content-box',
    height: 45,
    padding: theme.spacing(1, 2),
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  sliderLabel: {
    minWidth: 65,
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 32,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
}));

const Figure = ({
  className,
  src,
  fileName,
  height,
  isPdf,
  alt = '',
  editMode,
  deleteProgress,
  onDelete,
  onEdit,
  isPreview,
  canCrop = false,
  ...rest
}) => {
  const { open, onOpen, onClose } = useDialogHistory();

  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [crop, setCrop] = useState(undefined);
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [quality, setQuality] = useState(0.25);
  const [fileSize, setFileSize] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const minWidth500px = useMediaQuery('(min-width:800px)');

  const marks = [
    {
      value: 0.25,
      label: 'Petite',
    },
    {
      value: 0.5,
      label: 'Moyenne',
    },
    {
      value: 0.75,
      label: 'Grande',
    },
    {
      value: 1,
      label: 'Taille réelle',
    },
  ];

  const toBlob = (canvas) => {
    return new Promise((resolve) => {
      canvas.toBlob(resolve, 'image/jpeg', 0.95);
    });
  };

  let previewUrl = '';

  const saveCrop = async () => {
    const blob = await toBlob(previewCanvasRef.current);

    if (blob) {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }

      previewUrl = URL.createObjectURL(blob);
      onEdit && onEdit(src, previewUrl, blob);
      onClose();
    }
  };

  const selectAll = () => {
    setCrop({
      unit: '%',
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    });
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        setFileSize(
          await canvasPreview(
            imgRef.current,
            previewCanvasRef.current,
            completedCrop,
            scale,
            rotate,
            quality,
          ),
        );
      }
    },
    100,
    [completedCrop, scale, rotate, quality],
  );

  const handleDeleteClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onDelete && onDelete(src);
    handleCloseDelete();
  };
  const handleDeleteMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      const img = new Image();
      img.onload = () => {
        setLoading(false);
      };
      img.src = src;
    }
  }, [src, open]);

  // Delete
  const [openDelete, setOpenDelete] = useState(false);
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const renderImg = () => {
    if (canCrop) {
      return (
        <div className={classes.fullScreenImg}>
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body1">
                Faire une sélection pour éditer l'image
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                onClick={selectAll}
              >
                Tout sélectionner
              </Button>
            </Grid>
            <Grid item xs={minWidth500px ? 6 : 12}>
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
              >
                <img
                  ref={imgRef}
                  src={src}
                  alt=""
                  style={{
                    transform: `scale(${scale}) rotate(${rotate}deg)`,
                  }}
                />
              </ReactCrop>
            </Grid>
            <Grid item xs={minWidth500px ? 6 : 12}>
              {Boolean(completedCrop) && (
                <>
                  {fileSize ? (
                    <Typography variant="body1">{`Taille de l'image: ${previewCanvasRef.current?.width} x ${previewCanvasRef.current?.height}`}</Typography>
                  ) : null}
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      border: '1px solid black',
                      objectFit: 'contain',
                      width: minWidth500px ? '45vw' : '90vw',
                    }}
                  />
                </>
              )}
            </Grid>
          </Grid>
          {Boolean(completedCrop) && (
            <Grid container direction="row" alignItems="center">
              <Grid item xs={minWidth500px ? 10 : 12}>
                <Grid container direction="column">
                  <div className={classes.sliderContainer}>
                    <Typography
                      variant="overline"
                      classes={{ root: classes.sliderLabel }}
                    >
                      Zoom
                    </Typography>
                    <Slider
                      value={scale}
                      min={1}
                      max={1.5}
                      step={0.01}
                      aria-labelledby="Zoom"
                      classes={{ root: classes.slider }}
                      onChange={(e, zoom) => setScale(zoom)}
                    />
                  </div>
                  <div className={classes.sliderContainer}>
                    <Typography
                      variant="overline"
                      classes={{ root: classes.sliderLabel }}
                    >
                      Rotation
                    </Typography>
                    <Slider
                      value={rotate}
                      min={-10}
                      max={10}
                      step={0.1}
                      aria-labelledby="Rotation"
                      classes={{ root: classes.slider }}
                      onChange={(e, rotation) => setRotate(rotation)}
                    />
                  </div>
                  <div
                    className={classes.sliderContainer}
                    style={{ marginBottom: '1em' }}
                  >
                    <Typography
                      variant="overline"
                      classes={{ root: classes.sliderLabel }}
                    >
                      Qualité
                    </Typography>
                    <Slider
                      value={quality}
                      min={0.25}
                      max={1}
                      step={0.25}
                      aria-labelledby="Quality"
                      classes={{ root: classes.slider }}
                      onChange={(e, qual) => setQuality(qual)}
                      marks={marks}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid
                item
                xs={minWidth500px ? 2 : 12}
                style={{ textAlign: 'center' }}
              >
                <Button
                  onClick={saveCrop}
                  variant="contained"
                  color="primary"
                  classes={{ root: classes.cropButton }}
                >
                  Valider
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      );
    }

    return (
      <img
        ref={imgRef}
        style={{ maxWidth: '90%', maxHeight: '100%' }}
        src={src}
        alt=""
      />
    );
  };

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.rootContainer}>
        {isPdf && !isPreview && (
          <Button
            startIcon={<DownloadIcon />}
            variant="outlined"
            color="primary"
            href={src}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Télécharger</span>
          </Button>
        )}
        {isPdf && isPreview && (
          <div
            style={{
              marginLeft: '-15px',
            }}
          >
            {fileName}
          </div>
        )}
        {!isPdf && (
          <ButtonBase className={classes.buttonRoot} onClick={onOpen} {...rest}>
            <img className={classes.img} src={src} alt={alt} height={height} />
          </ButtonBase>
        )}

        <Dialog open={open} fullScreen onClose={onClose}>
          <div className={classes.topBar}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.container}>
            {loading ? (
              <div>
                <CircularProgress size={24} />
              </div>
            ) : (
              renderImg()
            )}
          </div>

          <div className={classes.bottomBar}></div>
        </Dialog>

        {editMode && onDelete ? (
          <>
            <div className={classes.deleteContainer}>
              {isPreview ? (
                <IconButton
                  onClick={handleDeleteClick}
                  onMouseDown={handleDeleteMouseDown}
                >
                  <DeleteIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={handleOpenDelete}
                  onMouseDown={handleDeleteMouseDown}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
            <Dialog open={openDelete} onClose={handleCloseDelete}>
              <DialogTitle>Suppression</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Êtes-vous sûr de vouloir supprimer l'image ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <div>
                  <Button
                    autoFocus
                    variant="contained"
                    color="primary"
                    onClick={handleCloseDelete}
                  >
                    Annuler
                  </Button>
                </div>
                <div>
                  <ProgressButton
                    loading={deleteProgress}
                    onClick={handleDeleteClick}
                  >
                    Supprimer
                  </ProgressButton>
                </div>
              </DialogActions>
            </Dialog>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Figure;
