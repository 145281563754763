import { gql } from '@apollo/client';

import AbsenceFragment from './AbsenceFragment';

const AbsenceListFragment = gql`
  fragment AbsenceListFragment on AbsenceList {
    nextKey
    data {
      ...AbsenceFragment
    }
  }

  ${AbsenceFragment}
`;

export default AbsenceListFragment;
