import React from 'react';
import {
  Divider,
  Drawer,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
  Switch,
} from '@material-ui/core';
import DoctorIcon from '@material-ui/icons/Person';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ProviderIcon from '@material-ui/icons/Healing';
import AbsenceIcon from '@material-ui/icons/CardTravel';
import CheckupIcon from '@material-ui/icons/DoneAll';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Footer from '../Footer/Footer';
import TextField from '../System/TextField';
import useCurrentUser from '../System/useCurrentUser';
import {
  getThemeMode,
  onChangeThemeMode,
  isAutoTheme,
  getThemeType,
  onChangeThemeType,
  isLightTheme,
  GET_THEME_MODE,
  GET_THEME_TYPE,
} from '../../utils/theme';
import { getMapApps, onChangeMapApps, GET_MAP_APPS } from '../../utils/map-app';
import { MAP_APPS } from '../../config/global-config';
import packageJson from '../../../package.json';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: theme.palette.background.default,
  },
  title: {
    padding: theme.spacing(2, 2),
    fontSize: theme.typography.h1.fontSize,
    fontWeight: 'bold',
    lineHeight: '1.2rem',
  },
  version: {
    marginLeft: theme.spacing(1),
    fontSize: '8px',
    fontWeight: 'normal',
  },
  nav: {
    flex: 1,
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  parameters: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2, 2),
  },
  paramTitle: {
    marginTop: theme.spacing(1),
  },
}));

const SidebarContent = ({ user, onClose }) => {
  const classes = useStyles();
  const { userGroupId } = useParams();
  const themeMode = getThemeMode();
  const themeType = getThemeType();
  const themeAuto = isAutoTheme(themeMode);
  const themeLight = isLightTheme(themeType);
  const { client } = useQuery(GET_THEME_MODE);
  const { client: clientMapApps } = useQuery(GET_MAP_APPS);

  return (
    <aside className={classes.root}>
      <Typography className={classes.title}>
        EsaVitale
        <Typography className={classes.version} component="span">
          {packageJson.pubVersion}
        </Typography>
      </Typography>

      <Divider />

      <nav className={classes.nav}>
        <List disablePadding>
          <ListItem
            button
            component={Link}
            to={`/g/${userGroupId}/prescriptions`}
            onClick={onClose}
          >
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText>Ordonnances</ListItemText>
          </ListItem>
          <ListItem
            button
            component={Link}
            to={`/g/${userGroupId}/absences`}
            onClick={onClose}
          >
            <ListItemIcon>
              <AbsenceIcon />
            </ListItemIcon>
            <ListItemText>Absences</ListItemText>
          </ListItem>

          <ListItem
            button
            component={Link}
            to={`/g/${userGroupId}/care-checkups`}
            onClick={onClose}
          >
            <ListItemIcon>
              <CheckupIcon />
            </ListItemIcon>
            <ListItemText>Bilans</ListItemText>
          </ListItem>

          <ListItem
            button
            component={Link}
            to={`/g/${userGroupId}/doctors`}
            onClick={onClose}
          >
            <ListItemIcon>
              <DoctorIcon />
            </ListItemIcon>
            <ListItemText>Médecins</ListItemText>
          </ListItem>
          <ListItem
            button
            component={Link}
            to={`/g/${userGroupId}/providers`}
            onClick={onClose}
          >
            <ListItemIcon>
              <ProviderIcon />
            </ListItemIcon>
            <ListItemText>Prestataires</ListItemText>
          </ListItem>
        </List>
        <Divider />
        <div className={classes.parameters}>
          <Typography component="p" variant="caption">
            Mode d'apparence
          </Typography>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={!themeAuto}
                onChange={() => {
                  onChangeThemeMode(themeMode);
                  client.writeQuery({
                    query: GET_THEME_MODE,
                    data: { themeMode: getThemeMode() },
                  });
                }}
              />
            }
            label={themeAuto ? 'Automatique' : 'Manuel'}
          />
          {!themeAuto ? (
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={!themeLight}
                  onChange={() => {
                    onChangeThemeType(themeType);
                    client.writeQuery({
                      query: GET_THEME_TYPE,
                      data: { themeType: getThemeType() },
                    });
                  }}
                />
              }
              label={themeLight ? 'Clair' : 'Sombre'}
            />
          ) : null}
          <Typography
            component="p"
            variant="caption"
            className={classes.paramTitle}
          >
            Application de navigation
          </Typography>
          <TextField
            name="mapApps"
            select
            SelectProps={{
              native: true,
            }}
            value={getMapApps()}
            onChange={(event) => {
              const { value } = event.target;

              onChangeMapApps(value);
              clientMapApps.writeQuery({
                query: GET_MAP_APPS,
                data: { mapApps: getMapApps() },
              });
            }}
            margin="dense"
          >
            {MAP_APPS.map((app) => (
              <option key={app.value} value={app.value}>
                {app.label}
              </option>
            ))}
          </TextField>
        </div>
      </nav>

      <Footer className={classes.footer} user={user} onClose={onClose} />
    </aside>
  );
};

const Sidebar = ({ drawerOpen, onDrawerClose }) => {
  const { user, userLoading } = useCurrentUser();

  if (userLoading) {
    return null;
  }

  return (
    <Drawer open={drawerOpen} onClose={onDrawerClose}>
      <SidebarContent user={user} onClose={onDrawerClose} />
    </Drawer>
  );
};

export default Sidebar;
