import { gql } from '@apollo/client';

import AddressFragment from './AddressFragment';
import ImageFragment from './ImageFragment';

const ProviderFragment = gql`
  fragment ProviderFragment on Provider {
    id
    firstName
    lastName
    phones {
      name
      number
    }
    email1Name
    email1Value
    address {
      ...AddressFragment
    }
    adeli
    siret
    speciality
    unlockCode
    RIB
    CPS {
      ...ImageFragment
    }
    archived
  }

  ${AddressFragment}
  ${ImageFragment}
`;

export default ProviderFragment;
