import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  makeStyles,
} from '@material-ui/core';
import moment from 'moment';

import FieldsContainer from '../System/FieldsContainer';
import SectionProgress from '../System/SectionProgress';
import getCause from '../../utils/get-cause';
import getLastTreatmentDate from '../../utils/get-last-treatment-date';
import { usePrescriptionsByPatient } from '../Prescription/usePrescriptions';

const useStyles = makeStyles((theme) => ({
  editButton: {
    marginTop: theme.spacing(2),
  },
}));

const PrescriptionSelect = ({
  disabled,
  name,
  patient,
  selected,
  onChange,
}) => {
  const classes = useStyles();

  const [error, setError] = useState(false);

  const [open, setOpen] = useState(false);

  const [sortedPrescs, setSortedPrescs] = useState([]);

  const { loading, prescriptions } = usePrescriptionsByPatient(patient.id);

  useEffect(() => {
    if (prescriptions) {
      const prescs = prescriptions
        .map((p) => {
          return {
            ...p,
            date: moment([p.dateYear, p.dateMonth, p.dateDay]),
            endDate: getLastTreatmentDate({
              treatments: p.treatments,
            }),
          };
        })
        .sort((a, b) => a.date - b.date);

      setSortedPrescs(prescs);
    }
  }, [prescriptions]);

  const handleClose = () => {
    setError(false);
    if (selected !== 'all' && !selected.length) {
      setError(true);
    } else {
      setOpen(false);
    }
  };

  return loading ? (
    <SectionProgress />
  ) : (
    <div>
      <FieldsContainer>
        <Typography gutterBottom component="p" variant="caption">
          Ordonnances
        </Typography>

        {selected === 'all' || selected.length === 0 ? (
          <Typography component="p" variant="caption">
            S'applique à toutes les ordonnances.
          </Typography>
        ) : (
          <List dense>
            {selected.map((prescriptionId) => {
              const prescription = prescriptions.find(
                (p) => p.id === prescriptionId,
              );
              const endDate = getLastTreatmentDate({
                treatments: prescription.treatments,
              });

              return (
                <ListItem key={prescription.id}>
                  <ListItemText>
                    {prescription.soins2000Number
                      ? `N° Fact: ${prescription.soins2000Number} - `
                      : ''}
                    {prescription.doctor
                      ? `${prescription.doctor.lastName} - `
                      : ''}
                    {moment([
                      prescription.dateYear,
                      prescription.dateMonth,
                      prescription.dateDay,
                    ]).format('DD/MM/YYYY')}{' '}
                    - {getCause(prescription.cause)}
                    {endDate
                      ? `- Fin des soins le ${endDate.format('DD/MM/YYYY')}`
                      : ''}
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        )}

        {!disabled ? (
          <div>
            <Button
              className={classes.editButton}
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => setOpen(true)}
            >
              Choisir des ordonnances spécifiques...
            </Button>
          </div>
        ) : null}
      </FieldsContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Ordonnances</DialogTitle>
        <DialogContent>
          {error ? (
            <ListItemText primaryTypographyProps={{ color: 'error' }}>
              Au moins une ordonnance doit être sélectionnée.
            </ListItemText>
          ) : null}
          <List>
            <ListItem>
              <ListItemIcon>
                <Checkbox
                  name={name}
                  edge="start"
                  checked={selected === 'all'}
                  tabIndex={-1}
                  disableRipple
                  color="primary"
                  value="all"
                  onChange={onChange}
                />
              </ListItemIcon>
              <ListItemText>
                <b>Toutes les ordonnances</b>
              </ListItemText>
            </ListItem>
            {sortedPrescs
              .filter((p) => !p.endDate || p.endDate.isAfter(new Date()))
              .map((prescription) => {
                const cause = getCause(prescription.cause);

                return (
                  <ListItem key={prescription.id} disabled={selected === 'all'}>
                    <ListItemIcon>
                      <Checkbox
                        name={name}
                        edge="start"
                        checked={
                          selected === 'all' ||
                          selected.indexOf(prescription.id) > -1
                        }
                        tabIndex={-1}
                        disableRipple
                        color="primary"
                        value={prescription.id}
                        onChange={onChange}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      {prescription.soins2000Number
                        ? `N° Fact: ${prescription.soins2000Number} - `
                        : ''}
                      {prescription.doctor
                        ? `${prescription.doctor.lastName} - `
                        : ''}
                      {prescription.date.format('DD/MM/YYYY')}{' '}
                      {cause ? `- ${cause}` : ''}
                      {prescription.endDate
                        ? `- Fin des soins le ${prescription.endDate.format(
                            'DD/MM/YYYY',
                          )}`
                        : ''}
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
        </DialogContent>
        <DialogActions>
          <div>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              Fermer
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PrescriptionSelect;
