import { useEffect, useRef } from 'react';

const useAfterMount = (fn, deps) => {
  const firstCall = useRef(true);

  useEffect(() => {
    if (firstCall.current) {
      firstCall.current = false;
    } else {
      fn();
    }
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useAfterMount;
