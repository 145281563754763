import React, { useEffect, useState } from 'react';
import {
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link, useParams } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/MoreVert';
import AutoSizer from 'react-virtualized-auto-sizer';
import Papa from 'papaparse';
import { useLazyQuery, useMutation } from '@apollo/client';

import SectionProgress from '../System/SectionProgress';
import AppLayout from '../System/AppLayout';
import useMenu from '../System/useMenu';
import useNetworkStatus from '../System/useNetworkStatus';
import usePatients, { EXPORT_PATIENTS, SEARCH_PATIENTS } from './usePatients';
import { CREATE_PATIENT } from './useCreatePatient';
import useRoles, { isSuperAdmin } from '../System/useRoles';
import SearchField from '../System/SearchField';
//import ExportCsv from '../System/export-csv';
import UploadButton from '../System/UploadButton';
import cleanObject from '../../utils/clean-object';
import { sortPatients } from '../../utils/filters';
import PatientsList from './PatientsList';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: 'auto',
    maxWidth: '300px',
  },
  archived: {
    marginLeft: theme.spacing(0.5),
  },
  listContainer: {
    flex: 1,
  },
  listItemRoot: {
    display: 'flex',
    justifyContent: 'flex-start',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  listItem: {
    flex: 1,
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 2),
  },
  actionIcons: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    '& > *': {
      marginRight: theme.spacing(1),
      '& svg': {
        opacity: 0.9,
      },
    },
    '& > *:last-child': {
      marginRight: 0,
    },
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  emptyState: {
    marginTop: theme.spacing(2),
  },
  error: {
    color: 'red',
  },
}));

const handleUpload = (
  event,
  createPatient,
  userGroupId,
  setUploading,
  setCount,
  setTotal,
  setErrorMsg,
) => {
  const { files } = event.target;

  if (files && files.length) {
    const file = files[0];
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      async () => {
        const csv = Papa.parse(reader.result, {});
        let count = 1;

        setTotal(csv.data.length);
        if (csv.data.length > 500) {
          setErrorMsg('Le fichier ne peut dépasser 500 lignes');
        } else {
          setErrorMsg(null);
          for (const element of csv.data) {
            setCount(count++);
            if (
              element.length >= 22 &&
              element[1]?.length > 0 &&
              element[2]?.length > 0 &&
              element[4]?.length > 0 &&
              element[15]?.length > 0 &&
              element[16]?.length > 0 &&
              element[17]?.length > 0
            ) {
              const phones = [];
              if (element[12]) {
                phones.push({ name: element[11], number: element[12] });
              }
              if (element[14]) {
                phones.push({ name: element[13], number: element[14] });
              }
              try {
                const newPatient = cleanObject({
                  userGroupId,
                  lastName: element[1],
                  firstName: element[2],
                  useName: element[3],
                  visitAddress: element[4]
                    ? { formattedAddress: element[4] }
                    : {},
                  homeAddress: element[5]
                    ? { formattedAddress: element[5] }
                    : {},
                  addressExtra: element[6],
                  comment: element[7],
                  email1Name: element[8],
                  email1Value: element[9],
                  contact1: element[10],
                  phones: phones,
                  birthDay: Number(element[15]),
                  birthMonth: Number(element[16]) - 1,
                  birthYear: Number(element[17]),
                  socialSecuNumber: element[18],
                  socialSecuNumberRank: Number(element[19]),
                  mutuelle: element[20],
                  resources: [],
                  providersIds: [],
                });

                await createPatient({
                  variables: newPatient,
                });
              } catch (error) {
                console.log(error);
              }
            } else {
              console.log('Bad import data: ', element);
            }
          }
        }

        setUploading(false);
      },
      false,
    );

    if (file) {
      reader.readAsText(file);
    }
  }
};

const Patients = () => {
  const { readOnly } = useNetworkStatus();
  const [uploading, setUploading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [errorMsg, setErrorMsg] = useState();
  const [execQuery, { loading, data }] = useLazyQuery(SEARCH_PATIENTS, {
    fetchPolicy: 'no-cache',
  });
  const [exportQuery, { loading: loadingExport, data: exportScv }] =
    useLazyQuery(EXPORT_PATIENTS, {
      fetchPolicy: 'no-cache',
    });
  const { search, onSearchChange, archived, onArchivedChange } = usePatients();
  const patients = search && data ? sortPatients(data.searchPatients) : [];
  const classes = useStyles();
  const { userGroupId } = useParams();
  const { anchorEl, onClick: onMenuClick, onClose: onMenuClose } = useMenu();
  const { roles } = useRoles();
  const [createPatient] = useMutation(CREATE_PATIENT);

  useEffect(() => {
    search?.length > 2 &&
      (() => {
        execQuery({ variables: { userGroupId, search, archived } });
      })();
  }, [execQuery, archived, search, userGroupId]);

  const exportPatients = () => {
    setExporting(true);
    exportQuery({ variables: { userGroupId } });
  };

  useEffect(() => {
    if (exportScv && !loadingExport && exporting) {
      const blob = new Blob([exportScv.exportPatients], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');

      setExporting(false);
      link.download = `patients_${userGroupId}.csv`;
      link.href = url;
      link.target = '_blank';
      link.click();
      onMenuClose();
    }
  }, [exportScv, exporting, loadingExport, onMenuClose, userGroupId]);

  return (
    <AppLayout
      title="Patients"
      action={
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs>
            <div className={classes.searchContainer}>
              <SearchField
                value={search}
                onChange={onSearchChange}
                disabled={uploading || loadingExport}
              />
            </div>
          </Grid>
          <Grid item>
            <FormControlLabel
              className={classes.archived}
              control={
                <Switch
                  color="primary"
                  checked={archived}
                  onChange={onArchivedChange}
                  disabled={uploading || loadingExport}
                />
              }
              label="Archivé"
            />
          </Grid>
          {isSuperAdmin(roles) && !readOnly ? (
            <Grid item style={{ marginLeft: '20px' }}>
              <IconButton onClick={onMenuClick}>
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={onMenuClose}
              >
                <MenuItem disabled={uploading || loadingExport}>
                  <UploadButton
                    component="link"
                    onChange={(e) => {
                      onMenuClose();
                      setUploading(true);
                      handleUpload(
                        e,
                        createPatient,
                        userGroupId,
                        setUploading,
                        setCount,
                        setTotal,
                        setErrorMsg,
                      );
                    }}
                    accept=".csv"
                  >
                    Importer des patients
                  </UploadButton>
                </MenuItem>
                <MenuItem
                  disabled={uploading || loadingExport}
                  onClick={exportPatients}
                >
                  Exporter les patients
                </MenuItem>
              </Menu>
            </Grid>
          ) : null}
        </Grid>
      }
    >
      <div className={classes.root}>
        {loading || uploading || loadingExport ? (
          <>
            {uploading && (
              <Typography align="center" className={classes.emptyState}>
                {count} / {total}
                <br />
                Ne pas quitter cette page
              </Typography>
            )}
            <SectionProgress />
          </>
        ) : patients?.length ? (
          <div className={classes.listContainer}>
            <AutoSizer>
              {({ height, width }) => (
                <PatientsList
                  height={height}
                  width={width}
                  patients={patients}
                  classes={classes}
                />
              )}
            </AutoSizer>
          </div>
        ) : (
          <>
            <Typography align="center" className={classes.emptyState}>
              {search?.length > 2
                ? 'Aucun patient existant'
                : 'Veuillez saisir un critère de recherche (au moins 3 caractères)'}
            </Typography>
            {errorMsg && (
              <Typography align="center" className={classes.error}>
                {errorMsg}
              </Typography>
            )}
          </>
        )}
      </div>

      <Fab
        className={classes.fab}
        component={Link}
        to={`/g/${userGroupId}/patient/create`}
        color="primary"
        disabled={readOnly || uploading || loadingExport}
      >
        <AddIcon />
      </Fab>
    </AppLayout>
  );
};

export default Patients;
