import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import usePatient, { GET_PATIENT } from '../Patient/usePatient';
import useSnackbar from '../System/useSnackbar';

const DELETE_CONSTANT = gql`
  mutation DeleteConstant(
    $id: String!
    $userGroupId: String!
    $patientId: String!
  ) {
    deleteConstant(
      id: $id
      userGroupId: $userGroupId
      patientId: $patientId
    )
  }
`;

const useDeleteConstantForm = ({ constantId, patientId, onDeleteted }) => {
  const { userGroupId } = useParams();
  const { loading: loadingPatient, patient } = usePatient({ id: patientId });

  const { showSnackbar } = useSnackbar();
  const [deleteConstant, { loading, error }] = useMutation(
    DELETE_CONSTANT,
    {
      variables: {
        id: constantId,
        userGroupId,
        patientId,
      },
      update(cache) {
        cache.writeQuery({
          query: GET_PATIENT,
          variables: { userGroupId, id: patientId },
          data: {
            getPatient: {
              ...patient,
              constants: patient.constants.filter(aConstant => aConstant.id !== constantId)
            },
          },
        });
      },
      onCompleted() {
        showSnackbar('Constante supprimée');
        onDeleteted && onDeleteted();
      },
    },
  );

  const handleDelete = async (event) => {
    event && event.preventDefault();
    try {
      await deleteConstant();
    } catch (err) {
      // Do nothing, let Apollo useMutation handle errors
    }
  };

  return {
    loading: loading | loadingPatient,
    error,
    onDelete: handleDelete,
  };
};

export default useDeleteConstantForm;
