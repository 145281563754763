import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import useUpdateTourDialog from './useUpdateTourDialog';
import TextField from '../System/TextField';
import ProgressButton from '../System/ProgressButton';
import ImageUpload from '../System/ImageUpload';
import useDisableTour from './useDisableTour';
import useEnableTour from './useEnableTour';

const UpdateTour = ({ tour }) => {
  const {
    loading,
    open,
    name,
    image,
    onImageUploadChange,
    onDeleteImage,
    onSubmit,
    onChange,
    onOpen,
    onClose,
  } = useUpdateTourDialog({ tour });

  const { onDisable } = useDisableTour();
  const { onEnable } = useEnableTour();

  const [disabled, setDisabled] = useState(tour.disabled);
  useEffect(() => {
    setDisabled(!!tour.disabled);
  }, [tour]);

  return (
    <>
      <div>
        <div>
          <IconButton onClick={onOpen}>
            <EditIcon />
          </IconButton>
        </div>
      </div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Tournée</DialogTitle>
        <DialogContent>
          <div>
            <TextField label="Nom" autoFocus value={name} onChange={onChange} />
          </div>

          <ImageUpload
            name="image"
            title="Image"
            value={image}
            editMode
            disablePadding
            onChange={onImageUploadChange}
            onDelete={onDeleteImage}
          />

          <FormControlLabel
            control={
              <Switch
                checked={!disabled}
                onChange={(event) => {
                  const { checked } = event.target;
                  if (checked) {
                    setDisabled(false);
                    onEnable(tour.id);
                  } else {
                    setDisabled(true);
                    onDisable(tour.id);
                  }
                }}
                name="checkedA"
                color="primary"
              />
            }
            label="Actif"
          />
        </DialogContent>

        <DialogActions>
          <div>
            <Button onClick={onClose}>Annuler</Button>
          </div>
          <div>
            <ProgressButton
              disabled={!!image?.progress}
              loading={loading}
              onClick={onSubmit}
            >
              Enregistrer
            </ProgressButton>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateTour;
