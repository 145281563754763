import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import UserGroupFragment from '../../fragments/UserGroupFragment';
import { LIST_USER_GROUPS } from './useUserGroups';
import { GET_USER_GROUP } from './useEditUserGroup';

const UPDATE_USER_GROUP = gql`
  mutation UpdateUserGroup($id: String!, $name: String!, $archived: Boolean) {
    updateUserGroup(id: $id, name: $name, archived: $archived) {
      ...UserGroupFragment
    }
  }

  ${UserGroupFragment}
`;

const useEditUserGroupForm = ({ initialUserGroup }) => {
  const { userGroupId } = useParams();

  const [inputs, setInputs] = useState({
    name: initialUserGroup.name,
    archived: initialUserGroup.archived,
  });

  const handleInputChange = (event) => {
    const { name, value, checked, type } = event.target;

    setInputs({ ...inputs, [name]: type === 'checkbox' ? checked : value });
  };

  const [updateUserGroup, { loading }] = useMutation(UPDATE_USER_GROUP, {
    variables: {
      id: userGroupId,
      name: inputs.name,
      archived: inputs.archived,
    },
    update(cache, { data: { updatedUserGroup } }) {
      try {
        const { listUserGroups } = cache.readQuery({ query: LIST_USER_GROUPS });
        cache.writeQuery({
          query: LIST_USER_GROUPS,
          data: {
            listUserGroups: listUserGroups.map((g) => {
              if (g.id === userGroupId) {
                return { ...g, name: inputs.name, archived: inputs.archived };
              }
              return g;
            }),
          },
        });
      } catch (err) {}

      try {
        const { getUserGroup } = cache.readQuery({
          query: GET_USER_GROUP,
          variables: { id: userGroupId },
        });
        cache.writeQuery({
          query: GET_USER_GROUP,
          variables: { id: userGroupId },
          data: {
            getUserGroup: {
              ...getUserGroup,
              name: inputs.name,
              archived: inputs.archived,
            },
          },
        });
      } catch (err) {}
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    await updateUserGroup();
  };

  return {
    inputs,
    loading,
    onInputChange: handleInputChange,
    onSubmit: handleSubmit,
  };
};

export default useEditUserGroupForm;
