import React, { useEffect, useState, useRef } from 'react';
import {
  ListItem,
  ListItemText,
  Dialog,
  DialogContent,
  Button,
  Typography,
  makeStyles,
  InputAdornment,
  IconButton,
  LinearProgress,
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import EditIcon from '@material-ui/icons/Edit';

import useUpload from '../../utils/useUpload';
import TextField from '../System/TextField';
import useDialogHistory from '../System/useDialogHistory';
import ResourceDelete from './ResourceDelete';

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: theme.palette.background.default,
  },
  titleContainer: {
    padding: theme.spacing(2, 3),
    display: 'flex',
    alignItems: 'center',
    '& h2': {
      flex: 1,
      fontSize: '1.25rem',
      fontWeight: 500,
    },
  },
  downloadContainer: {
    marginTop: theme.spacing(2),
  },
  closeButton: {
    margin: theme.spacing(0, 2),
  },
}));

const ResourceDetail = ({
  name,
  fileToUpload,
  disabled,
  resource: { key, tmpKey, label, size, description } = {},
  onChange,
  onDelete,
  onCancel,
  onLabelChange,
  onDescriptionChange,
}) => {
  const classes = useStyles();
  const { open, onOpen, onClose } = useDialogHistory();

  // Label field
  const labelFieldRef = useRef();
  const [labelFieldValue, setLabelFieldValue] = useState(
    fileToUpload?.name || label || '',
  );
  const handleLabelFieldValueChange = (event) => {
    setLabelFieldValue(event.target.value);
  };
  const [editLabelMode, setEditLabelMode] = useState(false);
  const handleEditLabelModeToggle = (event) => {
    event && event.preventDefault();
    setEditLabelMode((prevEditMode) => !prevEditMode);
  };
  useEffect(() => {
    if (editLabelMode) {
      labelFieldRef.current.focus();
    }
  }, [editLabelMode]);
  const handleLabelSave = () => {
    onLabelChange({ key, label: labelFieldValue });
    handleEditLabelModeToggle();
  };

  // Description field
  const descriptionFieldRef = useRef();
  const [descriptionFieldValue, setDescriptionFieldValue] = useState(
    description || '',
  );
  const handleDescriptionFieldValueChange = (event) => {
    setDescriptionFieldValue(event.target.value);
  };
  const [editDescriptionMode, setEditDescriptionMode] = useState(false);
  const handleEditDescriptionModeToggle = (event) => {
    event && event.preventDefault();
    setEditDescriptionMode((prevEditMode) => !prevEditMode);
  };
  useEffect(() => {
    if (editDescriptionMode) {
      descriptionFieldRef.current.focus();
    }
  }, [editDescriptionMode]);
  const handleDescriptionSave = () => {
    onDescriptionChange({ key, description: descriptionFieldValue });
    handleEditDescriptionModeToggle();
  };

  // Upload
  const [addedKey, setAddedKey] = useState();

  const { progress } = useUpload({
    name,
    onUploaded: (event) => setAddedKey(event.target.value.key),
    fileToUpload,
  });

  const handleSubmit = () => {
    onChange &&
      onChange({
        target: {
          name,
          value: {
            label: labelFieldValue,
            description: descriptionFieldValue,
            addedKey,
            size: fileToUpload.size,
          },
        },
      });
  };

  const handleCancel = () => {
    onCancel && onCancel();
    onClose();
  };

  const handleDelete = (resourceKey) => {
    onDelete && onDelete(resourceKey);
    onClose();
  };

  return (
    <>
      {!fileToUpload ? (
        <ListItem
          divider
          disableGutters
          button
          onClick={onOpen}
          key={key || tmpKey}
        >
          <ListItemText primary={label} secondary={description} />
          <ArrowRightIcon />
        </ListItem>
      ) : null}

      <Dialog
        open={!!fileToUpload || open}
        fullScreen
        PaperProps={{ className: classes.dialog }}
        onClose={onClose}
      >
        <div className={classes.titleContainer}>
          <Typography variant="h2">
            {fileToUpload ? labelFieldValue : 'Ressource'}
          </Typography>
          {!fileToUpload && !disabled ? (
            <ResourceDelete
              resourceKey={key || tmpKey}
              onDelete={handleDelete}
            />
          ) : null}
        </div>
        <DialogContent>
          <div>
            <TextField
              ref={labelFieldRef}
              autoFocus={Boolean(fileToUpload)}
              label="Nom du fichier"
              value={labelFieldValue}
              disabled={!fileToUpload && !editLabelMode}
              onChange={handleLabelFieldValueChange}
              InputProps={{
                endAdornment:
                  !fileToUpload && !disabled ? (
                    <InputAdornment position="end">
                      {!editLabelMode ? (
                        <IconButton onClick={handleEditLabelModeToggle}>
                          <EditIcon />
                        </IconButton>
                      ) : (
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={handleLabelSave}
                        >
                          Valider
                        </Button>
                      )}
                    </InputAdornment>
                  ) : null,
              }}
            />
            <TextField
              ref={descriptionFieldRef}
              label="Description"
              value={descriptionFieldValue}
              disabled={!fileToUpload && !editDescriptionMode}
              onChange={handleDescriptionFieldValueChange}
              InputProps={{
                endAdornment:
                  !fileToUpload && !disabled ? (
                    <InputAdornment position="end">
                      {!editDescriptionMode ? (
                        <IconButton onClick={handleEditDescriptionModeToggle}>
                          <EditIcon />
                        </IconButton>
                      ) : (
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={handleDescriptionSave}
                        >
                          Valider
                        </Button>
                      )}
                    </InputAdornment>
                  ) : null,
              }}
            />
          </div>

          <div className={classes.downloadContainer}>
            {!fileToUpload ? (
              key ? (
                <div>
                  <Button
                    startIcon={<DownloadIcon />}
                    variant="outlined"
                    color="primary"
                    href={key}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>
                      Télécharger ({(size / 1000000).toFixed(2)}{' '}
                      <span style={{ textTransform: 'none' }}>Mo</span>
                    </span>
                  </Button>
                  <Button
                    onClick={handleCancel}
                    variant="outlined"
                    className={classes.closeButton}
                  >
                    {fileToUpload ? 'Annuler' : 'Fermer'}
                  </Button>
                </div>
              ) : (
                <Button
                  onClick={handleCancel}
                  variant="outlined"
                  className={classes.closeButton}
                >
                  {fileToUpload ? 'Annuler' : 'Fermer'}
                </Button>
              )
            ) : typeof progress === 'number' || typeof progress === 'string' ? (
              <div>
                <Typography variant="caption" component="p" gutterBottom>
                  Chargement en cours :
                </Typography>
                <div>
                  <LinearProgress
                    variant={progress === 100 ? 'indeterminate' : 'determinate'}
                    value={progress || 0}
                  />
                </div>
              </div>
            ) : (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Valider
                </Button>
                <Button
                  onClick={handleCancel}
                  variant="outlined"
                  className={classes.closeButton}
                >
                  {fileToUpload ? 'Annuler' : 'Fermer'}
                </Button>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ResourceDetail;
