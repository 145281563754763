import { gql } from '@apollo/client';

import PatientFragment from './PatientFragment';
import TransmissionBaseFragment from './TransmissionBaseFragment';

const TransmissionFragment = gql`
  fragment TransmissionFragment on Transmission {
    ...TransmissionBaseFragment
    patient {
      ...PatientFragment
    }
    lastDate
  }

  ${PatientFragment}
  ${TransmissionBaseFragment}
`;

export default TransmissionFragment;
