import React from 'react';
import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useQuery } from '@apollo/client';

import useApollo from './utils/useApollo';
import { GET_THEME_MODE, GET_THEME_TYPE, createTheme } from './utils/theme';
import AuthRouter from './components/Auth/AuthRouter';
import { createStoreProvider } from './utils/store';
import reducers from './utils/reducers';

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    flex: 1,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const App = () => {
  const { data: dataThemeMode } = useQuery(GET_THEME_MODE);
  const { data: dataThemeType } = useQuery(GET_THEME_TYPE);
  const mediaDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = createTheme(
    mediaDarkMode,
    dataThemeMode?.themeMode,
    dataThemeType?.themeType,
  );
  const initialState = {
    listPrescriptionsDense: true,
    listPrescriptionsOrderBy: 'date',
    listPrescriptionsOrder: 'desc',
    listPatientsArchived: false,
    listMessagesProcessed: false,
    listAbsencesProcessed: false,
  };
  const StoreProvider = createStoreProvider({ initialState, reducers });

  return (
    <StoreProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthRouter />
      </ThemeProvider>
    </StoreProvider>
  );
};

const MuiApp = () => {
  const classes = useStyles();
  const { client: apolloClient } = useApollo();

  return !apolloClient ? (
    <div className={classes.loadingContainer}>
      <CssBaseline />
      <CircularProgress size={20} />
    </div>
  ) : (
    <BrowserRouter>
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    </BrowserRouter>
  );
};

export default MuiApp;
