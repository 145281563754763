import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { LIST_PROVIDERS } from './useProviders';
import { sortProviders } from '../../utils/filters';
import ProviderFragment from '../../fragments/ProviderFragment';
import cleanObject from '../../utils/clean-object';
import useSnackbar from '../System/useSnackbar';
import cleanSignedUrl from '../../utils/clean-signed-url';

const UPDATE_PROVIDER = gql`
  mutation UpdateProvider(
    $userGroupId: String!
    $id: String!
    $firstName: String!
    $lastName: String!
    $phones: [PhoneInput]!
    $email1Name: String
    $email1Value: String
    $address: AddressInput
    $adeli: String
    $siret: String
    $speciality: String
    $unlockCode: String
    $RIB: String
    $CPS: ImageInput
    $archived: Boolean
  ) {
    updateProvider(
      userGroupId: $userGroupId
      id: $id
      firstName: $firstName
      lastName: $lastName
      phones: $phones
      email1Name: $email1Name
      email1Value: $email1Value
      address: $address
      adeli: $adeli
      siret: $siret
      speciality: $speciality
      unlockCode: $unlockCode
      RIB: $RIB
      CPS: $CPS
      archived: $archived
    ) {
      ...ProviderFragment
    }
  }

  ${ProviderFragment}
`;

const useUpdateProvider = ({ generalInputs, phones, onCompleted }) => {
  const { providerId, userGroupId } = useParams();
  const { showSnackbar } = useSnackbar();

  const cleanedVariables = cleanObject({
    userGroupId,
    id: providerId,
    firstName: generalInputs.firstName,
    lastName: generalInputs.lastName,
    phones,
    email1Name: generalInputs.email1Name,
    email1Value: generalInputs.email1Value,
    address: {
      formattedAddress: generalInputs.address?.formattedAddress,
      placeId: generalInputs.address?.placeId,
      geometry: generalInputs.address?.geometry
        ? {
            lat: generalInputs.address.geometry.lat,
            lng: generalInputs.address.geometry.lng,
          }
        : null,
    },
    adeli: generalInputs.adeli,
    siret: generalInputs.siret,
    speciality: generalInputs.speciality,
    unlockCode: generalInputs.unlockCode,
    RIB: generalInputs.RIB,
    CPS: {
      key: cleanSignedUrl(generalInputs.CPS?.key),
    },
  });

  const [updateProvider, { loading }] = useMutation(UPDATE_PROVIDER, {
    onCompleted() {
      showSnackbar('Prestataire mis à jour');
      onCompleted && onCompleted();
    },
    update(cache, { data: { updateProvider } }) {
      try {
        const { listProviders } = cache.readQuery({
          query: LIST_PROVIDERS,
          variables: { userGroupId },
        });
        const newListProviders = listProviders
          .map((provider) => {
            if (provider.id === providerId) {
              return {
                firstName: updateProvider.firstName,
                lastName: updateProvider.lastName,
                id: updateProvider.id,
                archived: updateProvider.archived,
                __typename: provider.__typename,
              };
            }
            return provider;
          })
          .filter((provider) => provider.archived !== true);

        cache.writeQuery({
          query: LIST_PROVIDERS,
          variables: { userGroupId },
          data: {
            listProviders: sortProviders(newListProviders),
          },
        });
      } catch (err) {}
    },
  });

  return {
    loading,
    onUpdate: ({ archived = false } = {}) =>
      updateProvider({
        variables: {
          ...cleanedVariables,
          archived,
          firstName: cleanedVariables.firstName || '',
        },
      }),
  };
};

export default useUpdateProvider;
