import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import TransmissionFragment from '../../fragments/TransmissionFragment';
import { useMemo, useState } from 'react';

const GET_TRANSMISSION = gql`
  query GetTransmission(
    $userGroupId: String!
    $tourId: String!
    $year: Int!
    $id: String!
  ) {
    getTransmission(
      userGroupId: $userGroupId
      tourId: $tourId
      year: $year
      id: $id
    ) {
      ...TransmissionFragment
    }
  }

  ${TransmissionFragment}
`;

const PATIENT_LIMIT = 4;

const useTransmission = ({ fetchPolicy = 'network-only' } = {}) => {
  const { userGroupId, tourId, date, transmissionId } = useParams();

  const { loading, data } = useQuery(GET_TRANSMISSION, {
    // Fetch policy network-only because it is too complicated to know wich queries with
    // which variables are cached when we need to update cache
    // after some mutations (eg: outsource a treatment to another tour)
    fetchPolicy,
    variables: {
      userGroupId,
      tourId,
      year: Number(moment(Number(date)).format('YYYY')),
      id: transmissionId,
    },
  });

  const [seeAllLogs, setSeeAllLogs] = useState(false);
  const handleToggleSeeAllLogs = () => {
    setSeeAllLogs((prev) => !prev);
  };

  const transmission = useMemo(() => {
    return data
      ? {
        ...data.getTransmission,
        patient: {
          ...data.getTransmission.patient,
          prescriptions: data.getTransmission.patient.prescriptions
            .slice()
            .sort((a, b) =>
              a.firstTreatmentDate < b.firstTreatmentDate ? 1 : -1,
            ),
          logs: [...data.getTransmission.patient.logs]
            .sort((a, b) => {
              return Number(a.date) < Number(b.date) ? 1 : -1;
            })
            .slice(0, seeAllLogs ? undefined : PATIENT_LIMIT),
        },
      }
      : null;
  }, [data, seeAllLogs]);

  return {
    loading,
    transmission,
    seeAllLogs,
    hasMoreLogs: data?.getTransmission.patient.logs?.length > PATIENT_LIMIT,
    onToggleSeeAllLogs: handleToggleSeeAllLogs,
  };
};

export default useTransmission;
export { GET_TRANSMISSION };
