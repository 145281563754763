import React from 'react';
import { Typography, Button, makeStyles } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';
import AdminIcon from '@material-ui/icons/VpnKey';

import LogoutButton from '../System/LogoutButton';
import useRoles from '../System/useRoles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  userGroupSelect: {
    marginBottom: theme.spacing(2),
  },
  adminContainer: {
    paddingBottom: theme.spacing(2),
  },
  email: {
    marginBottom: theme.spacing(2),
  },
}));

const Footer = ({ className, user, onClose }) => {
  const classes = useStyles();
  const { userGroupId } = useParams();

  const { isAdmin, isSuperAdmin } = useRoles();

  return (
    <footer className={clsx(classes.root, className)}>
      {isSuperAdmin || (isAdmin && userGroupId) ? (
        <div className={classes.adminContainer}>
          <Button
            variant="outlined"
            color="primary"
            component={Link}
            to={
              isAdmin || isSuperAdmin
                ? '/admin/user-groups'
                : `/admin/user-groups/edit/${userGroupId}`
            }
            startIcon={<AdminIcon />}
            className={classes.admin}
            onClick={onClose}
          >
            Espace administrateur
          </Button>
        </div>
      ) : null}

      <Typography className={classes.email} variant="body2">
        {user.email}
      </Typography>

      <div>
        <LogoutButton />
      </div>
    </footer>
  );
};

export default Footer;
