import { useState } from 'react';

import useCreateDoctor from './useCreateDoctor';
import useUpdateDoctor from './useUpdateDoctor';

const useDoctorForm = ({ initialDoctor = {}, type, onSubmitted }) => {
  // General inputs
  const [generalInputs, setGeneralInputs] = useState({
    firstName: initialDoctor.firstName || '',
    lastName: initialDoctor.lastName || '',
    rpps: initialDoctor.rpps || '',
    adeli: initialDoctor.adeli || '',
    finess: initialDoctor.finess || '',
    phone: initialDoctor.phone || '',
    address: initialDoctor.address || {},
    email: initialDoctor.email || '',
    speciality: initialDoctor.speciality || '',
    extra: initialDoctor.extra || '',
    archived: Boolean(initialDoctor.archived),
  });

  const handleGeneralInputChange = (event) => {
    const { name, value } = event.target;
    setGeneralInputs((prevGeneralInputs) => ({
      ...prevGeneralInputs,
      [name]: value,
    }));
  };

  // Create
  const { loading: createLoading, onCreate } = useCreateDoctor({
    generalInputs,
  });

  // Update
  const { loading: updateLoading, onUpdate } = useUpdateDoctor({
    generalInputs,
  });

  // Archive
  const handleArchive = async () => {
    await onUpdate({ archived: true });
  };

  // Submit
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (type === 'update') {
      await onUpdate();
    } else if (type === 'create') {
      await onCreate();
    }

    onSubmitted && onSubmitted();
  };

  return {
    loading: createLoading || updateLoading,
    generalInputs,
    onArchive: handleArchive,
    onGeneralInputChange: handleGeneralInputChange,
    onSubmit: handleSubmit,
  };
};

export default useDoctorForm;
