import React from 'react';
import {
  makeStyles,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import ProgressButton from '../System/ProgressButton';
import useUpdateUser from './useUpdateUser';
import useDialog from '../System/useDialog';
import DeleteUser from './DeleteUser';
import useCurrentUser from '../System/useCurrentUser';

const useStyles = makeStyles((theme) => ({
  email: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
  },
  submitContainer: {
    marginTop: theme.spacing(2),
  },
}));

const UpdateUser = ({ user: initialUser }) => {
  const classes = useStyles();

  const { open, onOpen, onClose } = useDialog();

  const { loading, user, onInputChange, onUpdate, error } = useUpdateUser({
    initialUser,
    onUpdated: onClose,
  });

  const { isAdmin, isSuperAdmin, user: currentUser } = useCurrentUser();
  const isSameUser =
    decodeURIComponent(initialUser?.email) ===
    decodeURIComponent(currentUser?.email);

  return (
    <>
      <div>
        <IconButton onClick={onOpen}>
          <EditIcon />
        </IconButton>
      </div>

      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Utilisateur</DialogTitle>

        <form onSubmit={onUpdate}>
          <DialogContent>
            <div>
              <Typography variant="body2" className={classes.email}>
                <b>E-mail :</b> {decodeURIComponent(user.email)}
              </Typography>
            </div>
            <div>
              <TextField
                name="firstName"
                label="Prénom"
                fullWidth
                variant="outlined"
                margin="normal"
                value={user.firstName}
                onChange={onInputChange}
              />
            </div>
            <div>
              <TextField
                name="lastName"
                label="Nom"
                fullWidth
                variant="outlined"
                margin="normal"
                value={user.lastName}
                onChange={onInputChange}
              />
            </div>
            {isSuperAdmin ? (
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={user.isAdmin}
                      color="primary"
                      onChange={onInputChange}
                      name="isAdmin"
                      disabled={isSameUser}
                    />
                  }
                  label={<Typography>Administrateur</Typography>}
                />
              </div>
            ) : null}

            {isSameUser || !(isAdmin || isSuperAdmin) ? null : (
              <DeleteUser
                userId={user.id}
                userEmail={user.email}
                onDelete={onClose}
              />
            )}
            {error?.message ? (
              <Typography color="error">
                {error.message === 'User already in a group'
                  ? "L'utilisateur est déjà présent dans un autre groupe"
                  : error.message}
              </Typography>
            ) : null}
          </DialogContent>
          <DialogActions>
            <div>
              <Button variant="outlined" onClick={onClose}>
                Annuler
              </Button>
            </div>
            <div>
              <ProgressButton
                loading={loading}
                variant="contained"
                type="submit"
                color="primary"
              >
                Modifier
              </ProgressButton>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default UpdateUser;
