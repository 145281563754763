import React, { useCallback, useEffect, useContext, useState } from 'react';
import {
  Fab,
  makeStyles,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import MenuIcon from '@material-ui/icons/MoreVert';
import _debounce from 'lodash/debounce';
import Papa from 'papaparse';
import { useMutation } from '@apollo/client';

import AppLayout from '../System/AppLayout';
import SearchField from '../System/SearchField';
import Doctors from './Doctors';
import useNetworkStatus from '../System/useNetworkStatus';
import { Store } from '../../utils/store';
import { T } from '../../utils/reducers';
import useRoles, { isSuperAdmin } from '../System/useRoles';
import useMenu from '../System/useMenu';
import UploadButton from '../System/UploadButton';
import cleanObject from '../../utils/clean-object';
import { CREATE_DOCTOR } from './useCreateDoctor';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: 'auto',
    minWidth: '125px',
    maxWidth: '300px',
  },
}));

const handleUpload = (event, createDoctor) => {
  const { files } = event.target;

  if (files && files.length) {
    const file = files[0];
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        const csv = Papa.parse(reader.result, {});

        csv.data.forEach(async (element) => {
          if (
            element.length === 9 &&
            element[1]?.length > 0 &&
            element[2]?.length > 0 &&
            element[0]?.length > 0
          ) {
            createDoctor({
              variables: cleanObject({
                rpps: element[0],
                lastName: element[1],
                firstName: element[2],
                address: element[3] ? { formattedAddress: element[3] } : {},
                email: element[4] || '',
                phone: element[5] || '',
                adeli: element[6] || '',
                speciality: element[7] || '',
                extra: element[8] || '',
              }),
            });
          } else {
            console.log('Bad import data: ', element);
          }
        });
      },
      false,
    );

    if (file) {
      reader.readAsText(file);
    }
  }
};

const DoctorsPage = () => {
  const { readOnly } = useNetworkStatus();
  const classes = useStyles();
  const { userGroupId } = useParams();
  const { state, dispatch } = useContext(Store);
  const [searchValue, setSearchValue] = useState(state.listDoctorsSearch);
  const { roles } = useRoles();
  const { anchorEl, onClick: onMenuClick, onClose: onMenuClose } = useMenu();
  const [createDoctor] = useMutation(CREATE_DOCTOR);
  const setSearch = (payload) =>
    dispatch({ type: T.listDoctorsSearch, payload });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(_debounce(setSearch, 500), []);
  const handleSearchChange = (event) => {
    const { value } = event.target;

    setSearchValue(value);
  };

  useEffect(() => {
    debounceFn(searchValue);
  }, [debounceFn, searchValue]);

  return (
    <AppLayout
      title="Médecins"
      action={
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs>
            <div className={classes.searchContainer}>
              <SearchField value={searchValue} onChange={handleSearchChange} />
            </div>
          </Grid>
          {isSuperAdmin(roles) && !readOnly ? (
            <Grid item>
              <IconButton onClick={onMenuClick}>
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={onMenuClose}
              >
                <MenuItem>
                  <UploadButton
                    component="link"
                    onChange={(e) => handleUpload(e, createDoctor)}
                    accept=".csv"
                  >
                    Importer des médecins
                  </UploadButton>
                </MenuItem>
              </Menu>
            </Grid>
          ) : null}
        </Grid>
      }
    >
      <Doctors search={state.listDoctorsSearch} />

      <Fab
        className={classes.fab}
        component={Link}
        to={`/g/${userGroupId}/doctor/create`}
        color="primary"
        disabled={readOnly}
      >
        <AddIcon />
      </Fab>
    </AppLayout>
  );
};

export default DoctorsPage;
