import React, { useMemo } from 'react';
import moment from 'moment';

import ImageUpload from '../System/ImageUpload';
import ProgressButton from '../System/ProgressButton';
import useCareCheckupForm from './useCareCheckupForm';
import useStyles from './useStyles';
import Field from './CareCheckupField';
import Select from './CareCheckupSelect';
import MultiSelect from './CareCheckupMultiSelect';
import Bool from './CareCheckupBool';

const BsiCheckupForm = ({
  initialCareCheckup,
  type,
  editMode,
  patient,
  createdBy,
  onSubmitted,
}) => {
  const classes = useStyles();

  const {
    loading,
    generalInputs,
    onTechCareUploadChange,
    onGeneralInputChange,
    onSubmit,
  } = useCareCheckupForm({
    initialCareCheckup,
    type,
    onSubmitted,
    checkupType: 'bsi',
  });

  const loadingImg = useMemo(() => {
    return typeof generalInputs.techCare?.progress !== 'undefined';
  }, [generalInputs]);

  return (
    <div className={classes.form}>
      <div className={classes.inputsContainer}>
        <fieldset className={classes.fieldset} disabled={!editMode}>
          <div>
            {createdBy ? (
              <Field
                disabled
                value={`${
                  createdBy.firstName
                } ${createdBy.lastName.toUpperCase()}`}
                label="Créé par"
              />
            ) : null}
            <Field
              disabled
              value={`${patient?.lastName.toUpperCase() ?? ''} ${
                patient?.firstName ?? ''
              }`}
              label="Patient"
            />

            <Field
              disabled
              value={
                (patient &&
                  moment([
                    patient.birthYear,
                    patient.birthMonth,
                    patient.birthDay,
                  ]).format('DD/MM/YYYY')) ||
                ''
              }
              label="Date de naissance"
            />

            <Select
              name="environnementhumain"
              value={generalInputs['environnementhumain']}
              label="Environnement Humain"
              onChange={onGeneralInputChange}
            >
              <option value="1">
                Aidant naturel : présence en permanence ou passage quotidien
              </option>
              <option value="2">Aidant naturel : passage occasionnel</option>
              <option value="3">
                Aidant professionnel (secteur social et médico-social)
              </option>
            </Select>

            <Field
              type="number"
              name="poidskg"
              value={generalInputs['poidskg']}
              label="Poids Kg"
              onChange={onGeneralInputChange}
            />

            <Field
              type="number"
              name="taillem"
              value={generalInputs['taillem']}
              label="Taille m"
              onChange={onGeneralInputChange}
            />

            <Field
              name="forfait"
              value={generalInputs['forfait']}
              label="Forfait accordé"
              onChange={onGeneralInputChange}
            />

            <Field
              type="number"
              name="imc"
              value={generalInputs['imc']}
              label="IMC"
              onChange={onGeneralInputChange}
            />

            <Select
              name="segaage"
              value={generalInputs['segaage']}
              label="SEGA Age"
              onChange={onGeneralInputChange}
            >
              <option value="1">74 ans ou moins</option>
              <option value="2">Entre 75 ans et 84 ans</option>
              <option value="3">85 ans et plus</option>
            </Select>

            <Select
              name="provenance"
              value={generalInputs['provenance']}
              label="Provenance"
              onChange={onGeneralInputChange}
            >
              <option value="1">Domicile</option>
              <option value="2">Domicile avec aide professionnelle</option>
              <option value="3">FL ou EHPAD</option>
            </Select>

            <Select
              name="medicaments"
              value={generalInputs['medicaments']}
              label="Médicaments"
              onChange={onGeneralInputChange}
            >
              <option value="1">3 médicaments ou moins</option>
              <option value="2">4 à 5 médicaments</option>
              <option value="3">6 médicaments ou plus</option>
            </Select>

            <Select
              name="humeur"
              value={generalInputs['humeur']}
              label="Humeur"
              onChange={onGeneralInputChange}
            >
              <option value="1">Normale</option>
              <option value="2">Parfois anxieux ou triste</option>
              <option value="3">Déprimé</option>
            </Select>

            <Select
              name="perceptiondesante"
              value={generalInputs['perceptiondesante']}
              label="Perception de santé "
              onChange={onGeneralInputChange}
            >
              <option value="1">Meilleure santé</option>
              <option value="2">Santé équivalente</option>
              <option value="3">Moins bonne santé</option>
            </Select>

            <Select
              name="chutesds6derniersmois"
              value={generalInputs['chutesds6derniersmois']}
              label="Chutes ds 6 derniers mois"
              onChange={onGeneralInputChange}
            >
              <option value="1">Aucune chute</option>
              <option value="2">Une chute sans gravité</option>
              <option value="3">Chute(s) multiples ou compliquée(s)</option>
            </Select>

            <Select
              name="nutrition"
              value={generalInputs['nutrition']}
              label="Nutrition"
              onChange={onGeneralInputChange}
            >
              <option value="1">Poids stable apparence normale</option>
              <option value="2">
                Perte d'appétit nette depuis 15j ou perte de poids (3kg)
              </option>
              <option value="3">Dénutrition franche</option>
            </Select>

            <Select
              name="maladiesassociees"
              value={generalInputs['maladiesassociees']}
              label="Maladies associées"
              onChange={onGeneralInputChange}
            >
              <option value="1">Absence de maladie connue et traitée</option>
              <option value="2">De 1 à 3 maladies</option>
              <option value="3">Plus de 3 maladies</option>
            </Select>

            <Select
              name="aivq"
              value={generalInputs['aivq']}
              label="AIVQ"
              onChange={onGeneralInputChange}
            >
              <option value="1">Indépendante</option>
              <option value="2">Aide partielle</option>
              <option value="3">Incapacité</option>
            </Select>

            <Select
              name="mobilite"
              value={generalInputs['mobilite']}
              label="Mobilité"
              onChange={onGeneralInputChange}
            >
              <option value="1">Indépendance</option>
              <option value="2">Soutien</option>
              <option value="3">Incapacité</option>
            </Select>

            <Select
              name="continenceurinaireetoufecal920761"
              value={generalInputs['continenceurinaireetoufecal920761']}
              label="Continence (urinaire et/ou fécale)"
              onChange={onGeneralInputChange}
            >
              <option value="1">Continence</option>
              <option value="2">Incontinence occasionnelle</option>
              <option value="3">Incontinence permanente</option>
            </Select>

            <Select
              name="prisedesrepas"
              value={generalInputs['prisedesrepas']}
              label="Prise des repas"
              onChange={onGeneralInputChange}
            >
              <option value="1">Indépendance</option>
              <option value="2">Aide ponctuelle</option>
              <option value="3">Assistance complète</option>
            </Select>

            <Select
              name="fonctionscognitives"
              value={generalInputs['fonctionscognitives']}
              label="Fonctions cognitives"
              onChange={onGeneralInputChange}
            >
              <option value="1">Normales</option>
              <option value="2">Peu altérées</option>
              <option value="3">Assistance complète</option>
            </Select>

            <Select
              name="hospitalisationaucoursdes6579351"
              value={generalInputs['hospitalisationaucoursdes6579351']}
              label="Hospitalisation au cours des 6 derniers mois"
              onChange={onGeneralInputChange}
            >
              <option value="1">Aucune hospitalisation</option>
              <option value="2">1 hospitalisation &lt;3 mois</option>
              <option value="3">Plusieurs hospi ou 1 seule &gt;3mois</option>
            </Select>

            <Select
              name="vision"
              value={generalInputs['vision']}
              label="Vision"
              onChange={onGeneralInputChange}
            >
              <option value="1">Normale (avec ou sans correction)</option>
              <option value="2">Diminuée</option>
              <option value="3">Très diminuée</option>
            </Select>

            <Select
              name="audition"
              value={generalInputs['audition']}
              label="Audition"
              onChange={onGeneralInputChange}
            >
              <option value="1">Normale (avec ou sans correction)</option>
              <option value="2">Diminuée</option>
              <option value="3">Très diminuée</option>
            </Select>

            <Select
              name="supportsocialentourage"
              value={generalInputs['supportsocialentourage']}
              label="Support social / entourage"
              onChange={onGeneralInputChange}
            >
              <option value="1">Couple (ou famille)</option>
              <option value="2">Seul sans aide</option>
              <option value="3">Seul avec aide</option>
            </Select>

            <Select
              name="aideadomicileprofessionnell912751"
              value={generalInputs['aideadomicileprofessionnell912751']}
              label="Aide à domicile professionnelle"
              onChange={onGeneralInputChange}
            >
              <option value="1">Aucun besoin</option>
              <option value="2">Aide unique occasionnelle</option>
              <option value="3">Aide quotidien ou multiple</option>
            </Select>

            <Select
              name="aidantnaturel"
              value={generalInputs['aidantnaturel']}
              label="Aidant naturel"
              onChange={onGeneralInputChange}
            >
              <option value="1">Aucun besoin</option>
              <option value="2">Aide unique occasionnelle</option>
              <option value="3">Aide quotidien ou multiple</option>
            </Select>

            <Select
              name="perceptiondelachargeparle953731"
              value={generalInputs['perceptiondelachargeparle953731']}
              label="Perception de la charge par les proches"
              onChange={onGeneralInputChange}
            >
              <option value="1">Supportable</option>
              <option value="2">Importante</option>
              <option value="3">Trop importante</option>
            </Select>

            <Select
              name="habitat"
              value={generalInputs['habitat']}
              label="Habitat"
              onChange={onGeneralInputChange}
            >
              <option value="1">Adapté</option>
              <option value="2">Peu adapté</option>
              <option value="3">Inadéquat</option>
            </Select>

            <Select
              name="situationfinanciere"
              value={generalInputs['situationfinanciere']}
              label="Situation financière"
              onChange={onGeneralInputChange}
            >
              <option value="1">Pas de problème</option>
              <option value="2">Aide déjà en place</option>
              <option value="3">Problème identifié et absence d'aide</option>
            </Select>

            <Select
              name="perpectivesdavenirselonlap40541"
              value={generalInputs['perpectivesdavenirselonlap40541']}
              label="Perpectives d'avenir selon la personne"
              onChange={onGeneralInputChange}
            >
              <option value="1">Maintien lieu de vie actuel</option>
              <option value="2">
                Maintien lieu de vie et renforcement aides
              </option>
              <option value="3">Changement lieu de vie souhaité</option>
            </Select>

            <Select
              name="perpectivesdavenirselonlent650271"
              value={generalInputs['perpectivesdavenirselonlent650271']}
              label="Perpectives d'avenir selon l'entourage"
              onChange={onGeneralInputChange}
            >
              <option value="1">Indépendance</option>
              <option value="2">Aide ponctuelle</option>
              <option value="3">Assistance complète</option>
            </Select>

            <Select
              name="diagnosticsinfirmiersaliment415641"
              value={generalInputs['diagnosticsinfirmiersaliment415641']}
              label="Diagnostics Infirmiers Alimentation"
              onChange={onGeneralInputChange}
            >
              <option value="1">Alimentation insuffisante</option>
              <option value="2">Excès nutritionnel</option>
              <option value="3">
                Incapacité à s'alimenter /s'hydrater pour éviter une
                dénutrition/déhydratation,
              </option>
              <option value="4">Autre diagnostic infirmier</option>
            </Select>

            <Field
              name="precisionsdiagnoticsalimenta680191"
              value={generalInputs['precisionsdiagnoticsalimenta680191']}
              label="Précisions diagnotics Alimentation"
              onChange={onGeneralInputChange}
            />

            <Select
              name="testmnalepatientatilmoin719751"
              value={generalInputs['testmnalepatientatilmoin719751']}
              label="Test MNA Le patient a t'il moins mangé ces 3 derniers mois"
              onChange={onGeneralInputChange}
            >
              <option value="1">Baisse sévère des prises alimentaires</option>
              <option value="2">Légère baisse des prises alimentaires</option>
              <option value="3">Pas de baisse des prises alimentaires</option>
            </Select>

            <Select
              name="perterecentedepoids3mois"
              value={generalInputs['perterecentedepoids3mois']}
              label="Perte récente de poids (<3mois)"
              onChange={onGeneralInputChange}
            >
              <option value="1">Perte de poids &gt;3kg</option>
              <option value="2">Ne sait pas</option>
              <option value="3">Perte de poids entre 1 et 3kg</option>
              <option value="4">Pas de perte de poids</option>
            </Select>

            <Select
              name="motricite"
              value={generalInputs['motricite']}
              label="Motricité"
              onChange={onGeneralInputChange}
            >
              <option value="1">Au lit ou au fauteuil</option>
              <option value="2">Autonome à l'intérieur</option>
              <option value="3">Sort du domicile</option>
            </Select>

            <Bool
              name="maladieaigueoustresspsycho903311"
              value={generalInputs['maladieaigueoustresspsycho903311']}
              label="Maladie aiguë ou stress psycho au cours des 3 derniers mois"
              onChange={onGeneralInputChange}
            />

            <Select
              name="problemesneurologiques"
              value={generalInputs['problemesneurologiques']}
              label="Problèmes neurologiques"
              onChange={onGeneralInputChange}
            >
              <option value="1">Démence ou dépression sévère</option>
              <option value="2">Démence légère</option>
              <option value="3">Pas de problème psychologique</option>
            </Select>

            <Select
              name="imc39"
              value={generalInputs['imc39']}
              label="IMC_39"
              onChange={onGeneralInputChange}
            >
              <option value="1">IMC &lt; 19</option>
              <option value="2">19 ≤ IMC &lt; 21</option>
              <option value="3">21 ≤ IMC &lt; 23</option>
              <option value="4">IMC ≥ 23</option>
            </Select>

            <Select
              name="circonferencedumolletencm"
              value={generalInputs['circonferencedumolletencm']}
              label="Circonférence du mollet en cm"
              onChange={onGeneralInputChange}
            >
              <option value="1">CM &lt; 31</option>
              <option value="2">CM &gt; 31</option>
            </Select>

            <MultiSelect
              name="interventionsinfirmieresalim626481"
              value={generalInputs['interventionsinfirmieresalim626481']}
              label="Interventions infirmières Alimentation"
              items={[
                {
                  label:
                    "Surveillance clinique de l'équilibre alimentaire et/ou hydrique (aspects qualitatifs et quantitatifs)",
                  value: '1',
                },
                {
                  label:
                    "Surveillance de l'observance d'un régime alimentaire prescrit",
                  value: '2',
                },
                {
                  label:
                    'Surveillance des mesures thérapeutiques prescrites : (compléments alimentaires,…)',
                  value: '3',
                },
                {
                  label:
                    'Planification des apports alimentaires et hydriques et stimulation à leur prise',
                  value: '4',
                },
                {
                  label:
                    "Planification d'un régime alimentaire prescrit et contrôle de sa mise en œuvre",
                  value: '5',
                },
                {
                  label:
                    "Surveillance clinique de l'état bucco-dentaire pour adaptation de l'alimentation",
                  value: '6',
                },
                {
                  label:
                    "Mise en œuvre de mesures de prévention auprès d'un patient présentant un risque de fausse route ou après fausse route",
                  value: '7',
                },
              ]}
              onChange={onGeneralInputChange}
            />

            <Field
              name="observationscomplementairesd962741"
              value={generalInputs['observationscomplementairesd962741']}
              label="Observations complémentaires de l'infirmière au médecin Alim"
              multiline
              onChange={onGeneralInputChange}
            />

            <Field
              type="number"
              name="pressionarteriellesystolique908731"
              value={generalInputs['pressionarteriellesystolique908731']}
              label="Pression artérielle systolique (en mmHg)"
              onChange={onGeneralInputChange}
            />

            <Field
              type="number"
              name="pressionarteriellediastoliqu945421"
              value={generalInputs['pressionarteriellediastoliqu945421']}
              label="Pression artérielle diastolique (en mmHg)"
              onChange={onGeneralInputChange}
            />

            <Field
              type="number"
              name="frequencecardiaqueparmn"
              value={generalInputs['frequencecardiaqueparmn']}
              label="Fréquence cardiaque (par mn)"
              onChange={onGeneralInputChange}
            />

            <Bool
              name="frequencecardiaquenonmesura873931"
              value={generalInputs['frequencecardiaquenonmesura873931']}
              label="Fréquence cardiaque non mesurable"
              onChange={onGeneralInputChange}
            />

            <Field
              type="number"
              name="frequencerespiratoireparmn"
              value={generalInputs['frequencerespiratoireparmn']}
              label="Fréquence respiratoire (par mn)"
              onChange={onGeneralInputChange}
            />

            <Bool
              name="diaginfirespietcardiotrou330941"
              value={generalInputs['diaginfirespietcardiotrou330941']}
              label="Diag Infi Respi et cardio Troubles de la respiration "
              onChange={onGeneralInputChange}
            />

            <Bool
              name="diaginfirespietcardiooede840971"
              value={generalInputs['diaginfirespietcardiooede840971']}
              label="Diag Infi Respi et cardio Oedeme des membres inférieurs"
              onChange={onGeneralInputChange}
            />

            <Bool
              name="diaginfirespietcardiodysp330241"
              value={generalInputs['diaginfirespietcardiodysp330241']}
              label="Diag Infi Respi et cardio Dyspnée ou risque de dyspnée "
              onChange={onGeneralInputChange}
            />

            <Bool
              name="diaginfirespietcardioautr332651"
              value={generalInputs['diaginfirespietcardioautr332651']}
              label="Diag Infi Respi et cardio Autre diagnostic infirmier "
              onChange={onGeneralInputChange}
            />

            <Select
              name="interventionsinfirmierescard931121"
              value={generalInputs['interventionsinfirmierescard931121']}
              label="Interventions infirmières Cardio"
              onChange={onGeneralInputChange}
            >
              <option value="1">
                Surveillance clinique de la fonction respiratoire
              </option>
              <option value="2">
                Soins infirmiers du patient avec un dispositif de pression
                positive continue pour traitement de l'apnée du sommeil
              </option>
              <option value="3">
                Soins infirmiers pour un patient appareillé par un dispositif
                d'oxygénothérapie,
              </option>
              <option value="4">
                Soins infirmiers d'un patient nécessitant des aspirations
                bronchiques (avec ou sans trachéotomie)
              </option>
            </Select>

            <Bool
              name="surveillancecliniquefonction628161"
              value={generalInputs['surveillancecliniquefonction628161']}
              label="Surveillance clinique fonction Cardio-vasc"
              onChange={onGeneralInputChange}
            />

            <Bool
              name="posedeposedecompressionvas880451"
              value={generalInputs['posedeposedecompressionvas880451']}
              label="Pose dépose de compression vasculaire"
              onChange={onGeneralInputChange}
            />

            <Field
              multiline
              name="observationscomplementairesd555111"
              value={generalInputs['observationscomplementairesd555111']}
              label="Observations complémentaires de l'infirmière au médecin Cardio"
              onChange={onGeneralInputChange}
            />

            <MultiSelect
              name="diagnosticsinfirmierselimina653501"
              value={generalInputs['diagnosticsinfirmierselimina653501']}
              label="Diagnostics Infirmiers Elimination "
              items={[
                { label: "Altération de l'élimination urinaire", value: '1' },
                { label: 'Rétention urinaire', value: '2' },
                { label: 'Incontinence urinaire', value: '3' },
                { label: 'Constipation Chronique', value: '4' },
                { label: 'Diarrhée Chronique', value: '5' },
                { label: 'Incontinence fécale', value: '6' },
                { label: 'Autre diagnostic infirmier', value: '7' },
              ]}
              onChange={onGeneralInputChange}
            />

            <Field
              multiline
              name="diagnosticsinfirmierselimina864971"
              value={generalInputs['diagnosticsinfirmierselimina864971']}
              label="Diagnostics Infirmiers Elimination Précisions"
              onChange={onGeneralInputChange}
            />

            <MultiSelect
              name="eliminationinterventionsinfi990881"
              value={generalInputs['eliminationinterventionsinfi990881']}
              label="Elimination Interventions Infirmières"
              items={[
                {
                  label:
                    "Surveillance clinique de l'élimination urinaire et/ou fécale (aspects qualitatifs et quantitatifs)",
                  value: 1,
                },
                {
                  label:
                    'Soins infirmiers liés à une incontinence non permanente urinaire et/ou fécale',
                  value: 2,
                },
                {
                  label:
                    'Soins infirmiers liés à une incontinence urinaire permanente et/ou fécale',
                  value: 3,
                },
                {
                  label:
                    "Surveillance clinique d'un patient porteur d'une sonde urinaire à demeure et soins infirmiers - associée ou non à un trouble de l'élimination fécale",
                  value: 4,
                },
                { label: "Surveillance clinique d'une constipation", value: 5 },
              ]}
              onChange={onGeneralInputChange}
            />

            <Bool
              name="planificationdessoinsperso347551"
              value={generalInputs['planificationdessoinsperso347551']}
              label="Planification des soins perso et aide à l'élimination urinaire ou fécale"
              onChange={onGeneralInputChange}
            />

            <Bool
              name="eliminationinterventionsinfi214901"
              value={generalInputs['eliminationinterventionsinfi214901']}
              label="Elimination Interventions Infirmières Massage colique"
              onChange={onGeneralInputChange}
            />

            <Field
              multiple
              name="observationscomplementairesd801501"
              value={generalInputs['observationscomplementairesd801501']}
              label="Observations complémentaires de l'infirmière au médecin Elimination"
              onChange={onGeneralInputChange}
            />

            <MultiSelect
              name="diagnosticsinfirmiershygiene32921"
              value={generalInputs['diagnosticsinfirmiershygiene32921']}
              label="Diagnostics Infirmiers Hygiène"
              items={[
                {
                  label: 'Incapacité partielle ou totale à se vêtir/dévêtir',
                  value: 1,
                },
                {
                  label:
                    "Incapacité partielle ou totale à se laver & effectuer ses soins d'hygiène",
                  value: 2,
                },
                {
                  label: "Risque d'atteinte à l'intégrité cutanée et d'escarre",
                  value: 3,
                },
                {
                  label:
                    'Escarre(s) ou plaie(s) complexe(s) chronique(s) constituée(s)',
                  value: 4,
                },
                { label: 'Autre diagnostic infirmier', value: 5 },
              ]}
              onChange={onGeneralInputChange}
            />

            <Field
              multiline
              name="diagnosticsinfirmiershygiene707201"
              value={generalInputs['diagnosticsinfirmiershygiene707201']}
              label="Diagnostics Infirmiers Hygiène Précisions"
              onChange={onGeneralInputChange}
            />

            <Select
              name="hygienetestdevaluationrisq886031"
              value={generalInputs['hygienetestdevaluationrisq886031']}
              label="Hygiène  Test d'évaluation risque d'escarre  Test de Norton Condition physique"
              onChange={onGeneralInputChange}
            >
              <option value="1">Mauvaise</option>
              <option value="2">Pauvre</option>
              <option value="3">Moyenne</option>
              <option value="4">Bonne</option>
            </Select>

            <Select
              name="hygienetestdevaluationrisq724871"
              value={generalInputs['hygienetestdevaluationrisq724871']}
              label="Hygiène  Test d'évaluation risque d'escarre  Test de Norton Condition mentale"
              onChange={onGeneralInputChange}
            >
              <option value="1">Stuporeux</option>
              <option value="2">Confus</option>
              <option value="3">Apathique</option>
              <option value="4">Alerte</option>
            </Select>

            <Select
              name="hygienetestdevaluationrisq483801"
              value={generalInputs['hygienetestdevaluationrisq483801']}
              label="Hygiène  Test d'évaluation risque d'escarre  Test de Norton Activité"
              onChange={onGeneralInputChange}
            >
              <option value="1">Couché</option>
              <option value="2">Fauteuil</option>
              <option value="3">Marche aidée</option>
              <option value="4">Ambulant</option>
            </Select>

            <Select
              name="hygienetestdevaluationrisq406311"
              value={generalInputs['hygienetestdevaluationrisq406311']}
              label="Hygiène  Test d'évaluation risque d'escarre  Test de Norton Mobilité"
              onChange={onGeneralInputChange}
            >
              <option value="1">Immobile</option>
              <option value="2">Très limitée</option>
              <option value="3">Peu limitée</option>
              <option value="4">Complète</option>
            </Select>

            <Select
              name="hygienetestdevaluationrisq964411"
              value={generalInputs['hygienetestdevaluationrisq964411']}
              label="Hygiène  Test d'évaluation risque d'escarre  Test de Norton Incontinence"
              onChange={onGeneralInputChange}
            >
              <option value="1">Fécale et urinaire</option>
              <option value="2">Urinaire</option>
              <option value="3">Occasionnelle</option>
              <option value="4">Continent</option>
            </Select>

            <Select
              name="hygienetestdevaluationrisq979521"
              value={generalInputs['hygienetestdevaluationrisq979521']}
              label="Hygiène  Test d'évaluation risque d'escarre  Test Braden  Sensibilité"
              onChange={onGeneralInputChange}
            >
              <option value="1">Complètement limitée</option>
              <option value="2">Très limitée</option>
              <option value="3">Légèrement limitée</option>
              <option value="4">Pas de gêne</option>
            </Select>

            <Select
              name="hygienetestdevaluationrisq941951"
              value={generalInputs['hygienetestdevaluationrisq941951']}
              label="Hygiène  Test d'évaluation risque d'escarre  Test Braden  Humidité"
              onChange={onGeneralInputChange}
            >
              <option value="1">Constamment humide</option>
              <option value="2">Très humide</option>
              <option value="3">Parfois humide</option>
              <option value="4">Rarement humide</option>
            </Select>

            <Select
              name="hygienetestdevaluationrisq56981"
              value={generalInputs['hygienetestdevaluationrisq56981']}
              label="Hygiène  Test d'évaluation risque d'escarre  Test Braden  Activité"
              onChange={onGeneralInputChange}
            >
              <option value="1">Confiné au lit</option>
              <option value="2">Confiné en chaise</option>
              <option value="3">Marche parfois</option>
              <option value="4">Marche fréquemment</option>
            </Select>

            <Select
              name="hygienetestdevaluationrisq362901"
              value={generalInputs['hygienetestdevaluationrisq362901']}
              label="Hygiène  Test d'évaluation risque d'escarre  Test Braden  Mobilité"
              onChange={onGeneralInputChange}
            >
              <option value="1">Totalement immobile</option>
              <option value="2">Très limitée</option>
              <option value="3">Légèrement limitée</option>
              <option value="4">Pas de limitation</option>
            </Select>

            <Select
              name="hygienetestdevaluationrisq734191"
              value={generalInputs['hygienetestdevaluationrisq734191']}
              label="Hygiène  Test d'évaluation risque d'escarre  Test Braden  Nutrition"
              onChange={onGeneralInputChange}
            >
              <option value="1">Très pauvre</option>
              <option value="2">Probablement inadéquate</option>
              <option value="3">Correcte</option>
              <option value="4">Excellente</option>
            </Select>

            <Select
              name="hygienetestdevaluationrisq765861"
              value={generalInputs['hygienetestdevaluationrisq765861']}
              label="Hygiène  Test d'évaluation risque d'escarre  Test Braden Frictions et frottements"
              onChange={onGeneralInputChange}
            >
              <option value="1">Problème permanent</option>
              <option value="2">Problème potentiel</option>
              <option value="3">Pas de problème apparent</option>
            </Select>

            <MultiSelect
              name="hygieneinterventionsinfirmie328911"
              value={generalInputs['hygieneinterventionsinfirmie328911']}
              label="Hygiène Interventions Infirmières"
              items={[
                {
                  label:
                    "Surveillance du confort d'installation (lit - régulation température - préparation à l'endormissement,etc) et de l'intégrité de la peau",
                  value: 1,
                },
                {
                  label:
                    "Planification des soins d'hygiène - stimulation et apprentissage visant à maintenir les capacités d'autonomie restantes",
                  value: 2,
                },
                {
                  label:
                    "Aide du patient pour la réalisation de ses soins d'hygiène et de son habillage/déshabillage",
                  value: 3,
                },
                {
                  label:
                    "Réalisation complète des soins d'hygiène et de l'habillage/déshabillage - sans participation du patient",
                  value: 4,
                },
                {
                  label:
                    "Surveillance de l'intégrité de la peau et mise en œuvre de mesures de prévention auprès d'un patient présentant un risque d'escarre",
                  value: 5,
                },
                {
                  label:
                    "Pose - surveillance-entretien d'orthèses - de prothèses -d'appareillage de suppléance",
                  value: 6,
                },
              ]}
              onChange={onGeneralInputChange}
            />

            <Field
              multiline
              name="observationscomplementairesd278571"
              value={generalInputs['observationscomplementairesd278571']}
              label="Observations complémentaires de l'infirmière au médecin Hygiène"
              onChange={onGeneralInputChange}
            />

            <MultiSelect
              name="diagnosticsinfirmiersmobilit878901"
              value={generalInputs['diagnosticsinfirmiersmobilit878901']}
              label="Diagnostics Infirmiers Mobilité"
              items={[
                { label: 'Risque de chute', value: 1 },
                { label: 'Mobilité physique réduite', value: 2 },
                { label: 'Etat grabataire', value: 3 },
                { label: 'Autre diagnostic infirmier ', value: 4 },
              ]}
              onChange={onGeneralInputChange}
            />

            <Field
              multiline
              name="precisionsdiagnoticsmobilite329291"
              value={generalInputs['precisionsdiagnoticsmobilite329291']}
              label="Précisions diagnotics Mobilité"
              onChange={onGeneralInputChange}
            />

            <Select
              name="seleverdunfauteuilavecacc23891"
              value={generalInputs['seleverdunfauteuilavecacc23891']}
              label="Se lever d'un fauteuil avec accoudoir"
              onChange={onGeneralInputChange}
            >
              <option value="1">Fait</option>
              <option value="2">Ne fait pas</option>
              <option value="3">Non réalisable</option>
            </Select>

            <Select
              name="traverserlapiecedistance3411121"
              value={generalInputs['traverserlapiecedistance3411121']}
              label="Traverser la pièce - Distance 3 mètres"
              onChange={onGeneralInputChange}
            >
              <option value="1">Fait</option>
              <option value="2">Ne fait pas</option>
              <option value="3">Non réalisable</option>
            </Select>

            <Select
              name="fairedemitour"
              value={generalInputs['fairedemitour']}
              label="Faire demi-Tour"
              onChange={onGeneralInputChange}
            >
              <option value="1">Fait</option>
              <option value="2">Ne fait pas</option>
              <option value="3">Non réalisable</option>
            </Select>

            <Select
              name="revenirsasseoir"
              value={generalInputs['revenirsasseoir']}
              label="Revenir s'asseoir"
              onChange={onGeneralInputChange}
            >
              <option value="1">Fait</option>
              <option value="2">Ne fait pas</option>
              <option value="3">Non réalisable</option>
            </Select>

            <Field
              type="number"
              name="dureenecessaire"
              value={generalInputs['dureenecessaire']}
              label="Durée nécessaire"
              onChange={onGeneralInputChange}
            />

            <Field
              name="interpretationscore"
              value={generalInputs['interpretationscore']}
              label="Interprétation score"
              onChange={onGeneralInputChange}
            />

            <Select
              name="teststationunipodalepiedd"
              value={generalInputs['teststationunipodalepiedd']}
              label="Test station unipodale Pied D"
              onChange={onGeneralInputChange}
            >
              <option value="1">Oui</option>
              <option value="2">Non</option>
              <option value="3">Non réalisable</option>
            </Select>

            <Select
              name="teststationunipodalepiedg"
              value={generalInputs['teststationunipodalepiedg']}
              label="Test station unipodale Pied G"
              onChange={onGeneralInputChange}
            >
              <option value="1">Oui</option>
              <option value="2">Non</option>
              <option value="3">Non réalisable</option>
            </Select>

            <MultiSelect
              name="intervinfirmieremobilite"
              value={generalInputs['intervinfirmieremobilite']}
              label="Interv Infirmière Mobilité"
              items={[
                {
                  label:
                    "Surveillance et observation, de la sécurité de l'environnement du patient et de l'utilisation des aides techniques : (cannes, déambulateur, attelles,…)",
                  value: '1',
                },
                {
                  label:
                    'Surveillance et mise en place de mesures de prévention des chutes chez une personne à risque',
                  value: '2',
                },
                {
                  label:
                    'Changements de positions fréquents, prévention des attitudes vicieuses, des complications de décubitus (planification et réalisation des soins)',
                  value: '3',
                },
              ]}
              onChange={onGeneralInputChange}
            />

            <Select
              name="intervinfirmieremobilitebi660131"
              value={generalInputs['intervinfirmieremobilitebi660131']}
              label="Interv Infirmière  Mobilité Bis"
              onChange={onGeneralInputChange}
            >
              <option value="1">
                Aide à l'installation (lit, fauteuil) et aux déplacements (avec
                ou sans aide technique)
              </option>
              <option value="2">
                Aide partielle aux transferts (couché/assis/debout), avec lever
                ergonomique et installation du patient, surveillance et
                prévention des complications
              </option>
              <option value="3">
                Aide totale aux transferts (couché/assis/debout), avec ou sans
                lève-malade et installation du patient, surveillance et
                prévention des complications
              </option>
            </Select>

            <Field
              multiple
              name="observationscomplementairesd10461"
              value={generalInputs['observationscomplementairesd10461']}
              label="Observations complémentaires de l'infirmière au médecin Mobilité"
              onChange={onGeneralInputChange}
            />

            <MultiSelect
              name="diagnosticsinfirmierstraitem889831"
              value={generalInputs['diagnosticsinfirmierstraitem889831']}
              label="Diagnostics Infirmiers Traitement de la douleur"
              items={[
                {
                  label:
                    'Douleurs chroniques invalidantes avec retentissement important sur la qualité de vie',
                  value: '2',
                },
                {
                  label:
                    'Traitement polymédicamenteux au long cours avec risque iatrogénique',
                  value: '3',
                },
                { label: 'Autre diagnostic infirmier', value: '4' },
              ]}
              onChange={onGeneralInputChange}
            />

            <Field
              multiline
              name="diagnosticsinfirmierstraitem45831"
              value={generalInputs['diagnosticsinfirmierstraitem45831']}
              label="Diagnostics Infirmiers Traitement de la douleur  Précisions"
              onChange={onGeneralInputChange}
            />

            <Field
              type="number"
              name="testdevaluationdeladouleur260241"
              value={generalInputs['testdevaluationdeladouleur260241']}
              label="Test d'evaluation de la douleur - EVA - JOUR"
              onChange={onGeneralInputChange}
            />

            <Field
              type="number"
              name="testdevaluationdeladouleur120591"
              value={generalInputs['testdevaluationdeladouleur120591']}
              label="Test d'evaluation de la douleur - EVA - NUIT"
              onChange={onGeneralInputChange}
            />

            <Select
              name="testdoloplusplaintessomatiq232711"
              value={generalInputs['testdoloplusplaintessomatiq232711']}
              label="Test DOLOPLUS Plaintes somatiques"
              onChange={onGeneralInputChange}
            >
              <option value="1">Pas de plainte</option>
              <option value="2">Plainte uniquement à la sollicitation</option>
              <option value="3">Plaintes spontanées occasionnelles</option>
              <option value="4">Plaintes spontanées continues</option>
            </Select>

            <Select
              name="testdolopluspositionsantalg648021"
              value={generalInputs['testdolopluspositionsantalg648021']}
              label="Test DOLOPLUS Positions antalgiques au repos"
              onChange={onGeneralInputChange}
            >
              <option value="1">Pas de position antalgique</option>
              <option value="2">
                Le sujet évite certaines positions de façon occasionnelle
              </option>
              <option value="3">
                Position antalgique permanente et efficace
              </option>
              <option value="4">
                Position antalgique permanente et inefficace
              </option>
            </Select>

            <Select
              name="testdoloplusprotectiondez853931"
              value={generalInputs['testdoloplusprotectiondez853931']}
              label="Test DOLOPLUS  Protection de zones douloureuses"
              onChange={onGeneralInputChange}
            >
              <option value="1">Pas de protection</option>
              <option value="2">
                Protection à la sollicitation n'empêchant pas la poursuite
              </option>
              <option value="3">
                Protection à la sollicitation empêchant tout examen ou soin
              </option>
              <option value="4">
                Protection au repos, en l'absence de toute sollicitation
              </option>
            </Select>

            <Select
              name="testdoloplusmimique"
              value={generalInputs['testdoloplusmimique']}
              label="Test DOLOPLUS Mimique "
              onChange={onGeneralInputChange}
            >
              <option value="1">Mimique Habituelle</option>
              <option value="2">
                Mimique semblant exprimer la douleur à la sollicitation
              </option>
              <option value="3">
                Mimique semblant exprimer la douleur en l'absence de toute
                sollicitation
              </option>
              <option value="4">
                Mimique inexpressive en permanence et de manière inhabituelle
                (Atone, figée, regard vide)
              </option>
            </Select>

            <Select
              name="testdoloplussommeil"
              value={generalInputs['testdoloplussommeil']}
              label="Test DOLOPLUS Sommeil "
              onChange={onGeneralInputChange}
            >
              <option value="1">Sommeil habituel</option>
              <option value="2">Difficultés d'endormissement</option>
              <option value="3">Réveils fréquents (agitation motrice)</option>
              <option value="4">
                Insomnie avec retentissement sur les phases d'éveil
              </option>
            </Select>

            <Select
              name="testdoloplustoiletteetouha224021"
              value={generalInputs['testdoloplustoiletteetouha224021']}
              label="Test DOLOPLUS Toilette et/ou habillage"
              onChange={onGeneralInputChange}
            >
              <option value="1">Possibilités habituelles inchangées</option>
              <option value="2">
                Possibilités habituelles peu diminuées (précautionneux mais
                complet)
              </option>
              <option value="3">
                Possibilités habituelles très diminuées, toilette et/ou
                habillage étant difficiles et partiels
              </option>
              <option value="4">
                Toilette et/ou habillage impossibles, le malade exprimant son
                opposition à toute tentative
              </option>
            </Select>

            <Select
              name="testdoloplusmouvements"
              value={generalInputs['testdoloplusmouvements']}
              label="Test DOLOPLUS Mouvements "
              onChange={onGeneralInputChange}
            >
              <option value="1">Possibilités habituelles inchangées</option>
              <option value="2">
                Possibilités habituelles actives limitées (le malade évite
                certains mouvements, diminue son périmètre de marche)
              </option>
              <option value="3">
                Possibilités habituelles actives et passives limitées (même
                aidé, le malade diminue ses mouvements
              </option>
              <option value="4">
                Mouvement impossible, toute mobilisation entraînant une
                opposition
              </option>
            </Select>

            <Select
              name="testdolopluscommunication"
              value={generalInputs['testdolopluscommunication']}
              label="Test DOLOPLUS Communication "
              onChange={onGeneralInputChange}
            >
              <option value="1">Inchangée</option>
              <option value="2">
                Intensifiée (la personne attire l'attention de manière
                inhabituelle)
              </option>
              <option value="3">Diminuée (la personne s'isole)</option>
              <option value="4">Absence ou refus de toute vie sociale</option>
            </Select>

            <Select
              name="testdoloplusviesociale"
              value={generalInputs['testdoloplusviesociale']}
              label="Test DOLOPLUS Vie sociale"
              onChange={onGeneralInputChange}
            >
              <option value="1">
                Participation habituelle aux différentes activités
                (repas,animations,ateliers thérapeutiques ...)
              </option>
              <option value="2">
                Participation aux différentes activités uniquement à la
                sollicitation
              </option>
              <option value="3">
                Refus partiel de participation aux différentes activités
              </option>
              <option value="4">Refus de toute vie sociale</option>
            </Select>

            <Select
              name="testdoloplustroublesducomp56071"
              value={generalInputs['testdoloplustroublesducomp56071']}
              label="Test DOLOPLUS Troubles du comportement"
              onChange={onGeneralInputChange}
            >
              <option value="1">Comportement habituel</option>
              <option value="2">
                Troubles du comportement à la sollicitation et itératif
              </option>
              <option value="3">
                Troubles du comportement à la sollicitation et permanent
              </option>
              <option value="4">
                Troubles du comportement permanent (en dehors de toute
                sollicitation)
              </option>
            </Select>

            <Bool
              name="testdn4douleurscaracteristi610261"
              value={generalInputs['testdn4douleurscaracteristi610261']}
              label="Test DN4 Douleurs Caractéristiques Brûlure"
              onChange={onGeneralInputChange}
            />

            <Bool
              name="testdn4douleurscaracteristi690951"
              value={generalInputs['testdn4douleurscaracteristi690951']}
              label="Test DN4 Douleurs Caractéristiques Sensation de froid douloureux"
              onChange={onGeneralInputChange}
            />

            <Bool
              name="testdn4douleurscaracteristi180841"
              value={generalInputs['testdn4douleurscaracteristi180841']}
              label="Test DN4 Douleurs Caractéristiques Décharges électriques"
              onChange={onGeneralInputChange}
            />

            <Bool
              name="testdn4douleurassocieedsm100621"
              value={generalInputs['testdn4douleurassocieedsm100621']}
              label="Test DN4 Douleur associée ds même région à 1 ou plusieurs symptomes Fourmillement"
              onChange={onGeneralInputChange}
            />

            <Bool
              name="testdn4douleurassocieedsm485311"
              value={generalInputs['testdn4douleurassocieedsm485311']}
              label="Test DN4 Douleur associée ds même région à 1 ou plusieurs symptomes Picotements"
              onChange={onGeneralInputChange}
            />

            <Bool
              name="testdn4douleurassocieedsm97351"
              value={generalInputs['testdn4douleurassocieedsm97351']}
              label="Test DN4 Douleur associée ds même région à 1 ou plusieurs symptomes Engourdissements"
              onChange={onGeneralInputChange}
            />

            <Bool
              name="testdn4douleurassocieedsm745641"
              value={generalInputs['testdn4douleurassocieedsm745641']}
              label="Test DN4 Douleur associée ds même région à 1 ou plusieurs symptomes Démangeaisons"
              onChange={onGeneralInputChange}
            />

            <Bool
              name="testdn4douleurlocaliseehyp364731"
              value={generalInputs['testdn4douleurlocaliseehyp364731']}
              label="Test DN4 Douleur localisée Hypoesthésie au tact "
              onChange={onGeneralInputChange}
            />

            <Bool
              name="testdn4douleurlocaliseehyp181581"
              value={generalInputs['testdn4douleurlocaliseehyp181581']}
              label="Test DN4 Douleur localisée Hypoesthésie à la piqûre"
              onChange={onGeneralInputChange}
            />

            <Bool
              name="testdn4douleurprovoqueepar41051"
              value={generalInputs['testdn4douleurprovoqueepar41051']}
              label="Test DN4 Douleur provoquée par Frottement"
              onChange={onGeneralInputChange}
            />

            <MultiSelect
              name="interventionsinfirmieresdoul689941"
              value={generalInputs['interventionsinfirmieresdoul689941']}
              label="Interventions infirmières Douleur"
              items={[
                {
                  label:
                    "Evaluation et suivi par test d'une douleur chronique invalidante avec retentissement important sur la qualité de vie, prise en charge relationnelle infirmière de la douleur, et le cas échéant adaptation du traitement anti-douleur selon protocole prescrit",
                  value: '1',
                },
                {
                  label:
                    "Surveillance d'un patient à risque de iatrogénie médicamenteuse en lien avec le médecin et/ou le pharmacien : repérer les patients à risque d'événements indésirables médicamenteux  , sécuriser la gestion des médicaments, et gérer les situations à risque et les alertes",
                  value: '2',
                },
              ]}
              onChange={onGeneralInputChange}
            />

            <Field
              multiline
              name="observationcomplementairespo113741"
              value={generalInputs['observationcomplementairespo113741']}
              label="Observation complémentaires pour le médecin Douleur"
              onChange={onGeneralInputChange}
            />

            <MultiSelect
              name="interventionsinfirmieressoin157741"
              value={generalInputs['interventionsinfirmieressoin157741']}
              label="Interventions Infirmières Soins Relationnels "
              items={[
                {
                  label:
                    "Un déficit sensoriel majeur même appareillé ou un trouble majeur de la compréhension ou de l'expression du langage oral, par communication non verbale",
                  value: '1',
                },
                {
                  label:
                    'Des troubles comportementaux importants et chroniques (angoisse, agressivité, apathie…)',
                  value: '2',
                },
                {
                  label:
                    "Un état anxio-dépressif chronique avec souffrance morale et retentissement important sur l'activité",
                  value: '3',
                },
                {
                  label:
                    "Des troubles démentiels (désorienté, incohérent, perte de l'autonomie sociale, fugueur…)  ",
                  value: '4',
                },
                {
                  label:
                    "Des troubles psychiatriques sévères avec incurie (manque total de soins de soi et d'hygiène avec indifférence aux conséquences)",
                  value: '5',
                },
                {
                  label:
                    "Une situation de crise (annonce d'une pathologie grave, d'une perte de fonction/organe avec handicap, fin de vie…)",
                  value: '6',
                },
              ]}
              onChange={onGeneralInputChange}
            />

            <Field
              multiline
              name="observationscomplementairesp226621"
              value={generalInputs['observationscomplementairesp226621']}
              label="Observations complémentaires pour le médecin  Soins Relationnels "
              onChange={onGeneralInputChange}
            />

            <Bool
              name="interventionsinfirmieressoin780331"
              value={generalInputs['interventionsinfirmieressoin780331']}
              label="Interventions Infirmières Soins Educatifs Aide éducative"
              onChange={onGeneralInputChange}
            />

            <MultiSelect
              name="interventionsinfirmieressoin469011"
              value={generalInputs['interventionsinfirmieressoin469011']}
              label="Interventions Infirmières Soins Educatifs  Pathologies"
              items={[
                { label: 'Pathologie cardiovasculaire, diabète', value: '1' },
                { label: 'Pathologie respiratoire', value: '2' },
                { label: 'Pathologie rénale', value: '3' },
                { label: 'Pathologie neurologique', value: '4' },
                { label: 'Pathologie digestive', value: '5' },
                { label: 'Autre pathologie', value: '6' },
              ]}
              onChange={onGeneralInputChange}
            />

            <Field
              multiline
              name="interventionsinfirmieressoin780101"
              value={generalInputs['interventionsinfirmieressoin780101']}
              label="Interventions Infirmières Soins Educatifs  Pathologies Précisions"
              onChange={onGeneralInputChange}
            />

            <Bool
              name="interventionsinfirmieressoin184071"
              value={generalInputs['interventionsinfirmieressoin184071']}
              label="Interventions Infirmières Soins Educatifs Aide à l'apprentissage"
              onChange={onGeneralInputChange}
            />

            <MultiSelect
              name="interventionsinfirmieressoin307381"
              value={generalInputs['interventionsinfirmieressoin307381']}
              label="Interventions Infirmières Soins Educatifs  Situations"
              items={[
                {
                  label:
                    "Diabète à l'instauration d'un traitement par insuline ",
                  value: '1',
                },
                {
                  label: "Instauration d'un traitement par anticoagulant",
                  value: '2',
                },
                {
                  label: "Patient en sortie d'une hospitalisation",
                  value: '3',
                },
                { label: 'Autre situation', value: '4' },
              ]}
              onChange={onGeneralInputChange}
            />

            <Field
              multiline
              name="interventionsinfirmieressoin751801"
              value={generalInputs['interventionsinfirmieressoin751801']}
              label="Interventions Infirmières Soins Educatifs  Aide à l'apprentissage Précisions"
              onChange={onGeneralInputChange}
            />

            <Field
              multiline
              name="observationcomplementairespo416171"
              value={generalInputs['observationcomplementairespo416171']}
              label="Observation complémentaires pour le médecin  Soins Relationnels Aide à l'apprentissage "
              onChange={onGeneralInputChange}
            />

            <ImageUpload
              name="techCare"
              title="Photo Soins techniques"
              value={generalInputs.techCare}
              editMode={editMode}
              emptyText="Aucune photo n'a été ajoutée"
              onChange={onTechCareUploadChange}
              onDelete={onGeneralInputChange}
              multiple={true}
            />

            <MultiSelect
              name="criteresdemajorationliesau380611"
              value={generalInputs['criteresdemajorationliesau380611']}
              label="Critères de majoration Liés au patient"
              items={[
                { label: 'IMC > 30 ', value: '1' },
                { label: 'Tétraplégie', value: '2' },
                { label: 'Grabataire', value: '3' },
                { label: 'Patient opposant ou agité', value: '4' },
                { label: 'Soins palliatifs / Fin de vie', value: '5' },
                { label: 'Enfants de < 15 ans', value: '6' },
                { label: 'Aucun', value: '7' },
              ]}
              onChange={onGeneralInputChange}
            />
            <MultiSelect
              name="criteresdemajorationliesa420071"
              value={generalInputs['criteresdemajorationliesa420071']}
              label="Critères de majoration Liés à l'environnement"
              items={[
                { label: 'Locaux insalubres ', value: '1' },
                { label: 'Sanitaires non adaptés', value: '2' },
                { label: "Absence d'aidant ou aidant inadapté", value: '3' },
                {
                  label:
                    "Matériel médical d'aide aux soins refusé par le patient ou l'entourage ou non utilisable au domicile du patient",
                  value: '4',
                },
                { label: 'Aucun', value: '5' },
              ]}
              onChange={onGeneralInputChange}
            />
            <Field
              multiline
              name="observationcomplementairespo556321"
              value={generalInputs['observationcomplementairespo556321']}
              label="Observation complémentaires pour le médecin  Critères de majoration"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n1prelevementparponctionvei77561"
              value={generalInputs['n1prelevementparponctionvei77561']}
              label="1- Prélèvement par ponction veineuse directe Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />

            <Field
              type="number"
              name="n1prelevementparponctionvei16591"
              value={generalInputs['n1prelevementparponctionvei16591']}
              label="1- Prélèvement par ponction veineuse directe Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n2prelevementcutaneoudesec944441"
              value={generalInputs['n2prelevementcutaneoudesec944441']}
              label="2- Prélèvement cutané ou de sécrétions muqueuses, prélèvement de selles ou d'urine pour examens cytologiques, bactériologiques, mycologiques, virologiques ou parasitologiques  Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n2prelevementcutaneoudesec955381"
              value={generalInputs['n2prelevementcutaneoudesec955381']}
              label="2- Prélèvement cutané ou de sécrétions muqueuses, prélèvement de selles ou d'urine pour examens cytologiques, bactériologiques, mycologiques, virologiques ou parasitologiques Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n3saigneenbredactesparmois542781"
              value={generalInputs['n3saigneenbredactesparmois542781']}
              label="3- Saignée Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n3saigneedureeprevisiblede620771"
              value={generalInputs['n3saigneedureeprevisiblede620771']}
              label="3- Saignée  Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n4injectionintramusculaireso218181"
              value={generalInputs['n4injectionintramusculaireso218181']}
              label="4- Injection Intramusculaire, sous-cutanée, intradermique Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n4injectionintramusculaireso977511"
              value={generalInputs['n4injectionintramusculaireso977511']}
              label="4- Injection Intramusculaire, sous-cutanée, intradermique Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n5injectionintraveineusedire572361"
              value={generalInputs['n5injectionintraveineusedire572361']}
              label="5- Injection intraveineuse directe ou par l'intermédiaire d'un site implanté ou par l'intermédiaire d'un cathéter central   Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n5injectionintraveineusedire504891"
              value={generalInputs['n5injectionintraveineusedire504891']}
              label="5- Injection intraveineuse directe ou par l'intermédiaire d'un site implanté ou par l'intermédiaire d'un cathéter central   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n6pansementcourantnbredac925731"
              value={generalInputs['n6pansementcourantnbredac925731']}
              label="6- Pansement courant   Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n6pansementcourantdureepr176721"
              value={generalInputs['n6pansementcourantdureepr176721']}
              label="6- Pansement courant   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n7pansementlourdetcomplexe832891"
              value={generalInputs['n7pansementlourdetcomplexe832891']}
              label="7- Pansement lourd et complexe   Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n7pansementlourdetcomplexe237111"
              value={generalInputs['n7pansementlourdetcomplexe237111']}
              label="7- Pansement lourd et complexe   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n8pansementlourdetcomplexe34821"
              value={generalInputs['n8pansementlourdetcomplexe34821']}
              label="8- Pansement lourd et complexe pour ulcère veineux dont la prise en charge nécessite la pose d'une compression par bandes   Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n8pansementlourdetcomplexe114951"
              value={generalInputs['n8pansementlourdetcomplexe114951']}
              label="8- Pansement lourd et complexe pour ulcère veineux dont la prise en charge nécessite la pose d'une compression par bandes   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n9pansementlourdetcomplexe120121"
              value={generalInputs['n9pansementlourdetcomplexe120121']}
              label="9 - Pansement lourd et complexe dont la réalisation des soins nécessite une analgésie topique préalable  Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n9pansementlourdetcomplexe333601"
              value={generalInputs['n9pansementlourdetcomplexe333601']}
              label="9 - Pansement lourd et complexe dont la réalisation des soins nécessite une analgésie topique préalable   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n10soinsdestomiesycpansem501041"
              value={generalInputs['n10soinsdestomiesycpansem501041']}
              label="10 - Soins de stomies (yc pansement)  Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n10soinsdestomiesycpansem404151"
              value={generalInputs['n10soinsdestomiesycpansem404151']}
              label="10 - Soins de stomies (yc pansement)   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n11aerosolmedicamenteuxnbr866851"
              value={generalInputs['n11aerosolmedicamenteuxnbr866851']}
              label="11- Aérosol médicamenteux   Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n11aerosolmedicamenteuxdur394511"
              value={generalInputs['n11aerosolmedicamenteuxdur394511']}
              label="11- Aérosol médicamenteux   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n12lavagedunsinusnbredac899871"
              value={generalInputs['n12lavagedunsinusnbredac899871']}
              label="12- Lavage d'un sinus   Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n12lavagedunsinusdureepr169981"
              value={generalInputs['n12lavagedunsinusdureepr169981']}
              label="12- Lavage d'un sinus   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n13pansementdetracheotomiey407651"
              value={generalInputs['n13pansementdetracheotomiey407651']}
              label="13- Pansement de trachéotomie, y compris l'aspiration et l'éventuel changement de canule ou sonde   Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n13pansementdetracheotomiey49251"
              value={generalInputs['n13pansementdetracheotomiey49251']}
              label="13- Pansement de trachéotomie, y compris l'aspiration et l'éventuel changement de canule ou sonde   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n14injectionvaginalenbred447571"
              value={generalInputs['n14injectionvaginalenbred447571']}
              label="14- Injection vaginale   Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n14injectionvaginaleduree468511"
              value={generalInputs['n14injectionvaginaleduree468511']}
              label="14- Injection vaginale   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n15posechangementetuipenien761161"
              value={generalInputs['n15posechangementetuipenien761161']}
              label="15- Pose/changement étui pénien   Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n15posechangementetuipenien693971"
              value={generalInputs['n15posechangementetuipenien693971']}
              label="15- Pose/changement étui pénien   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n16sondageurinaireposechang937791"
              value={generalInputs['n16sondageurinaireposechang937791']}
              label="16- Sondage urinaire (pose, changement, sondage intermittent)   Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n16sondageurinaireposechang576821"
              value={generalInputs['n16sondageurinaireposechang576821']}
              label="16- Sondage urinaire (pose, changement, sondage intermittent)   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n17instillationetoulavageve356011"
              value={generalInputs['n17instillationetoulavageve356011']}
              label="17- Instillation et/ou lavage vésical   Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n17instillationetoulavageve63651"
              value={generalInputs['n17instillationetoulavageve63651']}
              label="17- Instillation et/ou lavage vésical   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n18soinsdeboucheavecapplic147321"
              value={generalInputs['n18soinsdeboucheavecapplic147321']}
              label="18- Soins de bouche avec application de produit médicamenteux   Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n18soinsdeboucheavecapplic246031"
              value={generalInputs['n18soinsdeboucheavecapplic246031']}
              label="18- Soins de bouche avec application de produit médicamenteux    Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n19sondegastriqueposesurvei628751"
              value={generalInputs['n19sondegastriqueposesurvei628751']}
              label="19- Sonde gastrique (pose, surveillance, ablation)   Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n19sondegastriqueposesurvei457361"
              value={generalInputs['n19sondegastriqueposesurvei457361']}
              label="19- Sonde gastrique (pose, surveillance, ablation)   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n20seancedalimentationentera610461"
              value={generalInputs['n20seancedalimentationentera610461']}
              label="20- Séance d'alimentation entérale (y compris surveillance)"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n20seancedalimentationentera680401"
              value={generalInputs['n20seancedalimentationentera680401']}
              label="20- Séance d'alimentation entérale (y compris surveillance)   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n21lavementevacuateurnbre454241"
              value={generalInputs['n21lavementevacuateurnbre454241']}
              label="21- Lavement évacuateur   Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n21lavementevacuateurduree635541"
              value={generalInputs['n21lavementevacuateurduree635541']}
              label="21- Lavement évacuateur   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n22extractiondefecalomeoum713871"
              value={generalInputs['n22extractiondefecalomeoum713871']}
              label="22- Extraction de fécalome ou manuelle des selles   Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n22extractiondefecalomeoum522631"
              value={generalInputs['n22extractiondefecalomeoum522631']}
              label="22- Extraction de fécalome ou manuelle des selles   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n23irrigationcoliquecontinue337981"
              value={generalInputs['n23irrigationcoliquecontinue337981']}
              label="23- Irrigation colique continue/discontinue   Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n23irrigationcoliquecontinue100701"
              value={generalInputs['n23irrigationcoliquecontinue100701']}
              label="23- Irrigation colique continue/discontinue   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n24soinsdentretienduncathet541821"
              value={generalInputs['n24soinsdentretienduncathet541821']}
              label="24- Soins d'entretien d'un cathéter péritonéal   Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n24soinsdentretienduncathet209201"
              value={generalInputs['n24soinsdentretienduncathet209201']}
              label="24- Soins d'entretien d'un cathéter péritonéal   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n25soinsdentretienduncathet445191"
              value={generalInputs['n25soinsdentretienduncathet445191']}
              label="25- Soins d'entretien d'un cathéter extériorisé ou site implantable ou cathéter veineux central implanté par voie périphérique, y compris le pansement   Nbre d'actes par mois."
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n25soinsdentretienduncathet830571"
              value={generalInputs['n25soinsdentretienduncathet830571']}
              label="25- Soins d'entretien d'un cathéter extériorisé ou site implantable ou cathéter veineux central implanté par voie périphérique, y compris le pansement   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n26seancedeperfusion1heur529641"
              value={generalInputs['n26seancedeperfusion1heur529641']}
              label="26- Séance de perfusion ≤ 1 heure avec surveillance continue y compris retrait dispositif, pansement et tenue du dossier   Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n26seancedeperfusion1heur164461"
              value={generalInputs['n26seancedeperfusion1heur164461']}
              label="26- Séance de perfusion ≤ 1 heure avec surveillance continue y compris retrait dispositif, pansement et tenue du dossier   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n27seancedeperfusion1heur207771"
              value={generalInputs['n27seancedeperfusion1heur207771']}
              label="27- Séance de perfusion > 1 heure avec organisation, planification d'une surveillance y compris changement de flacon (s), retrait dispositif, pansement et tenue du dossier   Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n27seancedeperfusion1heur189651"
              value={generalInputs['n27seancedeperfusion1heur189651']}
              label="27- Séance de perfusion > 1 heure avec organisation, planification d'une surveillance y compris changement de flacon (s), retrait dispositif, pansement et tenue du dossier   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n28surveillanceobservationave483411"
              value={generalInputs['n28surveillanceobservationave483411']}
              label="28- Surveillance/observation avec tenue d'une fiche d'un patient diabétique insulino-traité avec adaptation des doses selon le protocole et la surveillance glycémique   Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n28surveillanceobservationave708361"
              value={generalInputs['n28surveillanceobservationave708361']}
              label="28- Surveillance/observation avec tenue d'une fiche d'un patient diabétique insulino-traité avec adaptation des doses selon le protocole et la surveillance glycémique   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n29organisationsurveillanceb782991"
              value={generalInputs['n29organisationsurveillanceb782991']}
              label="29- Organisation, surveillance, branchement/débranchement, y compris soins d'entretien du cathéter   Nbre d'actes par mois"
              onChange={onGeneralInputChange}
            />
            <Field
              type="number"
              name="n29organisationsurveillanceb857511"
              value={generalInputs['n29organisationsurveillanceb857511']}
              label="29- Organisation, surveillance, branchement/débranchement, y compris soins d'entretien du cathéter   Durée prévisible des soins (actes) en mois"
              onChange={onGeneralInputChange}
            />
          </div>
        </fieldset>
      </div>

      {editMode ? (
        <div className={classes.submitContainer}>
          <ProgressButton
            loading={loading}
            disabled={loadingImg}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            onClick={onSubmit}
          >
            {loadingImg
              ? 'Chargement des images en cours...'
              : type === 'update'
              ? 'Sauvegarder'
              : 'Créer'}
          </ProgressButton>
        </div>
      ) : null}
    </div>
  );
};

export default BsiCheckupForm;
