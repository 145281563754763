import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  makeStyles,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import moment from 'moment';
import { Link, useParams, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxHeight: 300,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },
}));

const CustomTransmissionsList = ({ transmissions, disabled }) => {
  const { userGroupId } = useParams();
  const { pathname } = useLocation();
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <List dense>
        {transmissions?.length
          ? transmissions.map((transmission) => {
              return (
                <ListItem
                  key={transmission.id}
                  button
                  component={Link}
                  to={`/g/${userGroupId}/transmission/${transmission.tourId}/${transmission.date}/${transmission.id}?back=${pathname}`}
                  disabled={disabled}
                >
                  <ListItemText>
                    {`${moment
                      .utc(Number(transmission.date))
                      .format('DD/MM/YYYY HH:mm')}${
                      transmission.type ? ` - ${transmission.type}` : ''
                    }${
                      transmission.comment ? ` - ${transmission.comment}` : ''
                    }`}
                  </ListItemText>
                  <ArrowRightIcon />
                </ListItem>
              );
            })
          : null}
      </List>
    </Paper>
  );
};

export default CustomTransmissionsList;
