import React, { useMemo } from 'react';
import moment from 'moment';

import ProgressButton from '../System/ProgressButton';
import useCareCheckupForm from './useCareCheckupForm';
import useStyles from './useStyles';
import Field from './CareCheckupField';
import Select from './CareCheckupSelect';
import MultiSelect from './CareCheckupMultiSelect';
import Bool from './CareCheckupBool';
import ImageUpload from '../System/ImageUpload';

const BipCheckupForm = ({
  initialCareCheckup,
  type,
  editMode,
  patient,
  createdBy,
  onSubmitted,
  parentClasses,
  printMode,
}) => {
  const _classes = useStyles();
  const classes = printMode ? parentClasses : _classes;

  const {
    loading,
    generalInputs,
    onGeneralInputChange,
    onPhotoPlaieUploadChange,
    onSubmit,
  } = useCareCheckupForm({
    initialCareCheckup,
    type,
    onSubmitted,
    checkupType: 'bip',
  });
  const loadingImg = useMemo(() => {
    return typeof generalInputs.photoPlaie?.progress !== 'undefined';
  }, [generalInputs]);

  return (
    <div className={classes.form}>
      <div className={classes.inputsContainer}>
        <fieldset className={classes.fieldset} disabled={!editMode}>
          <div>
            {createdBy ? (
              <Field
                disabled
                value={`${
                  createdBy.firstName
                } ${createdBy.lastName.toUpperCase()}`}
                label="Créé par"
              />
            ) : null}
            <Field
              disabled
              value={`${patient?.lastName.toUpperCase() ?? ''} ${
                patient?.firstName ?? ''
              }`}
              label="Patient"
            />

            <Field
              disabled
              value={
                (patient &&
                  moment([
                    patient.birthYear,
                    patient.birthMonth,
                    patient.birthDay,
                  ]).format('DD/MM/YYYY')) ||
                ''
              }
              label="Date de naissance"
            />

            <Field
              type="number"
              name="taillem"
              value={generalInputs['taillem']}
              label="Taille m"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <Field
              type="number"
              name="poidskg"
              value={generalInputs['poidskg']}
              label="Poids Kg"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <Field
              name="motif"
              value={generalInputs['motif']}
              label="Motif du bilan"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <Select
              name="prestataire"
              value={generalInputs['prestataire']}
              label="Prestataire"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            >
              {patient?.providers?.map((provider) => (
                <option key={provider.id} value={provider.id}>
                  {' '}
                  {`${provider.firstName || ''} ${provider.lastName}`}
                </option>
              ))}
              )
            </Select>

            <Field
              disabled
              value={
                (patient &&
                  patient.doctor &&
                  `${patient.doctor.firstName || ''} ${
                    patient.doctor.lastName
                  }`) ||
                ''
              }
              label="Médecin"
            />

            <Select
              name="histopec"
              value={generalInputs['histopec']}
              label="Histoire de la plaie et de sa prise en charge"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            >
              <option value="1">Plaie initiale</option>
              <option value="2">1ère récidive</option>
              <option value="3">Multi récidivante</option>
            </Select>

            <MultiSelect
              name="plaieaiguetrauma"
              value={generalInputs['plaieaiguetrauma']}
              label="Plaie aigüe traumatique"
              items={[
                { label: 'Brûlures', value: '1' },
                { label: 'Amputations', value: '2' },
                { label: 'Fistule digestive', value: '3' },
                { label: 'Perte de substance', value: '4' },
                { label: 'Méchage - irrigation', value: '5' },
                { label: 'Matériel ostéosynthèse extériorisé', value: '6' },
                { label: 'Radiothérapie', value: '7' },
              ]}
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <MultiSelect
              name="plaiechronique"
              value={generalInputs['plaiechronique']}
              label="Plaie Chronique"
              items={[
                { label: 'Ulcères', value: '1' },
                { label: 'Escarres', value: '2' },
                { label: 'Plaie diabétique', value: '3' },
              ]}
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <Bool
              name="prelevementcutane"
              value={generalInputs['prelevementcutane']}
              label="Prélèvements cutanés"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <Bool
              name="suivispe"
              value={generalInputs['suivispe']}
              label="Suivi spécialisé"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <Bool
              name="intolerancesconnues"
              value={generalInputs['intolerancesconnues']}
              label="Intolérances connues"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <Field
              name="intolerances"
              value={generalInputs['intolerances']}
              label="Intolérences Lesquels"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <Bool
              name="douleur"
              value={generalInputs['douleur']}
              label="Douleur"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <Select
              name="eva"
              value={generalInputs['eva']}
              label="Score EVA"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            >
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </Select>

            <MultiSelect
              name="examcompltreal"
              value={generalInputs['examcompltreal']}
              label="Examens complémentaires réalisés"
              items={[
                { label: 'Doppler', value: '1' },
                { label: 'Radio', value: '2' },
                { label: 'Biopsie', value: '3' },
                { label: 'Autre :', value: '4' },
              ]}
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <Field
              name="examcompltrealtxt"
              value={generalInputs['examcompltrealtxt']}
              label="Exam compl. réalisés : Lesquels"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <Bool
              name="atcdmedcognitifs"
              value={generalInputs['atcdmedcognitifs']}
              label="ATCD médicaux - Troubles cognitifs"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <MultiSelect
              name="atcdmedrisques"
              value={generalInputs['atcdmedrisques']}
              label="ATCD médicaux - Facteur de risques"
              items={[
                { label: 'Alcool', value: '1' },
                { label: 'Diabète', value: '2' },
                { label: 'Obésité', value: '3' },
                { label: 'Sédentarité :', value: '4' },
                { label: 'AOMI :', value: '5' },
                { label: 'Autre :', value: '6' },
              ]}
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <Field
              name="traitements"
              value={generalInputs['traitements']}
              label="Traitements"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <Field
              name="allergie"
              value={generalInputs['allergie']}
              label="Allergie"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <Field
              name="localisation"
              value={generalInputs['localisation']}
              label="Localisation"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <MultiSelect
              name="stade"
              value={generalInputs['stade']}
              label="Stade"
              items={[
                { label: 'Nécrose', value: '1' },
                { label: 'Fibrine', value: '2' },
                { label: 'Bourgeonnement', value: '3' },
                { label: 'Epidermisation :', value: '4' },
              ]}
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <Select
              name="litplaie"
              value={generalInputs['litplaie']}
              label="Lit de la plaie"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            >
              <option value="1">Saine</option>
              <option value="2">Atone</option>
              <option value="3">Hyperkératose</option>
              <option value="3">Sèche</option>
            </Select>

            <MultiSelect
              name="peauperilesion"
              value={generalInputs['peauperilesion']}
              label="Peau péri lésionnelle"
              items={[
                { label: 'Surélevés', value: '1' },
                { label: 'Atone', value: '2' },
                { label: 'Invaginés', value: '3' },
                { label: 'Erosion :', value: '4' },
              ]}
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <MultiSelect
              name="bords"
              value={generalInputs['bords']}
              label="Bords"
              items={[
                { label: 'Rougeur', value: '1' },
                { label: 'Douleur', value: '2' },
                { label: 'Chaleur', value: '3' },
                { label: 'Oedème :', value: '4' },
              ]}
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <Select
              name="signesinflammatoires"
              value={generalInputs['signesinflammatoires']}
              label="Signes inflammatoires"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            >
              <option value="1">Absence</option>
              <option value="2">Léger</option>
              <option value="3">Important</option>
              <option value="3">Très important</option>
            </Select>

            <MultiSelect
              name="exsudats"
              value={generalInputs['exsudats']}
              label="Exsudats"
              items={[
                { label: 'Odeur', value: '1' },
                { label: 'Séreux', value: '2' },
                { label: 'Séro sanglant', value: '3' },
                { label: 'Sanglant :', value: '4' },
                { label: 'Purulent :', value: '5' },
              ]}
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <Select
              name="contention"
              value={generalInputs['contention']}
              label="Contention"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            >
              <option value="1">Simple</option>
              <option value="2">Double</option>
            </Select>

            <Select
              name="chaussage"
              value={generalInputs['chaussage']}
              label="Chaussage"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            >
              <option value="1">Adapté</option>
              <option value="2">Inadapté</option>
            </Select>

            <Field
              name="conditionshygiene"
              value={generalInputs['conditionshygiene']}
              label="Conditions d’hygiène"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <Select
              name="marche"
              value={generalInputs['marche']}
              label="Marche"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            >
              <option value="1">Normale</option>
              <option value="2">Difficile</option>
              <option value="3">Impossible</option>
            </Select>

            <Bool
              name="education"
              value={generalInputs['education']}
              label="Education du patient"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <Bool
              name="compliant"
              value={generalInputs['compliant']}
              label="Patient compliant"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <Select
              name="retentissement"
              value={generalInputs['retentissement']}
              label="Retentissement"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            >
              <option value="1">Psychologique</option>
              <option value="2">Social</option>
            </Select>

            <Field
              name="retentissementautre"
              value={generalInputs['retentissementautre']}
              label="Retentissement - Autre"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <MultiSelect
              name="facteursretardcicatrisation"
              value={generalInputs['facteursretardcicatrisation']}
              label="Facteurs de retard de cicatrisation"
              items={[
                { label: 'Dénutrition', value: '1' },
                { label: 'Anémie', value: '2' },
                { label: 'Tabac', value: '3' },
                {
                  label: 'Anticoagulants–anti-agrégants plaquettaires :',
                  value: '4',
                },
                { label: 'Diabète', value: '6' },
                { label: 'Infection', value: '7' },
                { label: 'Insuffisance veineuse/artérielle', value: '8' },
                {
                  label: 'Intolérance au dispositif médical/Allergie',
                  value: '9',
                },
                { label: 'AOMI', value: '10' },
                { label: 'Autre', value: '11' },
              ]}
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <Field
              name="objectifsdesoin"
              value={generalInputs['objectifsdesoin']}
              label="Objectifs de soin"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <Field
              name="actions"
              value={generalInputs['actions']}
              label="Actions"
              onChange={onGeneralInputChange}
              disabled={!editMode}
            />

            <ImageUpload
              name="photoPlaie"
              title="Photo de la plaie"
              value={generalInputs.photoPlaie}
              editMode={editMode}
              emptyText="Aucune photo n'a été ajoutée"
              onChange={onPhotoPlaieUploadChange}
              onDelete={onGeneralInputChange}
              multiple={true}
              disabled={!editMode}
            />
          </div>
        </fieldset>
      </div>

      {editMode ? (
        <div className={classes.submitContainer}>
          <ProgressButton
            loading={loading}
            disabled={loadingImg}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            onClick={onSubmit}
          >
            {loadingImg
              ? 'Chargement des images en cours...'
              : type === 'update'
              ? 'Sauvegarder'
              : 'Créer'}
          </ProgressButton>
        </div>
      ) : null}
    </div>
  );
};

export default BipCheckupForm;
