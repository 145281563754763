import moment from 'moment';
import { MOMENTS } from '../config/global-config';

export const getMoment = (hour, minute) => {
  const time = moment(hour + ':' + minute, 'HH:mm');

  return MOMENTS.find((aMoment) => {
    const from = moment(aMoment.from, 'HH:mm');
    const to = moment(aMoment.to, 'HH:mm');

    return time.isSameOrAfter(from) && time.isSameOrBefore(to);
  });
};

export const getTimeMoment = (time) => {
  return MOMENTS.find((aMoment) => {
    const from = moment(aMoment.from, 'HH:mm');
    const to = moment(aMoment.to, 'HH:mm');

    return time.isSameOrAfter(from) && time.isSameOrBefore(to);
  });
};

export default getMoment;
