import { gql } from '@apollo/client';

import DoctorFragment from './DoctorFragment';
import PrescriptionFragment from './PrescriptionFragment';

const TransmissionBaseFragment = gql`
  fragment TransmissionBaseFragment on Transmission {
    id
    date
    tourId
    type
    doctor {
      ...DoctorFragment
    }
    prescription {
      ...PrescriptionFragment
    }
    treatmentId
    absence
    moment
    comment
    done {
      date
      latitude
      longitude
      accuracy
    }
    doneBy {
      id
      firstName
      lastName
    }
  }

  ${DoctorFragment}
  ${PrescriptionFragment}
`;

export default TransmissionBaseFragment;
