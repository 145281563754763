import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import useMessage from './useMessage';
import AppLayout from '../System/AppLayout';
import MessageForm from './MessageForm';
import SectionProgress from '../System/SectionProgress';
import useCurrentUser from '../System/useCurrentUser';
import DeleteButton from './DeleteButton';

const UpdateMessage = () => {
  const { userGroupId } = useParams();
  const { isAdmin, isSuperAdmin } = useCurrentUser();
  const { replace } = useHistory();

  // Message
  const { loading, message } = useMessage();

  // Edit mode
  const [editMode, setEditMode] = useState(false);
  const handleEdit = () => {
    setEditMode(true);
  };
  const handleStopEdit = (processed) => {
    setEditMode(false);
    if (processed) {
      replace(`/g/${userGroupId}/messages`);
    }
  };

  return (
    <AppLayout
      title={'Message'}
      direction="row"
      backTo={`/g/${userGroupId}/messages`}
      action={
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          {!editMode ? (
            <>
              {message ? (
                <>
                  {!message.processed ? (
                    <IconButton onClick={handleEdit}>
                      <EditIcon />
                    </IconButton>
                  ) : null}
                  {isAdmin || isSuperAdmin ? <DeleteButton /> : null}
                </>
              ) : null}
            </>
          ) : null}
        </Grid>
      }
    >
      {loading ? (
        <SectionProgress />
      ) : (
        <>
          <MessageForm
            initialMessage={message}
            type="update"
            editMode={editMode}
            onSubmitted={(processed) => handleStopEdit(processed)}
            isAdmin={isAdmin || isSuperAdmin}
          />
        </>
      )}
    </AppLayout>
  );
};

export default UpdateMessage;
