import React from 'react';
import { makeStyles, TextField } from '@material-ui/core';

import useCreateUserGroup from './useCreateUserGroup';
import AdminTopBar from '../System/AdminTopbar';
import ProgressButton from '../System/ProgressButton';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(2),
  },
  toursContainer: {
    margin: theme.spacing(2, 0),
  },
  submitButtonContainer: {
    marginTop: theme.spacing(2),
  },
  progress: {
    marginLeft: theme.spacing(2),
  },
  tourTitle: {
    marginTop: theme.spacing(4),
  },
}));

const CreateUserGroup = () => {
  const classes = useStyles();

  const { loading, inputs, onInputChange, onSubmit } = useCreateUserGroup();

  return (
    <>
      <AdminTopBar
        backTo="/admin/user-groups"
        title="Nouveau groupe utilisateur"
      />
      <div className={classes.container} noValidate autoComplete="off">
        <div>
          <TextField
            autoFocus
            name="name"
            label="Nom du groupe"
            value={inputs.name}
            variant="outlined"
            fullWidth
            onChange={onInputChange}
          />
        </div>

        <div className={classes.submitButtonContainer}>
          <ProgressButton
            loading={loading}
            disabled={loading}
            fullWidth
            variant="contained"
            color="primary"
            onClick={onSubmit}
          >
            Créer
          </ProgressButton>
        </div>
      </div>
    </>
  );
};

export default CreateUserGroup;
