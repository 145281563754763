import { gql, useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';

import { LIST_PATIENTS } from './usePatients';
import PatientFragment from '../../fragments/PatientFragment';
import cleanObject from '../../utils/clean-object';
import { sortPatients } from '../../utils/filters';
import useSnackbar from '../System/useSnackbar';

const CREATE_PATIENT = gql`
  mutation CreatePatient(
    $userGroupId: String!
    $firstName: String!
    $lastName: String!
    $useName: String
    $visitAddress: AddressInput!
    $homeAddress: AddressInput
    $addressExtra: String
    $comment: String
    $email1Name: String
    $email1Value: String
    $contact1: String
    $phones: [PhoneInput]!
    $birthDay: Int!
    $birthMonth: Int!
    $birthYear: Int!
    $preferredTourId: String
    $socialSecuNumber: String
    $socialSecuNumberRank: Int
    $vitaleCard: ImageInput
    $mutuelleCertificate: ImageInput
    $mutuelle: String
    $dmpINSC: String
    $dmpImage: ImageInput
    $dmpId: String
    $dmpPassword: String
    $doctorId: String
    $providersIds: [String]!
    $resources: [ResourceInput]!
  ) {
    createPatient(
      userGroupId: $userGroupId
      firstName: $firstName
      lastName: $lastName
      useName: $useName
      visitAddress: $visitAddress
      homeAddress: $homeAddress
      addressExtra: $addressExtra
      comment: $comment
      email1Name: $email1Name
      email1Value: $email1Value
      contact1: $contact1
      phones: $phones
      birthDay: $birthDay
      birthMonth: $birthMonth
      birthYear: $birthYear
      preferredTourId: $preferredTourId
      socialSecuNumber: $socialSecuNumber
      socialSecuNumberRank: $socialSecuNumberRank
      vitaleCard: $vitaleCard
      mutuelleCertificate: $mutuelleCertificate
      mutuelle: $mutuelle
      dmpINSC: $dmpINSC
      dmpImage: $dmpImage
      dmpId: $dmpId
      dmpPassword: $dmpPassword
      doctorId: $doctorId
      providersIds: $providersIds
      resources: $resources
    ) {
      ...PatientFragment
    }
  }

  ${PatientFragment}
`;

const useCreatePatient = ({ generalInputs, phones }) => {
  const { showSnackbar } = useSnackbar();

  const { replace } = useHistory();
  const { userGroupId } = useParams();
  const [createPatient, { loading, error }] = useMutation(CREATE_PATIENT, {
    update(cache, { data: { createPatient } }) {
      try {
        const { listPatients } = cache.readQuery({
          query: LIST_PATIENTS,
          variables: { userGroupId },
        });
        cache.writeQuery({
          query: LIST_PATIENTS,
          variables: { userGroupId },
          data: {
            listPatients: sortPatients([
              ...listPatients,
              {
                __typename: 'PatientSummary',
                id: createPatient.id,
                firstName: createPatient.firstName,
                lastName: createPatient.lastName,
                useName: createPatient.useName,
                visitAddress: createPatient.visitAddress,
                phones: createPatient.phones,
                archived: createPatient.archived,
              },
            ]),
          },
        });
      } catch (err) {}
    },
    onCompleted({ createPatient }) {
      replace(`/g/${userGroupId}/patient/update/${createPatient.id}`);
      showSnackbar('Patient créé avec succès.');
    },
  });

  const handleCreate = async () => {
    try {
      await createPatient({
        variables: cleanObject({
          userGroupId,
          firstName: generalInputs.firstName,
          lastName: generalInputs.lastName,
          useName: generalInputs.useName,
          visitAddress: generalInputs.visitAddress.formattedAddress
            ? generalInputs.visitAddress
            : null,
          homeAddress: generalInputs.homeAddress.formattedAddress
            ? generalInputs.homeAddress
            : null,
          addressExtra: generalInputs.addressExtra,
          comment: generalInputs.comment,
          email1Name: generalInputs.email1Name,
          email1Value: generalInputs.email1Value,
          contact1: generalInputs.contact1,
          phones,
          birthDay: generalInputs.birthDay,
          birthMonth: generalInputs.birthMonth,
          birthYear: generalInputs.birthYear,
          preferredTourId: generalInputs.preferredTourId,
          socialSecuNumber: generalInputs.socialSecuNumber,
          socialSecuNumberRank: generalInputs.socialSecuNumberRank,
          vitaleCard: { key: generalInputs.vitaleCard?.key },
          mutuelleCertificate: { key: generalInputs.mutuelleCertificate?.key },
          mutuelle: generalInputs.mutuelle,
          dmpINSC: generalInputs.dmpINSC,
          dmpImage: { key: generalInputs.dmpImage?.key },
          dmpId: generalInputs.dmpId,
          dmpPassword: generalInputs.dmpPassword,
          doctorId: generalInputs.doctor?.id,
          providersIds: generalInputs.providers.map((p) => p.id),
          resources: generalInputs.resources.map((resource) => {
            return {
              key: resource.key || resource.addedKey,
              size: resource.size,
              label: resource.label,
              description: resource.description,
            };
          }),
        }),
      });
    } catch (err) {
      // Do nothing, let Apollo useMutation handle errors
    }
  };

  return { loading, error, onCreate: handleCreate };
};

export default useCreatePatient;
export { CREATE_PATIENT };
