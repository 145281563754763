import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import TreatmentFragment from '../../fragments/TreatmentFragment';
import useSnackbar from '../System/useSnackbar';

const GENERATE_TRANSMISSIONS = gql`
  mutation GenerateTransmissions(
    $userGroupId: String!
    $prescriptionId: String!
    $treatmentId: String!
    $deleteExisting: Boolean
  ) {
    generateTransmissions(
      userGroupId: $userGroupId
      prescriptionId: $prescriptionId
      treatmentId: $treatmentId
      deleteExisting: $deleteExisting
    ) {
      ...TreatmentFragment
    }
  }

  ${TreatmentFragment}
`;

const useGenerateTransmissions = ({ treatments, onGenerated } = {}) => {
  const { userGroupId, prescriptionId } = useParams();

  const { showSnackbar } = useSnackbar();
  const [generateTransmissions, { loading: generateLoading }] = useMutation(
    GENERATE_TRANSMISSIONS,
    {
      onCompleted() {
        showSnackbar('Transmissions générées');
        onGenerated && onGenerated();
      },
    },
  );

  const handleGenerateTransmissions = async ({
    treatmentId,
    forcedPrescriptionId,
    noDelete,
  }) => {
    await generateTransmissions({
      variables: {
        userGroupId,
        prescriptionId: forcedPrescriptionId || prescriptionId,
        treatmentId: treatmentId,
        deleteExisting: !noDelete,
      },
    });
  };

  const handleGenerate = async ({
    prescriptionId: forcedPrescriptionId,
    treatments: forcedTreatments,
    noDelete,
  } = {}) => {
    const promises = (forcedTreatments || treatments).map((treatment) => {
      return handleGenerateTransmissions({
        treatmentId: treatment.id,
        forcedPrescriptionId,
        noDelete,
      });
    });
    let flag;

    try {
      await Promise.all(promises);
      flag = true;
    } catch (err) {
      err.graphQLErrors.forEach((error) => {
        if (
          error.message === 'generated transmissions number must not exceed 366'
        ) {
          showSnackbar(
            'Erreur : vous ne pouvez pas générer plus de 366 rendez-vous',
            10000,
            'error',
          );
        }

        flag = false;
      });
    }

    return flag;
  };

  return {
    loading: generateLoading,
    onGenerate: handleGenerate,
  };
};

export default useGenerateTransmissions;
