import { gql, useMutation } from '@apollo/client';

import MessageFragment from '../../fragments/MessageFragment';
import { useParams } from 'react-router-dom';
import { LIST_MESSAGES } from './useMessages';

const PROCESS_MESSAGE = gql`
  mutation ProcessMessage($userGroupId: String!, $id: String!) {
    processMessage(userGroupId: $userGroupId, id: $id) {
      ...MessageFragment
    }
  }

  ${MessageFragment}
`;

const useProcessMessage = () => {
  const { userGroupId, messageId } = useParams();

  const [processMessage, { loading }] = useMutation(PROCESS_MESSAGE, {
    variables: {
      userGroupId,
      id: messageId,
    },
    update(cache) {
      // Remove from unprocessed list
      try {
        const { listMessages } = cache.readQuery({
          query: LIST_MESSAGES,
          variables: { userGroupId, processed: false },
        });
        cache.writeQuery({
          query: LIST_MESSAGES,
          variables: { userGroupId, processed: false },
          data: {
            listMessages: {
              ...listMessages,
              data: listMessages.data.filter((m) => m.id !== messageId),
            },
          },
        });
      } catch (err) {}
    },
  });

  return {
    onProcess: processMessage,
    loading,
  };
};

export default useProcessMessage;
