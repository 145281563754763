import { gql, useMutation } from '@apollo/client';

import { useParams } from 'react-router-dom';

const MARK_ABSENCES_PROCESSED = gql`
  mutation MarkAbsencesProcessed(
    $userGroupId: String!
    $absenceIds: [String!]!
  ) {
    markAbsencesProcessed(userGroupId: $userGroupId, absenceIds: $absenceIds) {
      id
      startDate
      startMoment
      endDate
      endMoment
      patientId
      processed
    }
  }
`;

const useMarkAbsencesProcessed = (absenceIds) => {
  const { userGroupId } = useParams();
  const [markAbsencesProcessed, { loading }] = useMutation(
    MARK_ABSENCES_PROCESSED,
    {
      variables: {
        userGroupId,
        absenceIds,
      },
    },
  );

  return {
    onMarkAbsencesProcessed: markAbsencesProcessed,
    loading,
  };
};

export default useMarkAbsencesProcessed;
