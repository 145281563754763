import { useState } from 'react';

const useForm = ({ initialValues }) => {
  const [inputs, setInputs] = useState(initialValues);
  const handleInputChange = (event) => {
    const { name, value, checked, type } = event.target;

    setInputs((prev) => ({ ...prev, [name]: type === 'checkbox' ? checked : value }));
  };

  return { inputs, onInputChange: handleInputChange };
};

export default useForm;
