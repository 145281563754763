import { gql } from '@apollo/client';

const TransmissionSummaryBaseFragment = gql`
  fragment TransmissionSummaryBaseFragment on TransmissionSummary {
    id
    date
    tourId
    treatmentId
    type
    absence
    done {
      date
      latitude
      longitude
      accuracy
    }
    doneBy {
      id
      firstName
      lastName
    }
    lastDate
    prescriptionPhotoMissing
    comment
  }
`;

export default TransmissionSummaryBaseFragment;
