import React from 'react';
import { useParams } from 'react-router-dom';

import AppLayout from '../System/AppLayout';
import PatientForm from './PatientForm';
import useTours from '../Tours/useTours';

const CreatePatient = () => {
  const { userGroupId } = useParams();
  const { tours, loading } = useTours();

  return (
    <AppLayout title="Nouveau patient" backTo={`/g/${userGroupId}/patients`}>
      {loading ? null : (
        <PatientForm tours={tours} type="create" autoFocus editMode />
      )}
    </AppLayout>
  );
};

export default CreatePatient;
