import React from 'react';
import moment from 'moment';
import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Link, useLocation, useParams } from 'react-router-dom';
import clsx from 'clsx';

import { MOMENTS } from '../System/MomentSelect';
import { isInProgress } from '../Absence/libs/absence-utils';
import { urlWithBacks } from '../../utils/back-search';

const useStyles = makeStyles((theme) => ({
  absenceInProgress: {
    color: '#28bcff',
  },
  paper: {
    maxHeight: 300,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },
}));

const AbsencesList = ({ absences, disabled }) => {
  const { userGroupId, patientId } = useParams();
  const classes = useStyles();
  const { pathname } = useLocation();

  return (
    <Paper className={classes.paper}>
      <List disablePadding dense>
        {absences.length ? (
          absences.map(({ id, startDate, startMoment, endDate, endMoment }) => {
            const hasEndDate =
              Number(endDate) <
              moment.utc('2100-01-01T00:00:00.000Z').valueOf();
            const inProgress = isInProgress({ startDate, endDate });

            return (
              <ListItem
                key={id}
                button
                component={Link}
                to={urlWithBacks({
                  url: `/g/${userGroupId}/absence/update/${patientId}/${startDate}/${id}`,
                  back: pathname,
                })}
                disabled={disabled}
              >
                <ListItemText
                  className={clsx(inProgress && classes.absenceInProgress)}
                >
                  {hasEndDate ? 'Du ' : 'À partir du '}
                  {moment.utc(Number(startDate)).format('DD/MM/YYYY')}{' '}
                  {MOMENTS.find(
                    (m) => m.value === startMoment,
                  ).label.toLowerCase()}
                  {hasEndDate
                    ? ` au ${moment.utc(Number(endDate)).format('DD/MM/YYYY')}`
                    : ''}{' '}
                  {hasEndDate
                    ? MOMENTS.find(
                        (m) => m.value === endMoment,
                      ).label.toLowerCase()
                    : null}
                </ListItemText>
                <ArrowRightIcon />
              </ListItem>
            );
          })
        ) : (
          <ListItem>
            <ListItemText>Aucune absence</ListItemText>
          </ListItem>
        )}
      </List>
    </Paper>
  );
};

export default AbsencesList;
