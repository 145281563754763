import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import NumberFormat from 'react-number-format';

import TextField from '../System/TextField';
import ProgressButton from '../System/ProgressButton';
import useSnackbar from '../System/useSnackbar';
import { getType, getTypes } from '../../utils/get-constant-type';
import SelectDateTimePicker from '../System/SelectDateTimePicker';

const useStyles = makeStyles((theme) => ({
  timeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  timeSep: {
    margin: theme.spacing(0, 1),
  },
  textField: {},
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, name, ...other } = props;
  const type = getType(name.split('.').pop());

  return (
    <NumberFormat
      {...other}
      allowNegative={false}
      decimalScale={type?.digits || 0}
      isNumericString={true}
      decimalSeparator={','}
      getInputRef={inputRef}
      inputMode={type?.digits ? 'decimal' : 'numeric'}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
    />
  );
}

const hasError = (name, value) => {
  const type = getType(name);
  const min = type.min;
  const max = type.max;
  const num = value && Number(value);
  const flag =
    num === undefined ||
    num === null ||
    (min === undefined && max === undefined) ||
    (min !== undefined && max !== undefined && num >= min && num <= max) ||
    (min !== undefined && max === undefined && num >= min) ||
    (max !== undefined && min === undefined && num <= max);

  return !flag;
};

const ConstantForm = ({
  inputs,
  loading,
  open,
  onClose,
  onInputChange,
  onSubmit,
  onDelete,
  error,
}) => {
  const [canSave, setCanSave] = useState(false);
  const [errors, setErrors] = useState({});
  const classes = useStyles();

  const { showSnackbar } = useSnackbar();
  const handleDateChange = (date) => {
    const now = new Date();
    const newDate = moment(date);

    if (newDate.isSameOrBefore(moment(now))) {
      onInputChange &&
        onInputChange({
          target: { name: 'date', value: newDate.utc().valueOf() },
        });
    } else {
      showSnackbar('Vous ne pouvez saisir une constante dans le futur');
    }
  };
  const values = useMemo(() => {
    return inputs?.datas || [];
  }, [inputs]);
  const date = useMemo(() => {
    return inputs?.date
      ? moment.utc(Number(inputs?.date)).valueOf()
      : new Date();
  }, [inputs]);

  useEffect(() => {
    setCanSave(
      Object.entries(errors).find(([key, value]) => value) === undefined,
    );
  }, [errors]);

  useEffect(() => {
    let theErrors = {};
    getTypes().forEach((type) => {
      const value = values.find((data) => data.name === type.key)?.number;
      theErrors = { ...theErrors, [type.key]: hasError(type.key, value) };
    });
    setErrors(theErrors);
  }, [values]);

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Création d'une constante</DialogTitle>
        <DialogContent>
          <SelectDateTimePicker
            label="Date"
            value={date}
            onChange={handleDateChange}
            readOnly={false}
            disableFuture
          />

          <div>
            {getTypes().map((type) => {
              const value = values.find(
                (data) => data.name === type.key,
              )?.number;

              return (
                <TextField
                  key={type.key}
                  //type={type.dataType || 'number'}
                  className={classes.textField}
                  name={`datas.${type.key}`}
                  multiline
                  value={value || ''}
                  label={type.name}
                  onChange={onInputChange}
                  InputProps={
                    type.min || type.max
                      ? {
                          inputComponent: NumberFormatCustom,
                        }
                      : {}
                  }
                  error={errors[type.key]}
                  helperText={`${type.unit ? type.unit : ''}
                    ${
                      type.min !== undefined || type.max !== undefined
                        ? ' ('
                        : ' '
                    }
                    ${type.min !== undefined ? ' min:' + type.min : ''}
                    ${type.max !== undefined ? ' max:' + type.max : ''}
                    ${
                      type.min !== undefined || type.max !== undefined
                        ? ')'
                        : ''
                    }`}
                />
              );
            })}
          </div>

          {error?.message ? (
            <Typography color="error">
              Une erreur s'est produite : {error.message}
            </Typography>
          ) : null}
        </DialogContent>

        <DialogActions>
          <div>
            <Button variant="outlined" onClick={onClose}>
              Annuler
            </Button>
          </div>
          {onDelete ? (
            <div>
              <ProgressButton
                loading={loading}
                variant="outlined"
                color="secondary"
                onClick={onDelete}
              >
                Supprimer
              </ProgressButton>
            </div>
          ) : null}
          <div>
            <ProgressButton
              loading={loading}
              variant="outlined"
              color="primary"
              onClick={onSubmit}
              disabled={!canSave}
            >
              Sauvegarder
            </ProgressButton>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConstantForm;
