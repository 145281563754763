import { gql, useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';

import { LIST_PROVIDERS } from './useProviders';
import ProviderFragment from '../../fragments/ProviderFragment';
import cleanObject from '../../utils/clean-object';
import useSnackbar from '../System/useSnackbar';
import { sortProviders } from '../../utils/filters';

const CREATE_PROVIDER = gql`
  mutation CreateProvider(
    $userGroupId: String!
    $firstName: String!
    $lastName: String!
    $phones: [PhoneInput]!
    $email1Name: String
    $email1Value: String
    $address: AddressInput
    $adeli: String
    $siret: String
    $speciality: String
    $unlockCode: String
    $RIB: String
    $CPS: ImageInput
  ) {
    createProvider(
      userGroupId: $userGroupId
      firstName: $firstName
      lastName: $lastName
      phones: $phones
      email1Name: $email1Name
      email1Value: $email1Value
      address: $address
      adeli: $adeli
      siret: $siret
      speciality: $speciality
      unlockCode: $unlockCode
      RIB: $RIB
      CPS: $CPS
    ) {
      ...ProviderFragment
    }
  }

  ${ProviderFragment}
`;

const useCreateProvider = ({ generalInputs, phones }) => {
  const { showSnackbar } = useSnackbar();

  const { replace } = useHistory();
  const { userGroupId } = useParams();

  const cleanedVariables = cleanObject({
    userGroupId,
    firstName: generalInputs.firstName,
    lastName: generalInputs.lastName,
    phones,
    email1Name: generalInputs.email1Name,
    email1Value: generalInputs.email1Value,
    address: generalInputs.address,
    adeli: generalInputs.adeli,
    siret: generalInputs.siret,
    speciality: generalInputs.speciality,
    unlockCode: generalInputs.unlockCode,
    RIB: generalInputs.RIB,
    CPS: { key: generalInputs.CPS?.key },
  });

  const [createProvider, { loading }] = useMutation(CREATE_PROVIDER, {
    variables: {
      ...cleanedVariables,
      firstName: cleanedVariables.firstName || '',
    },
    update(cache, { data: { createProvider } }) {
      try {
        const { listProviders } = cache.readQuery({
          query: LIST_PROVIDERS,
          variables: { userGroupId },
        });
        cache.writeQuery({
          query: LIST_PROVIDERS,
          variables: { userGroupId },
          data: {
            listProviders: sortProviders([
              ...listProviders,
              { ...createProvider, __typename: 'ProviderSummary' },
            ]),
          },
        });
      } catch (err) { }
    },
    onCompleted({ createProvider }) {
      replace(
        `/g/${userGroupId}/provider/update/${createProvider.id}/${createProvider.lastName}/${createProvider.firstName}`,
      );
      showSnackbar('Prestaire créé avec succès.');
    },
  });

  return { loading, onCreate: createProvider };
};

export default useCreateProvider;
export { CREATE_PROVIDER };