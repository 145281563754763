import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';

import AppLayout from '../System/AppLayout';
import CareCheckups from './CareCheckups';
import SearchField from '../System/SearchField';
import { Store } from '../../utils/store';
import { T } from '../../utils/reducers';

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: 'auto',
    minWidth: '125px',
    maxWidth: '300px',
  },
}));

const CareCheckupsPage = () => {
  const classes = useStyles();
  const { state, dispatch } = useContext(Store);
  const setSearch = (payload) =>
    dispatch({ type: T.listCareCheckupSearch, payload });
  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  return (
    <AppLayout
      title="Bilans"
      action={
        <div className={classes.searchContainer}>
          <SearchField
            value={state.listCareCheckupSearch}
            onChange={handleSearchChange}
          />
        </div>
      }
    >
      <CareCheckups search={state.listCareCheckupSearch} />
    </AppLayout>
  );
};

export default CareCheckupsPage;
