import React, { useContext } from 'react';
import {
  Fab,
  makeStyles,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import MenuIcon from '@material-ui/icons/MoreVert';
import Papa from 'papaparse';
import { useMutation } from '@apollo/client';

import AppLayout from '../System/AppLayout';
import SearchField from '../System/SearchField';
import Providers from './Providers';
import ExportCsv from '../System/export-csv';
import { useCompleteProviders, LIST_PROVIDERS } from './useProviders';
import { CREATE_PROVIDER } from './useCreateProvider';
import useMenu from '../System/useMenu';
import useRoles, { isSuperAdmin } from '../System/useRoles';
import UploadButton from '../System/UploadButton';
import cleanObject from '../../utils/clean-object';
import { sortProviders } from '../../utils/filters';
import useNetworkStatus from '../System/useNetworkStatus';
import { Store } from '../../utils/store';
import { T } from '../../utils/reducers';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: 'auto',
    minWidth: '125px',
    maxWidth: '300px',
  },
}));

const handleUpload = (event, createProvider, userGroupId) => {
  const { files } = event.target;

  if (files && files.length) {
    const file = files[0];
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        const csv = Papa.parse(reader.result, {});

        csv.data.forEach((element) => {
          if (element.length === 13 && element[1]?.length > 0) {
            const phones = [];
            if (element[7]) {
              phones.push({ name: element[6], number: element[7] });
            }
            if (element[9]) {
              phones.push({ name: element[8], number: element[9] });
            }
            const newProvider = {
              ...cleanObject({
                userGroupId,
                lastName: element[1],
                address: element[3] ? { formattedAddress: element[3] } : {},
                email1Name: element[4],
                email1Value: element[5],
                phones: phones,
                adeli: element[10],
                siret: element[11],
                speciality: element[12],
              }),
              firstName: element[2] || '',
            };

            try {
              createProvider({
                variables: newProvider,
                update(cache, { data: { createProvider } }) {
                  const { listProviders } = cache.readQuery({
                    query: LIST_PROVIDERS,
                    variables: { userGroupId },
                  });
                  cache.writeQuery({
                    query: LIST_PROVIDERS,
                    variables: { userGroupId },
                    data: {
                      listProviders: sortProviders([
                        ...listProviders,
                        { ...createProvider, __typename: 'ProviderSummary' },
                      ]),
                    },
                  });
                },
              });
            } catch (err) {
              console.log(err);
            }
          } else {
            console.log('Bad import data: ', element);
          }
        });
      },
      false,
    );

    if (file) {
      reader.readAsText(file);
    }
  }
};

const ProvidersPage = () => {
  const { readOnly } = useNetworkStatus();
  const classes = useStyles();
  const { userGroupId } = useParams();
  const { state, dispatch } = useContext(Store);

  const setSearch = (payload) =>
    dispatch({ type: T.listProvidersSearch, payload });
  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };
  const { loading, providers } = useCompleteProviders();
  const fileName = 'providers.csv';
  const headers = [
    { label: 'Numéro', key: 'id' },
    { label: 'Nom', key: 'lastName' },
    { label: 'Prénom', key: 'firstName' },
    { label: 'Adresse', key: 'address.formattedAddress' },
    { label: 'Nom E-mail', key: 'email1Name' },
    { label: 'E-mail', key: 'email1Value' },
    { label: 'Nom téléphones 1', key: 'phones[0].name' },
    { label: 'Numéro téléphones 1', key: 'phones[0].number' },
    { label: 'Nom téléphones 2', key: 'phones[1].name' },
    { label: 'Numéro téléphones 2', key: 'phones[1].number' },
    { label: 'Num ADELI', key: 'adeli' },
    { label: 'Num Siret', key: 'siret' },
    { label: 'Spécialité', key: 'speciality' },
  ];
  const { roles } = useRoles();
  const { anchorEl, onClick: onMenuClick, onClose: onMenuClose } = useMenu();
  const [createProvider] = useMutation(CREATE_PROVIDER);

  return (
    <AppLayout
      title="Prestataires"
      action={
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs>
            <div className={classes.searchContainer}>
              <SearchField
                value={state.listProvidersSearch}
                onChange={handleSearchChange}
              />
            </div>
          </Grid>
          {isSuperAdmin(roles) && !readOnly ? (
            <Grid item>
              <IconButton onClick={onMenuClick}>
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={onMenuClose}
              >
                {providers?.length ? (
                  <MenuItem>
                    {loading ? (
                      <CircularProgress size={12} color="inherit" />
                    ) : (
                      <ExportCsv
                        data={providers}
                        headers={headers}
                        filename={fileName}
                        title={'Exporter les prestataires'}
                      />
                    )}
                  </MenuItem>
                ) : null}
                <MenuItem>
                  <UploadButton
                    component="link"
                    onChange={(e) =>
                      handleUpload(e, createProvider, userGroupId)
                    }
                    accept=".csv"
                  >
                    Importer des prestataires
                  </UploadButton>
                </MenuItem>
              </Menu>
            </Grid>
          ) : null}
        </Grid>
      }
    >
      <Providers search={state.listProvidersSearch} />

      <Fab
        className={classes.fab}
        component={Link}
        to={`/g/${userGroupId}/provider/create`}
        color="primary"
        disabled={readOnly}
      >
        <AddIcon />
      </Fab>
    </AppLayout>
  );
};

export default ProvidersPage;
