import React from 'react';
import { useParams } from 'react-router-dom';
import usePatient from '../Patient/usePatient';

import AppLayout from '../System/AppLayout';
import BsiCheckup2Form from './BsiCheckup2Form';
import BipCheckupForm from './BipCheckupForm';
import { getType } from '../../utils/get-care-checkup-type';

const CreateCareCheckup = () => {
  const { userGroupId, type } = useParams();
  const { patient } = usePatient();

  return (
    <AppLayout
      title={`Nouveau ${getType(type)}`}
      backTo={`/g/${userGroupId}/patient/update/${patient.id}`}
    >
      {type === 'bsi2' ? (
        <BsiCheckup2Form type="create" patient={patient} autoFocus editMode />
      ) : type === 'bip' ? (
        <BipCheckupForm type="create" patient={patient} autoFocus editMode />
      ) : null}
    </AppLayout>
  );
};

export default CreateCareCheckup;
