import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useState } from 'react';

import TransmissionFragment from '../../fragments/TransmissionFragment';
import useSnackbar from '../System/useSnackbar';

const UPDATE_DONE_TRANSMISSION = gql`
  mutation UpdateTransmission(
    $userGroupId: String!
    $tourId: String!
    $year: String!
    $id: String!
    $done: DoneStatusInput
  ) {
    updateDoneTransmission(
      userGroupId: $userGroupId
      tourId: $tourId
      year: $year
      id: $id
      done: $done
    ) {
      ...TransmissionFragment
    }
  }

  ${TransmissionFragment}
`;

const useDone = () => {
  const { userGroupId, tourId, date, transmissionId } = useParams();

  const { showSnackbar } = useSnackbar();
  const [updateDoneTransmission] = useMutation(UPDATE_DONE_TRANSMISSION, {
    onCompleted() {
      showSnackbar('Transmission terminée.');
    },
  });

  const [loading, setLoading] = useState(false);
  const handleDone = async () => {
    setLoading(true);

    let coords = {};
    //try {
    //  const result = await getPosition();
    //  coords = result.coords;
    //} catch (err) { }

    try {
      const { latitude, longitude, accuracy } = coords;

      await updateDoneTransmission({
        variables: {
          userGroupId,
          tourId,
          year: moment(Number(date)).format('YYYY'),
          id: transmissionId,
          done:
            latitude && longitude && accuracy
              ? {
                  latitude,
                  longitude,
                  accuracy: Math.round(accuracy),
                }
              : undefined,
        },
      });
    } catch (err) {}
    setLoading(false);
  };

  return {
    loading,
    geolocationAvaialble: Boolean(navigator?.geolocation),
    onDone: handleDone,
  };
};

export default useDone;
