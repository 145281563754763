import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import { filterProviders } from '../../utils/filters';
import ProviderFragment from '../../fragments/ProviderFragment';
import ProviderSummaryFragment from '../../fragments/ProviderSummaryFragment';

const LIST_PROVIDERS = gql`
  query ListProviders($userGroupId: String!) {
    listProviders(userGroupId: $userGroupId) {
      ...ProviderSummaryFragment
    }
  }

  ${ProviderSummaryFragment}
`;

const LIST_COMPLETE_PROVIDERS = gql`
  query ListCompleteProviders($userGroupId: String!) {
    listCompleteProviders(userGroupId: $userGroupId) {
      ...ProviderFragment
    }
  }

  ${ProviderFragment}
`;

const useProviders = (search) => {
  const { userGroupId } = useParams();
  const { loading, data } = useQuery(LIST_PROVIDERS, {
    variables: {
      userGroupId,
    }
  });

  const providers = useMemo(() => {
    return data && search
      ? filterProviders(data?.listProviders, search)
      : data?.listProviders;
  }, [data, search]);

  return {
    loading,
    providers,
  };
};

const useCompleteProviders = () => {
  const { userGroupId } = useParams();
  const { loading, data } = useQuery(LIST_COMPLETE_PROVIDERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      userGroupId,
    }
  });

  const providers = useMemo(() => {
    return data?.listCompleteProviders
      ? data?.listCompleteProviders.map(provider => {
        return {
          ...provider,
          address: provider.address ? provider.address : {}
        }
      })
      : null;
  }, [data]);

  return {
    loading,
    providers,
  };
};

export default useProviders;
export { useCompleteProviders, LIST_PROVIDERS, LIST_COMPLETE_PROVIDERS };
