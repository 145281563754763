import { gql } from '@apollo/client';

const OutsourcedTreatmentFragment = gql`
  fragment OutsourcedTreatmentFragment on OutsourcedTreatment {
    id
    fromTour
    toTour
    from
    to
  }
`;

export default OutsourcedTreatmentFragment;
