import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import UserGroupFragment from '../../fragments/UserGroupFragment';
import useUpload from '../../utils/useUpload';
import { GET_TOURS } from '../Tours/useTours';
import cleanSignedUrl from '../../utils/clean-signed-url';
import { sortTours } from '../../utils/filters';

const UPDATE_USER_GROUP_TOUR = gql`
  mutation UpdateUserGroupTour(
    $userGroupId: String!
    $tourId: String!
    $name: String!
    $image: ImageInput
    $disabled: Boolean
  ) {
    updateUserGroupTour(
      userGroupId: $userGroupId
      tourId: $tourId
      name: $name
      image: $image
      disabled: $disabled
    ) {
      ...UserGroupFragment
    }
  }

  ${UserGroupFragment}
`;

const useUpdateTourDialog = ({ tour }) => {
  const { userGroupId } = useParams();
  const [open, setOpen] = useState(false);

  const [name, setName] = useState(tour.name);
  const handleChange = (event) => {
    const { value } = event.target;
    setName(value);
  };

  const [image, setImage] = useState(tour.image);
  const { progress, onChange: handleImageUploadChange } = useUpload({
    name: 'image',
    onUploaded: (event) => {
      const { value } = event.target;
      setImage(value);
    },
  });
  const handleDeleteImage = () => {
    setImage(null);
  };

  const [updateUserGroupTour, { loading }] = useMutation(
    UPDATE_USER_GROUP_TOUR,
    {
      variables: {
        userGroupId,
        tourId: tour.id,
        name,
        image: image?.key && { key: cleanSignedUrl(image?.key) },
        disabled: !!tour.disabled,
      },
      update(cache, { data: { updateUserGroupTour } }) {
        try {
          cache.writeQuery({
            query: GET_TOURS,
            variables: { userGroupId },
            data: {
              getTours: sortTours(updateUserGroupTour.tours.filter((t) => !t.disabled)),
            },
          });
        } catch (err) { }
      },
      onCompleted() {
        setOpen(false);
      },
    },
  );

  return {
    loading,
    name,
    open,
    image,
    imageProgress: progress,
    onImageUploadChange: handleImageUploadChange,
    onDeleteImage: handleDeleteImage,
    onChange: handleChange,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    onSubmit: updateUserGroupTour,
  };
};

export default useUpdateTourDialog;
