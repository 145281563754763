import { useContext } from 'react';

import { SnackbarContext } from './SnackbarProvider';

const useSnackbar = () => {
  const { showSnackbar } = useContext(SnackbarContext);

  return { showSnackbar };
};

export default useSnackbar;
