import { gql } from '@apollo/client';

const ResourceFragment = gql`
  fragment ResourceFragment on Resource {
    key
    size
    label
    description
  }
`;

export default ResourceFragment;
