import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { GET_NETWORK_STATUS } from '../../utils/useApollo';
import useCurrentUser from './useCurrentUser';

const IS_USER_GROUP_ARCHIVED = gql`
  query IsUserGroupArchived($id: String!) {
    isUserGroupArchived(id: $id)
  }
`;

const useNetworkStatus = () => {
  const { userGroupId } = useParams();
  const { data: netWorkStatus } = useQuery(GET_NETWORK_STATUS);
  const { loading, data: dataUserGroup } = useQuery(IS_USER_GROUP_ARCHIVED, {
    variables: { id: userGroupId },
  });
  const { isSuperAdmin } = useCurrentUser();

  return {
    offline: netWorkStatus?.offline || false,
    readOnly:
      !isSuperAdmin &&
      (netWorkStatus?.offline ||
        loading ||
        dataUserGroup?.isUserGroupArchived ||
        false),
  };
};

export default useNetworkStatus;
