import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import TransmissionSummaryBaseFragment from '../../fragments/TransmissionSummaryBaseFragment';

const LIST_TRANSMISSIONS_BY_TREATMENT = gql`
  query ListTransmissionsByTreatment(
    $userGroupId: String!
    $treatmentId: String!
  ) {
    listTransmissionsByTreatment(
      userGroupId: $userGroupId
      treatmentId: $treatmentId
    ) {
      ...TransmissionSummaryBaseFragment
    }
  }

  ${TransmissionSummaryBaseFragment}
`;

const useTreatmentTransmissions = (forcedTreatmentId) => {
  const { userGroupId, treatmentId } = useParams();

  return useTransmissionsForTreatment(
    userGroupId,
    forcedTreatmentId || treatmentId,
  );
};

const useTransmissionsForTreatment = (userGroupId, treatmentId) => {
  const { loading, data } = useQuery(LIST_TRANSMISSIONS_BY_TREATMENT, {
    // Fetch policy cache-and-network because it is too complicated to know wich queries with
    // which variables are cached when we need to update cache
    // after some mutations (eg: outsource a treatment to another tour)
    fetchPolicy: 'cache-and-network',
    variables: {
      userGroupId,
      treatmentId,
    },
  });

  return {
    transmissions: data?.listTransmissionsByTreatment,
    loading: loading,
  };
};

export default useTreatmentTransmissions;
export { useTransmissionsForTreatment, LIST_TRANSMISSIONS_BY_TREATMENT };
