import React, { useContext, useMemo } from 'react';
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  makeStyles,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/MoreVert';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

import SearchField from '../System/SearchField';
import AppLayout from '../System/AppLayout';
import usePrescriptions from './usePrescriptions';
import useImportPrescriptions from './useImportPrescriptions';
import useTours from '../Tours/useTours';
import ProgressButton from '../System/ProgressButton';
import { MOMENTS } from '../System/MomentSelect';
import { filterPatients, sortDoctor, sortPatient } from '../../utils/filters';
import normalize from '../../utils/normalize';
import { Store } from '../../utils/store';
import { T } from '../../utils/reducers';
import useCurrentUser from '../System/useCurrentUser';
import useNetworkStatus from '../System/useNetworkStatus';
import useMenu from '../System/useMenu';
import UploadButton from '../System/UploadButton';

const useStyles = makeStyles((theme) => ({
  table: {
    '& td': {
      whiteSpace: 'nowrap',
      cursor: 'pointer',
    },
  },
  loadMoreContainer: {
    margin: theme.spacing(2),
  },
  searchContainer: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  tableCellPair: {
    backgroundColor: theme.palette.type === 'dark' ? '#132432' : '#eeeeee',
  },
  tableCellImpair: {
    backgroundColor: 'transparent',
  },
  treatmentHeaders: {
    top: 34,
  },
  soins: {
    textAlign: 'center',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const WEEK_DAYS = ['LU', 'MA', 'ME', 'JE', 'VE', 'SA', 'DI'];
const TREATMENT_LENGTH = 8;

const getBackground = (i, classes) =>
  i % 2 === 0 ? classes.tableCellPair : classes.tableCellImpair;
const getHStyle = (i, classes) =>
  clsx(getBackground(i, classes), classes.treatmentHeaders);

const renderTreatmentTitles = ({ maxTreatments, classes }) => {
  let Titles = [];
  for (let i = 0; i < maxTreatments; i += 1) {
    Titles = [
      ...Titles,
      <TableCell
        key={`treat-title-${i}`}
        className={clsx(getBackground(i, classes), classes.soins)}
        colSpan={TREATMENT_LENGTH}
      >
        Soin n° {i + 1}
      </TableCell>,
    ];
  }
  return Titles;
};

const renderTreatmentHeaders = ({ maxTreatments, classes }) => {
  let Headers = [];
  for (let i = 0; i < maxTreatments; i += 1) {
    const className = getHStyle(i, classes);

    Headers = [
      ...Headers,
      <TableCell
        className={clsx('MuiTableCell-stickyHeader', className)}
        key={`treat-head-${i}-0`}
      >
        Type
      </TableCell>,
      <TableCell
        className={clsx('MuiTableCell-stickyHeader', className)}
        key={`treat-head-${i}-1`}
      >
        Début
      </TableCell>,
      <TableCell
        className={clsx('MuiTableCell-stickyHeader', className)}
        key={`treat-head-${i}-2`}
      >
        Heure
      </TableCell>,
      <TableCell
        className={clsx('MuiTableCell-stickyHeader', className)}
        key={`treat-head-${i}-3`}
      >
        Durée
      </TableCell>,
      <TableCell
        className={clsx('MuiTableCell-stickyHeader', className)}
        key={`treat-head-${i}-4`}
      >
        Moment
      </TableCell>,
      <TableCell
        className={clsx('MuiTableCell-stickyHeader', className)}
        key={`treat-head-${i}-5`}
      >
        Tournée
      </TableCell>,
      <TableCell
        className={clsx('MuiTableCell-stickyHeader', className)}
        key={`treat-head-${i}-6`}
      >
        Fréquence
      </TableCell>,
      <TableCell
        className={clsx('MuiTableCell-stickyHeader', className)}
        key={`treat-head-${i}-7`}
      >
        Jours
      </TableCell>,
    ];
  }
  return Headers;
};

const headCells = [
  { id: 'id', numeric: true, disableSorting: true, label: 'ID' },
  {
    id: 'patient',
    disableSorting: false,
    label: 'Patient',
  },
  {
    id: 'date',
    disableSorting: false,
    label: 'Date de prescription',
  },
  {
    id: 'endDate',
    disableSorting: false,
    label: 'Fin de prescription',
  },
  {
    id: 'photo',
    disableSorting: true,
    label: 'Photo',
  },
  {
    id: 'doctor',
    disableSorting: false,
    label: 'Medecin prescripteur',
  },
  {
    id: 'soins2000Number',
    disableSorting: false,
    label: 'N° fact.',
  },
];

const ListPrescriptions = () => {
  const classes = useStyles();
  const { tours } = useTours();
  const {
    error,
    prescriptions,
    loading,
    maxTreatments,
    hasMore,
    onClick,
    onFetchMore,
    refetch,
  } = usePrescriptions();
  const { userGroupId } = useParams();
  const { state, dispatch } = useContext(Store);
  const { isSuperAdmin } = useCurrentUser();
  const { readOnly } = useNetworkStatus();
  const { anchorEl, onClick: onMenuClick, onClose: onMenuClose } = useMenu();
  const { upload, clearErrors, errors } = useImportPrescriptions({ refetch });

  const setSearch = (payload) =>
    dispatch({ type: T.listPrescriptionsSearch, payload });
  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };
  const setDense = (payload) =>
    dispatch({ type: T.listPrescriptionsDense, payload });
  const setOrder = (payload) =>
    dispatch({ type: T.listPrescriptionsOrder, payload });
  const setOrderBy = (payload) =>
    dispatch({ type: T.listPrescriptionsOrderBy, payload });

  const handleRequestSort = (event, property) => {
    const isAsc =
      state.listPrescriptionsOrderBy === property &&
      state.listPrescriptionsOrder === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filteredAndSortedPrescriptions = useMemo(() => {
    const comparePresc = (presc1, presc2) => {
      let result = 0;
      const multiplier = state.listPrescriptionsOrder === 'desc' ? -1 : 1;

      if (state.listPrescriptionsOrderBy) {
        switch (state.listPrescriptionsOrderBy) {
          case 'patient':
            const patient1 = presc1.patient;
            const patient2 = presc2.patient;

            result = multiplier * sortPatient(patient1, patient2);
            break;

          case 'date':
            const date1 = moment([
              presc1.dateYear,
              presc1.dateMonth,
              presc1.dateDay,
            ]);
            const date2 = moment([
              presc2.dateYear,
              presc2.dateMonth,
              presc2.dateDay,
            ]);

            result =
              multiplier *
              (date1.isSame(date2) ? 0 : date1.isBefore(date2) ? -1 : 1);
            break;

          case 'endDate':
            const endDate1 = moment(presc1.endDate);
            const endDate2 = moment(presc2.endDate);

            result =
              multiplier *
              (endDate1.isSame(endDate2)
                ? 0
                : endDate1.isBefore(endDate2)
                ? -1
                : 1);
            break;

          case 'doctor':
            const doctor1 = presc1.doctor;
            const doctor2 = presc2.doctor;

            if (doctor1 === doctor2) return 0;
            else if (doctor1 === null) return 1;
            else if (doctor2 === null) return -1;
            else result = multiplier * sortDoctor(doctor1, doctor2);

            break;

          case 'soins2000Number':
            const fact1 = presc1.soins2000Number;
            const fact2 = presc2.soins2000Number;

            if (fact1 === fact2) return 0;
            else if (fact1 === null) return 1;
            else if (fact2 === null) return -1;
            else result = multiplier * fact1.localeCompare(fact2);

            break;

          default:
            break;
        }
      }

      return result;
    };

    return prescriptions
      ?.filter((prescription) => {
        if (prescription.archived) {
          return false;
        } else if (state.listPrescriptionsSearch) {
          const patient = prescription.patient;
          const filteredPatients = patient
            ? filterPatients([patient], state.listPrescriptionsSearch)
            : [];

          return (
            filteredPatients.length ||
            (prescription.soins2000Number &&
              normalize(prescription.soins2000Number).includes(
                normalize(state.listPrescriptionsSearch),
              ))
          );
        }

        return true;
      })
      .sort((presc1, presc2) => comparePresc(presc1, presc2));
  }, [
    prescriptions,
    state.listPrescriptionsOrder,
    state.listPrescriptionsOrderBy,
    state.listPrescriptionsSearch,
  ]);

  const renderPrescriptions = () => {
    return (
      <Table
        size={state.listPrescriptionsDense ? 'small' : 'medium'}
        className={classes.table}
        stickyHeader
      >
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell rowSpan={2} key={headCell.id}>
                {headCell.disableSorting ? (
                  headCell.label
                ) : (
                  <TableSortLabel
                    active={state.listPrescriptionsOrderBy === headCell.id}
                    direction={
                      state.listPrescriptionsOrderBy === headCell.id
                        ? state.listPrescriptionsOrder
                        : 'asc'
                    }
                    onClick={(event) => handleRequestSort(event, headCell.id)}
                  >
                    {headCell.label}
                    {state.listPrescriptionsOrderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {state.listPrescriptionsOrder === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
            {renderTreatmentTitles({ maxTreatments, classes })}
          </TableRow>
          <TableRow>
            {renderTreatmentHeaders({ maxTreatments, classes })}
          </TableRow>
        </TableHead>

        <TableBody>
          {loading || !tours?.length ? (
            <TableRow>
              <TableCell style={{ textAlign: 'center' }} colSpan={5}>
                Chargement en cours...
              </TableCell>
            </TableRow>
          ) : error ? (
            <TableRow>
              <TableCell style={{ textAlign: 'center' }} colSpan={5}>
                Une erreur s'est produite
              </TableCell>
            </TableRow>
          ) : !prescriptions?.length ? (
            <TableRow>
              <TableCell style={{ textAlign: 'center' }} colSpan={5}>
                Aucune ordonnance
              </TableCell>
            </TableRow>
          ) : (
            filteredAndSortedPrescriptions.map((prescription) => {
              const patient = prescription.patient;

              return (
                <TableRow
                  key={prescription.id}
                  hover
                  onClick={() => onClick(prescription)}
                >
                  <TableCell>{`${prescription.id}`}</TableCell>
                  <TableCell>
                    {patient ? (
                      <>
                        <b>{patient.lastName.toUpperCase()}</b>{' '}
                        {patient.firstName}
                      </>
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell>
                    {moment([
                      prescription.dateYear,
                      prescription.dateMonth,
                      prescription.dateDay,
                    ]).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    {moment(prescription.endDate).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    {prescription.photo?.key ? <PhotoCameraIcon /> : null}
                  </TableCell>
                  <TableCell>
                    {prescription.doctor
                      ? `${prescription.doctor.lastName} ${
                          prescription.doctor.firstName || ''
                        }`
                      : ''}
                  </TableCell>
                  <TableCell>
                    {prescription.soins2000Number
                      ? prescription.soins2000Number
                      : ''}
                  </TableCell>
                  {prescription.treatments.map((treatment, index) => {
                    const className = getHStyle(index, classes);
                    const C = () => (
                      <>
                        <TableCell className={className}>
                          {treatment.type || ''}
                        </TableCell>
                        <TableCell className={className}>
                          {moment([
                            treatment.year,
                            treatment.month,
                            treatment.day,
                          ]).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell className={className}>
                          {treatment.timeHour < 10 ? `0` : ``}
                          {treatment.timeHour}:
                          {treatment.timeMinute < 10 ? `0` : ``}
                          {treatment.timeMinute}
                        </TableCell>
                        <TableCell className={className}>
                          {treatment.count} {treatment.duration}
                        </TableCell>
                        <TableCell className={className}>
                          {
                            MOMENTS.find((mo) => mo.value === treatment.moment)
                              ?.label
                          }
                        </TableCell>
                        <TableCell className={className}>
                          {tours.find((t) => t.id === treatment.tour)?.name ||
                            ''}
                        </TableCell>
                        <TableCell className={className}>
                          {treatment.frequency} {treatment.frequencyDuration}
                        </TableCell>
                        <TableCell className={className}>
                          {treatment.weekDays?.reduce(
                            (str, d) =>
                              `${str}${str.length ? ' / ' : ''}${WEEK_DAYS[d]}`,
                            '',
                          )}
                        </TableCell>
                        {index === prescription.treatments.length - 1 &&
                        index < maxTreatments - 1 ? (
                          <TableCell
                            colSpan={
                              TREATMENT_LENGTH * (maxTreatments - (index + 1))
                            }
                          />
                        ) : null}
                      </>
                    );
                    return <C key={treatment.id} />;
                  })}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    );
  };

  const renderErrors = () => {
    return (
      <div style={{ padding: '1em' }}>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={clearErrors}
        >
          Effacer les erreurs...
        </Button>
        {errors.map((error) => (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            key={error.line}
          >
            <Grid item>{`Ligne ${error.line}`}</Grid>
            <Grid item xs>
              {error?.text?.split('\n').map((line) => {
                return <div>{line}</div>;
              })}
            </Grid>
          </Grid>
        ))}
      </div>
    );
  };

  return (
    <AppLayout
      title="Ordonnances"
      action={
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs>
            <div className={classes.searchContainer}>
              <SearchField
                value={state.listPrescriptionsSearch}
                onChange={handleSearchChange}
              />
            </div>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={state.listPrescriptionsDense}
                  onChange={() => setDense(!state.listPrescriptionsDense)}
                />
              }
              label="Dense"
            />
          </Grid>
          {isSuperAdmin && !readOnly ? (
            <Grid item style={{ marginLeft: '20px' }}>
              <IconButton onClick={onMenuClick}>
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={onMenuClose}
              >
                <MenuItem>
                  <UploadButton
                    component="link"
                    onChange={(e) => upload(e, userGroupId, tours)}
                    accept=".csv"
                  >
                    Importer des ordonnances
                  </UploadButton>
                </MenuItem>
              </Menu>
            </Grid>
          ) : null}
        </Grid>
      }
    >
      {errors?.length ? renderErrors() : renderPrescriptions()}
      {hasMore ? (
        <div className={classes.loadMoreContainer}>
          <ProgressButton
            loading={loading}
            variant="outlined"
            color="primary"
            onClick={onFetchMore}
          >
            Voir plus...
          </ProgressButton>
        </div>
      ) : null}
    </AppLayout>
  );
};

export default ListPrescriptions;
