import { gql, useMutation } from '@apollo/client';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';

import { GET_PATIENT } from '../Patient/usePatient';
import AbsenceFragment from '../../fragments/AbsenceFragment';
import backSearch from '../../utils/back-search';
import cleanObject from '../../utils/clean-object';
import useSnackbar from '../System/useSnackbar';

const CREATE_ABSENCE = gql`
  mutation CreateAbsence(
    $userGroupId: String!
    $patientId: String!
    $startDate: String!
    $startMoment: MOMENT!
    $endDate: String
    $endMoment: MOMENT
    $prescriptionsIds: [String]
  ) {
    createAbsence(
      userGroupId: $userGroupId
      patientId: $patientId
      startDate: $startDate
      startMoment: $startMoment
      endDate: $endDate
      endMoment: $endMoment
      prescriptionsIds: $prescriptionsIds
    ) {
      ...AbsenceFragment
    }
  }

  ${AbsenceFragment}
`;

const useCreateAbsence = ({
  generalInputs,
  unknownEnd,
  noRedirect,
  noSnackbar,
}) => {
  const { showSnackbar } = useSnackbar();

  const { replace } = useHistory();
  const { userGroupId, patientId } = useParams();
  const { search: s } = useLocation();
  const backTo =
    backSearch(s) || `/g/${userGroupId}/patient/update/${patientId}`;

  let createVariables = {
    userGroupId,
    patientId,
    startDate: moment
      .utc(generalInputs.startDate)
      .startOf('day')
      .valueOf()
      .toString(),
    startMoment: generalInputs.startMoment,
    prescriptionsIds:
      generalInputs.prescriptions !== 'all' ? generalInputs.prescriptions : [],
  };

  // Handle unknown end date
  if (!unknownEnd) {
    createVariables = {
      ...createVariables,
      endDate: moment
        .utc(generalInputs.endDate)
        .endOf('day')
        .valueOf()
        .toString(),
      endMoment: generalInputs.endMoment,
    };
  }

  const [createAbsence, { loading, error }] = useMutation(CREATE_ABSENCE, {
    variables: cleanObject(createVariables),
    update(cache, { data: { createAbsence } }) {
      try {
        const { getPatient } = cache.readQuery({
          query: GET_PATIENT,
          variables: { userGroupId, id: patientId },
        });
        cache.writeQuery({
          query: GET_PATIENT,
          variables: { userGroupId, id: patientId },
          data: {
            getPatient: {
              ...getPatient,
              absences: [createAbsence, ...getPatient.absences],
            },
          },
        });
      } catch (err) {}
    },
    onCompleted({ createAbsence }) {
      if (!noRedirect) {
        replace(backTo);
      }
      if (!noSnackbar) {
        showSnackbar('Absence créée avec succès.');
      }
    },
  });

  const handleAdd = async (event) => {
    event && event.preventDefault();
    try {
      await createAbsence();
    } catch (err) {
      // Do nothing, let Apollo useMutation handle errors
    }
  };

  return { loading, error, onCreate: handleAdd, createAbsence };
};

export default useCreateAbsence;
