import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

import TextField from '../System/TextField';
import useAddPatientLog from './useAddPatientLog';
import ProgressButton from '../System/ProgressButton';

const useStyles = makeStyles((theme) => ({
  addContainer: {
    marginTop: theme.spacing(2),
  },
}));

const AddPatientLogForm = ({ patientId, disabled }) => {
  const classes = useStyles();

  const {
    showInputs,
    inputs,
    loading,
    onToggleShowInputs,
    onAddPatientLog,
    onInputChange,
  } = useAddPatientLog({
    patientId,
  });

  return showInputs ? (
    <form onSubmit={onAddPatientLog}>
      <div>
        <TextField
          autoFocus
          name="text"
          value={inputs.text}
          label="Nouveau commentaire"
          size="small"
          multiline
          onChange={onInputChange}
        />
      </div>

      <div>
        <ProgressButton
          disabled={loading || !inputs.text || disabled}
          type="submit"
          loading={loading}
          fullWidth
          variant="outlined"
        >
          Ajouter un commentaire
        </ProgressButton>
      </div>
    </form>
  ) : (
      <div className={classes.addContainer}>
        <Button
          fullWidth
          variant="outlined"
          size="small"
          color="primary"
          onClick={onToggleShowInputs}
          disabled={disabled}
        >
          Ajouter...
      </Button>
      </div>
    );
};

export default AddPatientLogForm;
