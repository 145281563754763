import { gql } from '@apollo/client';

const LogFragment = gql`
  fragment LogFragment on Log {
    id
    date
    text
  }
`;

export default LogFragment;
