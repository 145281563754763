import React, { useRef } from 'react';
import { Button, CircularProgress, makeStyles, Link } from '@material-ui/core';
import shortid from 'shortid';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  title: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const UploadButton = ({
  className,
  children,
  ButtonProps,
  progress,
  onChange,
  component,
  accept,
  multiple = false,
  ...rest
}) => {
  const classes = useStyles();
  const idRef = useRef(shortid.generate());
  const id = idRef.current;

  return (
    <div className={className} {...rest}>
      <input
        accept={accept}
        className={classes.input}
        id={id}
        type="file"
        multiple={multiple}
        disabled={!!progress}
        onChange={onChange}
      />
      <label htmlFor={id}>
        {component && component === 'link' ? (
          <Link className={classes.title}>
            {progress ? `${progress} %` : children}
          </Link>
        ) : (
          <Button
            variant="outlined"
            color="primary"
            component="span"
            disabled={!!progress}
            endIcon={
              progress ? (
                <CircularProgress color="inherit" size={16} />
              ) : accept.indexOf('image') !== -1 ? (
                <PhotoCameraIcon />
              ) : null
            }
            {...ButtonProps}
          >
            {progress ? `${progress} %` : children}
          </Button>
        )}
      </label>
    </div>
  );
};

export default UploadButton;
