import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { GET_PATIENT } from '../Patient/usePatient';
import PrescriptionFragment from '../../fragments/PrescriptionFragment';
import cleanObject from '../../utils/clean-object';
import useSnackbar from '../System/useSnackbar';
import cleanSignedUrl from '../../utils/clean-signed-url';

const UPDATE_PRESCRIPTION = gql`
  mutation UpdatePrescription(
    $userGroupId: String!
    $id: String!
    $num: String
    $dateDay: Int
    $dateMonth: Int
    $dateYear: Int
    $photo: ImageInput
    $cause: TREATMENT_CAUSE
    $pec: [ResourceInput]!
    $pecImage: ImageInput
    $motherHoodDay: Int
    $motherHoodMonth: Int
    $motherHoodYear: Int
    $workAccidentDay: Int
    $workAccidentMonth: Int
    $workAccidentYear: Int
    $workAccidentPhoto: ImageInput
    $soins2000Number: String
    $chronic: Boolean!
    $treatments: [TreatmentInput]!
    $doctorId: String
    $archived: Boolean
    $cotation: String
    $liens: String
  ) {
    updatePrescription(
      userGroupId: $userGroupId
      id: $id
      num: $num
      dateDay: $dateDay
      dateMonth: $dateMonth
      dateYear: $dateYear
      photo: $photo
      cause: $cause
      pec: $pec
      pecImage: $pecImage
      motherHoodDay: $motherHoodDay
      motherHoodMonth: $motherHoodMonth
      motherHoodYear: $motherHoodYear
      workAccidentDay: $workAccidentDay
      workAccidentMonth: $workAccidentMonth
      workAccidentYear: $workAccidentYear
      workAccidentPhoto: $workAccidentPhoto
      soins2000Number: $soins2000Number
      chronic: $chronic
      treatments: $treatments
      doctorId: $doctorId
      archived: $archived
      cotation: $cotation
      liens: $liens
    ) {
      ...PrescriptionFragment
    }
  }

  ${PrescriptionFragment}
`;

const useUpdatePrescription = ({
  generalInputs,
  patientId: forcedPatientId,
  prescriptionId: forcedPrescriptionId,
  successMessage,
  onCompleted,
}) => {
  const { showSnackbar } = useSnackbar();
  const {
    userGroupId,
    patientId: patientIdParam,
    prescriptionId: prescriptionIdParam,
  } = useParams();
  const patientId = forcedPatientId || patientIdParam;
  const prescriptionId = forcedPrescriptionId || prescriptionIdParam;

  const [updatePrescription, { loading }] = useMutation(UPDATE_PRESCRIPTION, {
    onCompleted() {
      showSnackbar(successMessage || 'Ordonnance mise à jour.');
      onCompleted && onCompleted();
    },
  });

  const [error, setError] = useState();
  const handleUpdate = async ({ archived = false } = {}) => {
    setError(null);

    // Check treatments
    if (!generalInputs.treatments || generalInputs.treatments.length === 0) {
      setError('Votre ordonnance doit avoir des soins.');
      throw new Error();
    }

    // Check treatment dates
    for (const treatment of generalInputs.treatments) {
      if (
        moment([treatment.year, treatment.month, treatment.day]).isBefore(
          moment([
            generalInputs.dateYear,
            generalInputs.dateMonth,
            generalInputs.dateDay,
          ]),
        )
      ) {
        setError(
          "Les dates de soins ne peuvent pas être antérieur à la date de l'ordonnance.",
        );
        throw new Error();
      }
    }

    await updatePrescription({
      variables: cleanObject({
        userGroupId,
        id: prescriptionId,
        num: generalInputs.num,
        dateDay: generalInputs.dateDay,
        dateMonth: generalInputs.dateMonth,
        dateYear: generalInputs.dateYear,
        photo: { key: cleanSignedUrl(generalInputs.photo?.key) },
        cause: generalInputs.cause,
        pec: generalInputs.pec.map((p) => {
          return {
            key: cleanSignedUrl(p.key) || p.addedKey,
            size: p.size,
            label: p.label,
            description: p.description,
          };
        }),
        pecImage: { key: cleanSignedUrl(generalInputs.pecImage?.key) },
        motherHoodDay: generalInputs.motherHoodDay,
        motherHoodMonth: generalInputs.motherHoodMonth,
        motherHoodYear: generalInputs.motherHoodYear,
        workAccidentDay: generalInputs.workAccidentDay,
        workAccidentMonth: generalInputs.workAccidentMonth,
        workAccidentYear: generalInputs.workAccidentYear,
        workAccidentPhoto: {
          key: cleanSignedUrl(generalInputs.workAccidentPhoto?.key),
        },
        soins2000Number: generalInputs.soins2000Number?.length
          ? generalInputs.soins2000Number
          : 'Virtuelle',
        chronic: generalInputs.chronic,
        cotation: generalInputs.cotation,
        liens: generalInputs.liens,
        treatments: generalInputs.treatments.map((treatment) => {
          return {
            id: treatment.id,
            day: treatment.day,
            month: treatment.month,
            year: treatment.year,
            type: treatment.type,
            timeHour: treatment.timeHour,
            timeMinute: treatment.timeMinute,
            count: treatment.count,
            duration: treatment.duration,
            moment: treatment.moment,
            tour: treatment.tour,
            frequency: treatment.frequency,
            frequencyDuration: treatment.frequencyDuration,
            weekDays: treatment.weekDays,
            chronic: treatment.chronic,
            comment: treatment.comment,
            transmissionsGenerated: treatment.transmissionsGenerated,
            haveTransmissions: treatment.haveTransmissions,
            outsourcedTreatments: treatment.outsourcedTreatments,
          };
        }),
        doctorId: generalInputs.doctor?.id,
        archived,
      }),
      update(cache, { data: { updatePrescription } }) {
        try {
          const { getPatient } = cache.readQuery({
            query: GET_PATIENT,
            variables: { userGroupId, id: patientId },
          });

          if (updatePrescription.archived) {
            cache.writeQuery({
              query: GET_PATIENT,
              variables: { userGroupId, id: patientId },
              data: {
                getPatient: {
                  ...getPatient,
                  prescriptions: getPatient.prescriptions.filter(
                    (p) => p.id !== prescriptionId,
                  ),
                },
              },
            });
          } else {
            const firstTreatmentDate =
              generalInputs.treatments.length &&
              new Date(
                generalInputs.treatments[0].year,
                generalInputs.treatments[0].month,
                generalInputs.treatments[0].day,
              )
                .valueOf()
                .toString();

            cache.writeQuery({
              query: GET_PATIENT,
              variables: { userGroupId, id: patientId },
              data: {
                getPatient: {
                  ...getPatient,
                  prescriptions: getPatient.prescriptions.map((p) => {
                    if (p.id === updatePrescription.id) {
                      return {
                        __typename: 'PrescriptionSummary',
                        id: updatePrescription.id,
                        dateDay: updatePrescription.dateDay,
                        dateMonth: updatePrescription.dateMonth,
                        dateYear: updatePrescription.dateYear,
                        cause: updatePrescription.cause,
                        doctor: updatePrescription.doctor,
                        firstTreatmentDate: firstTreatmentDate,
                        soins2000Number: updatePrescription.soins2000Number,
                      };
                    }
                    return p;
                  }),
                },
              },
            });
          }
        } catch (err) {}
      },
    });
  };

  return { loading, error, onUpdate: handleUpdate };
};

export default useUpdatePrescription;
