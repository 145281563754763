import { gql } from '@apollo/client';

import UserSummaryFragment from './UserSummaryFragment';
import TourFragment from './TourFragment';

const UserGroupFragment = gql`
  fragment UserGroupFragment on UserGroup {
    id
    name
    archived
    users {
      ...UserSummaryFragment
    }
    tours {
      ...TourFragment
    }
  }

  ${UserSummaryFragment}
  ${TourFragment}
`;

export default UserGroupFragment;
