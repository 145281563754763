import { useState, useEffect, useRef } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import s3PostSignedUrl from '../utils/s3-post-signed-url';

const GET_UPLOAD_SIGNED_URL = gql`
  mutation GetUploadSignedUrl(
    $userGroupId: String!
    $filename: String!
    $contentType: String!
  ) {
    getUploadSignedUrl(
      userGroupId: $userGroupId
      filename: $filename
      contentType: $contentType
    ) {
      url
      fields
    }
  }
`;

const useUpload = ({ name, fileToUpload, onUploaded }) => {
  const { userGroupId } = useParams();

  const [getUploadSignedUrl] = useMutation(GET_UPLOAD_SIGNED_URL);

  const [progress, setProgress] = useState(false);
  const fileRef = useRef();
  const preview = useRef();

  const handleUpload = (file) => {
    const upload = async () => {
      fileRef.current = file;
      const { data: signedData } = await getUploadSignedUrl({
        variables: {
          userGroupId,
          filename: file.name,
          contentType: file.type || 'binary/octet-stream',
        },
      });
      const signedUrl = signedData && signedData.getUploadSignedUrl;

      if (signedUrl) {
        const isPdf = file.type && file.type === 'application/pdf';
        const updatePicture = async () => {
          try {
            const { key } = await s3PostSignedUrl(
              { file: fileRef.current, signedUrl },
              {
                onProgress: ({ progress }) => {
                  setProgress(progress);

                  onUploaded &&
                    onUploaded({
                      target: {
                        name,
                        value: { progress, src: URL.createObjectURL(fileRef.current), isPdf },
                      },
                    });
                },
              },
            );

            onUploaded &&
              onUploaded({
                target: { name, value: { key, src: URL.createObjectURL(fileRef.current), isPdf } },
              });
          } catch (error) {
            console.log('handleUpload error: ', error);
            onUploaded &&
              onUploaded({
                target: { name, value: {} },
              });
          }

          setProgress(null);
        };

        updatePicture();
      }
    }

    upload();
  }

  const handleChange = (event) => {
    const upload = async () => {
      setProgress(0);

      onUploaded &&
        onUploaded({
          target: { name, value: { progress: 0 } },
        });

      const { files } = event.target;

      // Get S3 signed URL
      if (files && files.length) {
        const file = files[0];
        fileRef.current = file;

        // Preview
        const reader = new FileReader();
        reader.addEventListener(
          'load',
          () => {
            preview.current = reader.result;

            onUploaded &&
              onUploaded({ target: { name, value: { src: preview.current } } });
          },
          false,
        );

        if (file) {
          reader.readAsDataURL(file);
        }

        // Upload
        const { data: signedData } = await getUploadSignedUrl({
          variables: {
            userGroupId,
            filename: file.name,
            contentType: file.type || 'binary/octet-stream',
          },
        });

        const signedUrl = signedData && signedData.getUploadSignedUrl;
        if (signedUrl) {
          const updatePicture = async () => {
            try {
              const { key } = await s3PostSignedUrl(
                { file: fileRef.current, signedUrl },
                {
                  onProgress: ({ progress }) => {
                    setProgress(progress);

                    onUploaded &&
                      onUploaded({
                        target: {
                          name,
                          value: { progress, src: preview.current },
                        },
                      });
                  },
                },
              );

              onUploaded &&
                onUploaded({
                  target: { name, value: { key, src: preview.current } },
                });
            } catch (error) {
              console.log('handleChange error: ', error);
            }

            setProgress(null);
          };
          updatePicture();
        }
      } else {
        onUploaded &&
          onUploaded({
            target: { name, value: null },
          });
        setProgress(null);
      }
    };

    upload();
  };

  useEffect(() => {
    if (fileToUpload) {
      handleChange({ target: { files: [fileToUpload] } });
    }
  }, []); // eslint-disable-line

  return { progress, onChange: handleChange, onUpload: handleUpload };
};

export default useUpload;
