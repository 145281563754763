import React from 'react';
import { makeStyles, DialogContentText } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import useDeleteAbsence from './useDeleteAbsence';
import ProgressButton from '../System/ProgressButton';
import ConfirmDialog from '../System/ConfirmDialog';
import useDialog from '../System/useDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 0 30px rgba(0, 0, 0, 0.4)',
    zIndex: 100,
  },
}));

const DeleteAbsence = () => {
  const classes = useStyles();

  const { open, onClose, onOpen } = useDialog();
  const { onDelete, loading } = useDeleteAbsence();

  return (
    <>
      <div className={classes.root}>
        <ProgressButton
          loading={loading}
          startIcon={<DeleteIcon />}
          fullWidth
          variant="outlined"
          onClick={onOpen}
        >
          Annuler l'absence...
        </ProgressButton>
      </div>

      <ConfirmDialog
        title="Annuler l'absence"
        open={open}
        confirmLoading={loading}
        onConfirm={onDelete}
        onClose={onClose}
      >
        <DialogContentText>
          Cette action supprimera l'absence sur toutes les transmissions à
          venir.
        </DialogContentText>
        <DialogContentText>Êtes-vous sûr ?</DialogContentText>
      </ConfirmDialog>
    </>
  );
};

export default DeleteAbsence;
