import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import TreatmentFragment from '../../fragments/TreatmentFragment';
import useSnackbar from '../System/useSnackbar';

const DELETE_TRANSMISSIONS_BY_TREATMENT = gql`
  mutation DeleteTransmissionsByTreatment(
    $userGroupId: String!
    $prescriptionId: String!
    $treatmentId: String!
  ) {
    deleteTransmissionsByTreatment(
      userGroupId: $userGroupId
      prescriptionId: $prescriptionId
      treatmentId: $treatmentId
    ) {
      ...TreatmentFragment
    }
  }

  ${TreatmentFragment}
`;

const useDeleteTransmissionsByTreatment = ({
  treatmentId,
  onDeleted,
  fromDate,
} = {}) => {
  const { userGroupId, prescriptionId } = useParams();

  const { showSnackbar } = useSnackbar();
  const [deleteTransmissions, { loading }] = useMutation(
    DELETE_TRANSMISSIONS_BY_TREATMENT,
    {
      onCompleted() {
        showSnackbar('Transmissions supprimées');
        onDeleted && onDeleted();
      },
    },
  );

  const handleDelete = async ({ variables = {} } = {}) => {
    await deleteTransmissions({
      variables: {
        userGroupId,
        treatmentId,
        prescriptionId,
        ...variables,
      },
    });
  };

  return { loading, onDelete: handleDelete };
};

export default useDeleteTransmissionsByTreatment;
