import { gql, useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';

import { LIST_USER_GROUPS } from './useUserGroups';

const DELETE_USER_GROUP = gql`
  mutation DeleteUserGroup($id: String!) {
    deleteUserGroup(id: $id)
  }
`;

const useDeleteUserGroup = () => {
  const { userGroupId } = useParams();
  const { replace } = useHistory();

  const [deleteUserGroup, { loading }] = useMutation(DELETE_USER_GROUP, {
    variables: { id: userGroupId },
    update(cache) {
      try {
        const { listUserGroups } = cache.readQuery({ query: LIST_USER_GROUPS });
        cache.writeQuery({
          query: LIST_USER_GROUPS,
          data: {
            listUserGroups: listUserGroups.filter((g) => g.id !== userGroupId),
          },
        });
      } catch (err) {}
    },
    onCompleted() {
      replace('/admin/user-groups');
    },
  });

  return { loading, onDelete: deleteUserGroup };
};

export default useDeleteUserGroup;
