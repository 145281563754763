import { Button, makeStyles, TextField, Typography } from '@material-ui/core';
import { BrowserDatamatrixCodeReader, BrowserCodeReader } from '@zxing/browser';
import { useState } from 'react';
import { useRef, useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  videoContainer: {},
  video: {
    width: '100%',
    maxWidth: '100%',
  },
  helper: {
    padding: theme.spacing(1, 1, 0, 1),
  },
  toolbar: {
    display: 'grid',
    gridTemplateColumns: `1fr auto`,
    gap: `${theme.spacing(2)}px`,
    padding: theme.spacing(2, 4),
  },
}));

export default function QRCodeReader({ name, onDecode, onClose }) {
  const classes = useStyles();

  const videoRef = useRef();
  const [inputDevices, setInputDevices] = useState([]);

  const [selectedInputDevice, setSelectedInputDevice] = useState();

  useEffect(() => {
    const init = async () => {
      try {
        const videoInputDevices = await BrowserCodeReader.listVideoInputDevices();
        setInputDevices(videoInputDevices);
        if (videoInputDevices?.length) {
          setSelectedInputDevice(videoInputDevices[0]);
        }
      } catch (err) {
        alert(
          "Aucune caméra n'est detectée sur votre appareil. Si vous êtes sur iPhone, veuillez utiliser Safari.",
        );
      }
    };
    init();
  }, []);

  const codeReaderRef = useRef();

  const handleClose = () => {
    codeReaderRef?.current?.reset && codeReaderRef.current.reset();
    onClose();
  };

  useEffect(() => {
    const initReader = async () => {
      if (selectedInputDevice) {
        codeReaderRef?.current?.reset && codeReaderRef.current.reset();

        codeReaderRef.current = new BrowserDatamatrixCodeReader();

        try {
          const result = await codeReaderRef.current.decodeOnceFromVideoDevice(
            selectedInputDevice.deviceId,
            videoRef.current,
          );

          onDecode({ target: { name, value: result.text || '' } });

          codeReaderRef?.current?.reset && codeReaderRef.current.reset();
        } catch (err) {
          alert(`Erreur lors de l'initialisation de la caméra: ${err.message}`);
        }
      }
    };

    initReader();
  }, [selectedInputDevice, name]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDeviceInputChange = (event) => {
    const { value: deviceId } = event.target;

    codeReaderRef?.current?.reset && codeReaderRef.current.reset();

    setSelectedInputDevice(
      inputDevices.find((device) => device.deviceId === deviceId),
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.videoContainer}>
        <video ref={videoRef} className={classes.video}></video>
      </div>

      <div className={classes.helper}>
        <Typography align="center" variant="body2">
          Placez le Flashcode sur l'écran et patientez. La fenêtre se fermera
          automatiquement à la détection du code.
        </Typography>
      </div>

      <div className={classes.toolbar}>
        <div>
          {inputDevices?.length && selectedInputDevice ? (
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              select
              SelectProps={{ native: true }}
              value={selectedInputDevice.deviceId}
              onChange={handleDeviceInputChange}
            >
              {inputDevices.map((device) => (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.label}
                </option>
              ))}
            </TextField>
          ) : null}
        </div>
        <Button variant="outlined" onClick={handleClose}>
          Fermer
        </Button>
      </div>
    </div>
  );
}
