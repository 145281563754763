import React from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { Link, useLocation, useParams } from 'react-router-dom';

import { backSearch, urlWithBacks } from '../../utils/back-search';

const TreatmentMenu = ({ className, treatment }) => {
  const { userGroupId, patientId, prescriptionId } = useParams();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { search: s } = useLocation();
  const backToUrl = backSearch(s);

  return (
    <>
      <IconButton className={className} onClick={handleOpen}>
        <MoreIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          component={Link}
          to={urlWithBacks({
            url: `/g/${userGroupId}/prescription/transmissions/${patientId}/${prescriptionId}/${treatment.id}`,
            initialBack: backToUrl,
          })}
        >
          <ListItemIcon>
            <ReceiptIcon />
          </ListItemIcon>
          <ListItemText>Voir les rendez-vous</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default TreatmentMenu;
