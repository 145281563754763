import { gql } from '@apollo/client';

import AddressFragment from './AddressFragment';
import ImageFragment from './ImageFragment';
import ResourceFragment from './ResourceFragment';
import PrescriptionSummaryFragment from './PrescriptionSummaryFragment';
import LogFragment from './LogFragment';
import TransmissionBaseFragment from './TransmissionBaseFragment';
import DoctorFragment from './DoctorFragment';
import AbsenceFragment from './AbsenceFragment';
import ProviderFragment from './ProviderFragment';
import MessageFragment from './MessageFragment';
import ConstantFragment from './ConstantFragment';

const PatientFragment = gql`
  fragment PatientFragment on Patient {
    id
    firstName
    lastName
    useName
    visitAddress {
      ...AddressFragment
    }
    homeAddress {
      ...AddressFragment
    }
    addressExtra
    comment
    email1Name
    email1Value
    contact1
    phones {
      name
      number
    }
    birthDay
    birthMonth
    birthYear
    preferredTourId
    socialSecuNumber
    socialSecuNumberRank
    vitaleCard {
      ...ImageFragment
    }
    mutuelleCertificate {
      ...ImageFragment
    }
    mutuelle
    dmpINSC
    dmpImage {
      ...ImageFragment
    }
    dmpId
    dmpPassword
    resources {
      ...ResourceFragment
    }
    prescriptions {
      ...PrescriptionSummaryFragment
    }
    logs {
      ...LogFragment
    }
    customTransmissions {
      ...TransmissionBaseFragment
    }
    constants {
      ...ConstantFragment
    }
    doctor {
      ...DoctorFragment
    }
    absences {
      ...AbsenceFragment
    }
    providers {
      ...ProviderFragment
    }
    messages {
      ...MessageFragment
    }
    archived
  }

  ${AddressFragment}
  ${ImageFragment}
  ${ResourceFragment}
  ${PrescriptionSummaryFragment}
  ${LogFragment}
  ${TransmissionBaseFragment}
  ${DoctorFragment}
  ${AbsenceFragment}
  ${ProviderFragment}
  ${MessageFragment}
  ${ConstantFragment}
`;

export default PatientFragment;
