import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import ProtectedRoute from '../System/ProtectedRoute';
import useCurrentUser from '../System/useCurrentUser';
import UserGroupLayout from './UserGroupLayout';

const AdminLayout = () => {
  const { isAdmin, isSuperAdmin } = useCurrentUser();

  return (
    <Switch>
      <ProtectedRoute
        roles={['admin', 'super-admin']}
        redirect="/"
        path="/admin/user-groups/:action?/:userGroupId?"
      >
        <UserGroupLayout />
      </ProtectedRoute>

      <Redirect to={isSuperAdmin || isAdmin ? '/admin/user-groups' : '/'} />
    </Switch>
  );
};

export default AdminLayout;
