import React from 'react';
import { MenuItem, DialogContentText, ListItemIcon } from '@material-ui/core';
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import ArchiveIcon from '@material-ui/icons/Archive';

import PatientSummaryFragment from '../../fragments/PatientSummaryFragment';
import useDialog from '../System/useDialog';
import ConfirmDialog from '../System/ConfirmDialog';
import useSnackbar from '../System/useSnackbar';
import { LIST_PATIENTS } from './usePatients';
import { GET_PATIENT } from './usePatient';
import { sortPatients } from '../../utils/filters';

const ARCHIVE_PATIENT = gql`
  mutation ArchivePatient(
    $userGroupId: String!
    $id: String!
    $archived: Boolean!
  ) {
    archivePatient(userGroupId: $userGroupId, id: $id, archived: $archived) {
      ...PatientSummaryFragment
    }
  }

  ${PatientSummaryFragment}
`;

const ArchivePatientMenuItem = ({ archived }) => {
  const { userGroupId, patientId } = useParams();
  const { showSnackbar } = useSnackbar();
  const [archivePatient, { loading }] = useMutation(ARCHIVE_PATIENT, {
    variables: { userGroupId, id: patientId, archived },
    onCompleted(data) {
      showSnackbar(
        `Patient ${data?.archivePatient?.archived ? 'archivé' : 'Desarchivé'}`,
      );
    },
    update(cache, data) {
      const archivePatient = data?.data?.archivePatient;

      if (archivePatient) {
        try {
          const { listPatients } = cache.readQuery({
            query: LIST_PATIENTS,
            variables: { userGroupId },
          });
          const newList = sortPatients([
            ...listPatients.filter((p) => p.id !== patientId),
            archivePatient,
          ]);

          cache.writeQuery({
            query: LIST_PATIENTS,
            variables: { userGroupId },
            data: {
              listPatients: newList,
            },
          });

          const { getPatient } = cache.readQuery({
            query: GET_PATIENT,
            variables: { userGroupId, id: patientId },
          });
          cache.writeQuery({
            query: GET_PATIENT,
            variables: { userGroupId, id: patientId },
            data: {
              getPatient: { ...getPatient, archived },
            },
          });
        } catch (err) {
          console.log(err);
        }
      }
    },
  });

  const { open, onOpen, onClose } = useDialog();
  const label = archived ? 'Supprimer' : 'Annuler la suppression';

  return (
    <>
      <MenuItem button onClick={onOpen}>
        <ListItemIcon>
          <ArchiveIcon fontSize="small" />
        </ListItemIcon>
        {label}
      </MenuItem>

      <ConfirmDialog
        title={label}
        open={open}
        confirmLoading={loading}
        onConfirm={archivePatient}
        onClose={onClose}
      >
        <DialogContentText>
          {`Cette action ${
            archived ? 'supprimera le' : 'annulera la suppression du'
          } patient.`}
        </DialogContentText>
        <DialogContentText>Êtes-vous sûr ?</DialogContentText>
      </ConfirmDialog>
    </>
  );
};

export default ArchivePatientMenuItem;
