import { gql, useMutation } from '@apollo/client';

import MessageFragment from '../../fragments/MessageFragment';
import { useParams } from 'react-router-dom';
import { LIST_MESSAGES } from './useMessages';

const PROCESS_MESSAGES = gql`
  mutation ProcessMessages($userGroupId: String!, $messageIds: [String!]!) {
    processMessages(userGroupId: $userGroupId, messageIds: $messageIds) {
      ...MessageFragment
    }
  }

  ${MessageFragment}
`;

const useProcessMessages = (messages) => {
  const { userGroupId } = useParams();

  const messageIds = messages?.map((m) => m.id);
  const [processMessages, { loading }] = useMutation(PROCESS_MESSAGES, {
    variables: {
      userGroupId,
      messageIds: messageIds,
    },
    update(cache) {
      // Remove from unprocessed list
      try {
        const { listMessages } = cache.readQuery({
          query: LIST_MESSAGES,
          variables: { userGroupId, processed: false },
        });
        cache.writeQuery({
          query: LIST_MESSAGES,
          variables: { userGroupId, processed: false },
          data: {
            listMessages: {
              ...listMessages,
              data: listMessages.data.filter(
                (m) => messageIds.indexOf(m.id) === -1,
              ),
            },
          },
        });
      } catch (err) {}
    },
  });

  return {
    onMarkMessagesProcessed: processMessages,
    loading,
  };
};

export default useProcessMessages;
