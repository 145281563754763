import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import UserGroupFragment from '../../fragments/UserGroupFragment';

const ENABLE_USER_GROUP_TOUR = gql`
  mutation EnableUserGroupTour($userGroupId: String!, $id: String!) {
    enableUserGroupTour(userGroupId: $userGroupId, id: $id) {
      ...UserGroupFragment
    }
  }

  ${UserGroupFragment}
`;

const useEnableTour = () => {
  const { userGroupId } = useParams();

  const [enableUserGroupTour, { loading }] = useMutation(
    ENABLE_USER_GROUP_TOUR,
  );

  const handleEnable = (id) => {
    enableUserGroupTour({ variables: { id, userGroupId } });
  };

  return {
    loading,
    onEnable: handleEnable,
  };
};

export default useEnableTour;
