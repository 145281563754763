import { gql } from '@apollo/client';

import AddressFragment from './AddressFragment';

const DoctorFragment = gql`
  fragment DoctorFragment on Doctor {
    id
    lastName
    firstName
    rpps
    adeli
    finess
    phone
    address {
      ...AddressFragment
    }
    email
    speciality
    extra
    archived
  }
  ${AddressFragment}
`;

export default DoctorFragment;
