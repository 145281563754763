import React from 'react'
import { CSVLink } from "react-csv";
import { ListItemText, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
  },
}));

const ExportCsv = (props) => {
  const { data, filename, headers, title, onClick } = props;
  const classes = useStyles();

  if (data && filename && headers) {
    return (
      <CSVLink
        data={data}
        headers={headers}
        filename={filename}
        onClick={onClick}
        target="_blank"
        className={classes.title}
      >
        <ListItemText>{title ? title : 'Exporter'}</ListItemText>
      </CSVLink>
    );
  }

  return null;
}

export default ExportCsv