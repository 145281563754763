const getCause = (cause) => {
  switch (cause) {
    case 'disease':
      return 'Maladie';
    case 'motherhood':
      return 'Maternité';
    case 'workAccident':
      return 'Accident de travail';

    default:
      return null;
  }
};

export default getCause;
