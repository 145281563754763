import moment from 'moment';

const getBirthday = ({ patient, offset = 5 }) => {
  let now = moment();
  let birthdayMoment = moment([
    now.year(),
    patient.birthMonth,
    patient.birthDay,
  ]);
  let birthMoment = moment([
    patient.birthYear,
    patient.birthMonth,
    patient.birthDay,
  ]);
  const age = now.diff(birthMoment, 'years');

  if (birthdayMoment.isBefore(moment().startOf('day'))) {
    // Birthday passed this year, set it to next year
    birthdayMoment.add(1, 'year');
  }

  const showBirthday = moment(birthdayMoment)
    .subtract(offset, 'days')
    .isSameOrBefore(moment().startOf('day'));

  const duration = birthdayMoment.isSame(moment().startOf('day'))
    ? "aujourd'hui"
    : birthdayMoment.from(moment().startOf('day'));

  const birthday = `${birthdayMoment.format('DD MMMM')} ${patient.birthYear}`;

  return { showBirthday, age, duration, birthday };
};

export default getBirthday;
