import React from 'react';
import {
  MenuItem,
  Checkbox,
  Typography,
  ListItemText,
  Select as MuiSelect,
} from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './useStyles';

const CareCheckupMultiSelect = ({ label, items, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.field)}>
      <Typography component="p" className={classes.label} variant="caption">
        {label}
      </Typography>

      <MuiSelect
        {...rest}
        classes={{ select: classes.select }}
        margin="dense"
        variant="outlined"
        fullWidth
        multiple
        autoWidth
        renderValue={(selected) =>
          `- ${selected
            .map(
              (value) => items.find((i) => i.value === value)?.label || 'n/a',
            )
            .join('\n\n- ')}`
        }
        onChange={(e) => {
          rest.onChange(e);
        }}
      >
        {items.map(({ label, value }) => (
          <MenuItem style={{ whiteSpace: 'normal' }} key={value} value={value}>
            <Checkbox checked={rest.value.indexOf(value) > -1} />
            <ListItemText>{label}</ListItemText>
          </MenuItem>
        ))}
      </MuiSelect>
    </div>
  );
};

export default CareCheckupMultiSelect;
