import { gql } from '@apollo/client';

import AddressFragment from './AddressFragment';

const DoctorSummaryFragment = gql`
  fragment DoctorSummaryFragment on DoctorSummary {
    id
    lastName
    firstName
    rpps
    adeli
    finess
    address {
      ...AddressFragment
    }
  }
  ${AddressFragment}
`;

export default DoctorSummaryFragment;
