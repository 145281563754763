import React from 'react';

import usePatient from '../Patient/usePatient';
import useCareCheckup from './useCareCheckup';

import AppLayout from '../System/AppLayout';
import BsiCheckup2Form from './BsiCheckup2Form';
import BipCheckupForm from './BipCheckupForm';
import SectionProgress from '../System/SectionProgress';
import { getType } from '../../utils/get-care-checkup-type';

const DuplicateCareCheckup = () => {
  const { loading, careCheckup, type } = useCareCheckup();
  const { patient } = usePatient();

  return (
    <AppLayout title={`Copie de ${getType(type)}`}>
      {loading ? (
        <SectionProgress />
      ) : type === 'bsi2' ? (
        <BsiCheckup2Form
          initialCareCheckup={careCheckup}
          type="create"
          patient={patient}
          autoFocus
          editMode
        />
      ) : type === 'bip' ? (
        <BipCheckupForm
          initialCareCheckup={careCheckup}
          type="create"
          patient={patient}
          autoFocus
          editMode
        />
      ) : null}
    </AppLayout>
  );
};

export default DuplicateCareCheckup;
