const checkSecuNumber = (numss) => {
  var numberString = numss.replace(/\s/g, '').replace(/[^0-9AB.]/g, '');
  var s7 = numberString.substring(6, 7).toUpperCase();

  console.log(numberString, s7);
  if (s7 === 'A' || s7 === 'B')
    numberString =
      numberString.substring(0, 6) + '0' + numberString.substring(7, 15);
  // La zone doit alors etre numérique et ne contenir que 15 digits on valide par expression régulière
  var filter = /^\d{15}$/;
  if (!filter.test(numberString)) {
    console.log('la zone est numérique n a pas 15 caractères');
    return false;
  }
  // la zone est numérique avec 15 caractères
  else {
    const firstChar = numberString.substring(0, 1);

    if (
      firstChar !== '1' &&
      firstChar !== '2' &&
      firstChar !== '7' &&
      firstChar !== '8'
    ) {
      console.log('Ne commence pas par 1, 2, 7 ou 8');
      return false;
    }
    var deb13 = parseInt(numberString.substring(0, 13));
    // traiter le cas de la corse
    if (s7 === 'A') deb13 -= 1000000;
    if (s7 === 'B') deb13 -= 2000000;
    // La Clé SS est égale au reste de la division par 97
    var div97 = 97 - (deb13 % 97);
    var sdiv97 = String(div97).padStart(2, '0');
    var key = numberString.substring(13, 15);

    if (key !== sdiv97) {
      console.log(key, sdiv97);
      return false;
    }
  }
  return true;
};

export default checkSecuNumber;
