import { gql, useMutation } from '@apollo/client';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import useSnackbar from '../System/useSnackbar';
import {
  backSearch,
  initialBackSearch,
  urlWithBacks,
} from '../../utils/back-search';
import {
  LIST_CARE_CHECKUPS,
  LIST_CARE_CHECKUPS_BY_PATIENT,
} from './useCareCheckups';

const CREATE_CARE_CHECKUP = gql`
  mutation CreateCareCheckup(
    $userGroupId: String!
    $patientId: String!
    $type: String!
    $checkup: String!
  ) {
    createCareCheckup(
      userGroupId: $userGroupId
      patientId: $patientId
      type: $type
      checkup: $checkup
    ) {
      id
      patientId
      type
      checkup
    }
  }
`;

const useCreateCareCheckup = ({ generalInputs, type }) => {
  const { showSnackbar } = useSnackbar();
  const { replace } = useHistory();
  const { search } = useLocation();
  const backToUrl = backSearch(search);
  const initialBackUrl = initialBackSearch(search);
  const { userGroupId, patientId } = useParams();
  const [createCareCheckup, { loading }] = useMutation(CREATE_CARE_CHECKUP, {
    variables: {
      userGroupId,
      patientId,
      type,
      checkup: JSON.stringify(generalInputs),
    },
    update(cache, { data: { createCareCheckup } }) {
      try {
        const { listCareCheckups } = cache.readQuery({
          query: LIST_CARE_CHECKUPS,
          variables: { userGroupId },
        });
        cache.writeQuery({
          query: LIST_CARE_CHECKUPS,
          variables: { userGroupId },
          data: {
            listCareCheckups: [
              {
                __typename: 'CareCheckupSummary',
                id: createCareCheckup.id,
                patientId,
                type,
              },
              ...listCareCheckups,
            ],
          },
        });
      } catch (err) {}
      try {
        const { listCareCheckupsByPatient } = cache.readQuery({
          query: LIST_CARE_CHECKUPS_BY_PATIENT,
          variables: { userGroupId, patientId },
        });
        cache.writeQuery({
          query: LIST_CARE_CHECKUPS_BY_PATIENT,
          variables: { userGroupId, patientId },
          data: {
            listCareCheckupsByPatient: [
              {
                __typename: 'CareCheckupSummary',
                id: createCareCheckup.id,
                patientId,
                type,
              },
              ...listCareCheckupsByPatient,
            ],
          },
        });
      } catch (err) {}
    },
    onCompleted({ createCareCheckup }) {
      replace(
        urlWithBacks({
          url: `/g/${userGroupId}/care-checkup/update/${patientId}/${createCareCheckup.id}`,
          back: initialBackUrl || backToUrl,
        }),
      );
      showSnackbar('Bilan créé avec succès.');
    },
  });

  return { loading, onCreate: createCareCheckup };
};

export default useCreateCareCheckup;
