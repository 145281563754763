import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import DoctorFragment from '../../fragments/DoctorFragment';
import cleanObject from '../../utils/clean-object';
import useSnackbar from '../System/useSnackbar';

const UPDATE_DOCTOR = gql`
  mutation UpdateDoctor(
    $id: String!
    $lastName: String!
    $firstName: String
    $rpps: String
    $adeli: String
    $finess: String
    $phone: String
    $address: AddressInput
    $email: String
    $speciality: String
    $extra: String
    $archived: Boolean
  ) {
    updateDoctor(
      id: $id
      lastName: $lastName
      firstName: $firstName
      rpps: $rpps
      adeli: $adeli
      finess: $finess
      phone: $phone
      address: $address
      email: $email
      speciality: $speciality
      extra: $extra
      archived: $archived
    ) {
      ...DoctorFragment
    }
  }

  ${DoctorFragment}
`;

const useUpdateDoctor = ({ generalInputs, onCompleted }) => {
  const { doctorId } = useParams();
  const { showSnackbar } = useSnackbar();

  const [updateDoctor, { loading }] = useMutation(UPDATE_DOCTOR, {
    onCompleted() {
      showSnackbar('Médecin mis à jour');
      onCompleted && onCompleted();
    },
  });

  return {
    loading,
    onUpdate: ({ archived = false } = {}) =>
      updateDoctor({
        variables: {
          ...cleanObject({
            id: doctorId,
            firstName: generalInputs.firstName || '',
            lastName: generalInputs.lastName,
            rpps: generalInputs.rpps || '',
            adeli: generalInputs.adeli || '',
            finess: generalInputs.finess || '',
            phone: generalInputs.phone,
            email: generalInputs.email,
            speciality: generalInputs.speciality,
            extra: generalInputs.extra,
          }),
          archived,
          address: generalInputs.address?.formattedAddress
            ? {
                formattedAddress: generalInputs.address?.formattedAddress,
                placeId: generalInputs.address?.placeId,
                geometry: generalInputs.address?.geometry?.lat
                  ? {
                      lat: generalInputs.address.geometry.lat,
                      lng: generalInputs.address.geometry.lng,
                    }
                  : null,
              }
            : { formattedAddress: '' },
        },
      }),
  };
};

export default useUpdateDoctor;
