import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import AppLayout from '../System/AppLayout';
import AbsenceForm from './AbsenceForm';
import usePatient from '../Patient/usePatient';
import backSearch from '../../utils/back-search';

const CreateAbsence = () => {
  const { userGroupId, patientId } = useParams();
  const { loading, patient } = usePatient();
  const { search: s } = useLocation();
  const backTo = backSearch(s) || `/g/${userGroupId}/patient/update/${patientId}`;

  return (
    <AppLayout
      title="Nouvelle absence"
      backTo={backTo}
    >
      {loading ? null : (
        <AbsenceForm
          type="create"
          initialAbsence={{ patient }}
          autoFocus
          editMode
        />
      )}
    </AppLayout>
  );
};

export default CreateAbsence;
