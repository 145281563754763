import moment from 'moment';

const getNextDayFromDays = (date, days, daysIndex) => {
  if (daysIndex === days.length - 1) {
    const newDaysIndex = 0;
    const newDate = moment(date).isoWeekday(days[newDaysIndex] + 7 + 1);
    return { daysIndex: newDaysIndex, date: newDate.valueOf() };
  } else {
    const newDaysIndex = daysIndex + 1;
    const newDate = moment(date).isoWeekday(days[newDaysIndex] + 1);
    return { daysIndex: newDaysIndex, date: newDate.valueOf() };
  }
};

export default getNextDayFromDays;
