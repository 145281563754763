export const adminUsers = {
  'contact@damien-monni.com': {
    firstName: 'Damien',
    lastName: 'MONNI',
  },
  'mon.coaching.immo@gmail.com': {
    firstName: 'Serge',
    lastName: 'SCHMIDT',
  },
  'stephan.mertz@gmail.com': {
    firstName: 'Stéphan',
    lastName: 'MERTZ',
  },
  'audrey.schmidt02@gmail.com': {
    firstName: 'Audrey',
    lastName: 'SCHMIDT',
  },
  'cabinet.infirmier.schmidt@gmail.com': {
    firstName: 'Emmanuelle',
    lastName: 'SCHMIDT',
  },
  'infirmier.eckbo@free.fr': {
    firstName: 'Guillaume',
    lastName: 'ROBIN',
  },
  'esavitale.fr@gmail.com': {
    firstName: 'Serge',
    lastName: 'SCHMIDT',
  },
}

export const MOMENTS = [
  { value: 'morning', label: 'Matin', from: '05:00', to: '11:30' },
  { value: 'midday', label: 'Midi', from: '11:31', to: '14:00' },
  { value: 'afternoon', label: 'Après-midi', from: '14:01', to: '16:00' },
  { value: 'evening', label: 'Soir', from: '16:01', to: '22:00' },
];

export const MAP_APPS = [
  { value: 'google', label: 'Google Maps' },
  { value: 'apple', label: 'Apple Maps' },
  { value: 'waze', label: 'Waze Maps' },
];
