import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { GET_PATIENT } from '../Patient/usePatient';
import PrescriptionFragment from '../../fragments/PrescriptionFragment';
import cleanObject from '../../utils/clean-object';
import useSnackbar from '../System/useSnackbar';
import { useState } from 'react';

const CREATE_PRESCRIPTION = gql`
  mutation CreatePrescription(
    $userGroupId: String!
    $patientId: String!
    $num: String
    $dateDay: Int
    $dateMonth: Int
    $dateYear: Int
    $photo: ImageInput
    $cause: TREATMENT_CAUSE
    $pec: [ResourceInput]!
    $pecImage: ImageInput
    $motherHoodDay: Int
    $motherHoodMonth: Int
    $motherHoodYear: Int
    $workAccidentDay: Int
    $workAccidentMonth: Int
    $workAccidentYear: Int
    $workAccidentPhoto: ImageInput
    $soins2000Number: String
    $chronic: Boolean!
    $treatments: [TreatmentInput]!
    $doctorId: String
    $cotation: String
    $liens: String
  ) {
    createPrescription(
      userGroupId: $userGroupId
      patientId: $patientId
      num: $num
      dateDay: $dateDay
      dateMonth: $dateMonth
      dateYear: $dateYear
      photo: $photo
      cause: $cause
      pec: $pec
      pecImage: $pecImage
      motherHoodDay: $motherHoodDay
      motherHoodMonth: $motherHoodMonth
      motherHoodYear: $motherHoodYear
      workAccidentDay: $workAccidentDay
      workAccidentMonth: $workAccidentMonth
      workAccidentYear: $workAccidentYear
      workAccidentPhoto: $workAccidentPhoto
      soins2000Number: $soins2000Number
      chronic: $chronic
      treatments: $treatments
      doctorId: $doctorId
      cotation: $cotation
      liens: $liens
    ) {
      ...PrescriptionFragment
    }
  }

  ${PrescriptionFragment}
`;

const useCreatePrescription = ({ generalInputs }) => {
  const { showSnackbar } = useSnackbar();

  const { userGroupId, patientId } = useParams();

  const [createPrescription, { loading }] = useMutation(CREATE_PRESCRIPTION, {
    update(cache, { data: { createPrescription } }) {
      try {
        const { getPatient } = cache.readQuery({
          query: GET_PATIENT,
          variables: { userGroupId, id: patientId },
        });

        const firstTreatmentDate =
          generalInputs.treatments.length &&
          new Date(
            generalInputs.treatments[0].year,
            generalInputs.treatments[0].month,
            generalInputs.treatments[0].day,
          )
            .valueOf()
            .toString();

        cache.writeQuery({
          query: GET_PATIENT,
          variables: { userGroupId, id: patientId },

          data: {
            getPatient: {
              ...getPatient,
              prescriptions: [
                {
                  __typename: 'PrescriptionSummary',
                  id: createPrescription.id,
                  dateDay: createPrescription.dateDay,
                  dateMonth: createPrescription.dateMonth,
                  dateYear: createPrescription.dateYear,
                  cause: createPrescription.cause,
                  doctor: createPrescription.doctor,
                  firstTreatmentDate: firstTreatmentDate,
                  soins2000Number: createPrescription.soins2000Number,
                },
                ...getPatient.prescriptions,
              ],
            },
          },
        });
      } catch (err) {}
    },
    onCompleted() {
      showSnackbar('Ordonnance créée avec succès.');
    },
  });

  const [error, setError] = useState();

  const handleCreate = async () => {
    setError(null);

    // Check treatment dates
    for (const treatment of generalInputs.treatments) {
      if (
        moment([treatment.year, treatment.month, treatment.day]).isBefore(
          moment([
            generalInputs.dateYear,
            generalInputs.dateMonth,
            generalInputs.dateDay,
          ]),
        )
      ) {
        setError(
          "Les dates de soins ne peuvent pas être antérieur à la date de l'ordonnance.",
        );
        throw new Error();
      }
    }

    return createPrescription({
      variables: cleanObject({
        userGroupId,
        patientId,
        num: generalInputs.num,
        dateDay: generalInputs.dateDay,
        dateMonth: generalInputs.dateMonth,
        dateYear: generalInputs.dateYear,
        photo: { key: generalInputs.photo?.key },
        cause: generalInputs.cause,
        pec: generalInputs.pec.map((p) => {
          return {
            key: p.key || p.addedKey,
            size: p.size,
            label: p.label,
            description: p.description,
          };
        }),
        pecImage: { key: generalInputs.pecImage?.key },
        motherHoodDay: generalInputs.motherHoodDay,
        motherHoodMonth: generalInputs.motherHoodMonth,
        motherHoodYear: generalInputs.motherHoodYear,
        workAccidentDay: generalInputs.workAccidentDay,
        workAccidentMonth: generalInputs.workAccidentMonth,
        workAccidentYear: generalInputs.workAccidentYear,
        workAccidentPhoto: { key: generalInputs.workAccidentPhoto?.key },
        soins2000Number: generalInputs.soins2000Number?.length
          ? generalInputs.soins2000Number
          : 'Virtuelle',
        chronic: generalInputs.chronic,
        cotation: generalInputs.cotation,
        liens: generalInputs.liens,
        treatments: generalInputs.treatments.map((treatment) => {
          return {
            id: treatment.id,
            day: treatment.day,
            month: treatment.month,
            year: treatment.year,
            type: treatment.type,
            timeHour: treatment.timeHour,
            timeMinute: treatment.timeMinute,
            count: treatment.count,
            duration: treatment.duration,
            moment: treatment.moment,
            tour: treatment.tour,
            frequency: treatment.frequency,
            frequencyDuration: treatment.frequencyDuration,
            weekDays: treatment.weekDays,
            chronic: treatment.chronic,
            comment: treatment.comment,
            outsourcedTreatments: treatment.outsourcedTreatments,
          };
        }),
        doctorId: generalInputs.doctor?.id,
      }),
    });
  };

  return { loading, error, onCreate: handleCreate };
};

export default useCreatePrescription;
export { CREATE_PRESCRIPTION };
