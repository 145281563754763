import React, { useState } from 'react';
import {
  makeStyles,
  Button,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import moment from 'moment';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import SectionProgress from '../System/SectionProgress';
import AppLayout from '../System/AppLayout';
import SearchField from '../System/SearchField';
import useMessages from './useMessages';
import ConfirmDialog from '../System/ConfirmDialog';
import useDialog from '../System/useDialog';
import useProcessMessages from './useProcessMessages';
import useDeleteMessages from './useDeleteMessages';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  processedButton: {
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
  },
  checkBox: {
    display: 'flex',
    width: '50px',
    height: '65px',
    padding: theme.spacing(0, 0, 0, 3),
  },
  listContainer: {
    flex: 1,
  },
  listItemRoot: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  listItem: {
    flex: 1,
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 2),
    overflow: 'hidden',
    display: 'block',
    '& > p': {
      fontSize: '1rem',
      position: 'relative',
      top: 1,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      lineHeight: '1.3rem',
    },
  },
  emptyState: {
    marginTop: theme.spacing(2),
  },
  loadMoreContainer: {
    margin: theme.spacing(2),
  },
  answered: {
    fontSize: '0.9rem',
    marginRight: theme.spacing(1),
  },
  processed: {
    opacity: 0.7,
    fontStyle: 'italic',
  },
  checkIcon: {
    fontSize: '0.9rem',
    position: 'relative',
    top: 2,
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(1),
  },
  showProcessed: {
    marginLeft: theme.spacing(0.5),
  },
}));

const Messages = () => {
  const {
    loading,
    messages,
    search,
    onSearchChange,
    showProcessed,
    onProcessedChange,
  } = useMessages();
  const classes = useStyles();
  const { userGroupId } = useParams();
  const minWidth500px = useMediaQuery('(min-width:500px)');
  const [checkedMessages, setCheckedMessages] = useState([]);
  const [deleteMode, setDeleteMode] = useState(false);
  const { open, onOpen, onClose } = useDialog();
  const { onMarkMessagesProcessed, loading: loadingMarkProcessed } =
    useProcessMessages(checkedMessages);
  const { onDelete, loading: loadingDelete } =
    useDeleteMessages(checkedMessages);
  const isLoading = loading || loadingMarkProcessed || loadingDelete;

  return (
    <AppLayout
      title="Messages"
      action={
        <Grid
          container
          direction={minWidth500px ? 'row' : 'column'}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs>
            <div className={classes.searchContainer}>
              <SearchField value={search} onChange={onSearchChange} />
            </div>
          </Grid>
          <Grid item>
            <FormControlLabel
              className={classes.showProcessed}
              control={
                <Switch
                  checked={showProcessed}
                  color="primary"
                  onChange={onProcessedChange}
                />
              }
              label="Voir les messages traités"
            />
          </Grid>
          {checkedMessages.length > 0 && (
            <>
              {!showProcessed && (
                <Grid item>
                  <div className={classes.processedButton}>
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      startIcon={<CheckIcon />}
                      onClick={() => {
                        setDeleteMode(false);
                        onOpen();
                      }}
                      disabled={isLoading}
                    >
                      Traiter
                    </Button>
                  </div>
                </Grid>
              )}
              <Grid item>
                <IconButton
                  onClick={() => {
                    setDeleteMode(true);
                    onOpen();
                  }}
                  disabled={isLoading}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </>
          )}
        </Grid>
      }
    >
      <div className={classes.root}>
        {isLoading ? (
          <SectionProgress />
        ) : messages?.length ? (
          <div className={classes.listContainer}>
            <AutoSizer>
              {({ height, width }) => (
                <List
                  height={height}
                  width={width}
                  itemCount={messages.length}
                  itemSize={45}
                >
                  {({ index, style }) => {
                    const message = messages[index];

                    return (
                      <div className={classes.listItemRoot} style={style}>
                        <Checkbox
                          className={classes.checkBox}
                          edge="start"
                          checked={checkedMessages.indexOf(message) !== -1}
                          tabIndex={-1}
                          disableRipple
                          color="primary"
                          value="all"
                          onChange={(e) => {
                            let newCheckedMessages;

                            if (checkedMessages.indexOf(message) === -1) {
                              newCheckedMessages = [
                                ...checkedMessages,
                                message,
                              ];
                            } else {
                              newCheckedMessages = checkedMessages.filter(
                                (m) => m.id !== message.id,
                              );
                            }

                            setCheckedMessages(newCheckedMessages);
                          }}
                        />
                        <ButtonBase
                          className={classes.listItem}
                          component={Link}
                          to={`/g/${userGroupId}/message/update/${message.id}`}
                        >
                          <Typography>
                            {message.processed ? (
                              <>
                                <CheckIcon className={classes.checkIcon} />
                                <CheckIcon
                                  style={{ marginLeft: -8, top: 3 }}
                                  className={classes.checkIcon}
                                />
                              </>
                            ) : message.response ? (
                              <CheckIcon
                                style={{ marginRight: 4 }}
                                className={classes.checkIcon}
                              />
                            ) : null}
                            {message.patient ? (
                              <b>
                                {message.patient.lastName}{' '}
                                {message.patient.firstName}
                              </b>
                            ) : null}{' '}
                            : {message.text}
                          </Typography>
                          <Typography>
                            <Typography variant="caption">
                              Par{' '}
                              <i>
                                {message.author.lastName}{' '}
                                {message.author.firstName}
                              </i>{' '}
                              le{' '}
                              {moment(Number(message.createdAt)).format(
                                'DD/MM/YYYY à HH:mm',
                              )}
                            </Typography>
                          </Typography>
                        </ButtonBase>
                      </div>
                    );
                  }}
                </List>
              )}
            </AutoSizer>
          </div>
        ) : (
          <Typography align="center" className={classes.emptyState}>
            Aucun message.
          </Typography>
        )}
        <ConfirmDialog
          title="Confirmation"
          open={open}
          confirmLoading={loading}
          onConfirm={() => {
            deleteMode ? onDelete() : onMarkMessagesProcessed();
            setCheckedMessages([]);
          }}
          onClose={onClose}
        >
          {`Êtes-vous sûr de vouloir ${
            deleteMode ? 'supprimer' : 'traiter'
          } ce${checkedMessages.length > 1 ? 's' : ''} message${
            checkedMessages.length > 1 ? 's' : ''
          } ?`}
        </ConfirmDialog>
      </div>
    </AppLayout>
  );
};

export default Messages;
