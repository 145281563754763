import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import PatientFragment from '../../fragments/PatientFragment';
import { useState } from 'react';

const ADD_PATIENT_LOG = gql`
  mutation AddPatientLog(
    $userGroupId: String!
    $patientId: String!
    $text: String!
  ) {
    addPatientLog(
      userGroupId: $userGroupId
      patientId: $patientId
      text: $text
    ) {
      ...PatientFragment
    }
  }

  ${PatientFragment}
`;

const useAddPatientLog = ({ patientId }) => {
  const { userGroupId } = useParams();

  const [inputs, setInputs] = useState({ text: '' });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const [addPatientLog, { loading }] = useMutation(ADD_PATIENT_LOG, {
    variables: { userGroupId, patientId, text: inputs.text },
  });

  const [showInputs, setShowInputs] = useState(false);
  const handleToggleShowInputs = () => {
    setShowInputs((prev) => !prev);
  };

  const handleAddPatientLog = async (event) => {
    event && event.preventDefault();

    await addPatientLog();
    setInputs({ text: '' });
    setShowInputs(false);
  };

  return {
    inputs,
    loading,
    showInputs,
    onToggleShowInputs: handleToggleShowInputs,
    onAddPatientLog: handleAddPatientLog,
    onInputChange: handleInputChange,
  };
};

export default useAddPatientLog;
