import { gql } from '@apollo/client';

const ConstantFragment = gql`
  fragment ConstantFragment on Constant {
    id
    date
    datas {
      name
      number
    }
  }
`;

export default ConstantFragment;
