import React from 'react';
import { makeStyles, Typography, ButtonBase } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import ksuid from 'ksuid';
import moment from 'moment';

import SectionProgress from '../System/SectionProgress';
import useCareCheckups from './useCareCheckups';
import { getType } from '../../utils/get-care-checkup-type';
import { filterCareCheckups } from '../../utils/filters';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  listContainer: {
    flex: 1,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  actionIcons: {
    marginLeft: 'auto',
    '& > *': {
      marginRight: theme.spacing(2),
      '& svg': {
        opacity: 0.9,
      },
    },
    '& > *:last-child': {
      marginRight: 0,
    },
  },
  emptyState: {
    marginTop: theme.spacing(2),
  },
}));

const CareCheckups = ({ search }) => {
  const { loading, careCheckups } = useCareCheckups();
  const classes = useStyles();
  const { userGroupId } = useParams();
  const filteredCareCheckups = loading
    ? []
    : filterCareCheckups(careCheckups, search);

  return (
    <div className={classes.root}>
      {loading ? (
        <SectionProgress />
      ) : filteredCareCheckups.length ? (
        <div className={classes.listContainer}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                width={width}
                itemCount={filteredCareCheckups.length}
                itemSize={65}
              >
                {({ index, style }) => {
                  const checkup = filteredCareCheckups[index];
                  const id = ksuid.parse(checkup.id);

                  return (
                    <ButtonBase
                      style={style}
                      className={classes.listItem}
                      component={Link}
                      to={`/g/${userGroupId}/care-checkup/update/${checkup.patientId}/${checkup.id}`}
                    >
                      <div>
                        <Typography variant="body2">
                          {getType(checkup.type)}
                        </Typography>
                        {checkup.patient ? (
                          <Typography variant="body2">
                            {checkup.patient.lastName.toUpperCase()}{' '}
                            {checkup.patient.firstName}
                          </Typography>
                        ) : null}
                        <Typography variant="body2">
                          Créé le{' '}
                          {moment(id.date).format('DD MMMM YYYY [à] HH:mm')}
                        </Typography>
                      </div>
                    </ButtonBase>
                  );
                }}
              </List>
            )}
          </AutoSizer>
        </div>
      ) : (
        <Typography align="center" className={classes.emptyState}>
          Aucun bilan existant.
        </Typography>
      )}
    </div>
  );
};

export default CareCheckups;
