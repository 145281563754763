import { gql } from '@apollo/client';

import AddressFragment from './AddressFragment';
import PrescriptionSummaryFragment from './PrescriptionSummaryFragment';

const PatientSummaryFragment = gql`
  fragment PatientSummaryFragment on PatientSummary {
    id
    firstName
    lastName
    useName
    visitAddress {
      ...AddressFragment
    }
    phones {
      name
      number
    }
    prescriptions {
      ...PrescriptionSummaryFragment
    }
    archived
  }

  ${AddressFragment}
  ${PrescriptionSummaryFragment}
`;

export default PatientSummaryFragment;
