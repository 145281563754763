import { gql } from '@apollo/client';

const CareCheckupSummaryFragment = gql`
  fragment CareCheckupSummaryFragment on CareCheckupSummary {
    id
    patientId
    type
    patient {
      id
      firstName
      lastName
      useName
    }
  }
`;

export default CareCheckupSummaryFragment;
