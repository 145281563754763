import { gql } from '@apollo/client';

import ImageFragment from './ImageFragment';

const TourFragment = gql`
  fragment TourFragment on Tour {
    id
    name
    image {
      ...ImageFragment
    }
    disabled
  }

  ${ImageFragment}
`;

export default TourFragment;
