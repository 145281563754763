import React, { useState } from 'react';
import { Button, Drawer, makeStyles, } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import SearchField from '../System/SearchField';
import Providers from './Providers';

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    height: '85%',
  },
}));

const ProviderPicker = ({ children, ButtonProps, name, onSelect }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [search, setSearch] = useState('');
  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };
  const handleSelect = (provider) => {
    onSelect && onSelect({ target: { name, value: provider } });
    handleClose();
  };

  return (
    <>
      <div>
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          startIcon={<AddIcon />}
          {...ButtonProps}
          onClick={handleOpen}
        >
          {children}
        </Button>
      </div>

      <Drawer
        PaperProps={{ classes: { root: classes.paperRoot } }}
        anchor="bottom"
        open={open}
        onClose={handleClose}
      >
        <>
          <SearchField value={search} onChange={handleSearchChange} />
          <Providers select onSelect={handleSelect} search={search} />
        </>
      </Drawer>
    </>
  );
};

export default ProviderPicker;
