import { gql } from '@apollo/client';

import PatientSummaryFragment from './PatientSummaryFragment';
import ImageFragment from './ImageFragment';
import ResourceFragment from './ResourceFragment';
import TreatmentFragment from './TreatmentFragment';
import DoctorFragment from './DoctorFragment';

const PrescriptionFragment = gql`
  fragment PrescriptionFragment on Prescription {
    id
    patient {
      ...PatientSummaryFragment
    }
    num
    dateDay
    dateMonth
    dateYear
    photo {
      ...ImageFragment
    }
    cause
    pec {
      ...ResourceFragment
    }
    pecImage {
      ...ImageFragment
    }
    motherHoodDay
    motherHoodMonth
    motherHoodYear
    workAccidentDay
    workAccidentMonth
    workAccidentYear
    workAccidentPhoto {
      ...ImageFragment
    }
    soins2000Number
    chronic
    treatments {
      ...TreatmentFragment
    }
    doctor {
      ...DoctorFragment
    }
    archived
    cotation
    liens
  }

  ${PatientSummaryFragment}
  ${ImageFragment}
  ${ResourceFragment}
  ${TreatmentFragment}
  ${DoctorFragment}
`;

export default PrescriptionFragment;
