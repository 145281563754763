import React from 'react';

import useStyles from './useStyles';
import Field from './CareCheckupField';

const CareCheckupSelect = (props) => {
  const classes = useStyles();

  return (
    <Field
      select
      SelectProps={{ native: true, classes: { select: classes.select } }}
      {...props}
    />
  );
};

export default CareCheckupSelect;
