import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { GET_PRESCRIPTION } from './usePrescription';

const DELETE_OUTSOURCED_TREATMENT = gql`
  mutation DeleteOutsourcedTreatment(
    $userGroupId: String!
    $prescriptionId: String!
    $treatmentId: String!
    $outsourcedTreatmentId: String!
  ) {
    deleteOutsourcedTreatment(
      userGroupId: $userGroupId
      prescriptionId: $prescriptionId
      treatmentId: $treatmentId
      outsourcedTreatmentId: $outsourcedTreatmentId
    )
  }
`;

const useDeleteOutsourcedTreatment = ({
  treatmentId,
  outsourcedTreatmentId,
}) => {
  const { userGroupId, prescriptionId } = useParams();

  const [deleteOutsourcedTreatment, { loading }] = useMutation(
    DELETE_OUTSOURCED_TREATMENT,
    {
      variables: {
        userGroupId,
        prescriptionId,
        treatmentId,
        outsourcedTreatmentId,
      },
      update(cache) {
        const { getPrescription } = cache.readQuery({
          query: GET_PRESCRIPTION,
          variables: { userGroupId, id: prescriptionId },
        });
        cache.writeQuery({
          query: GET_PRESCRIPTION,
          variables: { userGroupId, id: prescriptionId },
          data: {
            getPrescription: {
              ...getPrescription,
              treatments: getPrescription.treatments.map((treatment) => {
                if (treatment.id === treatmentId) {
                  return {
                    ...treatment,
                    outsourcedTreatments: treatment.outsourcedTreatments.filter(
                      (t) => t.id !== outsourcedTreatmentId,
                    ),
                  };
                }
                return treatment;
              }),
            },
          },
        });
      },
    },
  );

  return { loading, onDelete: deleteOutsourcedTreatment };
};

export default useDeleteOutsourcedTreatment;
