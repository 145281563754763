import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  fieldsContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
  },
  disablePadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const FieldsContainer = React.forwardRef(
  ({ className, disablePadding, children, ...rest }, ref) => {
    const classes = useStyles();

    return (
      <div
        ref={ref}
        className={clsx(
          classes.fieldsContainer,
          disablePadding && classes.disablePadding,
          className,
        )}
        {...rest}
      >
        {children}
      </div>
    );
  },
);

export default FieldsContainer;
