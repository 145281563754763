const cleanSignedUrl = (url) => {
  let result = url;

  if (result) {
    if (result.indexOf('amazonaws.com/') !== -1) {
      result = result.split('amazonaws.com/')[1];
    }

    result = result.split('?')[0];
  }

  return result;
}

export default cleanSignedUrl;