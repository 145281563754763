import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import useAddTourDialog from './useAddTourDialog';
import TextField from '../System/TextField';
import ProgressButton from '../System/ProgressButton';
import ImageUpload from '../System/ImageUpload';

const AddTourDialog = ({ disabled }) => {
  const {
    loading,
    open,
    name,
    image,
    onImageUploadChange,
    onSubmit,
    onChange,
    onOpen,
    onClose,
  } = useAddTourDialog();

  return (
    <>
      <div>
        <div>
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={onOpen}
            disabled={disabled}
          >
            Ajouter une tournée
          </Button>
        </div>
      </div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Ajouter une tournée</DialogTitle>
        <DialogContent>
          <div>
            <TextField label="Nom" autoFocus value={name} onChange={onChange} />
          </div>

          <ImageUpload
            name="image"
            title="Image"
            value={image}
            editMode
            disablePadding
            onChange={onImageUploadChange}
          />
        </DialogContent>

        <DialogActions>
          <div>
            <Button onClick={onClose}>Annuler</Button>
          </div>
          <div>
            <ProgressButton
              disabled={!!image?.progress}
              loading={loading}
              onClick={onSubmit}
            >
              Ajouter
            </ProgressButton>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddTourDialog;
