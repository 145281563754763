import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

import ProgressButton from './ProgressButton';

const ConfirmDialog = ({
  className,
  open,
  title,
  confirmText,
  onConfirm,
  confirmLoading,
  children,
  onClose,
  ...rest
}) => {
  const handleConfirm = async () => {
    await onConfirm();
    onClose();
  };

  return (
    <Dialog open={open} className={className} onClose={onClose} {...rest}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {typeof children === 'string' ? (
          <DialogContentText>{children}</DialogContentText>
        ) : (
          children
        )}
      </DialogContent>

      <DialogActions>
        <div>
          <Button onClick={onClose}>Annuler</Button>
        </div>
        <div>
          <ProgressButton loading={confirmLoading} onClick={handleConfirm}>
            {confirmText || 'Confirmer'}
          </ProgressButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
