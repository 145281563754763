import React, { useState } from 'react';
import { Button, Typography, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import TextField from '../System/TextField';
import UpdateTour from './UpdateTour';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  tourContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  tourName: {
    flex: 1,
  },
  form: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    flex: 1,
    marginRight: theme.spacing(2),
    width: '100%',
  },
  disabledTour: {
    textDecoration: 'line-through',
    color: theme.palette.error.main,
  },
}));

const Tours = ({ name, tours, noLocalForm, onChange, disabled }) => {
  const classes = useStyles();

  const [tourName, setTourName] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    onChange &&
      onChange({ target: { name, value: [...tours, { name: tourName }] } });
    setTourName('');
  };

  return (
    <>
      {!noLocalForm && !disabled ? (
        <form className={classes.form} onSubmit={handleSubmit}>
          <div className={classes.name}>
            <TextField
              name="tourName"
              value={tourName}
              label="Ajouter une tournée"
              placeholder="Nom"
              fullWidth
              onChange={(event) => setTourName(event.target.value)}
            />
          </div>
          <div>
            <Button startIcon={<AddIcon />} type="submit" variant="outlined">
              Ajouter
            </Button>
          </div>
        </form>
      ) : null}

      {tours.map((tour, index) => (
        <div className={classes.tourContainer} key={`${tour.name}_${index}`}>
          <Typography
            className={clsx(
              classes.tourName,
              tour.disabled && classes.disabledTour,
            )}
          >
            {tour.name}
          </Typography>

          {!disabled && (
            <UpdateTour key={`${tour.name}_${index}`} tour={tour} />
          )}
        </div>
      ))}
    </>
  );
};

export default Tours;
