import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  makeStyles,
  DialogActions,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import TextField from '../System/TextField';
import useDialog from '../System/useDialog';
import useAddTransmissionForm from './useAddTransmissionForm';
import useTours from '../Tours/useTours';
import ProgressButton from '../System/ProgressButton';
import MomentSelect from '../System/MomentSelect';
import SelectDateTimePicker from '../System/SelectDateTimePicker';
import getMoment from '../../utils/get-moment';

const useStyles = makeStyles((theme) => ({
  timeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  timeSep: {
    margin: theme.spacing(0, 1),
  },
  multiFieldSelect: {
    flex: 1,
  },
  datePicker: {
    marginTop: '8px',
  },
}));

const AddTransmissionForm = ({ className, disabled }) => {
  const classes = useStyles();
  const matches375px = useMediaQuery('(min-width:375px)');

  const { open, onOpen, onClose } = useDialog();
  const { loading, tours } = useTours();
  const {
    loading: addLoading,
    error,
    inputs,
    onInputChange,
    onInputsChange,
    onAdd,
  } = useAddTransmissionForm({ tours, onAdded: onClose });
  const [treatmentDate, setTreatmentDate] = React.useState(inputs.date);
  const handleTreatmentDateChange = (date) => {
    if (date) {
      const timeHour = date.getHours();
      const timeMinute = date.getMinutes();
      const transMoment = getMoment(timeHour, timeMinute);

      setTreatmentDate(date);
      onInputsChange({
        date,
        timeHour,
        timeMinute,
        moment: transMoment?.value,
      });
    }
  };

  return loading ? null : (
    <>
      <Button
        className={className}
        size="small"
        startIcon={<AddIcon />}
        variant="outlined"
        color="primary"
        onClick={onOpen}
        disabled={disabled}
      >
        Ajouter un rendez-vous
      </Button>

      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Création d'un rendez-vous</DialogTitle>
        <DialogContent>
          <div>
            <TextField
              name="type"
              label="Type de soins"
              value={inputs.type}
              onChange={onInputChange}
            />
          </div>

          <Grid container spacing={1}>
            <Grid item className={classes.datePicker} xs={12} sm="auto">
              <SelectDateTimePicker
                label="Début des soins"
                format={`${
                  matches375px ? 'E dd/MM/yyyy à HH:mm' : 'dd/MM/yyyy à HH:mm'
                }`}
                value={treatmentDate}
                onChange={handleTreatmentDateChange}
                readOnly={false}
              />
            </Grid>

            <Grid item xs={12} sm="auto">
              <MomentSelect
                value={inputs.moment}
                onChange={onInputChange}
                size="small"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="tourId"
                value={inputs.tourId}
                label="Tournée"
                size="small"
                select
                SelectProps={{
                  native: true,
                }}
                onChange={onInputChange}
              >
                {tours.map((tour) => (
                  <option key={tour.id} value={tour.id}>
                    {tour.name}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <div>
            <TextField
              name="comment"
              multiline
              value={inputs.comment || ''}
              label="Transmissions"
              onChange={onInputChange}
            />
          </div>

          {error?.message ? (
            <Typography color="error">
              {error.message === 'transmission slot already exists'
                ? 'Un rendez-vous existe déjà pour ce créneau.'
                : `Une erreur s'est produite`}
            </Typography>
          ) : null}
        </DialogContent>

        <DialogActions>
          <div>
            <Button variant="outlined" onClick={onClose}>
              Annuler
            </Button>
          </div>
          <div>
            <ProgressButton
              loading={addLoading}
              variant="outlined"
              color="primary"
              onClick={onAdd}
            >
              Sauvegarder
            </ProgressButton>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddTransmissionForm;
