import { gql, useQuery } from '@apollo/client';

import TourFragment from '../../fragments/TourFragment';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { sortTours } from '../../utils/filters';

const GET_TOURS = gql`
  query GetTours($userGroupId: String!, $enabledOnly: Boolean) {
    getTours(userGroupId: $userGroupId, enabledOnly: $enabledOnly) {
      ...TourFragment
    }
  }

  ${TourFragment}
`;

const useTours = (enabledOnly = true) => {
  const { userGroupId } = useParams();

  const { loading, data } = useQuery(GET_TOURS, {
    variables: {
      userGroupId,
      enabledOnly,
    },
  });

  const tours = useMemo(() => {
    return sortTours(data?.getTours.slice());
  }, [data]);

  return { loading, tours };
};

export default useTours;
export { GET_TOURS };
