import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { ButtonBase, List, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';

import AppLayout from '../System/AppLayout';
import SectionProgress from '../System/SectionProgress';
import useTours from './useTours';

const useStyles = makeStyles((theme) => ({
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: `${theme.spacing(1)}px`,
    padding: theme.spacing(1),
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    flexDirection: 'column',
  },
  figure: {
    margin: 0,
    padding: theme.spacing(1),

    '& img': {
      maxWidth: '210px',
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const Tours = () => {
  const classes = useStyles();

  const { userGroupId } = useParams();
  const { tours, loading } = useTours();

  return (
    <AppLayout title="Tournées">
      {loading ? (
        <SectionProgress />
      ) : (
        <List className={classes.list}>
          {tours.map((tour) => (
            <ButtonBase
              className={classes.card}
              key={tour.id}
              component={Link}
              to={`/g/${userGroupId}/transmissions/${tour.id
                }/${moment.utc().startOf('day').valueOf()}`}
            >
              {tour.image?.key ? (
                <figure className={classes.figure}>
                  <img
                    src={tour.image.key}
                    alt={tour.name}
                  />
                </figure>
              ) : null}
              <Typography align="center" paragraph>
                <b>{tour.name}</b>
              </Typography>
            </ButtonBase>
          ))}
        </List>
      )}
    </AppLayout>
  );
};

export default Tours;
