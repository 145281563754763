import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import DoctorFragment from '../../fragments/DoctorFragment';

const GET_DOCTOR = gql`
  query GetDoctor($id: String!) {
    getDoctor(id: $id) {
      ...DoctorFragment
    }
  }

  ${DoctorFragment}
`;

const GET_DOCTOR_WITH_RPPS = gql`
  query GetDoctorWithRpps($rpps: String!) {
    getDoctorWithRpps(rpps: $rpps) {
      ...DoctorFragment
    }
  }

  ${DoctorFragment}
`;

const useDoctor = () => {
  const { doctorId } = useParams();

  const { data, loading } = useQuery(GET_DOCTOR, {
    variables: { id: doctorId },
  });

  return {
    loading,
    doctor: data?.getDoctor,
  };
};

export default useDoctor;
export { GET_DOCTOR, GET_DOCTOR_WITH_RPPS };
