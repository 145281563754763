import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';

import AdminTopBar from '../System/AdminTopbar';
import ProgressButton from '../System/ProgressButton';
import useCurrentUser from '../System/useCurrentUser';
import useInviteUser from './useInviteUser';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(1, 2, 2, 2),
  },
  submitContainer: {
    marginTop: theme.spacing(2),
  },
}));

const InviteUser = () => {
  const classes = useStyles();
  const { userGroupId } = useParams();
  const { loading, inputs, onInputChange, onSubmit, error } = useInviteUser();
  const { isSuperAdmin } = useCurrentUser();

  return (
    <>
      <AdminTopBar
        title="Invitation"
        backTo={`/admin/user-groups/edit/${userGroupId}`}
      />
      <form className={classes.form} onSubmit={onSubmit}>
        <div>
          <TextField
            name="email"
            label="E-mail"
            fullWidth
            type="email"
            variant="outlined"
            margin="normal"
            autoFocus
            disabled={loading}
            value={inputs.email}
            onChange={onInputChange}
          />
        </div>
        <div>
          <TextField
            name="firstName"
            label="Prénom"
            fullWidth
            variant="outlined"
            margin="normal"
            disabled={loading}
            value={inputs.firstName}
            onChange={onInputChange}
          />
        </div>
        <div>
          <TextField
            name="lastName"
            label="Nom"
            fullWidth
            variant="outlined"
            margin="normal"
            disabled={loading}
            value={inputs.lastName}
            onChange={onInputChange}
          />
        </div>
        {isSuperAdmin ? (
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={inputs.isAdmin}
                  color="primary"
                  onChange={onInputChange}
                  name="isAdmin"
                />
              }
              label={<Typography>Administrateur</Typography>}
            />
          </div>
        ) : null}

        <div className={classes.submitContainer}>
          <ProgressButton
            loading={loading}
            fullWidth
            variant="contained"
            type="submit"
            color="primary"
          >
            Inviter
          </ProgressButton>
        </div>

        {error?.message ? (
          <Typography color="error">
            {error.message === 'Email invalid'
              ? "L'adresse email n'est pas valide"
              : error.message === 'User already in a group'
              ? "L'utilisateur est déjà présent dans un autre groupe"
              : error.message}
          </Typography>
        ) : null}
      </form>
    </>
  );
};

export default InviteUser;
