import React, { useMemo, useState } from 'react';
import {
  makeStyles,
  Button,
  Divider,
  Grid,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Close';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Mail';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';

import QRCodeIcon from '../System/QrCodeIcon';
import ProgressButton from '../System/ProgressButton';
import TextField from '../System/TextField';
import usePatientForm from './usePatientForm';
import FieldsContainer from '../System/FieldsContainer';
import QRCodeReaderDialog from '../System/QRCodeReaderDialog';
import useDialog from '../System/useDialog';
import ImageUpload from '../System/ImageUpload';
import Resources from '../Resource/Resources';
import PrescriptionsList from '../Prescription/PrescriptionsList';
import Logs from './Logs';
import AddPatientLogForm from './AddPatientLogForm';
import AddTransmissionForm from './AddTransmissionForm';
import AddConstantForm from '../Constant/AddConstantForm';
import CustomTransmissionsList from './CustomTransmissionsList';
import DoctorPicker from '../Doctor/DoctorPicker';
import PatientCareCheckups from '../CareCheckup/PatientCareCheckups';
import AbsencesList from './AbsencesList';
import ProviderPicker from '../Provider/ProviderPicker';
import GooglePlaceAutoComplete from '../System/GooglePlaceAutoComplete';
import MessagesList from './MessagesList';
import ShowIf from '../System/ShowIf';
import ConstantList from '../Constant/ConstantList';
import useNetworkStatus from '../System/useNetworkStatus';
import SelectDatePicker from '../System/SelectDatePicker';
import NumberSelect from '../System/NumberSelect';

const useStyles = makeStyles((theme) => ({
  form: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  inputsContainer: {
    overflow: 'auto',
    '-webkit-overflow-scrolling': 'touch',
  },
  fieldset: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  addPhoneContainer: {
    margin: theme.spacing(2, 0),
  },
  subtitle: {
    margin: theme.spacing(2, 2),
    fontWeight: 'bold',
  },
  logsContainer: {
    margin: theme.spacing(0, 2, 2, 2),
  },
  actionBar: {
    margin: theme.spacing(2),
  },
  customTransmissions: {
    margin: theme.spacing(2),
    fontWeight: 'bold',
  },
  addCustomTransmissionForm: {
    margin: theme.spacing(0, 2),
  },
  constants: {
    margin: theme.spacing(0, 2),
    fontWeight: 'bold',
  },
  addConstants: {
    margin: theme.spacing(2, 0, 2, 2),
  },
  absences: {
    margin: theme.spacing(2),
    marginBottom: 0,
    fontWeight: 'bold',
  },
  messages: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    fontWeight: 'bold',
  },
  dmpContainer: {
    padding: theme.spacing(2),
  },
  dmpTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  submitContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  numSecuRank: {
    marginTop: theme.spacing(1),
    minWidth: 50,
  },
}));

const renderProviders = ({
  editMode,
  generalInputs,
  initialPatient,
  onProviderAdd,
  onProviderDelete,
}) => {
  const providers = editMode
    ? generalInputs.providers
    : initialPatient.providers;

  return (
    <FieldsContainer>
      <Typography gutterBottom component="p" variant="caption">
        Prestataires
      </Typography>

      {providers?.length ? (
        <List dense>
          {providers.map((provider) => {
            const phone = provider.phones?.find((p) => !!p?.number)?.number;

            return (
              <ListItem key={provider.id} disableGutters divider>
                <ListItemText
                  primary={`${provider.lastName}${
                    provider.firstName ? ' ' + provider.firstName : ''
                  }`}
                />
                <ShowIf condition={editMode || phone}>
                  <ListItemSecondaryAction>
                    <ShowIf condition={phone}>
                      <IconButton
                        component="a"
                        href={`tel:${phone}`}
                        size="small"
                      >
                        <PhoneIcon />
                      </IconButton>
                    </ShowIf>
                    <ShowIf condition={editMode}>
                      <IconButton
                        edge="end"
                        onClick={() => onProviderDelete(provider.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ShowIf>
                  </ListItemSecondaryAction>
                </ShowIf>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Typography gutterBottom variant="caption" component="p">
          Aucun prestataire ajouté
        </Typography>
      )}

      {editMode ? (
        <ProviderPicker name="providers" onSelect={onProviderAdd}>
          Ajouter un prestataire
        </ProviderPicker>
      ) : null}
    </FieldsContainer>
  );
};

const PatientForm = ({
  initialPatient,
  type,
  autoFocus,
  editMode,
  seeAllLogs,
  hasMoreLogs,
  tours,
  onToggleSeeAllLogs,
  onSubmitted,
}) => {
  const classes = useStyles();

  const { userGroupId, patientId } = useParams();
  const { readOnly } = useNetworkStatus();

  const {
    loading,
    generalInputs,
    phones,
    error,
    mutationError,
    onAddPhone,
    onPhoneChange,
    onGeneralInputChange,
    onGeneralInputsChange,
    onVitaleUploadChange,
    onMutuelleUploadChange,
    onProviderAdd,
    onProviderDelete,
    onSubmit,
  } = usePatientForm({ initialPatient, type, tours, onSubmitted });

  // Mutuelle flashcode
  const {
    open: mutuelleOpen,
    onOpen: onMutuelleOpen,
    onClose: onMutuelleClose,
  } = useDialog();

  const [birthDate, setBirthDate] = useState(
    new moment(
      new Date(
        generalInputs.birthYear,
        generalInputs.birthMonth,
        generalInputs.birthDay,
      ),
    ),
  );

  const loadingImg = useMemo(() => {
    return (
      typeof generalInputs.vitaleCard?.progress !== 'undefined' ||
      typeof generalInputs.mutuelleCertificate?.progress !== 'undefined'
    );
  }, [generalInputs]);

  return (
    <div className={classes.form}>
      <div className={classes.inputsContainer}>
        <fieldset className={classes.fieldset} disabled={!editMode}>
          <div>
            <TextField
              required
              autoFocus={autoFocus}
              name="firstName"
              value={generalInputs.firstName}
              label="Prénom"
              onChange={onGeneralInputChange}
            />
          </div>
          <div>
            <TextField
              required
              name="lastName"
              value={generalInputs.lastName}
              label="Nom"
              onChange={onGeneralInputChange}
            />
          </div>

          <ShowIf condition={editMode || generalInputs.useName}>
            <div>
              <TextField
                name="useName"
                value={generalInputs.useName}
                label="Nom d'usage (si différent)"
                onChange={onGeneralInputChange}
              />
            </div>
          </ShowIf>

          <div>
            <GooglePlaceAutoComplete
              required
              name="visitAddress"
              label="Adresse de visite"
              placeholder=""
              value={generalInputs.visitAddress}
              onChange={onGeneralInputChange}
            />
          </div>

          <ShowIf
            condition={editMode || generalInputs.homeAddress?.formattedAddress}
          >
            <div>
              <GooglePlaceAutoComplete
                name="homeAddress"
                label="Adresse domicile"
                placeholder=""
                value={generalInputs.homeAddress}
                helperText="Si différente de l'adresse de visite"
                onChange={onGeneralInputChange}
              />
            </div>
          </ShowIf>

          <ShowIf condition={editMode || generalInputs.addressExtra}>
            <div>
              <TextField
                name="addressExtra"
                value={generalInputs.addressExtra}
                label="Complément d'adresse"
                multiline
                onChange={onGeneralInputChange}
              />
            </div>
          </ShowIf>

          <ShowIf condition={editMode || generalInputs.comment}>
            <div>
              <TextField
                name="comment"
                multiline
                value={generalInputs.comment}
                label="Commentaire"
                onChange={onGeneralInputChange}
              />
            </div>
          </ShowIf>

          {phones.map((phone, index) => (
            <ShowIf
              key={`phone-${index}`}
              condition={editMode || phone.name || phone.number}
            >
              <FieldsContainer>
                <Typography component="p" variant="caption">
                  Téléphone {index + 1}
                </Typography>

                <ShowIf condition={editMode || phone.name}>
                  <div>
                    <TextField
                      name={`phone-name-${index}`}
                      value={phone.name}
                      label={'Rôle - fonction - lien'}
                      onChange={onPhoneChange}
                    />
                  </div>
                </ShowIf>

                <ShowIf condition={editMode || phone.number}>
                  <div>
                    <TextField
                      name={`phone-number-${index}`}
                      value={phone.number}
                      type="tel"
                      error={Boolean(phone.name) && !Boolean(phone.number)}
                      helperText={
                        Boolean(phone.name) && !Boolean(phone.number)
                          ? 'Un nom a été renseigné sans numéro de téléphone'
                          : null
                      }
                      InputProps={{
                        endAdornment:
                          !editMode && phone.number ? (
                            <InputAdornment position="end">
                              <IconButton
                                component="a"
                                href={`tel:${phone.number}`}
                              >
                                <PhoneIcon />
                              </IconButton>
                            </InputAdornment>
                          ) : null,
                      }}
                      label={'Numéro'}
                      onChange={onPhoneChange}
                    />
                  </div>
                </ShowIf>
              </FieldsContainer>
            </ShowIf>
          ))}

          {editMode ? (
            <div className={classes.addPhoneContainer}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                startIcon={<AddIcon />}
                onClick={onAddPhone}
              >
                Ajouter un numéro
              </Button>
            </div>
          ) : null}

          <ShowIf
            condition={
              editMode || generalInputs.email1Name || generalInputs.email1Value
            }
          >
            <FieldsContainer>
              <Typography component="p" variant="caption">
                E-mail 1
              </Typography>

              <ShowIf condition={editMode || generalInputs.email1Name}>
                <div>
                  <TextField
                    name="email1Name"
                    value={generalInputs.email1Name}
                    label="Nom e-mail 1"
                    onChange={onGeneralInputChange}
                  />
                </div>
              </ShowIf>

              <ShowIf condition={editMode || generalInputs.email1Value}>
                <div>
                  <TextField
                    name="email1Value"
                    type="email"
                    value={generalInputs.email1Value}
                    label="E-mail 1"
                    error={
                      Boolean(generalInputs.email1Name) &&
                      !Boolean(generalInputs.email1Value)
                    }
                    helperText={
                      Boolean(generalInputs.email1Name) &&
                      !Boolean(generalInputs.email1Value)
                        ? 'Un nom a été renseigné sans e-mail'
                        : null
                    }
                    InputProps={{
                      endAdornment:
                        !editMode && generalInputs.email1Value ? (
                          <InputAdornment position="end">
                            <IconButton
                              component="a"
                              href={`mailto:${generalInputs.email1Value}`}
                            >
                              <EmailIcon />
                            </IconButton>
                          </InputAdornment>
                        ) : null,
                    }}
                    onChange={onGeneralInputChange}
                  />
                </div>
              </ShowIf>
            </FieldsContainer>
          </ShowIf>

          <ShowIf condition={editMode || generalInputs.contact1}>
            <div>
              <TextField
                name="contact1"
                value={generalInputs.contact1}
                label="Identifiant divers 1"
                helperText="WhatsApp, Skype, autre..."
                onChange={onGeneralInputChange}
              />
            </div>
          </ShowIf>

          <FieldsContainer>
            <SelectDatePicker
              keyboard={true}
              disableToolbar={false}
              label="Date de naissance"
              openTo="year"
              views={['year', 'month', 'date']}
              value={birthDate}
              onChange={(date) => {
                onGeneralInputsChange({
                  birthDay: date?.getDate(),
                  birthMonth: date?.getMonth(),
                  birthYear: date?.getFullYear(),
                });
                setBirthDate(date);
              }}
              readOnly={!editMode}
            />
          </FieldsContainer>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs>
              <TextField
                name="socialSecuNumber"
                value={generalInputs.socialSecuNumber}
                label="Numéro de sécurité sociale"
                onChange={onGeneralInputChange}
              />
            </Grid>
            <Grid item>
              <NumberSelect
                name="socialSecuNumberRank"
                value={generalInputs.socialSecuNumberRank}
                label="Rang"
                start={1}
                end={5}
                step={1}
                onChange={onGeneralInputChange}
              />
            </Grid>
          </Grid>
        </fieldset>

        <fieldset className={classes.fieldset}>
          <ImageUpload
            name="vitaleCard"
            title="Carte Vitale"
            value={generalInputs.vitaleCard}
            editMode={editMode}
            emptyText="Aucune carte Vitale n'a été ajoutée"
            onChange={onVitaleUploadChange}
            onDelete={onGeneralInputChange}
            multiple={true}
          />

          <ImageUpload
            name="mutuelleCertificate"
            title="Attestation Mutuelle"
            value={generalInputs.mutuelleCertificate}
            editMode={editMode}
            emptyText="Aucune attestation Mutuelle n'a été ajoutée"
            onChange={onMutuelleUploadChange}
            onDelete={onGeneralInputChange}
            multiple={true}
          />
        </fieldset>

        <fieldset className={classes.fieldset} disabled={!editMode}>
          <QRCodeReaderDialog
            open={mutuelleOpen}
            name="mutuelle"
            onDecode={onGeneralInputChange}
            onClose={onMutuelleClose}
          />
          <div>
            <TextField
              name="mutuelle"
              value={generalInputs.mutuelle}
              label="Flashcode Mutuelle"
              InputProps={{
                endAdornment: editMode ? (
                  <InputAdornment position="end">
                    <IconButton onClick={onMutuelleOpen}>
                      <QRCodeIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
              onChange={onGeneralInputChange}
            />
          </div>

          <div>
            <TextField
              name="preferredTourId"
              value={generalInputs.preferredTourId}
              label="Tournée préferée"
              select
              SelectProps={{
                native: true,
              }}
              onChange={onGeneralInputChange}
            >
              {tours.map((tour) => (
                <option key={tour.id} value={tour.id}>
                  {tour.name}
                </option>
              ))}
            </TextField>
          </div>

          <FieldsContainer>
            <Typography gutterBottom component="p" variant="caption">
              Médecin traitant
            </Typography>

            <Typography
              gutterBottom
              component="p"
              variant={generalInputs.doctor ? 'body1' : 'caption'}
            >
              {generalInputs.doctor
                ? `${generalInputs.doctor.lastName} ${
                    generalInputs.doctor.firstName || ''
                  }`
                : 'Aucun médecin traitant'}
              <ShowIf condition={!editMode && initialPatient?.doctor?.phone}>
                <IconButton
                  component="a"
                  href={`tel:${initialPatient?.doctor?.phone}`}
                >
                  <PhoneIcon />
                </IconButton>
              </ShowIf>
            </Typography>

            <ShowIf condition={editMode}>
              <DoctorPicker name="doctor" onChange={onGeneralInputChange} />
            </ShowIf>
          </FieldsContainer>

          {renderProviders({
            editMode,
            generalInputs,
            initialPatient,
            onProviderAdd,
            onProviderDelete,
          })}

          <Resources
            disabled={!editMode}
            name="resources"
            resources={generalInputs.resources}
            onChange={onGeneralInputChange}
          />
        </fieldset>

        {initialPatient ? (
          <FieldsContainer className={classes.logsContainer}>
            <Typography variant="caption" component="p">
              Diagnostics
            </Typography>

            <Logs
              logs={initialPatient.logs || []}
              editMode={editMode}
              seeAllLogs={seeAllLogs}
              hasMore={hasMoreLogs}
              onToggleSeeAllLogs={onToggleSeeAllLogs}
            />

            <AddPatientLogForm
              patientId={initialPatient.id}
              disabled={!editMode}
            />
          </FieldsContainer>
        ) : null}

        {type === 'update' ? (
          <>
            <Typography className={classes.constants} gutterBottom>
              Constantes
            </Typography>
            <div>
              <AddConstantForm
                patientId={patientId}
                className={classes.addConstants}
                disabled={readOnly || editMode}
              />
              <Button
                className={classes.addConstants}
                size="small"
                variant="outlined"
                color="primary"
                component={Link}
                to={`/g/${userGroupId}/patient/constantCharts/${patientId}`}
                disabled={
                  initialPatient.constants?.length === 0 || readOnly || editMode
                }
              >
                Voir les courbes
              </Button>
              <Button
                className={classes.addConstants}
                size="small"
                variant="outlined"
                color="primary"
                component={Link}
                to={`/constantPrint/${userGroupId}/patient/${patientId}`}
                target="_blank"
                disabled={
                  initialPatient.constants?.length === 0 || readOnly || editMode
                }
              >
                Impression
              </Button>
            </div>
            <ConstantList
              patientId={patientId}
              constants={initialPatient.constants}
              disabled={readOnly || editMode}
            />

            <Typography className={classes.subtitle}>Ordonnances</Typography>
            <div className={classes.actionBar}>
              <div>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  startIcon={<AddIcon />}
                  component={Link}
                  to={`/g/${userGroupId}/prescription/create/${patientId}`}
                  disabled={readOnly || editMode}
                >
                  Créer une ordonnance
                </Button>
              </div>
            </div>
            <PrescriptionsList
              prescriptions={initialPatient.prescriptions}
              disabled={readOnly || editMode}
            />

            <Typography className={classes.customTransmissions}>
              Rendez-vous exceptionnels
            </Typography>
            <AddTransmissionForm
              className={classes.addCustomTransmissionForm}
              disabled={readOnly || editMode}
            />
            <CustomTransmissionsList
              transmissions={initialPatient.customTransmissions}
              disabled={readOnly || editMode}
            />

            <Divider />
            <Typography className={classes.subtitle}>Bilans</Typography>
            <PatientCareCheckups disabled={readOnly || editMode} />

            <Divider />
            <Typography className={classes.absences}>Absences</Typography>
            <AbsencesList
              absences={initialPatient.absences}
              disabled={readOnly || editMode}
            />

            <Divider />
            <Typography className={classes.messages}>Messages</Typography>
            <MessagesList
              messages={initialPatient.messages}
              disabled={readOnly || editMode}
            />

            <Divider style={{ marginTop: 8 }} />
            <div className={classes.dmpContainer}>
              <Typography className={classes.dmpTitle}>DMP</Typography>
              <div>
                <TextField
                  name="dmpINSC"
                  value={generalInputs.dmpINSC}
                  label="DMP numéro INS-C"
                  type="number"
                  onChange={onGeneralInputChange}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>

      {editMode ? (
        <div className={classes.submitContainer}>
          {error ? (
            <Typography paragraph variant="body2" color="error">
              {error}
            </Typography>
          ) : null}
          {mutationError?.message ? (
            <Typography paragraph variant="body2" color="error">
              {mutationError.message ===
              'patient already exists with this socialSecuNumber'
                ? 'Un patient existe déjà avec ce numéro de sécurité sociale.'
                : mutationError.message ===
                  'patient already exists with this name'
                ? 'Un patient existe déjà avec ce nom et prénom.'
                : "Une erreur s'est produite"}
            </Typography>
          ) : null}

          <ProgressButton
            loading={loading}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              !generalInputs.firstName ||
              !generalInputs.lastName ||
              !generalInputs.visitAddress?.formattedAddress ||
              !generalInputs.birthDay ||
              typeof generalInputs.birthMonth === 'undefined' ||
              !generalInputs.birthYear ||
              loadingImg
            }
            onClick={onSubmit}
          >
            {loadingImg
              ? 'Chargement des images en cours...'
              : type === 'update'
              ? 'Sauvegarder'
              : 'Créer'}
          </ProgressButton>
        </div>
      ) : null}
    </div>
  );
};

export default PatientForm;
