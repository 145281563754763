import React from 'react';
import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import { Link, useLocation, useParams } from 'react-router-dom';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ksuid from 'ksuid';
import moment from 'moment';

import SectionProgress from '../System/SectionProgress';
import { useCareCheckupsByPatient } from './useCareCheckups';
import { getType } from '../../utils/get-care-checkup-type';
import { urlWithBacks } from '../../utils/back-search';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  listContainer: {
    flex: 1,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  actionIcons: {
    marginLeft: 'auto',
    '& > *': {
      marginRight: theme.spacing(2),
      '& svg': {
        opacity: 0.9,
      },
    },
    '& > *:last-child': {
      marginRight: 0,
    },
  },
  emptyState: {
    marginTop: theme.spacing(2),
  },
  paper: {
    maxHeight: 300,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },
}));

const PatientCareCheckups = ({ disabled }) => {
  const classes = useStyles();
  const { userGroupId, patientId } = useParams();
  const { loading, careCheckups } = useCareCheckupsByPatient(patientId);
  const { pathname } = useLocation();

  return (
    <div className={classes.root}>
      {loading ? (
        <SectionProgress />
      ) : careCheckups.length ? (
        <Paper className={classes.paper}>
          <List dense disablePadding>
            {careCheckups.map((checkup) => {
              const id = ksuid.parse(checkup.id);

              return (
                <ListItem
                  divider
                  button
                  key={checkup.id}
                  component={Link}
                  to={urlWithBacks({
                    url: `/g/${userGroupId}/care-checkup/update/${checkup.patientId}/${checkup.id}`,
                    back: pathname,
                  })}
                  disabled={disabled}
                >
                  <ListItemText>
                    {getType(checkup.type)} - Créé le{' '}
                    {moment(id.date).format('DD MMMM YYYY [à] HH:mm')}
                  </ListItemText>
                  <ArrowRightIcon />
                </ListItem>
              );
            })}
          </List>
        </Paper>
      ) : (
        <Typography align="center" className={classes.emptyState}>
          Aucun bilan existant.
        </Typography>
      )}
    </div>
  );
};

export default PatientCareCheckups;
