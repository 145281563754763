import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import UserGroupFragment from '../../fragments/UserGroupFragment';
import useSnackbar from '../System/useSnackbar';
import useForm from '../System/useForm';

const UPDATE_USER = gql`
  mutation UpdateUser(
    $groupId: String!
    $userId: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $isAdmin: Boolean!
  ) {
    updateUser(
      groupId: $groupId
      userId: $userId
      email: $email
      firstName: $firstName
      lastName: $lastName
      isAdmin: $isAdmin
    ) {
      ...UserGroupFragment
    }
  }

  ${UserGroupFragment}
`;

const useUpdateUser = ({ initialUser, onUpdated }) => {
  const { userGroupId } = useParams();

  const { inputs: user, onInputChange } = useForm({
    initialValues: initialUser,
  });

  const { showSnackbar } = useSnackbar();
  const [updateUser, { loading, error }] = useMutation(UPDATE_USER, {
    variables: {
      groupId: userGroupId,
      userId: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      isAdmin: user.isAdmin,
    },
    onCompleted() {
      showSnackbar('Utilisateur mis à jour');
      onUpdated();
    },
  });

  const handleUpdate = async (event) => {
    event.preventDefault();
    try {
      await updateUser();
    } catch (err) {
      // Do nothing, let Apollo useMutation handle errors
    }
  };

  return { user, loading, error, onInputChange, onUpdate: handleUpdate };
};

export default useUpdateUser;
