import { gql, useMutation } from '@apollo/client';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { LIST_MESSAGES } from './useMessages';
import { GET_PATIENT } from '../Patient/usePatient';
import MessageFragment from '../../fragments/MessageFragment';
import backSearch from '../../utils/back-search';
import cleanObject from '../../utils/clean-object';
import useSnackbar from '../System/useSnackbar';

const CREATE_MESSAGE = gql`
  mutation CreateMessage(
    $userGroupId: String!
    $patientId: String
    $date: String!
    $text: String!
  ) {
    createMessage(
      userGroupId: $userGroupId
      patientId: $patientId
      date: $date
      text: $text
    ) {
      ...MessageFragment
    }
  }

  ${MessageFragment}
`;

const useCreateMessage = ({ generalInputs }) => {
  const { showSnackbar } = useSnackbar();
  const { replace } = useHistory();
  const { userGroupId, patientId } = useParams();
  const { search: s } = useLocation();
  const backTo = backSearch(s) || `/g/${userGroupId}/patient/update/${patientId}`;
  const [createMessage, { loading }] = useMutation(CREATE_MESSAGE, {
    update(cache, { data: { createMessage } }) {
      // Update messages list
      const updateMessageList = (processed) => {
        try {
          const { listMessages } = cache.readQuery({
            query: LIST_MESSAGES,
            variables: { userGroupId, processed },
          });
          cache.writeQuery({
            query: LIST_MESSAGES,
            variables: { userGroupId, processed },
            data: {
              listMessages: {
                ...listMessages,
                data: [createMessage, ...listMessages.data],
              },
            },
          });
        } catch (err) { }
      };
      updateMessageList(false);
      updateMessageList(true);

      // Update patient's messages
      try {
        const { getPatient } = cache.readQuery({
          query: GET_PATIENT,
          variables: { userGroupId, id: patientId },
        });
        cache.writeQuery({
          query: GET_PATIENT,
          variables: { userGroupId, id: patientId },
          data: {
            getPatient: {
              ...getPatient,
              messages: [createMessage, ...getPatient.messages],
            },
          },
        });
      } catch (err) { }
    },
    onCompleted() {
      replace(backTo);
      showSnackbar('Message créé avec succès.');
    },
  });

  const handleCreate = () => {
    createMessage({
      variables: cleanObject({
        userGroupId,
        patientId,
        date: generalInputs.date,
        text: generalInputs.text,
      }),
    });
  };

  return { loading, onCreate: handleCreate };
};

export default useCreateMessage;
