const normalize = (s) => {
  return !s
    ? s
    : s
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
};

export default normalize;
