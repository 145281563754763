import { useQuery } from '@apollo/client';
import { isAdmin, isSuperAdmin, GET_CURRENT_USER } from './useRoles';

const useCurrentUser = () => {
  const { data, loading } = useQuery(GET_CURRENT_USER);
  const user = data?.currentUser;
  const superAdmin = user?.roles?.length && isSuperAdmin(user.roles);
  const admin = user?.roles?.length && isAdmin(user.roles);

  return {
    user,
    userLoading: loading,
    isAdmin: admin,
    isSuperAdmin: superAdmin,
  };
};

export default useCurrentUser;
