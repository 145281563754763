import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import MessageFragment from '../../fragments/MessageFragment';
import cleanObject from '../../utils/clean-object';
import useSnackbar from '../System/useSnackbar';

const UPDATE_MESSAGE = gql`
  mutation UpdateMessage(
    $userGroupId: String!
    $id: String!
    $date: String!
    $text: String!
    $response: String
  ) {
    updateMessage(
      userGroupId: $userGroupId
      id: $id
      date: $date
      text: $text
      response: $response
    ) {
      ...MessageFragment
    }
  }

  ${MessageFragment}
`;

const useUpdateMessage = ({ generalInputs }) => {
  const { messageId, userGroupId } = useParams();
  const { showSnackbar } = useSnackbar();

  const [updateMessage, { loading }] = useMutation(UPDATE_MESSAGE, {
    variables: cleanObject({
      userGroupId,
      id: messageId,
      date: generalInputs.date,
      text: generalInputs.text,
      response: generalInputs.response,
    }),
    refetchQueries: ['ListMessages'],
    onCompleted() {
      showSnackbar('Message mis à jour');
    },
  });

  return { loading, onUpdate: updateMessage };
};

export default useUpdateMessage;
