import React from 'react';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { fr } from 'date-fns/locale';

function SelectDateTimePicker({
  disabled = false,
  disableToolbar = true,
  showTodayButton = false,
  disablePast = false,
  disableFuture = false,
  format = 'E dd/MM/yyyy à HH:mm',
  value,
  onChange,
  readOnly = false,
  label,
}) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
      <DateTimePicker
        id="date-picker-inline"
        disabled={disabled}
        disableToolbar={disableToolbar}
        disablePast={disablePast}
        disableFuture={disableFuture}
        showTodayButton={showTodayButton}
        inputVariant="outlined"
        format={format}
        margin="dense"
        label={label}
        value={value}
        onChange={onChange}
        keyboardbuttonprops={{
          'aria-label': 'change date',
        }}
        readOnly={readOnly}
        ampm={false}
        invalidDateMessage="Format de date invalide"
        cancelLabel="Annuler"
        todayLabel="Aujourd'hui"
      />
    </MuiPickersUtilsProvider>
  );
}

export default SelectDateTimePicker;
