import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

const DELETE_RESOURCE = gql`
  mutation DeleteResource($userGroupId: String!, $key: String!) {
    deleteResource(userGroupId: $userGroupId, key: $key)
  }
`;

const useResourceDelete = ({ key }) => {
  const { userGroupId } = useParams();

  const [deleteResource, { loading }] = useMutation(DELETE_RESOURCE, {
    variables: {
      userGroupId,
      key,
    },
  });

  const handleDelete = async () => {
    if (key) {
      await deleteResource();
    }
  };

  return { loading, onDelete: handleDelete };
};

export default useResourceDelete;
