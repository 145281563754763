import React /*, { lazy }*/ from 'react';
import { makeStyles } from '@material-ui/core';
import { Switch, Route, useParams, Redirect } from 'react-router-dom';

import Nav from '../Nav/Nav';
import Sidebar from './Sidebar';
import useSidebarDrawerContext, {
  SidebarDrawerContext,
} from './useSidebarDrawerContext';

import Patients from '../Patient/Patients';
import CreatePatient from '../Patient/CreatePatient';
import UpdatePatient from '../Patient/UpdatePatient';
import CreatePrescription from '../Prescription/CreatePrescription';
import DuplicatePrescription from '../Prescription/DuplicatePrescription';
import UpdatePrescription from '../Prescription/UpdatePrescription';
import Tours from '../Tours/Tours';
import Transmissions from '../Transmission/Transmissions';
import TreatmentTransmissions from '../Transmission/TreatmentTransmissions';
import TransmissionPage from '../Transmission/TransmissionPage';
import DoctorsPage from '../Doctor/DoctorsPage';
import CreateDoctor from '../Doctor/CreateDoctor';
import UpdateDoctor from '../Doctor/UpdateDoctor';
import ProvidersPage from '../Provider/ProvidersPage';
import CreateProvider from '../Provider/CreateProvider';
import UpdateProvider from '../Provider/UpdateProvider';
import CreateAbsence from '../Absence/CreateAbsence';
import AbsencesPage from '../Absence/AbsencesPage';
import UpdateAbsence from '../Absence/UpdateAbsence';
import ListPrescriptions from '../Prescription/ListPrescriptions';
import CreateMessage from '../Message/CreateMessage';
import Messages from '../Message/Messages';
import UpdateMessage from '../Message/UpdateMessage';
import ErrorBoundary from '../System/ErrorBoundary';
import CareCheckupsPage from '../CareCheckup/CareCheckupsPage';
import UpdateCareCheckup from '../CareCheckup/UpdateCareCheckup';
import CreateCareCheckup from '../CareCheckup/CreateCareCheckup';
import DuplicateCareCheckup from '../CareCheckup/DuplicateCareCheckup';
import ConstantChart from '../Constant/ConstantChart';

/*const Patients = lazy(() => import('../Patient/Patients'));
const CreatePatient = lazy(() => import('../Patient/CreatePatient'));
const UpdatePatient = lazy(() => import('../Patient/UpdatePatient'));*/

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    height: '100vh',
    overflow: 'auto',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
    '& > *': {
      overflow: 'auto',
    },
  },
  nav: {
    marginTop: 'auto',
  },
}));

const MainApp = ({ showEmbed = false, onEmbed }) => {
  const classes = useStyles();

  const { userGroupId } = useParams();

  const {
    open: sidebarDrawerOpen,
    onClose: onSidebarDrawerClose,
    onToggle: onSidebarDrawerToggle,
  } = useSidebarDrawerContext();

  return (
    <>
      <div className={classes.root}>
        <SidebarDrawerContext.Provider
          value={{ onToggle: onSidebarDrawerToggle }}
        >
          <Sidebar
            drawerOpen={sidebarDrawerOpen}
            onDrawerClose={onSidebarDrawerClose}
          />

          <main className={classes.main}>
            <ErrorBoundary>
              <Switch>
                <Route path="/g/:userGroupId/patients">
                  <Patients />
                </Route>

                <Route path="/g/:userGroupId/patient/create">
                  <CreatePatient />
                </Route>

                <Route path="/g/:userGroupId/patient/update/:patientId">
                  <UpdatePatient />
                </Route>

                <Route path="/g/:userGroupId/prescriptions/">
                  <ListPrescriptions />
                </Route>

                <Route path="/g/:userGroupId/prescription/create/:patientId">
                  <CreatePrescription />
                </Route>

                <Route path="/g/:userGroupId/prescription/duplicate/:patientId/:prescriptionId">
                  <DuplicatePrescription />
                </Route>

                <Route path="/g/:userGroupId/prescription/update/:patientId/:prescriptionId">
                  <UpdatePrescription />
                </Route>

                <Route path="/g/:userGroupId/prescription/transmissions/:patientId/:prescriptionId/:treatmentId">
                  <TreatmentTransmissions />
                </Route>

                <Route path="/g/:userGroupId/tours">
                  <Tours />
                </Route>

                <Route path="/g/:userGroupId/transmissions/:tourId/:date">
                  <Transmissions />
                </Route>

                <Route path="/g/:userGroupId/transmission/:tourId/:date/:transmissionId">
                  <TransmissionPage />
                </Route>

                <Route path="/g/:userGroupId/doctors">
                  <DoctorsPage />
                </Route>

                <Route path="/g/:userGroupId/doctor/create">
                  <CreateDoctor />
                </Route>

                <Route path="/g/:userGroupId/doctor/update/:doctorId">
                  <UpdateDoctor />
                </Route>

                <Route path="/g/:userGroupId/providers">
                  <ProvidersPage />
                </Route>

                <Route path="/g/:userGroupId/provider/create">
                  <CreateProvider />
                </Route>

                <Route path="/g/:userGroupId/provider/update/:providerId">
                  <UpdateProvider />
                </Route>

                <Route path="/g/:userGroupId/absence/create/:patientId">
                  <CreateAbsence />
                </Route>

                <Route path="/g/:userGroupId/absence/update/:patientId/:absenceStartDate/:absenceId">
                  <UpdateAbsence />
                </Route>

                <Route path="/g/:userGroupId/absences">
                  <AbsencesPage />
                </Route>

                <Route path="/g/:userGroupId/message/create/:patientId">
                  <CreateMessage />
                </Route>

                <Route path="/g/:userGroupId/message/update/:messageId">
                  <UpdateMessage />
                </Route>

                <Route path="/g/:userGroupId/messages">
                  <Messages />
                </Route>

                <Route path="/g/:userGroupId/care-checkups">
                  <CareCheckupsPage />
                </Route>

                <Route path="/g/:userGroupId/care-checkup/create/:patientId/:type">
                  <CreateCareCheckup />
                </Route>

                <Route path="/g/:userGroupId/care-checkup/duplicate/:patientId/:careCheckupId">
                  <DuplicateCareCheckup />
                </Route>

                <Route path="/g/:userGroupId/care-checkup/update/:patientId/:careCheckupId">
                  <UpdateCareCheckup />
                </Route>

                <Route path="/g/:userGroupId/patient/constantCharts/:patientId">
                  <ConstantChart />
                </Route>

                <Redirect to={`/g/${userGroupId}/tours`} />
              </Switch>
            </ErrorBoundary>

            <Nav
              className={classes.nav}
              showEmbed={showEmbed}
              onEmbed={onEmbed}
            />
          </main>
        </SidebarDrawerContext.Provider>
      </div>
    </>
  );
};

export default MainApp;
