import React from 'react';
import { Button } from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';

import useDialog from '../System/useDialog';
import ConfirmDialog from '../System/ConfirmDialog';

const ArchiveDoctor = ({ className, loading, onArchive }) => {
  const { open, onOpen, onClose } = useDialog();

  return (
    <>
      <div className={className}>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          startIcon={<ArchiveIcon />}
          onClick={onOpen}
        >
          Supprimer
        </Button>
      </div>

      <ConfirmDialog
        title="Supprimer le médecin"
        open={open}
        confirmLoading={loading}
        onConfirm={onArchive}
        onClose={onClose}
      >
        Êtes-vous sûr de vouloir supprimer ce médecin ? Cette action est
        irréversible.
      </ConfirmDialog>
    </>
  );
};

export default ArchiveDoctor;
