import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import IMask from 'imask';

import useCreateProvider from './useCreateProvider';
import useUpdateProvider from './useUpdateProvider';
import useUpload from '../../utils/useUpload';

const phoneNumerMasked = IMask.createMask({
  mask: '00 00 00 00 00',
});

const useProviderForm = ({ initialProvider = {}, type, onSubmitted }) => {
  const { userGroupId } = useParams();
  const { replace } = useHistory();

  // General inputs
  const [generalInputs, setGeneralInputs] = useState({
    firstName: initialProvider.firstName || '',
    lastName: initialProvider.lastName || '',
    email1Name: initialProvider.email1Name || '',
    email1Value: initialProvider.email1Value || '',
    address: initialProvider.address || {},
    adeli: initialProvider.adeli || '',
    siret: initialProvider.siret || '',
    speciality: initialProvider.speciality || '',
    unlockCode: initialProvider.unlockCode || '',
    RIB: initialProvider.RIB || '',
    CPS: initialProvider.CPS,
    archived: Boolean(initialProvider.archived),
  });

  const handleGeneralInputChange = (event) => {
    const { name, value } = event.target;
    setGeneralInputs((prevGeneralInputs) => ({
      ...prevGeneralInputs,
      [name]: value,
    }));
  };

  // Phone
  const [phones, setPhones] = useState(
    initialProvider.phones?.length
      ? initialProvider.phones.map((p) => ({
          name: p.name || '',
          number: p.number || '',
        }))
      : [
          { name: '', number: '' },
          { name: '', number: '' },
        ],
  );
  const handlePhoneChange = (event) => {
    // Name format: phone-{name|number}-{index}
    const { name, value } = event.target;

    setPhones(
      phones.map((p, index) => {
        const [, fieldToEdit, indexToEdit] = name.split('-');
        if (indexToEdit === index.toString()) {
          return {
            ...p,
            [fieldToEdit]:
              fieldToEdit === 'name' ? value : phoneNumerMasked.resolve(value),
          };
        }
        return p;
      }),
    );
  };

  // Images uploads
  const { progress: cpsProgress, onUpload: handleCPSUploadChange } = useUpload({
    name: 'CPS',
    onUploaded: handleGeneralInputChange,
  });

  // Create
  const { loading: createLoading, onCreate } = useCreateProvider({
    generalInputs,
    phones,
  });

  // Update
  const { loading: updateLoading, onUpdate } = useUpdateProvider({
    generalInputs,
    phones,
  });

  // Archive
  const handleArchive = async () => {
    await onUpdate({ archived: true });
    replace(`/g/${userGroupId}/providers`);
  };

  // Submit
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (type === 'update') {
      await onUpdate();
    } else if (type === 'create') {
      await onCreate();
    }

    onSubmitted && onSubmitted();
  };

  return {
    loading: createLoading || updateLoading || cpsProgress,
    generalInputs,
    phones,
    onGeneralInputChange: handleGeneralInputChange,
    onPhoneChange: handlePhoneChange,
    onCPSUploadChange: handleCPSUploadChange,
    onSubmit: handleSubmit,
    onArchive: handleArchive,
  };
};

export default useProviderForm;
