import React from 'react';
import {
  makeStyles,
  Checkbox,
  ButtonBase,
  Typography,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Link, useParams, useLocation } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import CheckIcon from '@material-ui/icons/Check';
import moment from 'moment';
import clsx from 'clsx';

import SectionProgress from '../System/SectionProgress';
import { MOMENTS } from '../System/MomentSelect';
import { isInProgress } from './libs/absence-utils';
import { urlWithBacks } from '../../utils/back-search';
import getCause from '../../utils/get-cause';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  searchContainer: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}77`,
  },
  processedContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 2),
  },
  listContainer: {
    flex: 1,
  },
  listItemRoot: {
    display: 'flex',
    justifyContent: 'flex-start',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  checkBox: {
    padding: theme.spacing(0, 0, 0, 3),
  },
  listItem: {
    flex: 1,
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 2),
  },
  listItemProcessed: {
    padding: theme.spacing(0, 0, 0, 1),
  },
  actionIcons: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    '& > *': {
      marginRight: theme.spacing(1),
      '& svg': {
        opacity: 0.9,
      },
    },
    '& > *:last-child': {
      marginRight: 0,
    },
  },
  emptyState: {
    marginTop: theme.spacing(2),
  },
  absenceInProgress: {
    color: '#28bcff',
  },
}));

const Absences = ({
  select,
  onSelect,
  loading,
  absences,
  showProcessed,
  onCheckedAbsences,
  checkedAbsences,
}) => {
  const classes = useStyles();
  const { userGroupId } = useParams();
  const { pathname } = useLocation();

  return (
    <div className={classes.root}>
      {loading ? (
        <SectionProgress />
      ) : absences.length ? (
        <div className={classes.listContainer}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                width={width}
                itemCount={absences.length}
                itemSize={65}
              >
                {({ index, style }) => {
                  const absence = absences[index];
                  const patient = absence.patient;
                  const inProgress = isInProgress(absence);
                  const hasEndDate =
                    Number(absence.endDate) <
                    moment.utc('2100-01-01T00:00:00.000Z').valueOf();
                  const prescDesc = absence.prescriptionsIds
                    ? absence.prescriptionsIds
                        .map((prescId) => {
                          const presc = patient.prescriptions?.find(
                            (x) => x.id === prescId,
                          );
                          if (presc) {
                            return `${
                              presc.soins2000Number
                                ? 'N° Fact: ' + presc.soins2000Number + ' - '
                                : ''
                            }${
                              presc.doctor ? presc.doctor.lastName + ' - ' : ''
                            }${moment([
                              presc.dateYear,
                              presc.dateMonth,
                              presc.dateDay,
                            ]).format('DD/MM/YYYY')} - ${getCause(
                              presc.cause,
                            )}`;
                          }

                          return '';
                        })
                        .join(', ')
                    : '';
                  const absenceId = `${absence.startDate}#${absence.id}`;

                  return (
                    <div className={classes.listItemRoot} style={style}>
                      {!showProcessed && (
                        <Checkbox
                          className={classes.checkBox}
                          edge="start"
                          checked={checkedAbsences.indexOf(absenceId) !== -1}
                          tabIndex={-1}
                          disableRipple
                          color="primary"
                          value="all"
                          onChange={(e) => {
                            let newCheckedAbsences;

                            if (checkedAbsences.indexOf(absenceId) === -1) {
                              newCheckedAbsences = [
                                ...checkedAbsences,
                                absenceId,
                              ];
                            } else {
                              newCheckedAbsences = checkedAbsences.filter(
                                (id) => id !== absenceId,
                              );
                            }

                            onCheckedAbsences(newCheckedAbsences);
                          }}
                        />
                      )}
                      <ButtonBase
                        className={clsx(
                          classes.listItem,
                          showProcessed && classes.listItemProcessed,
                          inProgress && classes.absenceInProgress,
                        )}
                        component={select ? undefined : Link}
                        to={
                          select
                            ? undefined
                            : urlWithBacks({
                                url: `/g/${userGroupId}/absence/update/${absence.patientId}/${absence.startDate}/${absence.id}`,
                                back: pathname,
                              })
                        }
                        onClick={select ? () => onSelect(absence) : undefined}
                      >
                        <div>
                          <Typography
                            style={{
                              flex: 1,
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {absence.processed ? (
                              <CheckIcon style={{ marginRight: 8 }} />
                            ) : null}
                            <strong style={{ marginRight: 4 }}>
                              {patient.lastName}
                            </strong>{' '}
                            {patient.firstName}
                          </Typography>
                          <Typography
                            component="p"
                            variant="caption"
                            style={{ fontStyle: 'italic' }}
                          >
                            {hasEndDate ? 'Du ' : 'À partir du '}
                            {moment
                              .utc(Number(absence.startDate))
                              .format('DD/MM/YYYY')}{' '}
                            {MOMENTS.find(
                              (m) => m.value === absence.startMoment,
                            ).label.toLowerCase()}{' '}
                            {hasEndDate
                              ? ` au ${moment
                                  .utc(Number(absence.endDate))
                                  .format('DD/MM/YYYY')}`
                              : ''}{' '}
                            {hasEndDate
                              ? MOMENTS.find(
                                  (m) => m.value === absence.endMoment,
                                ).label.toLowerCase()
                              : null}
                          </Typography>
                          <Typography component="p" variant="caption">
                            {absence.prescriptionsIds?.length === 0
                              ? 'Toutes les ordonnances'
                              : prescDesc}
                          </Typography>
                        </div>
                      </ButtonBase>
                      <div className={classes.actionIcons}>
                        <ArrowRightIcon />
                      </div>
                    </div>
                  );
                }}
              </List>
            )}
          </AutoSizer>
        </div>
      ) : (
        <Typography align="center" className={classes.emptyState}>
          Aucune absence existante.
        </Typography>
      )}
    </div>
  );
};

export default Absences;
