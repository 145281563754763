import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import CheckIcon from '@material-ui/icons/Check';
import CallIcon from '@material-ui/icons/Call';
import MessageIcon from '@material-ui/icons/Message';
import TransmissionPhotoIcon from '@material-ui/icons/PhotoCamera';
import { useParams, Link } from 'react-router-dom';
import clsx from 'clsx';

import useTransmissions from './useTransmissions';
import AppLayout from '../System/AppLayout';
import SectionProgress from '../System/SectionProgress';
import getBirthday from '../System/getBirthday';
import SearchField from '../System/SearchField';
import useNetworkStatus from '../System/useNetworkStatus';
import useTours from '../Tours/useTours';
import SelectDatePicker from '../System/SelectDatePicker';

const useStyles = makeStyles((theme) => ({
  dateSelectorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2, 0),
  },
  select: {
    margin: theme.spacing(0, 2),
  },
  tourName: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },
  itemTextAbsence: {
    textDecoration: 'line-through',
  },
  PDS: {
    color: theme.palette.green.main,
  },
  socialSecuNumberMissing: {
    color: '#28bcff',
  },
  lastDate: {
    color: 'red',
  },
  photoIcon: {
    color: theme.palette.secondary.main,
  },
  secondaryListItemText: {
    width: '85%',
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: 16,
      height: 16,
    },
    '& > *': {
      marginRight: theme.spacing(0.5),
    },
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    minWidth: '125px',
    maxWidth: '300px',
    marginLeft: 'auto',
  },
  actionIcons: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    '& > *': {
      marginRight: theme.spacing(0),
      '& svg': {
        opacity: 0.9,
      },
    },
    '& > *:last-child': {
      marginRight: 0,
    },
  },
}));

const Transmissions = () => {
  const { offline } = useNetworkStatus();
  const classes = useStyles();

  const { userGroupId, tourId, date } = useParams();
  const { tours } = useTours();

  const {
    loading,
    transmissions,
    search,
    onPickerDateChange,
    onDateOffset,
    onSearchChange,
  } = useTransmissions({
    fetchPolicy: offline ? 'cache-first' : 'network-only',
  });

  return (
    <AppLayout
      title={`Transmissions de la tournée ${
        tours?.find((t) => t.id === tourId)?.name || '...'
      }`}
      action={
        <div className={classes.searchContainer}>
          <SearchField value={search} onChange={onSearchChange} />
        </div>
      }
    >
      <div className={classes.dateSelectorContainer}>
        <IconButton onClick={() => onDateOffset(-1)}>
          <ArrowLeftIcon />
        </IconButton>

        <SelectDatePicker
          label=""
          format="EEEE dd/MM/yyyy"
          value={moment.utc(Number(date))}
          onChange={onPickerDateChange}
          readOnly={false}
        />

        <IconButton onClick={() => onDateOffset(1)}>
          <ArrowRightIcon />
        </IconButton>
      </div>

      {loading ? (
        <SectionProgress />
      ) : (
        <List dense>
          {transmissions?.length ? (
            transmissions.map((transmission) => {
              const isPDS = Boolean(
                transmission.type?.toLowerCase()?.trim() === 'pds',
              );
              const { showBirthday } = getBirthday({
                patient: transmission.patient,
              });
              const isCloseToLastDate =
                transmission.lastDate &&
                moment(Number(transmission.lastDate))
                  .startOf('day')
                  .isBefore(
                    moment(Number(transmission.date))
                      .startOf('day')
                      .add(5, 'days'),
                  );
              const socialSecuNumberMissing =
                !transmission.patient.socialSecuNumber;
              const phone = transmission.patient.phones.find(
                (p) => !!p?.number,
              )?.number;

              return (
                <ListItem
                  key={transmission.id}
                  button
                  component={Link}
                  to={`/g/${userGroupId}/transmission/${tourId}/${date}/${transmission.id}`}
                >
                  <ListItemText
                    classes={{
                      primary: clsx(
                        classes.primaryListItemText,
                        transmission.absence && classes.itemTextAbsence,
                        isPDS && classes.PDS,
                        !isPDS &&
                          socialSecuNumberMissing &&
                          classes.socialSecuNumberMissing,
                        !isPDS && isCloseToLastDate && classes.lastDate,
                      ),
                      secondary: clsx(
                        phone && classes.secondaryListItemText,
                        transmission.absence && classes.itemTextAbsence,
                        isPDS && classes.PDS,
                        !isPDS &&
                          socialSecuNumberMissing &&
                          classes.socialSecuNumberMissing,
                        !isPDS && isCloseToLastDate && classes.lastDate,
                      ),
                    }}
                    secondary={`${moment
                      .utc(Number(transmission.date))
                      .format('HH:mm')}${
                      transmission.type ? ` - ${transmission.type}` : ''
                    }`}
                  >
                    <div className={classes.itemContainer}>
                      {showBirthday ? (
                        <span role="img" aria-label="birthday">
                          🎉{' '}
                        </span>
                      ) : null}
                      {isPDS ? (
                        <span role="img" aria-label="pds">
                          💉{' '}
                        </span>
                      ) : null}

                      {transmission.prescriptionPhotoMissing ? (
                        <span>
                          <TransmissionPhotoIcon
                            className={classes.photoIcon}
                          />
                        </span>
                      ) : null}
                      {Boolean(transmission.done?.date) ? <CheckIcon /> : null}
                      <span>
                        <b>{transmission.patient.lastName.toUpperCase()}</b>{' '}
                        {transmission.patient.firstName}{' '}
                      </span>
                    </div>
                  </ListItemText>
                  <ListItemSecondaryAction>
                    {phone ? (
                      <div className={classes.actionIconss}>
                        <IconButton
                          component="a"
                          href={`tel:${phone}`}
                          size="small"
                        >
                          <CallIcon />
                        </IconButton>
                        <IconButton
                          component="a"
                          href={`sms:${phone}`}
                          size="small"
                        >
                          <MessageIcon />
                        </IconButton>
                        <ArrowRightIcon />
                      </div>
                    ) : (
                      <ArrowRightIcon />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })
          ) : (
            <Typography align="center">
              Aucune transmission n'a été trouvée
            </Typography>
          )}
        </List>
      )}
    </AppLayout>
  );
};

export default Transmissions;
