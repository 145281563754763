import React from 'react';
import {
  Switch,
  Typography,
} from '@material-ui/core';

import useStyles from './useStyles';

const CareCheckupBool = ({ label, value, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.field}>
      <Typography component="p" variant="caption" className={classes.label}>
        {label}
      </Typography>
      <div className={classes.switch}>
        <Typography>Non</Typography>
        <Switch checked={Boolean(value)} color="primary" {...rest} />
        <Typography>Oui</Typography>
      </div>
    </div>
  );
};

export default CareCheckupBool;