import { useState, useCallback } from 'react';

import useCreateMessage from './useCreateMessage';
import useUpdateMessage from './useUpdateMessage';
import useProcessMessage from './useProcessMessage';

const useMessageForm = ({ initialMessage = {}, type, onSubmitted }) => {
  // General inputs
  const [generalInputs, setGeneralInputs] = useState({
    date: initialMessage.date || new Date().valueOf().toString(),
    text: initialMessage.text || '',
    response: initialMessage.response || '',
    processed: initialMessage.processed || false,
  });

  const handleGeneralInputChange = useCallback((event) => {
    const { name, value, checked, type } = event.target;

    setGeneralInputs((prevGeneralInputs) => ({
      ...prevGeneralInputs,
      [name]: type === 'checkbox' ? checked : value,
    }));
  }, []);

  // Create
  const { loading: createLoading, onCreate } = useCreateMessage({
    generalInputs,
  });

  // Update
  const { loading: updateLoading, onUpdate } = useUpdateMessage({
    generalInputs,
  });

  // Process
  const { loading: updateProcess, onProcess } = useProcessMessage();

  // Submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (type === 'update') {
      await onUpdate();
      if (generalInputs.processed) {
        await onProcess();
      }
    } else if (type === 'create') {
      await onCreate();
    }

    onSubmitted && onSubmitted(generalInputs.processed);
  };

  return {
    loading: createLoading || updateLoading || updateProcess,
    generalInputs,

    onGeneralInputChange: handleGeneralInputChange,

    onSubmit: handleSubmit,
  };
};

export default useMessageForm;
