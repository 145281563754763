import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Grid, IconButton, Link } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PrintIcon from '@material-ui/icons/Print';

import useCareCheckup from './useCareCheckup';
import AppLayout from '../System/AppLayout';
import BsiCheckupForm from './BsiCheckupForm';
import BsiCheckup2Form from './BsiCheckup2Form';
import BipCheckupForm from './BipCheckupForm';
import usePatient from '../Patient/usePatient';
import DeleteButton from './DeleteButton';
import SectionProgress from '../System/SectionProgress';
import useCurrentUser from '../System/useCurrentUser';
import { getType } from '../../utils/get-care-checkup-type';
import { backSearch, urlWithBacks } from '../../utils/back-search';

const UpdateCareCheckup = () => {
  const { userGroupId, careCheckupId, patientId } = useParams();
  // Care checkup
  const { loading, careCheckup, createdBy, type } = useCareCheckup();
  // Edit mode
  const [editMode, setEditMode] = useState(false);
  const handleEdit = () => {
    setEditMode(true);
  };
  const handleStopEdit = () => {
    setEditMode(false);
  };
  const { loading: loadingPatient, patient } = usePatient();
  const { isAdmin, isSuperAdmin } = useCurrentUser();
  const { pathname, search } = useLocation();
  const backToUrl = backSearch(search);

  return (
    <AppLayout
      title={getType(type)}
      backTo={`/g/${userGroupId}/care-checkups`}
      action={
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <>
            {!editMode && careCheckup ? (
              <>
                <IconButton onClick={handleEdit} title="Editer">
                  <EditIcon />
                </IconButton>
                <Link
                  href={urlWithBacks({
                    url: `/g/${userGroupId}/care-checkup/duplicate/${patientId}/${careCheckupId}`,
                    back: pathname,
                    initialBack: backToUrl,
                  })}
                  underline="none"
                >
                  <IconButton title="Dupliquer">
                    <ContentCopyIcon />
                  </IconButton>
                </Link>
                {isAdmin || isSuperAdmin ? <DeleteButton /> : null}
                {type === 'bip' && (
                  <IconButton
                    href={`/careCheckupPrint/${userGroupId}/care-checkup/${patientId}/${careCheckupId}`}
                    target="_blank"
                    title="Imprimer"
                  >
                    <PrintIcon />
                  </IconButton>
                )}
              </>
            ) : null}
          </>
        </Grid>
      }
    >
      {loading || loadingPatient ? (
        <SectionProgress />
      ) : type === 'bsi2' ? (
        <BsiCheckup2Form
          initialCareCheckup={careCheckup}
          createdBy={createdBy}
          type="update"
          editMode={editMode}
          patient={patient}
          onSubmitted={handleStopEdit}
        />
      ) : type === 'bip' ? (
        <BipCheckupForm
          initialCareCheckup={careCheckup}
          createdBy={createdBy}
          type="update"
          editMode={editMode}
          patient={patient}
          onSubmitted={handleStopEdit}
        />
      ) : type === 'bsi' ? (
        <BsiCheckupForm
          initialCareCheckup={careCheckup}
          createdBy={createdBy}
          type="update"
          editMode={editMode}
          patient={patient}
          onSubmitted={handleStopEdit}
        />
      ) : null}
    </AppLayout>
  );
};

export default UpdateCareCheckup;
