import React from 'react';

import TextField from './TextField';
import { MOMENTS } from '../../config/global-config';

const MomentSelect = ({ ...rest }) => {
  return (
    <TextField
      name="moment"
      select
      label="Passage"
      SelectProps={{
        native: true,
      }}
      {...rest}
    >
      {MOMENTS.map((moment) => (
        <option key={moment.value} value={moment.value}>
          {moment.label}
        </option>
      ))}
    </TextField>
  );
};

export default MomentSelect;
export { MOMENTS };
