import { gql, useQuery } from '@apollo/client';

const IS_USER_ACTIVE_IN_GROUP = gql`
  query IsUserActiveInGroup($userGroupId: String!, $email: String!) {
    isUserActiveInGroup(userGroupId: $userGroupId, email: $email)
  }
`;

const useIsUserActiveInGroup = ({ userGroupId, email }) => {
  const { loading, data } = useQuery(IS_USER_ACTIVE_IN_GROUP, {
    variables: { userGroupId, email },
    fetchPolicy: 'network-only',
  });

  return { loading, result: data?.isUserActiveInGroup };
};

export default useIsUserActiveInGroup;
