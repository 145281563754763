import React, { useCallback, useEffect, useState } from 'react';
import { MenuItem, ListItemIcon, makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
  },
  hidden: {
    display: 'none',
  },
}));

export const cleanPatient = (patient) => {
  return {
    id: patient.id,
    firstName: patient.firstName,
    lastName: patient.lastName,
    useName: patient.useName,
    homeAddress: patient.homeAddress?.formattedAddress,
    visitAddress: patient.visitAddress?.formattedAddress,
    addressExtra: patient.addressExtra,
    phones: patient.phones?.map((phone) => ({
      name: phone.name,
      number: phone.number,
    })),
    birthDate: `${patient.birthDay}/${patient.birthMonth + 1}/${
      patient.birthYear
    }`,
    emailName: patient.email1Name,
    emailValue: patient.email1Value,
    socialSecuNumber: patient.socialSecuNumber,
    socialSecuNumberRank: patient.socialSecuNumberRank,
    doctor: patient.doctor
      ? {
          firstName: patient.doctor.firstName,
          lastName: patient.doctor.lastName,
          rpps: patient.doctor.rpps,
          adeli: patient.doctor.adeli,
          finess: patient.doctor.finess,
        }
      : null,
  };
};

export const cleanPrescription = (prescription, patient) => {
  return {
    id: prescription.id,
    patientId: prescription.patientId,
    firstName: patient.firstName,
    lastName: patient.lastName,
    useName: patient.useName,
    socialSecuNumber: patient.socialSecuNumber,
    socialSecuNumberRank: patient.socialSecuNumberRank,
    date: `${prescription.dateDay}/${prescription.dateMonth + 1}/${
      prescription.dateYear
    }`,
    cause: prescription.cause,
    motherHoodDate:
      prescription.cause === 'motherhood'
        ? `${prescription.motherHoodDay}/${prescription.motherHoodMonth + 1}/${
            prescription.motherHoodYear
          }`
        : null,
    workAccidentDate:
      prescription.cause === 'workAccident'
        ? `${prescription.workAccidentDay}/${
            prescription.workAccidentMonth + 1
          }/${prescription.workAccidentYear}`
        : null,
    soins2000Number: prescription.soins2000Number,
    cotation: prescription.cotation,
    doctor: prescription.doctor
      ? {
          firstName: prescription.doctor.firstName,
          lastName: prescription.doctor.lastName,
          rpps: prescription.doctor.rpps,
          adeli: prescription.doctor.adeli,
          finess: prescription.doctor.finess,
        }
      : null,
  };
};

const ExportJson = ({ title, filename, data }) => {
  const classes = useStyles();
  const [fileDownloadUrl, setFileDownloadUrl] = useState('');
  const [dofileDownload, setDofileDownload] = useState();

  const download = useCallback((event, data) => {
    event.preventDefault();
    // Prepare the file
    const output = JSON.stringify(data, null, 4);

    // Download it
    const blob = new Blob([output]);
    const fileDownloadUrl = URL.createObjectURL(blob);
    setFileDownloadUrl(fileDownloadUrl);
  }, []);

  useEffect(() => {
    if (fileDownloadUrl !== '') {
      dofileDownload.click();
      URL.revokeObjectURL(fileDownloadUrl); // free up storage--no longer needed.
      setFileDownloadUrl('');
    }
  }, [dofileDownload, fileDownloadUrl]);

  return (
    <>
      <MenuItem onClick={(event) => download(event, data)}>
        <ListItemIcon>
          <GetAppIcon fontSize="small" />
        </ListItemIcon>
        {title ? title : 'Exporter'}
      </MenuItem>
      <a
        className={classes.hidden}
        download={filename}
        href={fileDownloadUrl}
        ref={(e) => setDofileDownload(e)}
      >
        download it
      </a>
    </>
  );
};

export default ExportJson;
