import { gql } from '@apollo/client';

import PrescriptionFragment from './PrescriptionFragment';

const PrescriptionListFragment = gql`
  fragment PrescriptionListFragment on PrescriptionList {
    nextKey
    data {
      ...PrescriptionFragment
    }
  }

  ${PrescriptionFragment}
`;

export default PrescriptionListFragment;
