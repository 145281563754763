import { gql } from '@apollo/client';

import PrescriptionSummaryFragment from './PrescriptionSummaryFragment';

const AbsenceFragment = gql`
  fragment AbsenceFragment on Absence {
    id
    startDate
    startMoment
    endDate
    endMoment
    patientId
    prescriptionsIds
    processed
    patient {
      id
      lastName
      firstName
      prescriptions {
        ...PrescriptionSummaryFragment
      }
    }
  }

  ${PrescriptionSummaryFragment}
`;

export default AbsenceFragment;
