import React from 'react';
import { makeStyles, InputAdornment, IconButton } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import { useParams } from 'react-router-dom';
import EmailIcon from '@material-ui/icons/Mail';

import ProgressButton from '../System/ProgressButton';
import TextField from '../System/TextField';
import useDoctorForm from './useDoctorForm';
import ArchiveDoctor from './ArchiveDoctor';
import useCurrentUser from '../System/useCurrentUser';
import GooglePlaceAutoComplete from '../System/GooglePlaceAutoComplete';
import useNetworkStatus from '../System/useNetworkStatus';

const useStyles = makeStyles((theme) => ({
  form: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  inputsContainer: {
    overflow: 'auto',
    '-webkit-overflow-scrolling': 'touch',
  },
  fieldset: {
    padding: theme.spacing(2),
  },
  cityContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      flex: 1,
    },
    '& > div:first-child': {
      marginRight: theme.spacing(2),
      width: 100,
      flex: 'initial',
    },
  },
  archiveDoctor: {
    marginTop: theme.spacing(1),
  },
  submitContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

const DoctorForm = ({
  initialDoctor,
  type,
  autoFocus,
  editMode,
  onSubmitted,
}) => {
  const { readOnly } = useNetworkStatus();
  const classes = useStyles();

  const { loading, generalInputs, onArchive, onGeneralInputChange, onSubmit } =
    useDoctorForm({ initialDoctor, type, onSubmitted });

  const { isSuperAdmin } = useCurrentUser();
  const { doctorId } = useParams();

  return (
    <div className={classes.form}>
      <div className={classes.inputsContainer}>
        <fieldset className={classes.fieldset} disabled={!editMode}>
          <div>
            <TextField
              required
              name="lastName"
              value={generalInputs.lastName}
              label="Nom / Structure"
              onChange={onGeneralInputChange}
            />
          </div>

          <div>
            <TextField
              autoFocus={autoFocus}
              name="firstName"
              value={generalInputs.firstName}
              label="Prénom"
              onChange={onGeneralInputChange}
            />
          </div>

          <div>
            <TextField
              name="rpps"
              value={generalInputs.rpps}
              label="RPPS"
              onChange={onGeneralInputChange}
            />
          </div>

          <div>
            <TextField
              name="adeli"
              value={generalInputs.adeli}
              label="ADELI / FINESS juridique"
              onChange={onGeneralInputChange}
            />
          </div>

          <div>
            <TextField
              name="finess"
              value={generalInputs.finess}
              label="FINESS établissement"
              onChange={onGeneralInputChange}
            />
          </div>

          <div>
            <TextField
              name="phone"
              value={generalInputs.phone}
              label="Téléphone"
              type="phone"
              InputProps={{
                endAdornment:
                  !editMode && generalInputs.phone ? (
                    <InputAdornment position="end">
                      <IconButton
                        component="a"
                        href={`tel:${generalInputs.phone}`}
                      >
                        <PhoneIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
              }}
              onChange={onGeneralInputChange}
            />
          </div>

          <div>
            <GooglePlaceAutoComplete
              name="address"
              label="Adresse"
              placeholder=""
              value={generalInputs.address}
              onChange={onGeneralInputChange}
            />
          </div>

          <div>
            <TextField
              name="email"
              value={generalInputs.email}
              label="E-mail"
              type="email"
              InputProps={{
                endAdornment:
                  !editMode && generalInputs.email ? (
                    <InputAdornment position="end">
                      <IconButton
                        component="a"
                        href={`mailto:${generalInputs.email}`}
                      >
                        <EmailIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
              }}
              onChange={onGeneralInputChange}
            />
          </div>

          <div>
            <TextField
              name="speciality"
              value={generalInputs.speciality}
              label="Spécialité"
              onChange={onGeneralInputChange}
            />
          </div>

          <div>
            <TextField
              name="extra"
              value={generalInputs.extra}
              label="Disciplines complémentaires"
              onChange={onGeneralInputChange}
            />
          </div>

          {!doctorId ||
          !isSuperAdmin ||
          initialDoctor?.archived ||
          readOnly ? null : (
            <ArchiveDoctor
              className={classes.archiveDoctor}
              loading={loading}
              onArchive={onArchive}
            />
          )}
        </fieldset>
      </div>

      {editMode ? (
        <div className={classes.submitContainer}>
          <ProgressButton
            loading={loading}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              (!generalInputs.firstName && !generalInputs.finess) ||
              !generalInputs.lastName ||
              (!generalInputs.adeli &&
                !generalInputs.rpps &&
                !generalInputs.finess)
            }
            onClick={onSubmit}
          >
            {type === 'update' ? 'Sauvegarder' : 'Créer'}
          </ProgressButton>
        </div>
      ) : null}
    </div>
  );
};

export default DoctorForm;
