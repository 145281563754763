import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import usePatient, { GET_PATIENT } from '../Patient/usePatient';
import ConstantFragment from '../../fragments/ConstantFragment';
import useSnackbar from '../System/useSnackbar';

const CREATE_CONSTANT = gql`
  mutation CreateConstant(
    $userGroupId: String!
    $patientId: String!
    $date: String!
    $datas: [ConstantDataInput]!
  ) {
    createConstant(
      userGroupId: $userGroupId
      patientId: $patientId
      date: $date
      datas: $datas
    ) {
      ...ConstantFragment
    }
  }

  ${ConstantFragment}
`;

const useAddConstantForm = ({ patientId, onAdded }) => {
  const { userGroupId } = useParams();
  const { loading: loadingPatient, patient } = usePatient({ id: patientId });

  const [inputs, setInputs] = useState({
    datas: [],
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const keys = name.split('.');

    if (keys.length && keys[0] === 'datas') {
      const datas = inputs.datas.filter((data) => data.name !== keys[1]);

      if (value && value !== '') {
        setInputs((prevInputs) => ({
          ...prevInputs,
          datas: [...datas, { name: keys[1], number: value }],
        }));
      } else {
        setInputs((prevInputs) => ({ ...prevInputs, datas }));
      }
    } else {
      setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
    }
  };

  const { showSnackbar } = useSnackbar();
  const [createConstant, { loading, error }] = useMutation(CREATE_CONSTANT, {
    variables: {
      userGroupId,
      patientId,
      date: inputs.date
        ? moment.utc(Number(inputs.date)).valueOf().toString()
        : moment().valueOf().toString(),
      datas: inputs.datas,
    },
    update(cache, { data: { createConstant } }) {
      const constants = [...patient.constants, createConstant];

      cache.writeQuery({
        query: GET_PATIENT,
        variables: { userGroupId, id: patientId },
        data: {
          getPatient: {
            ...patient,
            constants: constants.sort((a, b) => (a.date > b.date ? -1 : 1)),
          },
        },
      });
    },
    onCompleted() {
      setInputs({ datas: [] });
      showSnackbar('Constante créée');
      onAdded && onAdded();
    },
  });

  const handleAdd = async (event) => {
    event && event.preventDefault();
    try {
      await createConstant();
    } catch (err) {
      // Do nothing, let Apollo useMutation handle errors
    }
  };

  return {
    loading: loading | loadingPatient,
    error,
    inputs,
    onInputChange: handleInputChange,
    onAdd: handleAdd,
  };
};

export default useAddConstantForm;
