import React from 'react';
import { useParams } from 'react-router-dom';

import AppLayout from '../System/AppLayout';
import ProviderForm from './ProviderForm';

const CreateProvider = () => {
  const { userGroupId } = useParams();

  return (
    <AppLayout
      title="Nouveau prestataire"
      backTo={`/g/${userGroupId}/providers`}
    >
      <ProviderForm type="create" autoFocus editMode />
    </AppLayout>
  );
};

export default CreateProvider;
