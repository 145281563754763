import React, { useContext, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import BackIcon from '@material-ui/icons/ArrowBack';
import { Link, useHistory, useLocation } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { SidebarDrawerContext } from '../Auth/useSidebarDrawerContext';
import {
  backSearch,
  initialBackSearch,
  urlWithBacks,
} from '../../utils/back-search';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 0),
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 0 30px rgba(0, 0, 0, 0.4)',
    position: 'relative',
    zIndex: 1,
  },
  action: {
    textAlign: 'right',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const Topbar = ({
  title,
  backTo: rawBackTo,
  action,
  direction,
  editMode = false,
}) => {
  const classes = useStyles();

  // Handle back search parameter to replace backTo props
  const { onToggle } = useContext(SidebarDrawerContext) || {};
  const { replace } = useHistory();
  const { search: s } = useLocation();
  const backToUrl = backSearch(s);
  const initialBackToUrl = initialBackSearch(s);
  const backTo = urlWithBacks({
    url: backToUrl || rawBackTo,
    back: initialBackToUrl,
  });
  const minWidth500px = useMediaQuery('(min-width:500px)');
  const [openDialog, setOpenDialog] = useState(false);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCancel = () => {
    if (backTo) {
      replace(backTo);
    } else {
      onToggle();
    }

    setOpenDialog(false);
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction={direction || minWidth500px ? 'row' : 'column'}
        justifyContent="flex-start"
        alignItems={direction || minWidth500px ? 'flex-end' : 'flex-start'}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item>
              {editMode ? (
                <IconButton
                  color="inherit"
                  component={'button'}
                  onClick={handleOpenDialog}
                >
                  {backTo ? <BackIcon /> : <MenuIcon />}
                </IconButton>
              ) : (
                <IconButton
                  color="inherit"
                  component={backTo ? Link : 'button'}
                  to={backTo ? backTo : null}
                  replace={backTo ? true : null}
                  onClick={backTo ? null : onToggle}
                >
                  {backTo ? <BackIcon /> : <MenuIcon />}
                </IconButton>
              )}
            </Grid>
            {title ? (
              <Grid item>
                <Typography variant="h1">{title}</Typography>
              </Grid>
            ) : null}
          </Grid>
        </Grid>

        {action ? (
          <Grid item className={classes.action} xs>
            {action}
          </Grid>
        ) : null}
      </Grid>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Attention</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir quitter sans sauvegarder ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              autoFocus
              variant="contained"
              color="primary"
              onClick={handleCloseDialog}
            >
              Annuler
            </Button>
          </div>
          <div>
            <Button variant="contained" color="primary" onClick={handleCancel}>
              Quitter
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Topbar;
