import { useEffect, useState } from 'react';
import moment from 'moment';

import useCreateAbsence from './useCreateAbsence';
import useUpdateAbsence from './useUpdateAbsence';

const useAbsenceForm = ({ initialAbsence = {}, type, onSubmitted }) => {
  // General inputs
  const [dateError, setDateError] = useState();
  const [generalInputs, setGeneralInputs] = useState({
    startDate: Number(initialAbsence.startDate) || new Date().valueOf(),
    startMoment: initialAbsence.startMoment || 'morning',
    endDate: Number(initialAbsence.endDate) || new Date().valueOf(),
    endMoment: initialAbsence.endMoment || 'evening',
    prescriptions: initialAbsence.prescriptionsIds || 'all',
  });

  const handleGeneralInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'prescriptions') {
      if (value === 'all') {
        setGeneralInputs((prev) => ({
          ...prev,
          prescriptions: prev.prescriptions === 'all' ? [] : 'all',
        }));
      } else {
        setGeneralInputs((prev) => ({
          ...prev,
          prescriptions:
            prev.prescriptions === 'all'
              ? [value]
              : prev.prescriptions.indexOf(value) !== -1
              ? [...prev.prescriptions.filter((id) => id !== value)]
              : [...prev.prescriptions, value],
        }));
      }
    } else {
      setGeneralInputs((prevGeneralInputs) => ({
        ...prevGeneralInputs,
        [name]: value,
      }));
    }
  };

  const handleGeneralInputsChange = (values) => {
    setGeneralInputs((prevGeneralInputs) => ({
      ...prevGeneralInputs,
      ...values,
    }));
  };

  // Unknown end date and moment
  const [unknownEnd, setUnknownEnd] = useState(
    Number(initialAbsence.endDate) >=
      moment.utc('2099-01-01T00:00:00.000Z').valueOf(),
  );

  useEffect(() => {
    if (generalInputs) {
      const realMinDate = moment(Number(generalInputs.startDate)).startOf(
        'day',
      );
      const realEndDate = moment(Number(generalInputs.endDate)).endOf('day');
      const now = moment();

      if (!unknownEnd && realMinDate.isAfter(realEndDate)) {
        setDateError({ message: 'min date should be lower than max date' });
      } else if (
        type === 'create' &&
        realMinDate.isBefore(now) &&
        now.diff(realMinDate, 'days') > 5
      ) {
        setDateError({ message: 'min date should not be older then 5 days' });
      } else {
        setDateError(null);
      }
    }
  }, [generalInputs, type, unknownEnd]);

  const handleUnknownEndChange = (event) => {
    const { checked } = event.target;
    if (!checked) {
      setGeneralInputs((prev) => ({
        ...prev,
        endDate: new Date().valueOf(),
        endMoment: 'evening',
      }));
    }
    setUnknownEnd(checked);
  };

  // Create
  const {
    loading: createLoading,
    error: createError,
    onCreate,
  } = useCreateAbsence({
    generalInputs,
    unknownEnd,
  });

  // Update
  const {
    loading: updateLoading,
    error: updateError,
    onUpdate,
  } = useUpdateAbsence({
    generalInputs,
    unknownEnd,
  });

  // Submit
  const handleSubmit = (event) => {
    event.preventDefault();

    if (type === 'update') {
      onUpdate();
    } else if (type === 'create') {
      onCreate();
    }
  };

  return {
    loading: createLoading || updateLoading,
    error: createError || updateError || dateError,
    generalInputs,
    unknownEnd,
    onGeneralInputChange: handleGeneralInputChange,
    onGeneralInputsChange: handleGeneralInputsChange,
    onUnknownEndChange: handleUnknownEndChange,
    onSubmit: handleSubmit,
  };
};

export default useAbsenceForm;
