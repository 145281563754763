import { gql, useMutation } from '@apollo/client';
import React from 'react';
import { DialogContentText, ListItemIcon, MenuItem } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

import useSnackbar from '../System/useSnackbar';
import ConfirmDialog from '../System/ConfirmDialog';
import useDialog from '../System/useDialog';
import { GET_PATIENT } from '../Patient/usePatient';
import { LIST_TRANSMISSIONS_BY_TOUR } from './useTransmissions';

const DELETE_TRANSMISSION_BY_ID = gql`
  mutation DeleteTransmissionById(
    $userGroupId: String!
    $tourId: String!
    $year: String!
    $id: String!
  ) {
    deleteTransmissionById(
      userGroupId: $userGroupId
      tourId: $tourId
      year: $year
      id: $id
    )
  }
`;

const DeleteTransmission = React.forwardRef(
  ({ transmission, onDeleted, backTo }, ref) => {
    const { userGroupId, tourId, date, transmissionId } = useParams();
    const { replace } = useHistory();
    const minDate = moment.utc(Number(date)).startOf('day').valueOf().toString();
    const maxDate = moment.utc(Number(date)).endOf('day').valueOf().toString();

    const {
      open: deleteOpen,
      onOpen: deleteOnOpen,
      onClose: deleteOnClose,
    } = useDialog();

    const { showSnackbar } = useSnackbar();
    const [deleteTransmissionById, { loading }] = useMutation(
      DELETE_TRANSMISSION_BY_ID,
      {
        variables: {
          userGroupId,
          tourId,
          year: moment.utc(Number(date)).year().toString(),
          id: transmissionId,
        },
        update(cache) {
          const patientId = transmission?.patient.id;

          if (patientId) {
            try {
              const { getPatient } = cache.readQuery({
                query: GET_PATIENT,
                variables: { userGroupId, id: patientId },
              });
              cache.writeQuery({
                query: GET_PATIENT,
                variables: { userGroupId, id: patientId },
                data: {
                  getPatient: {
                    ...getPatient,
                    customTransmissions: getPatient.customTransmissions.filter(
                      (trans) => trans.id !== transmissionId,
                    ),
                  },
                },
              });
            } catch (err) { }
          } else {
            try {
              const { listTransmissionsByTour } = cache.readQuery({
                query: LIST_TRANSMISSIONS_BY_TOUR,
                variables: { userGroupId, tourId, minDate, maxDate },
              });
              cache.writeQuery({
                query: LIST_TRANSMISSIONS_BY_TOUR,
                variables: { userGroupId, tourId, minDate, maxDate },
                data: {
                  listTransmissionsByTour: listTransmissionsByTour.filter((trans) => trans.id !== transmissionId),
                },
              });
            } catch (err) { }
          }
        },
        onCompleted() {
          replace(backTo);
          showSnackbar('Transmission supprimée');
        },
      },
    );

    return (
      <>
        <MenuItem
          disabled={loading}
          onClick={() => {
            deleteOnOpen();
          }}
        >
          <ListItemIcon>
            <RemoveIcon fontSize="small" />
          </ListItemIcon>
          Supprimer la transmission
        </MenuItem>

        <ConfirmDialog
          title="Suppression"
          open={deleteOpen}
          confirmLoading={loading}
          onConfirm={deleteTransmissionById}
          onClose={deleteOnClose}
        >
          <DialogContentText>
            Êtes-vous sûr de vouloir supprimer la transmission ?
          </DialogContentText>
          <DialogContentText>Cette action est irréversible.</DialogContentText>
        </ConfirmDialog>
      </>
    );
  },
);

export default DeleteTransmission;
