import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
}));

const SectionProgress = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress size={20} />
    </div>
  );
};

export default SectionProgress;
