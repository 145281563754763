import React from 'react';
import { Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 0),
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 0 30px rgba(0, 0, 0, 0.4)',
    position: 'relative',
    zIndex: 1,
  },
  iconContainer: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
  },
  action: {
    textAlign: 'right',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const Topbar = ({ title, backTo, action, direction }) => {
  const classes = useStyles();
  const minWidth500px = useMediaQuery('(min-width:500px)');

  return (
    <div className={classes.root}>
      <Grid
        container
        direction={direction || minWidth500px ? 'row' : 'column'}
        alignItems={minWidth500px || backTo ? 'center' : 'flex-start'}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {backTo ? (
              <Grid item>
                <IconButton
                  color="inherit"
                  component={Link}
                  to={backTo}
                  replace
                >
                  <BackIcon />
                </IconButton>
              </Grid>
            ) : null}
            {title ? (
              <Grid item>
                <Typography
                  className={clsx(!backTo && classes.title)}
                  variant="h1"
                >
                  {title}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </Grid>

        {action ? (
          <Grid item className={classes.action} xs>
            {action}
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

export default Topbar;
