import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useContext, useMemo } from 'react';

import MessageListFragment from '../../fragments/MessageListFragment';
import { filterMessages } from '../../utils/filters';
import { Store } from '../../utils/store';
import { T } from '../../utils/reducers';

const LIST_MESSAGES = gql`
  query ListMessages($userGroupId: String!, $processed: Boolean) {
    listMessages(userGroupId: $userGroupId, processed: $processed) {
      ...MessageListFragment
    }
  }

  ${MessageListFragment}
`;

const useMessages = () => {
  const { userGroupId } = useParams();
  const { state, dispatch } = useContext(Store);

  const setShowProcessed = (payload) =>
    dispatch({ type: T.listMessagesProcessed, payload });
  const handleProcessedChange = () => {
    setShowProcessed(!state.listMessagesProcessed);
  };
  const setSearch = (payload) =>
    dispatch({ type: T.listMessagesSearch, payload });
  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  const { data, loading } = useQuery(LIST_MESSAGES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      userGroupId,
      processed: state.listMessagesProcessed,
    },
  });

  const filteredMsg = useMemo(() => {
    return filterMessages(data?.listMessages?.data, state.listMessagesSearch);
  }, [data?.listMessages?.data, state.listMessagesSearch]);

  return {
    loading,
    messages: filteredMsg,
    search: state.listMessagesSearch,
    onSearchChange: handleSearchChange,
    showProcessed: state.listMessagesProcessed,
    onProcessedChange: handleProcessedChange,
  };
};

export default useMessages;
export { LIST_MESSAGES };
