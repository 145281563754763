import React from 'react';
import {
  makeStyles,
  InputAdornment,
  IconButton,
  Typography,
} from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Mail';
import { useParams } from 'react-router-dom';

import ProgressButton from '../System/ProgressButton';
import TextField from '../System/TextField';
import useProviderForm from './useProviderForm';
import ShowIf from '../System/ShowIf';
import FieldsContainer from '../System/FieldsContainer';
import GooglePlaceAutoComplete from '../System/GooglePlaceAutoComplete';
import ImageUpload from '../System/ImageUpload';
import ArchiveProvider from './ArchiveProvider';
import useCurrentUser from '../System/useCurrentUser';
import useNetworkStatus from '../System/useNetworkStatus';

const useStyles = makeStyles((theme) => ({
  form: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  inputsContainer: {
    overflow: 'auto',
    '-webkit-overflow-scrolling': 'touch',
  },
  fieldset: {
    padding: theme.spacing(2),
  },
  cityContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      flex: 1,
    },
    '& > div:first-child': {
      marginRight: theme.spacing(2),
      width: 100,
      flex: 'initial',
    },
  },
  submitContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  archiveProvider: {
    marginTop: theme.spacing(1),
  },
}));

const ProviderForm = ({
  initialProvider,
  type,
  autoFocus,
  editMode,
  onSubmitted,
}) => {
  const classes = useStyles();
  const { isSuperAdmin } = useCurrentUser();
  const { readOnly } = useNetworkStatus();
  const { providerId } = useParams();

  const {
    loading,
    generalInputs,
    phones,
    onPhoneChange,
    onGeneralInputChange,
    onCPSUploadChange,
    onSubmit,
    onArchive,
  } = useProviderForm({ initialProvider, type, onSubmitted });

  return (
    <div className={classes.form}>
      <div className={classes.inputsContainer}>
        <fieldset className={classes.fieldset} disabled={!editMode}>
          <ShowIf condition={editMode || generalInputs.firstName}>
            <div>
              <TextField
                required
                autoFocus={autoFocus}
                name="firstName"
                value={generalInputs.firstName}
                label="Prénom"
                onChange={onGeneralInputChange}
              />
            </div>
          </ShowIf>

          <div>
            <TextField
              required
              name="lastName"
              value={generalInputs.lastName}
              label="Nom"
              onChange={onGeneralInputChange}
            />
          </div>

          <ShowIf
            condition={editMode || generalInputs.address?.formattedAddress}
          >
            <div>
              <GooglePlaceAutoComplete
                required
                name="address"
                label="Adresse"
                placeholder=""
                value={generalInputs.address}
                onChange={onGeneralInputChange}
              />
            </div>
          </ShowIf>

          {phones.map((phone, index) => (
            <ShowIf
              key={`phone-${index}`}
              condition={editMode || phone.name || phone.number}
            >
              <FieldsContainer>
                <Typography component="p" variant="caption">
                  Téléphone {index + 1}
                </Typography>

                <ShowIf condition={editMode || phone.name}>
                  <div>
                    <TextField
                      name={`phone-name-${index}`}
                      value={phone.name}
                      label={`Nom téléphone ${index + 1}`}
                      onChange={onPhoneChange}
                    />
                  </div>
                </ShowIf>

                <ShowIf condition={editMode || phone.number}>
                  <div>
                    <TextField
                      name={`phone-number-${index}`}
                      value={phone.number}
                      type="tel"
                      error={Boolean(phone.name) && !Boolean(phone.number)}
                      helperText={
                        Boolean(phone.name) && !Boolean(phone.number)
                          ? 'Un nom a été renseigné sans numéro de téléphone'
                          : null
                      }
                      InputProps={{
                        endAdornment:
                          !editMode && phone.number ? (
                            <InputAdornment position="end">
                              <IconButton
                                component="a"
                                href={`tel:${phone.number}`}
                              >
                                <PhoneIcon />
                              </IconButton>
                            </InputAdornment>
                          ) : null,
                      }}
                      label={`Numéro téléphone ${index + 1}`}
                      onChange={onPhoneChange}
                    />
                  </div>
                </ShowIf>
              </FieldsContainer>
            </ShowIf>
          ))}

          <ShowIf
            condition={
              editMode || generalInputs.email1Name || generalInputs.email1Value
            }
          >
            <FieldsContainer>
              <Typography component="p" variant="caption">
                E-mail 1
              </Typography>

              <ShowIf condition={editMode || generalInputs.email1Name}>
                <div>
                  <TextField
                    name="email1Name"
                    value={generalInputs.email1Name}
                    label="Nom e-mail 1"
                    onChange={onGeneralInputChange}
                  />
                </div>
              </ShowIf>

              <ShowIf condition={editMode || generalInputs.email1Value}>
                <div>
                  <TextField
                    name="email1Value"
                    type="email"
                    value={generalInputs.email1Value}
                    label="E-mail 1"
                    error={
                      Boolean(generalInputs.email1Name) &&
                      !Boolean(generalInputs.email1Value)
                    }
                    helperText={
                      Boolean(generalInputs.email1Name) &&
                      !Boolean(generalInputs.email1Value)
                        ? 'Un nom a été renseigné sans e-mail'
                        : null
                    }
                    InputProps={{
                      endAdornment:
                        !editMode && generalInputs.email1Value ? (
                          <InputAdornment position="end">
                            <IconButton
                              component="a"
                              href={`mailto:${generalInputs.email1Value}`}
                            >
                              <EmailIcon />
                            </IconButton>
                          </InputAdornment>
                        ) : null,
                    }}
                    onChange={onGeneralInputChange}
                  />
                </div>
              </ShowIf>
            </FieldsContainer>
          </ShowIf>

          <ShowIf condition={editMode || generalInputs.speciality}>
            <div>
              <TextField
                name="speciality"
                value={generalInputs.speciality}
                label="Spécialité"
                onChange={onGeneralInputChange}
              />
            </div>
          </ShowIf>

          <ShowIf condition={editMode || generalInputs.siret}>
            <div>
              <TextField
                name="siret"
                value={generalInputs.siret}
                label="SIRET"
                onChange={onGeneralInputChange}
              />
            </div>
          </ShowIf>

          <ShowIf condition={editMode || generalInputs.adeli}>
            <div>
              <TextField
                name="adeli"
                value={generalInputs.adeli}
                label="ADELI"
                onChange={onGeneralInputChange}
              />
            </div>
          </ShowIf>

          <ShowIf condition={editMode || generalInputs.CPS}>
            <ImageUpload
              name="CPS"
              title="CPS"
              value={generalInputs.CPS}
              editMode={editMode}
              emptyText="Aucune image CPS"
              onChange={onCPSUploadChange}
              onDelete={onGeneralInputChange}
              multiple={true}
            />
          </ShowIf>

          <ShowIf condition={editMode || generalInputs.unlockCode}>
            <div>
              <TextField
                name="unlockCode"
                value={generalInputs.unlockCode}
                label="Code de déblocage"
                onChange={onGeneralInputChange}
              />
            </div>
          </ShowIf>

          <ShowIf condition={editMode || generalInputs.RIB}>
            <div>
              <TextField
                name="RIB"
                value={generalInputs.RIB}
                label="RIB"
                onChange={onGeneralInputChange}
              />
            </div>
          </ShowIf>
          {!providerId ||
          !isSuperAdmin ||
          initialProvider?.archived ||
          readOnly ? null : (
            <ArchiveProvider
              className={classes.archiveProvider}
              loading={loading}
              onArchive={onArchive}
            />
          )}
        </fieldset>
      </div>

      {editMode ? (
        <div className={classes.submitContainer}>
          <ProgressButton
            loading={loading}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              !generalInputs.lastName ||
              typeof generalInputs.CPS?.progress !== 'undefined'
            }
            onClick={onSubmit}
          >
            {type === 'update' ? 'Sauvegarder' : 'Créer'}
          </ProgressButton>
        </div>
      ) : null}
    </div>
  );
};

export default ProviderForm;
